import React from "react";
import { Firebase, fbAuth } from "../../services/firebaseService/connection";
import { connect } from "react-redux";
import {
  fbRegisterAdmin,
  fbLogoutUser,
} from "../../services/firebaseService/endPoints/user";
import LoadingModal from "../commons/LoadingModal";
import { message } from "antd";

// import "react-phone-number-input/style.css";

import MobileVerification from "../commons/MobileVerification";
import { startUpdateUser } from "../../redux/actions/user";

class AdminRegister extends React.Component {
  constructor() {
    super();
    this.state = {
      phoneNumber: "",

      showAlert: { show: false, type: "success", message: "" },

      linkVerified: undefined,
      isLoading: false,
      loadingDocument: true,
    };
  }

  handleVerifyOtp = function () {
    this.setState({ showAlert: { show: false } });

    const prevUser = fbAuth.currentUser;
    if (prevUser) {
      this.setState({ isLoading: true });
      this.state.confirmResult
        .confirm(this.state.otp)
        .then((result) => {
          let email = prevUser.email;
          prevUser.delete().then(() => {
            fbRegisterAdmin(email, result.additionalUserInfo.isNewUser)
              .then(() => {
                // this.props.dispatch(startUpdateUser())
                // this.props.dispatch(loadingUser())
                window.indexedDB.deleteDatabase("chant").onsuccess = () => {
                  this.setState({ isLoading: false });
                  this.props.dispatch(startUpdateUser());
                  this.props.dispatch({ type: "LOADING_USER" });
                  this.props.history.replace("/admin/content");
                };

                // window.location.reload()
              })
              .catch((err) => {
                this.setState({
                  showAlert: {
                    show: true,
                    type: "error",
                    message: err.message,
                  },
                  isLoading: false,
                });
              });
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            showAlert: {
              show: true,
              type: "error",
              message:
                "The verification code you entered is incorrect. Please check the code and try again. If you entered an incorrect phone number you can edit the number.",
            },
          });
        });
    } else {
      this.setState({
        showAlert: {
          show: true,
          type: "error",
          message: "Email authentication failed",
        },
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <>
        <LoadingModal
          isLoading={this.state.isLoading || this.state.loadingDocument}
        />
        {!this.state.linkVerified &&
          !this.state.isLoading &&
          this.state.showAlert.show && (
            <div className="container mt-5">
              <div className="alert alert-danger" role="alert">
                {this.state.showAlert.message}
              </div>
            </div>
          )}

        {this.state.linkVerified && (
          <MobileVerification
            history={this.props.history}
            handleVerifyOtp={this.handleVerifyOtp}
            phoneNumber={this.state.phoneNumber}
          />
        )}
      </>
    );
  }

  getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    let results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  componentDidUpdate() {
    if (!this.state.isLoading) {
      if (
        this.state.loadingDocument === false &&
        this.state.linkVerified === undefined
      ) {
        this.setState({ isLoading: true });
        if (Firebase.auth().isSignInWithEmailLink(window.location.href)) {
          Firebase.auth()
            .signInWithEmailLink(
              this.props.match.params.email,
              window.location.href
            )
            .then((result) => {
              const phoneNumber = this.getUrlParameter("phoneNumber");
              this.setState({
                linkVerified: true,
                isLoading: false,
                phoneNumber: phoneNumber ? `+${phoneNumber}` : "",
              });
            })
            .catch((err) => {
              this.setState({
                linkVerified: false,
                showAlert: { show: true, type: "error", message: err.message },
                isLoading: false,
              });
            });
        } else {
          this.setState({
            linkVerified: false,
            showAlert: { show: true, type: "error", message: "Invalid link" },
            isLoading: false,
          });
        }

        message.success(
          "Do not refresh or close the window until the registration is complete",
          10
        );
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.loadingDocument && props.user.isAuthenticated === false) {
      return { loadingDocument: false };
    }
  }

  componentDidMount() {
    // this.props.dispatch(startLogoutUser())
    if (this.props.user.isAuthenticated) {
      fbLogoutUser();
    }
  }

  componentWillUnmount() {
    message.destroy();
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AdminRegister);
