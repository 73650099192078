import { Popconfirm, Table } from "antd";
import moment from "moment";
import React from "react";
import {
  getDefaultClub,
  getGroups,
} from "../../../services/firebaseService/endPoints/clubAdmin/groups";
import {
  deletePolls,
  fbGetPolls,
  postLive,
} from "../../../services/firebaseService/endPoints/clubAdmin/polls";
import { connect } from "react-redux";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";

class Polls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tableData: [],
      clubId: "",
      allGroups: [],
    };
  }

  postLive = (id) => {
    this.setState({
      isLoading: true,
    });

    postLive(id).then(() => {
      fbGetPolls().then((polls) => {
        this.setState({ tableData: polls, isLoading: false });
      });
    });
  };

  handleEdit = (row) => {
    this.props.history.push(
      `/clubadmin/polls/edit/${this.state.clubId}_polls/${row.id}`
    );
  };

  handleDelete = (row) => {
    this.setState({
      isLoading: true,
    });

    deletePolls(row.id).then(() => {
      fbGetPolls().then((polls) => {
        this.setState({ tableData: polls, isLoading: false });
      });
    });
  };

  render() {
    const columns = [
      {
        title: "Action",
        width: window.screen.width > 500 ? 150 : 100,
        className: "header-white",
        key: "action",
        render: (row) => {
          const currentTime = moment().unix();
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {row.isActive && currentTime <= row.closesOn ? (
                <span style={{ color: "red" }}>Live</span>
              ) : currentTime > row.closesOn ? (
                <span>Closed</span>
              ) : (
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    this.postLive(row.id);
                  }}
                  onCancel={() => {}}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger mt-3 mb-2"
                  >
                    Post Live
                  </button>
                </Popconfirm>
              )}
              {(!row.isActive || currentTime <= row.closesOn) && (
                <>
                  <i
                    className="fa fa-pencil cursor-pointer"
                    onClick={() => {
                      this.handleEdit(row);
                    }}
                  />
                  <Popconfirm
                    title="Are you sure?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      this.handleDelete(row);
                    }}
                    onCancel={() => {}}
                  >
                    <i className="fa fa-trash cursor-pointer" />
                  </Popconfirm>
                </>
              )}
            </div>
          );
        },
      },
      {
        title: "Date Posted",
        key: "postedOn",
        className: "header-white",
        dataIndex: "postedOn",
        render: (data) => (data ? moment.unix(data).format("MM/DD/YY") : ""),
      },
      {
        title: "Time Remaining",
        key: "timeRemaining",
        className: "header-white",
        dataIndex: "closesOn",
        render: (data) => {
          let timeRemaining = data - moment().unix();
          if (!!!data || timeRemaining < 0) {
            return "";
          }

          if (timeRemaining < 60) {
            return `${timeRemaining} sec`;
          } else if (timeRemaining < 60 * 60) {
            timeRemaining = Math.round(timeRemaining / 60.0);
            return `${timeRemaining} minutes`;
          } else if (timeRemaining < 24 * 60 * 60) {
            timeRemaining = Math.round(timeRemaining / (60.0 * 60.0));
            return `${timeRemaining} hours`;
          } else {
            timeRemaining = Math.round(timeRemaining / (24 * 60 * 60));
            return `${timeRemaining} days`;
          }
        },
      },
      {
        title: "Groups",
        key: "groups",
        className: "header-white",
        dataIndex: "groups",
        render: (groups) => {
          return (
            <ul style={{ listStyle: "none" }}>
              {groups.map((param) => {
                let group = this.state.allGroups.find(
                  (group) => group.id === param
                );
                if (group) {
                  return <li>{group.groupName}</li>;
                } else {
                  return <li>{param}</li>;
                }
              })}
            </ul>
          );
        },
      },
      {
        title: "Question",
        key: "question",
        className: "header-white",
        dataIndex: "desc",
        render: (data) => data || "",
      },
      {
        title: "Responses(%)",
        key: "response",
        className: "header-white",
        render: (data) => {
          let currentTime = moment().unix();
          if (data.isActive || currentTime > data.closesOn) {
            return (
              <ul
                style={{
                  listStyle: "none",
                }}
              >
                {Object.entries(data.stats).map(([key, value]) => {
                  return <li>{`${key}   ${value}%`}</li>;
                })}
              </ul>
            );
          } else {
            return (
              <ul
                style={{
                  listStyle: "none",
                }}
              >
                {Array.from({ length: 5 }, (_, index) => index)
                  .filter(
                    (index) =>
                      data[`op${index + 1}`] && data[`op${index + 1}`] !== null
                  )
                  .map((index) => {
                    return <li>{data[`op${index + 1}`].name}</li>;
                  })}
              </ul>
            );
          }
        },
      },
      {
        title: "#responses",
        key: "entries",
        className: "header-white",
        dataIndex: "noOfPolls",
        render: (entries) => entries || 0,
      },
      {
        title: "Response Rate(%)",
        key: "resRate",
        className: "header-white",
        render: (data) => {
          if (data.targetMembersCount === 0) {
            return 0;
          }

          return (data.noOfPolls * 100) / data.targetMembersCount;
        },
      },
      {
        title: "Sponsor",
        key: "sponsorName",
        className: "header-white",
        dataIndex: "sponsorName",
        render: (name) => name || "",
      },
    ];

    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div
        className="mx-auto"
        style={{ width: "95%", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            width: "100%",
            paddingTop: 30,
            display: "flex",
            flexDirection: "column",
            paddingBottom: 15,
          }}
        >
          <div className="">
            <ul
              className="nav nav-tabs"
              style={{ color: "#ffffff", position: "relative" }}
            >
              <li
                className="nav-item"
                style={{ borderBottom: `4px solid ${primaryColor}` }}
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="current"
                >
                  Polls
                </a>
              </li>
              <li
                className="ml-auto align-items-center collapsible-horizontal tab-menu"
                id="clubadmin-polls-utilities"
              >
                <a
                  class="icon"
                  style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                  onClick={() =>
                    switchCollapseHorizontal("clubadmin-polls-utilities")
                  }
                >
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm mr-1 .button-drop-shadow"
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: primaryColor || "#999999",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      this.props.history.push("/clubadmin/polls/create");
                    }}
                  >
                    Create New
                  </button>
                </div>
                <div className="show-for-mobile">
                  <div className="menu-list">
                    <div
                      style={{
                        backgroundColor: primaryColor || "#999999",
                        padding: "5px 10px",
                        color: "#000000",
                      }}
                      onClick={() => {
                        this.props.history.push("/clubadmin/polls/create");
                      }}
                    >
                      Create New
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <Table
          loading={this.state.isLoading}
          columns={columns}
          className={`table-backdrop mt-3 mb-3`}
          bodyStyle={{
            fontSize: window.screen.width > 500 ? "18px" : "12px",
            backgroundColor: "#ffffff",
            // boxShadow: "0px 3px 10px 1px",
          }}
          dataSource={this.state.tableData}
          scroll={{ x: "max-content" }}
          bordered
        />
      </div>
    );
  }

  componentDidMount() {
    fbGetPolls().then((polls) => {
      this.setState({ tableData: polls, isLoading: false });
    });

    let clubId = getDefaultClub();

    getGroups().then((groups) => {
      this.setState({ allGroups: groups, clubId });
    });
  }
}

const mapStateToProps = (state) => {
  let currentGroup = Object.keys(state.user.userRoles).find(
    (key) => state.user.userRoles[key] === "parentAdmin"
  );
  return {
    currentGroup,
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(Polls);
