import {
  fbGetGroup,
  fbGetAdminGroups,
} from "../../services/firebaseService/endPoints/user";
import { fbAuth } from "../../services/firebaseService/connection";
import {
  createDatabase,
  createSuperAdminDatabase,
} from "../../services/indexedDb/connection";
import logger from "../../utils/logger";

const superAdminNumbers = [
  "+14046681194",
  "+14702421278",
  "+12055099663",
  "+17323592218",
];

export const startUpdateUser = (role) => {
  return function (dispatch) {
    if (superAdminNumbers.includes(fbAuth.currentUser.phoneNumber)) {
      createSuperAdminDatabase().then((connection) => {
        dispatch({
          type: "UPDATE_SUPERADMIN",
          payload: connection,
        });
      });
    } else {
      fbGetAdminGroups()
        .then((groups) => {
          let profile = { ...fbAuth.currentUser };

          if (Object.keys(groups).length > 0) {
            let adminData = { allGroups: [] };
            let promises = [];
            let allGroups = Object.keys(groups);
            allGroups.forEach((group) => {
              promises.push(fbGetGroup(group));
            });

            Promise.all(promises).then((values) => {
              createDatabase(values.map((value) => value.id)).then(
                (connection) => {
                  dispatch({
                    type: "UPDATE_ADMIN_GROUPS",
                    payload: { groups: values, connection },
                  });
                  dispatch({
                    type: "UPDATE_ADMIN",
                    payload: { profile, groups },
                  });
                }
              );
            });
          } else {
            dispatch({ type: "UNAUTHORIZED_USER" });
          }
        })
        .catch((err) => {
          // no user found
          logger.error(err);
          dispatch({ type: "UNAUTHORIZED_USER" });
        });
    }
  };
};

export const loadingUser = () => {
  return {
    type: "LOADING_USER",
  };
};

export const disableLoginFlow = () => {
  return {
    type: "DISABLE_LOGIN_FLOW",
  };
};

export const logoutUser = () => {
  try {
    localStorage.clear();
    window.indexedDB.deleteDatabase("chant");
  } catch (e) {
    logger.error(e);
  }
  return {
    type: "LOGOUT_USER",
  };
};
