import React from "react";
import Header from "./Header";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/user";
import { fbAuth } from "../../services/firebaseService/connection";

import { startUpdateUser } from "../../redux/actions/user";

import { fbLogoutUser } from "../../services/firebaseService/endPoints/user";

import { message, Result, Button, Layout } from "antd";
import LoadingModal from "../commons/LoadingModal";

import ErrorBoundary from "./ErrorBoundary";
import {
  adminRoutes,
  superAdminRoutes,
  unauthenticatedRoutes,
  memberRoutes,
  commonRoutes,
  clubAdminRoutes,
} from "./Routes";
import updatePath from "../../redux/actions/appData/path";
import SideNav from "./SideNav";
import { dashboardChange } from "../../redux/actions/adminData";
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import GettingStarted from "../gettingStarted/GettingStarted";
import { isMobile } from "../../utils/helper";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      msg: "",
      loadingUser: true,
      width: window.innerWidth,
      expanded: true,
    };
  }

  getHeader = () => {
    let style = {
      position: this.state.width > 500 ? "fixed" : "relative",
      zIndex: 1000,
      width: "100%",
      marginTop: "-25px",
    };
    if (this.state.width > 500) {
      style.height = 80;
    }
    return (
      <div style={style}>
        {window.location.pathname.split("/").pop() !== "join" && (
          <Route
            path="/"
            render={(props) => (
              <Header
                {...props}
                toggleSideNav={() => {
                  this.setState({
                    expanded: !this.state.expanded,
                  });
                }}
              />
            )}
          />
        )}
      </div>
    );
  };

  render() {
    const pathName = window.location.pathname;
    const isRegForm = /\/[A-Za-z0-9%]*\/join/.test(pathName);

    if (this.props.user.isAuthenticated === "unauthorized") {
      // throw new Error("403")
      setTimeout(() => {
        fbLogoutUser();
      }, 0);
      return (
        <Result
          status="403"
          title="403"
          subTitle="You are not authorized to access this page"
          extra={
            <Button type="primary" onClick={fbLogoutUser}>
              Go Back
            </Button>
          }
        />
      );
      // this.props.dispatch(applicationError({}))
    } else if (this.props.user.isAuthenticated === undefined && !isRegForm) {
      return (
        <div>
          <LoadingModal loading={true} />
        </div>
      );
    }
    let routes = [];
    if (this.props.user.isAuthenticated && this.props.user.role === "admin")
      routes = adminRoutes;
    else if (
      this.props.user.isAuthenticated &&
      this.props.user.role === "super-admin"
    )
      routes = superAdminRoutes;
    else if (!this.props.user.isAuthenticated) routes = unauthenticatedRoutes;
    else if (this.props.user.isAuthenticated) routes = memberRoutes;

    let dashboard =
      this.props.dashboard || window.location.pathname.split("/")[1];

    if (
      this.props.user.isAuthenticated &&
      this.props.user.userRoles &&
      Object.values(this.props.user.userRoles).includes("parentAdmin")
    )
      routes = [...routes, ...clubAdminRoutes];
    return (
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        {/* {this.state.loadingUser && <LoadingModal />} */}
        <BrowserRouter>
          <Layout style={{ backgroundColor: "#ffffff" }}>
            <ErrorBoundary>
              {this.getHeader()}

              <Layout
                style={{
                  minHeight: "100vh",
                  flexDirection: "row",
                  marginTop: this.props.user.isAuthenticated
                    ? this.state.width > 500
                      ? 53
                      : 4
                    : 0,
                  backgroundColor: "#ffffff",
                }}
              >
                <Route
                  path="/"
                  render={(props) =>
                    this.props.user.isAuthenticated ? (
                      dashboard === "superadmin" ||
                      !dashboard ||
                      this.props.user.role === "super-admin" ? (
                        <></>
                      ) : (
                        <SideNav
                          {...props}
                          expanded={this.state.expanded}
                          handleNavCollapse={(collapsed) => {
                            this.setState({
                              expanded: !collapsed,
                            });
                          }}
                        />
                      )
                    ) : (
                      <></>
                    )
                  }
                />

                <Layout
                  style={{
                    marginLeft: !this.props.user.isAuthenticated
                      ? 0
                      : dashboard === "superadmin" ||
                          !dashboard ||
                          this.props.user.role === "super-admin"
                        ? 0
                        : !this.state.expanded
                          ? this.state.width < 500
                            ? 0
                            : 85
                          : 205,
                  }}
                >
                  <div
                    style={{
                      width: !this.props.user.isAuthenticated
                        ? "100vw"
                        : dashboard === "superadmin" ||
                            !dashboard ||
                            this.props.user.role === "super-admin"
                          ? "100vw"
                          : `calc(100vw - ${!this.state.expanded ? (this.state.width < 500 ? "0px" : "80px") : "200px"})`,
                      minHeight: "100vh",
                      backgroundColor: !this.props.user.isAuthenticated
                        ? "#343434"
                        : "#cbcccb",
                    }}
                  >
                    <Switch>
                      {routes.map((element) => {
                        return <Route {...element} />;
                      })}
                      {commonRoutes.map((element) => {
                        return <Route {...element} />;
                      })}
                    </Switch>
                  </div>
                </Layout>
              </Layout>
              {this.props.user.isAuthenticated &&
                this.props.user.role === "admin" &&
                !isMobile() && <GettingStarted />}
            </ErrorBoundary>
          </Layout>
        </BrowserRouter>
      </div>
    );
  }

  componentDidUpdate() {
    const pathName = window.location.pathname;
    const isRegForm = /\/[A-Za-z0-9%]*\/join/.test(pathName);
    if (this.state.loadingUser && this.props.user) {
      this.setState({ loadingUser: false });
    }

    if (isRegForm && this.state.loadingUser) {
      this.setState({ loadingUser: false });
    }

    let dashboard = this.props.dashboard || pathName.split("/")[1];
    if (dashboard !== this.props.dashboard) {
      this.props.dispatch(dashboardChange(dashboard));
    }
  }

  callServices = async () => {
    // let iframe = document.createElement('iframe');
    // iframe.src = "https://directory.chant.fan";
    // iframe.width = 0;
    // iframe.height = 0;
    // document.body.appendChild(iframe);

    // iframe.contentWindow.postMessage("SEND_TOKEN");
    let that = this;

    let c_name = "auth_token";

    let token = document.cookie.split("; ").reduce((r, v) => {
      const parts = v.split("=");
      return parts[0] === c_name ? decodeURIComponent(parts[1]) : r;
    }, "");
    document.cookie = `auth_token=${token}; domain=.chant.fan; expires=Thu, 01 Jan 1970 00:00:00; path=/`;
    if (!fbAuth.currentUser && token) {
      try {
        await signInWithCustomToken(fbAuth, token).then((_) => {
          that.props.dispatch(startUpdateUser());
        });
      } finally {
        if (this.props.history) {
          this.props.history.replace("/admin/directory/groupListing");
        } else {
          window.location.pathname = "/admin/directory/groupListing";
        }
      }
    }

    onAuthStateChanged(fbAuth, (user) => {
      if (!this.props.user.disableLoginFlow) {
        if (this.props.user.isAuthenticated === undefined) {
          if (user) {
            if (user.email || user.phoneNumber) {
              this.props.dispatch(startUpdateUser());
            }
          } else {
            this.props.dispatch(logoutUser());
          }
        } else {
          if (user) {
            if (
              (user.email || user.phoneNumber) &&
              window.location.pathname !== "/register"
            ) {
              // this.setState({ loadingUser: true })
              this.props.dispatch(startUpdateUser());
            }
          } else {
            if (this.props.user.isAuthenticated) {
              this.props.dispatch(logoutUser());
            }
          }
        }
      }
    });
  };

  componentDidMount() {
    const pathName = window.location.pathname;
    const isRegForm = /\/[A-Za-z0-9%]*\/join/.test(pathName);

    if (isRegForm) {
      return;
    }

    const isAdminPath = /\/admin\/[A-Za-z0-9%]*/.test(pathName);
    if (isAdminPath) {
      this.props.dispatch(updatePath(pathName));
    }

    this.callServices();

    window.addEventListener("offline", () => {
      let msg = message.warning("Your device lost its internet connection", 0);
      this.setState({ msg });
    });

    window.addEventListener("online", () => {
      if (this.state.msg) {
        this.state.msg();
        message.success("Your device is connected to the internet");
        this.setState({ msg: "" });
      }
    });

    window.onresize = () => {
      this.setState({
        width: window.innerWidth,
      });
    };
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    dashboard: state.adminData.dashboardType,
  };
};

export default connect(mapStateToProps)(App);
