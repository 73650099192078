import { Table, Tooltip } from "antd";
import React from "react";
import {
  fetchGroupsForReview,
  updateReviewStatus,
} from "../../../services/firebaseService/endPoints/group";
import moment from "moment";

const SORT_ORDER = ["Review", "Rejected", "Live"];

class DirectoryReview extends React.Component {
  state = {
    tableData: [],
    isLoading: false,
    groupIdForView: "",
  };

  fetchData = () => {
    fetchGroupsForReview().then((groups) => {
      groups.sort(
        (a, b) =>
          SORT_ORDER.indexOf(a.reviewStatus) -
          SORT_ORDER.indexOf(b.reviewStatus)
      );
      this.setState({
        tableData: groups,
        isLoading: false,
      });
    });
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.fetchData();
    this.changeHeaderColor();
  }

  handleChangeReviewStatus = (groupId, status) => {
    this.setState({
      isLoading: true,
    });

    updateReviewStatus(groupId, status).then(() => {
      this.fetchData();
    });
  };

  changeHeaderColor = () => {
    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = "#000000";
    });
  };

  render() {
    const columns = [
      {
        title: "",
        width: 150,
        render: (data) => (
          <div
            className="d-flex justify-content-center cursor-pointer"
            style={{ gap: 20 }}
          >
            <Tooltip title="View">
              <a
                href={`https://directory.chant.fan/details/${data.id}`}
                target="#"
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </a>
            </Tooltip>
          </div>
        ),
      },
      {
        title: "Review Status",
        key: "reviewStatus",
        render: (data) => {
          return (
            <div>
              <select
                value={
                  data.isLive === undefined
                    ? "Review"
                    : data.isLive
                      ? "Live"
                      : "Rejected"
                }
                onChange={(e) =>
                  this.handleChangeReviewStatus(data.id, e.target.value)
                }
                style={{ width: 80 }}
              >
                <option key={"review"} value={"Review"}>
                  {"Needs Review"}
                </option>
                <option key={"reject"} value={"Rejected"}>
                  {"Rejected"}
                </option>
                <option key={"live"} value={"Live"}>
                  {"Live"}
                </option>
              </select>
            </div>
          );
        },
        className: "header-white",
      },
      {
        title: "Group",
        key: "groupName",
        dataIndex: "groupName",
        className: "header-white",
        render: (status) => <span>{status}</span>,
      },
      {
        title: "Club",
        key: "clubName",
        dataIndex: "clubName",
        className: "header-white",
        render: (clubName) => <span>{clubName}</span>,
      },
      {
        title: "League",
        key: "leagueName",
        dataIndex: "leagueName",
        className: "header-white",
        render: (leagueName) => <span>{leagueName}</span>,
      },
      {
        title: "City",
        key: "city",
        dataIndex: "city",
        className: "header-white",
        render: (city) => <span>{city}</span>,
      },
      {
        title: "Country",
        key: "country",
        dataIndex: "country",
        className: "header-white",
        render: (country) => <span>{country}</span>,
      },
      {
        title: "Size",
        key: "size",
        className: "header-white",
        render: (rec) => (
          <span>{((rec.websiteData || {}).general || {}).size}</span>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        className: "header-white",
        render: (status) => <span>{status}</span>,
      },
      {
        title: "Account Creator",
        key: "accountCreator",
        className: "header-white",
        render: (rec) => <span>{(rec.creatorData || {}).name}</span>,
      },
      {
        title: "Email",
        key: "accountCreatorEmail",
        className: "header-white",
        render: (rec) => (
          <a href={`mailto:${(rec.creatorData || {}).email}`}>
            {(rec.creatorData || {}).email}
          </a>
        ),
      },
      {
        title: "Created",
        key: "createdOn",
        dataIndex: "createdOn",
        className: "header-white",
        render: (createdOn) => (
          <span>{moment.unix(createdOn).format("MMM DD YYYY")}</span>
        ),
      },
      {
        title: "Last Updated",
        key: "updatedOn",
        className: "header-white",
        render: (rec) => (
          <span>
            {moment.unix(rec?.websiteData?.updatedOn).format("MMM DD YYYY")}
          </span>
        ),
      },
      {
        title: "Website",
        key: "webiste",
        className: "header-white",
        render: (rec) => (
          <span>{((rec.websiteData || {}).contact || {}).website}</span>
        ),
      },
      {
        title: "Instagram",
        key: "insta",
        className: "header-white",
        render: (rec) => (
          <span>{((rec.websiteData || {}).social || {}).insta}</span>
        ),
      },
    ];
    return (
      <div
        className="mx-auto mt-3 py-5"
        style={{ width: "95%", display: "flex", flexDirection: "column" }}
      >
        <Table
          bordered
          columns={columns}
          className="table-backdrop mb-3"
          bodyStyle={{
            backgroundColor: "#ffffff",
          }}
          dataSource={this.state.tableData}
          loading={this.state.isLoading}
          rowKey="id"
          size={"small"}
          scroll={{ x: true }}
        />
      </div>
    );
  }
}

export default DirectoryReview;
