import React from "react";
import { Tooltip } from "antd";

export function FSelect(props) {
  return (
    <div
      className={`form-group  ${props.display || "row"} ${props.containerClassname}`}
    >
      {props.label && (
        <div className={props.display !== "col" ? "col-4" : ""}>
          <label
            className={props.textCenter ? "text-center" : ""}
            style={{ marginBottom: 0 }}
          >
            {props.label}
            {props.required && <span style={{ color: "red" }}>*</span>}
          </label>

          {props.tooltip && (
            <Tooltip
              placement="topLeft"
              title={() => (
                <div
                  style={{
                    height: "fit-content",
                    width: "400px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {props.tooltip}
                </div>
              )}
            >
              <span className="border-0">
                &nbsp;<i className="fa fa-question-circle-o"></i>
              </span>
            </Tooltip>
          )}
        </div>
      )}
      <select
        disabled={props.disabled}
        required={props.required}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        tabIndex={props.tabIndex || 0}
        className={`form-control form-control-sm ${props.display !== "col" && props.label && "col-8"}`}
        style={props.style || {}}
        defaultValue={props.defaultValue}
      >
        <option key="0" value={""}>
          {props.placeholder}
        </option>

        {props.dataList.length > 0 &&
          props.dataList.map((data) => {
            if (props.listBuilder) {
              return props.listBuilder(data);
            } else {
              return (
                <option
                  key={props.dataOnlyValue ? data : data[props.dataKey]}
                  value={props.dataOnlyValue ? data : data[props.dataKey]}
                >
                  {props.dataOnlyValue ? data : data[props.dataValue]}
                </option>
              );
            }
          })}
      </select>
      {props.bottomHint && (
        <div className="col">
          <div className="row" style={{ fontSize: 13 }}>
            <div className="col-md-4"></div>
            {props.bottomHint}
          </div>
        </div>
      )}
    </div>
  );
}
