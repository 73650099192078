import React from "react";
import "./membershipCard.css";
import { Upload, Icon, Popconfirm, Card } from "antd";
import FUpload from "../../commons/formFields/FUpload";

import { connect } from "react-redux";
import {
  fbUploadMembershipCard,
  fbDeleteMembershipCard,
} from "../../../services/firebaseService/endPoints/user";
import { startUpdateUser } from "../../../redux/actions/user";
import LoadingModal from "../../commons/LoadingModal";
import { fbSearchMember } from "../../../services/firebaseService/endPoints/admin/members";
import { CirclePicker } from "react-color";
import { db } from "../../../services/firebaseService/connection";
import { doc, updateDoc } from "@firebase/firestore";

class MembershipCard extends React.Component {
  constructor() {
    super();
    this.state = {
      membershipCard: {},
      membershipCardBackground: {},
      image: "",
      isLoading: false,
      memberCardTextColor: "",
    };
  }

  uploadAction = (file, fieldName) => {
    this.setState({
      isLoading: true,
      loadingTitle: "Uploading Membership Card",
    });
    fbUploadMembershipCard(file, this.props.currentGroup, fieldName).then(
      (downloadUrl) => {
        this.setState({
          action: "add",
          isLoading: false,
          [fieldName]: { uid: "-1", url: downloadUrl },
        });
        this.props.dispatch(startUpdateUser());
      }
    );
  };

  handleImageChange = ({ fileList }, field) => {
    if (fileList && fileList.length > 0) {
      this.uploadAction(fileList[0].originFileObj, field);
    } else {
      this.handleRemoveImage(field);
    }
  };

  handleTextColorChange = (val) => {
    this.setState({
      memberCardTextColor: val.hex,
    });

    updateDoc(doc(db, "group", this.props.currentGroup), {
      memberCardTextColor: val.hex,
    });
  };

  handleRemoveImage = (field) => {
    this.setState({
      isLoading: true,
      loadingTitle: "Removing Membership Card",
    });
    fbDeleteMembershipCard(this.props.currentGroup, field).then(() => {
      this.setState({ action: "remove" });
      this.props.dispatch(startUpdateUser());
    });
    // this.setState({ imageFile: {}, image: "" })
  };

  static getDerivedStateFromProps(props, state) {
    if (state.isLoading && state.action === "add") {
      if (props.membershipCard) {
        return { isLoading: false, action: "" };
      }
    }

    if (state.isLoading && state.action === "remove") {
      if (!props.membershipCard) {
        return { isLoading: false, action: "" };
      }
    }

    return null;
  }

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div className="col">
        <div style={{ width: "95%", padding: "30px 16px" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "#ffffff",
              }}
            >
              <a>Member Card</a>
            </li>
          </ul>
          <div
            className="container pt-3 row mx-auto custom-upload"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <LoadingModal
              loading={this.state.isLoading}
              title={this.state.loadingTitle}
            />
            {
              <>
                {
                  <div>
                    <p style={{ fontSize: "15px", color: "rgb(85, 85, 85)" }}>
                      Chant offers a digital membership card so your group no
                      longer needs to spend money printing physical cards. Alert
                      your partners to accept the card for any discounts the
                      group has negotiated.
                    </p>
                    <p style={{ fontSize: "15px", color: "rgb(85, 85, 85)" }}>
                      Use one option below.
                    </p>
                    <div className="form-group row">
                      <label
                        className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                        style={{ fontFamily: "Open Sans" }}
                      >
                        Chant
                      </label>
                      <CirclePicker
                        colors={["#000000", "#FFFFFF"]}
                        width="220px"
                        triangle={"hide"}
                        className="color-picker-border"
                        onChange={this.handleTextColorChange}
                        color={this.state.memberCardTextColor}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: 35,
                        fontWeight: "bold",
                        color: "#000000",
                        fontSize: 17,
                      }}
                    >
                      Option #1: Default card
                    </div>
                    <p style={{ fontSize: "15px", color: "rgb(85, 85, 85)" }}>
                      No action needed. Member and group details (name, photo,
                      member #, package, expiration, group name, logo) will
                      appear on a default background using your group’s color.
                    </p>

                    <div
                      style={{
                        marginTop: 35,
                        fontWeight: "bold",
                        color: "#000000",
                        fontSize: 17,
                      }}
                    >
                      Option #2: Upload custom background
                    </div>
                    <p
                      style={{
                        fontSize: "15px",
                        color: "rgb(85, 85, 85)",
                        marginBottom: 0,
                      }}
                    >
                      Upload a transparent background image (png file) or
                      background using your group color (jpg file). Member/Group
                      details will appear overlaid on the background image.
                      Background should be designed to ensure the custom details
                      appear clearly.
                    </p>
                    <FUpload
                      label=""
                      action={(file) => {
                        this.uploadAction(file, "membershipCardBackground");
                      }}
                      fileList={
                        this.state.membershipCardBackground.url
                          ? [this.state.membershipCardBackground]
                          : []
                      }
                      onChange={(e) =>
                        this.handleImageChange(e, "membershipCardBackground")
                      }
                      membershipCard={true}
                      display="col"
                      style={{ paddingLeft: 0 }}
                      isAvatar="false"
                      aspectRatio={16 / 9}
                    />

                    <div
                      style={{
                        marginTop: 35,
                        fontWeight: "bold",
                        color: "#000000",
                        fontSize: 17,
                      }}
                    >
                      Option #3: Upload custom card
                    </div>
                    <p
                      style={{
                        fontSize: "15px",
                        color: "rgb(85, 85, 85)",
                        marginBottom: 0,
                      }}
                    >
                      Card displayed as uploaded. No member/group details
                      overlaid.
                    </p>
                    <FUpload
                      label=""
                      action={(file) =>
                        this.uploadAction(file, "membershipCard")
                      }
                      fileList={
                        this.state.membershipCard.url
                          ? [this.state.membershipCard]
                          : []
                      }
                      onChange={(e) =>
                        this.handleImageChange(e, "membershipCard")
                      }
                      membershipCard={true}
                      display="col"
                      style={{ paddingLeft: 0 }}
                      isAvatar="false"
                      aspectRatio={16 / 9}
                    />
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    let groupData = this.props.adminData.data;
    let { membershipCard, membershipCardBackground } = groupData;
    this.setState({
      membershipCard: { uid: "-1", url: membershipCard },
      membershipCardBackground: { uid: "-2", url: membershipCardBackground },
    });
  }

  componentDidUpdate(prepProps) {
    if (
      prepProps.currentGroup !== this.props.currentGroup ||
      JSON.stringify(prepProps.adminData) !==
        JSON.stringify(this.props.adminData)
    ) {
      let groupData = this.props.adminData.data;
      let { membershipCard, membershipCardBackground } = groupData;
      this.setState({
        membershipCard: { uid: "-1", url: membershipCard },
        membershipCardBackground: { uid: "-2", url: membershipCardBackground },
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contents: state.contents,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    membershipCard:
      state.adminData.allGroups[state.adminData.currentGroup].data
        .membershipCard,
    membershipCardBackground:
      state.adminData.allGroups[state.adminData.currentGroup].data
        .membershipCardBackground,
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(MembershipCard);
