import moment from "moment";
import { db, storage } from "../../connection";
import { getDefaultClub } from "./groups";
import { fbGetClubAdminMembers } from "./members";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

export function createGiveaway(
  data,
  itemImage,
  proceedsSupportImage,
  donatedByImage,
) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = doc(
      collection(db, "giveaways", `${clubId}_club_giveaways`, "events"),
    );
    if (itemImage) {
      let imageUrl = await uploadItemImage(itemImage, clubId, docRef.id);
      data.itemImage = imageUrl || "";
    }

    if (proceedsSupportImage) {
      let imageUrl = await uploadProceedsImage(
        proceedsSupportImage,
        clubId,
        docRef.id,
      );
      data.proceedsSupportImage = imageUrl || "";
    }

    if (donatedByImage) {
      let imageUrl = await uploadDonatedByImage(
        donatedByImage,
        clubId,
        docRef.id,
      );
      data.donatedByImage = imageUrl || "";
    }

    await setDoc(
      docRef,
      {
        ...data,
        isDraft: true,
      },
      {
        merge: true,
      },
    )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateGiveaway(
  data,
  itemImage,
  proceedsSupportImage,
  donatedByImage,
  id,
) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = doc(db, "giveaways", `${clubId}_club_giveaways`, "events", id);
    if (itemImage) {
      let imageUrl = await uploadItemImage(itemImage, clubId, docRef.id);
      data.itemImage = imageUrl || "";
    }

    if (proceedsSupportImage) {
      let imageUrl = await uploadProceedsImage(
        proceedsSupportImage,
        clubId,
        docRef.id,
      );
      data.proceedsSupportImage = imageUrl || "";
    }

    if (donatedByImage) {
      let imageUrl = await uploadDonatedByImage(
        donatedByImage,
        clubId,
        docRef.id,
      );
      data.donatedByImage = imageUrl || "";
    }

    await setDoc(
      docRef,
      {
        ...data,
      },
      {
        merge: true,
      },
    )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function uploadItemImage(image, id, ref) {
  let path = `/giveaways/${id}/${ref}/itemImage`;
  return uploadImage(image, path);
}

function uploadProceedsImage(image, id, ref) {
  let path = `/giveaways/${id}/${ref}/proceedsImage`;
  return uploadImage(image, path);
}

function uploadDonatedByImage(image, id, ref) {
  let path = `/giveaways/${id}/${ref}/donatedByImage`;
  return uploadImage(image, path);
}

async function uploadImage(image, path) {
  let storageRef = storage.ref(path);
  let url = await storageRef
    .put(image)
    .then((snap) => {
      return snap.ref.getDownloadURL();
    })
    .then((url) => url);
  return url;
}

export async function postLive(id) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = db
      .collection("giveaways")
      .doc(`${clubId}_club_giveaways`)
      .collection("events")
      .doc(id);
    await docRef
      .update({
        isDraft: false,
        isLive: true,
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject({ err });
      });
  });
}

export async function sendWarning(id) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = db
      .collection("giveaways")
      .doc(`${clubId}_club_giveaways`)
      .collection("events")
      .doc(id);
    await docRef
      .update({
        isWarningDone: true,
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject({ err });
      });
  });
}

export async function closeEntries(id) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = db
      .collection("giveaways")
      .doc(`${clubId}_club_giveaways`)
      .collection("events")
      .doc(id);
    await docRef
      .update({
        isEntriesClosed: true,
        isLive: false,
        eventDate: moment().add(1, "day").unix(),
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject({ err });
      });
  });
}

export async function selectWinner(id) {
  let clubId = getDefaultClub();
  let docRef = db
    .collection("giveaways")
    .doc(`${clubId}_club_giveaways`)
    .collection("events")
    .doc(id);
  let { winners, quantity } = await docRef.get().then((doc) => doc.data());
  let participants = await docRef
    .collection("participants")
    .get()
    .then((snap) => {
      let res = [];
      snap.docs.forEach((doc) => {
        res.push(doc.data());
      });
      return res;
    });

  let winner;
  if (participants.length === 0) {
    await docRef.update({ quantity: 0, winners: [] });
  }
  if (winners && Array.isArray(winners)) {
    if (winners.length < quantity) {
      let winnerIds = winners.map((winner) => winner.id);
      let filteredParticipants = participants.filter(
        (participant) => !winnerIds.includes(participant.id),
      );
      winner = fisherYatesShuffle(filteredParticipants)[0];
    }
  } else {
    winner = fisherYatesShuffle(participants)[0];
    winners = [];
  }

  if (winner) {
    let winners = [...winners, winner];
    await docRef.update({ winners });
  }
}

function fisherYatesShuffle(arr) {
  //Cloning to prevent changing original array
  let array = JSON.parse(JSON.stringify(arr));

  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export async function markPrizeDelivered(id) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = db
      .collection("giveaways")
      .doc(`${clubId}_club_giveaways`)
      .collection("events")
      .doc(id);
    await docRef
      .update({
        isCompleted: true,
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject({ err });
      });
  });
}

export async function deleteGiveaway(id) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = doc(db, "giveaways", `${clubId}_club_giveaways`, "events", id);
    deleteDoc(docRef)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject({ err });
      });
  });
}

export async function fbGetGiveaways(id) {
  let clubId = getDefaultClub();

  let giveaways = await getDocs(
    query(
      collection(db, "giveaways", `${clubId}_club_giveaways`, "events"),
      where("groupId", "==", id),
    ),
  ).then((snap) => {
    let result = [];
    snap.docs.forEach((doc) => {
      result.push({ ...doc.data(), id: doc.id });
    });
    return result;
  });
  return giveaways;
}

export async function fbGetGiveaway(id) {
  let clubId = getDefaultClub();

  return await getDocs(
    doc(db, "giveaways", `${clubId}_club_giveaways`, "events", id),
  ).then((doc) => {
    return { ...doc.data(), id: doc.id };
  });
}
