import React from "react";

export const SolidHeader = (color, width, height) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 1280 152`}
    >
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="1280"
        height="152"
        fill={color}
      />
    </svg>
  );
};

export const StripeHeader = (color, width, height, secondaryColor) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1280 152"
    >
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="1280"
        height="152"
        fill={color}
      />
      <rect
        id="Rectangle_67"
        data-name="Rectangle 67"
        width="25"
        height="152"
        transform="translate(1062)"
        fill={secondaryColor || "#b9a968"}
      />
      <rect
        id="Rectangle_68"
        data-name="Rectangle 68"
        width="25"
        height="152"
        transform="translate(1100)"
        fill={secondaryColor || "#b9a968"}
      />
    </svg>
  );
};

export const AngularStripes = (color, width, height) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1280 152"
    >
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="1280"
        height="152"
        fill={color}
      />
      <g id="Mask_Group_3" data-name="Mask Group 3">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M262.52,231.575,59.81,761.8H170.973l201.41-530.226Z"
          transform="translate(878 -310.946)"
          opacity="0.08"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M262.52,231.575,59.81,761.8H170.973l201.41-530.226Z"
          transform="translate(339 -310.946)"
          opacity="0.08"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M262.52,231.575,59.81,761.8H170.973l201.41-530.226Z"
          transform="translate(477 -315.946)"
          opacity="0.08"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M262.52,231.575,59.81,761.8H170.973l201.41-530.226Z"
          transform="translate(807 -310.946)"
          opacity="0.08"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M262.52,231.575,59.81,761.8H170.973l201.41-530.226Z"
          transform="translate(980 -310.946)"
          opacity="0.08"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M262.52,231.575,59.81,761.8H170.973l201.41-530.226Z"
          transform="translate(702 -310.946)"
          opacity="0.08"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M262.52,231.575,59.81,761.8H170.973l201.41-530.226Z"
          transform="translate(609 -310.946)"
          opacity="0.08"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M262.52,231.575,59.81,761.8H170.973l201.41-530.226Z"
          transform="translate(544 -310.946)"
          opacity="0.08"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M262.52,231.575,59.81,761.8h42.163l201.41-530.226Z"
          transform="translate(438 -310.946)"
          opacity="0.08"
        />
      </g>
    </svg>
  );
};

export const GroupNameAndStripes = (color, width, height) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1285 152"
    >
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="1280"
        height="152"
        fill={color}
      />
      <g
        id="Mask_Group_15"
        data-name="Mask Group 15"
        clip-path="url(#clip-path)"
      >
        <g
          id="Group_118"
          data-name="Group 118"
          transform="translate(0 -278)"
          opacity="0.272"
        >
          <path
            id="Path_516"
            data-name="Path 516"
            d="M645.652,0V7.387L657.213,0Z"
            transform="translate(272.609)"
            fill="#454545"
          />
          <path
            id="Path_517"
            data-name="Path 517"
            d="M690.8,0,645.652,28.844V36.5L701.3.941V0Z"
            transform="translate(272.608)"
            fill="#454545"
          />
          <path
            id="Path_518"
            data-name="Path 518"
            d="M645.652,58.367,701.3,22.81V17.151L645.652,52.708Z"
            transform="translate(272.609 7.242)"
            fill="#454545"
          />
          <path
            id="Path_519"
            data-name="Path 519"
            d="M645.652,78.244,701.3,42.688V39.02L645.652,74.576Z"
            transform="translate(272.609 16.475)"
            fill="#454545"
          />
          <path
            id="Path_520"
            data-name="Path 520"
            d="M645.652,96.445v1.678l.368-.236L701.3,62.568V60.889l-.367.236Z"
            transform="translate(272.609 25.709)"
            fill="#454545"
          />
          <path
            id="Path_521"
            data-name="Path 521"
            d="M684.783,409.208l55.652-35.556V340.11l-55.652,35.556Z"
            transform="translate(289.13 -23.921)"
            fill="#454545"
          />
          <path
            id="Path_522"
            data-name="Path 522"
            d="M684.783,432.917l55.652-35.556V365.809l-55.652,35.556Z"
            transform="translate(289.13 -25.273)"
            fill="#454545"
          />
          <path
            id="Path_523"
            data-name="Path 523"
            d="M684.783,456.625l55.652-35.556V391.51l-55.652,35.556Z"
            transform="translate(289.13 -26.562)"
            fill="#454545"
          />
          <path
            id="Path_524"
            data-name="Path 524"
            d="M684.783,480.333l55.652-35.556V417.211l-55.652,35.554Z"
            transform="translate(289.13 -27.79)"
            fill="#454545"
          />
          <path
            id="Path_525"
            data-name="Path 525"
            d="M684.783,504.043l55.652-35.556V442.91l-55.652,35.557Z"
            transform="translate(289.13 -28.958)"
            fill="#454545"
          />
          <path
            id="Path_526"
            data-name="Path 526"
            d="M684.783,527.751,740.434,492.2V468.61l-55.652,35.556Z"
            transform="translate(289.13 -73.746)"
            fill="#454545"
          />
          <path
            id="Path_527"
            data-name="Path 527"
            d="M684.783,551.46,740.434,515.9V494.311l-55.652,35.556Z"
            transform="translate(289.13 -62.894)"
            fill="#454545"
          />
          <path
            id="Path_528"
            data-name="Path 528"
            d="M684.783,575.168l55.652-35.555v-19.6l-55.652,35.556Z"
            transform="translate(289.13 -52.043)"
            fill="#454545"
          />
          <path
            id="Path_529"
            data-name="Path 529"
            d="M684.783,598.876l55.652-35.556v-17.61l-55.652,35.556Z"
            transform="translate(289.13 -41.192)"
            fill="#454545"
          />
          <path
            id="Path_530"
            data-name="Path 530"
            d="M684.783,606.968v5.1H701.24l39.2-25.04v-15.62Z"
            transform="translate(289.131 -30.341)"
            fill="#454545"
          />
          <path
            id="Path_531"
            data-name="Path 531"
            d="M719.392,601.22h6.431v-4.109Z"
            transform="translate(303.743 -19.49)"
            fill="#454545"
          />
          <path
            id="Path_532"
            data-name="Path 532"
            d="M684.783,0V10.514L701.24,0Z"
            transform="translate(289.131)"
            fill="#454545"
          />
          <path
            id="Path_533"
            data-name="Path 533"
            d="M734,0,684.783,31.448V45.074L740.436,9.519V0Z"
            transform="translate(289.13)"
            fill="#454545"
          />
          <path
            id="Path_534"
            data-name="Path 534"
            d="M684.783,70l55.652-35.556V22.812L684.783,58.366Z"
            transform="translate(289.13 9.632)"
            fill="#454545"
          />
          <path
            id="Path_535"
            data-name="Path 535"
            d="M684.783,93.711l55.652-35.556V48.512L684.783,84.067Z"
            transform="translate(289.13 20.483)"
            fill="#454545"
          />
          <path
            id="Path_536"
            data-name="Path 536"
            d="M684.783,117.419l55.652-35.556V74.212l-55.652,35.556Z"
            transform="translate(289.13 31.334)"
            fill="#454545"
          />
          <path
            id="Path_537"
            data-name="Path 537"
            d="M684.783,141.128l55.652-35.557V99.912l-55.652,35.556Z"
            transform="translate(289.13 -5.338)"
            fill="#454545"
          />
          <path
            id="Path_538"
            data-name="Path 538"
            d="M684.783,164.836l55.652-35.556v-3.668l-55.652,35.556Z"
            transform="translate(289.13 -6.564)"
            fill="#454545"
          />
          <g id="Group_117" data-name="Group 117" transform="translate(0 0)">
            <g
              id="Group_116"
              data-name="Group 116"
              clip-path="url(#clip-path-2)"
            >
              <path
                id="Path_539"
                data-name="Path 539"
                d="M684.783,175.546v1.144c.6-.264,55.03-23.963,55.652-24.234v-1.144l-.367.161Z"
                transform="translate(289.131 -4.634)"
                fill="#454545"
              />
              <path
                id="Path_540"
                data-name="Path 540"
                d="M645.652,481.148,701.3,456.914V442.2L645.652,466.43Z"
                transform="translate(272.609 -13.543)"
                fill="#454545"
              />
              <path
                id="Path_541"
                data-name="Path 541"
                d="M645.652,501.66,701.3,477.426V464.065L645.652,488.3Z"
                transform="translate(272.609 -14.213)"
                fill="#454545"
              />
              <path
                id="Path_542"
                data-name="Path 542"
                d="M645.652,522.171,701.3,497.937v-12l-55.651,24.234Z"
                transform="translate(272.609 -14.882)"
                fill="#454545"
              />
              <path
                id="Path_543"
                data-name="Path 543"
                d="M645.652,542.684,701.3,518.45V507.8l-55.651,24.234Z"
                transform="translate(272.609 -15.552)"
                fill="#454545"
              />
              <path
                id="Path_544"
                data-name="Path 544"
                d="M645.652,563.2,701.3,538.961v-9.288l-55.651,24.234Z"
                transform="translate(272.609 -16.222)"
                fill="#454545"
              />
              <path
                id="Path_545"
                data-name="Path 545"
                d="M645.652,583.707,701.3,559.473v-7.93l-55.651,24.233Z"
                transform="translate(272.609 -16.892)"
                fill="#454545"
              />
              <path
                id="Path_546"
                data-name="Path 546"
                d="M701.3,573.412l-55.652,24.234v1.539h11.56l44.092-19.2Z"
                transform="translate(272.609 -17.562)"
                fill="#454545"
              />
              <path
                id="Path_547"
                data-name="Path 547"
                d="M677.4,599.855h10.5v-4.574Z"
                transform="translate(286.012 -18.231)"
                fill="#454545"
              />
              <path
                id="Path_548"
                data-name="Path 548"
                d="M723.913,181.022l55.651-24.234V133.925L723.913,158.16Z"
                transform="translate(305.652 -4.101)"
                fill="#454545"
              />
              <path
                id="Path_549"
                data-name="Path 549"
                d="M723.913,205.365l55.651-24.234V159.625L723.913,183.86Z"
                transform="translate(305.652 -4.889)"
                fill="#454545"
              />
              <path
                id="Path_550"
                data-name="Path 550"
                d="M723.913,229.708l55.651-24.234V185.326L723.913,209.56Z"
                transform="translate(305.652 -5.676)"
                fill="#454545"
              />
              <path
                id="Path_551"
                data-name="Path 551"
                d="M723.913,254.051l55.651-24.234v-18.79L723.913,235.26Z"
                transform="translate(305.652 -6.463)"
                fill="#454545"
              />
              <path
                id="Path_552"
                data-name="Path 552"
                d="M723.913,278.393l55.651-24.234V236.726L723.913,260.96Z"
                transform="translate(305.652 -7.25)"
                fill="#454545"
              />
              <path
                id="Path_553"
                data-name="Path 553"
                d="M723.913,302.736,779.565,278.5V262.426l-55.651,24.234Z"
                transform="translate(305.652 -8.037)"
                fill="#454545"
              />
              <path
                id="Path_554"
                data-name="Path 554"
                d="M723.913,327.079l55.651-24.234V288.127L723.913,312.36Z"
                transform="translate(305.652 -8.824)"
                fill="#454545"
              />
              <path
                id="Path_555"
                data-name="Path 555"
                d="M723.913,351.421l55.651-24.234V313.826l-55.651,24.234Z"
                transform="translate(305.652 -9.611)"
                fill="#454545"
              />
              <path
                id="Path_556"
                data-name="Path 556"
                d="M723.913,375.764l55.651-24.234v-12l-55.651,24.234Z"
                transform="translate(305.652 -10.398)"
                fill="#454545"
              />
              <path
                id="Path_557"
                data-name="Path 557"
                d="M723.913,400.107l55.651-24.234V365.227l-55.651,24.234Z"
                transform="translate(305.652 -11.186)"
                fill="#454545"
              />
              <path
                id="Path_558"
                data-name="Path 558"
                d="M723.913,424.449l55.651-24.233v-9.289l-55.651,24.234Z"
                transform="translate(305.652 -11.973)"
                fill="#454545"
              />
              <path
                id="Path_559"
                data-name="Path 559"
                d="M723.913,448.792l55.651-24.234v-7.93l-55.651,24.233Z"
                transform="translate(305.652 -12.76)"
                fill="#454545"
              />
              <path
                id="Path_560"
                data-name="Path 560"
                d="M723.913,473.135,779.565,448.9v-6.573l-55.651,24.234Z"
                transform="translate(305.652 -13.547)"
                fill="#454545"
              />
              <path
                id="Path_561"
                data-name="Path 561"
                d="M723.913,497.478l55.651-24.234v-5.215l-55.651,24.234Z"
                transform="translate(305.652 -14.334)"
                fill="#454545"
              />
              <path
                id="Path_562"
                data-name="Path 562"
                d="M723.913,521.821l55.651-24.235v-3.857l-55.651,24.234Z"
                transform="translate(305.652 -15.121)"
                fill="#454545"
              />
              <path
                id="Path_563"
                data-name="Path 563"
                d="M723.913,546.163l55.651-24.234v-2.5l-55.651,24.234Z"
                transform="translate(305.652 -15.908)"
                fill="#454545"
              />
              <path
                id="Path_564"
                data-name="Path 564"
                d="M723.913,569.362v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(305.652 -16.695)"
                fill="#454545"
              />
              <path
                id="Path_565"
                data-name="Path 565"
                d="M606.522,19.465,651.222,0h-43.15l-1.55.675Z"
                transform="translate(256.087 0)"
                fill="#454545"
              />
              <path
                id="Path_566"
                data-name="Path 566"
                d="M606.522,45.939,662.173,21.7V4.271L606.522,28.507Z"
                transform="translate(256.087 -0.131)"
                fill="#454545"
              />
              <path
                id="Path_567"
                data-name="Path 567"
                d="M606.522,73.156l55.651-24.234V32.847L606.522,57.081Z"
                transform="translate(256.087 -1.006)"
                fill="#454545"
              />
              <path
                id="Path_568"
                data-name="Path 568"
                d="M606.522,100.375,662.173,76.14V61.422L606.522,85.656Z"
                transform="translate(256.087 -1.881)"
                fill="#454545"
              />
              <path
                id="Path_569"
                data-name="Path 569"
                d="M606.522,127.592l55.651-24.234V90l-55.651,24.233Z"
                transform="translate(256.087 -2.756)"
                fill="#454545"
              />
              <path
                id="Path_570"
                data-name="Path 570"
                d="M606.522,154.809l55.651-24.234v-12l-55.651,24.234Z"
                transform="translate(256.087 -3.631)"
                fill="#454545"
              />
              <path
                id="Path_571"
                data-name="Path 571"
                d="M606.522,182.028l55.651-24.234V147.148l-55.651,24.234Z"
                transform="translate(256.087 -4.506)"
                fill="#454545"
              />
              <path
                id="Path_572"
                data-name="Path 572"
                d="M606.522,209.244l55.651-24.233v-9.289l-55.651,24.234Z"
                transform="translate(256.087 -5.382)"
                fill="#454545"
              />
              <path
                id="Path_573"
                data-name="Path 573"
                d="M606.522,236.463l55.651-24.234V204.3l-55.651,24.233Z"
                transform="translate(256.087 -6.257)"
                fill="#454545"
              />
              <path
                id="Path_574"
                data-name="Path 574"
                d="M606.522,263.681l55.651-24.234v-6.573l-55.651,24.234Z"
                transform="translate(256.087 -7.132)"
                fill="#454545"
              />
              <path
                id="Path_575"
                data-name="Path 575"
                d="M606.522,290.9l55.651-24.234v-5.215l-55.651,24.234Z"
                transform="translate(256.087 -8.007)"
                fill="#454545"
              />
              <path
                id="Path_576"
                data-name="Path 576"
                d="M606.522,318.116l55.651-24.235v-3.857l-55.651,24.235Z"
                transform="translate(256.087 -8.882)"
                fill="#454545"
              />
              <path
                id="Path_577"
                data-name="Path 577"
                d="M606.522,345.333,662.173,321.1v-2.5l-55.651,24.234Z"
                transform="translate(256.087 -9.757)"
                fill="#454545"
              />
              <path
                id="Path_578"
                data-name="Path 578"
                d="M606.522,371.408v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(256.087 -10.633)"
                fill="#454545"
              />
              <path
                id="Path_579"
                data-name="Path 579"
                d="M645.652,137.285,701.3,113.05V94.26l-55.651,24.234Z"
                transform="translate(272.609 -2.887)"
                fill="#454545"
              />
              <path
                id="Path_580"
                data-name="Path 580"
                d="M645.652,161.628,701.3,137.393V119.96l-55.651,24.234Z"
                transform="translate(272.609 -3.674)"
                fill="#454545"
              />
              <path
                id="Path_581"
                data-name="Path 581"
                d="M645.652,185.97,701.3,161.736V145.661l-55.651,24.234Z"
                transform="translate(272.609 -4.461)"
                fill="#454545"
              />
              <path
                id="Path_582"
                data-name="Path 582"
                d="M645.652,210.313,701.3,186.079V171.361l-55.651,24.233Z"
                transform="translate(272.609 -5.248)"
                fill="#454545"
              />
              <path
                id="Path_583"
                data-name="Path 583"
                d="M645.652,234.656,701.3,210.421V197.06l-55.651,24.234Z"
                transform="translate(272.609 -6.035)"
                fill="#454545"
              />
              <path
                id="Path_584"
                data-name="Path 584"
                d="M645.652,259,701.3,234.763v-12L645.652,247Z"
                transform="translate(272.609 -6.822)"
                fill="#454545"
              />
              <path
                id="Path_585"
                data-name="Path 585"
                d="M645.652,283.342,701.3,259.107V248.462L645.652,272.7Z"
                transform="translate(272.609 -7.609)"
                fill="#454545"
              />
              <path
                id="Path_586"
                data-name="Path 586"
                d="M645.652,307.683,701.3,283.45v-9.289L645.652,298.4Z"
                transform="translate(272.609 -8.396)"
                fill="#454545"
              />
              <path
                id="Path_587"
                data-name="Path 587"
                d="M645.652,332.026,701.3,307.792v-7.93l-55.651,24.233Z"
                transform="translate(272.609 -9.184)"
                fill="#454545"
              />
              <path
                id="Path_588"
                data-name="Path 588"
                d="M645.652,356.37,701.3,332.135v-6.573L645.652,349.8Z"
                transform="translate(272.609 -9.971)"
                fill="#454545"
              />
              <path
                id="Path_589"
                data-name="Path 589"
                d="M645.652,380.712,701.3,356.477v-5.215L645.652,375.5Z"
                transform="translate(272.609 -10.758)"
                fill="#454545"
              />
              <path
                id="Path_590"
                data-name="Path 590"
                d="M645.652,405.055,701.3,380.819v-3.857L645.652,401.2Z"
                transform="translate(272.609 -11.545)"
                fill="#454545"
              />
              <path
                id="Path_591"
                data-name="Path 591"
                d="M645.652,429.4,701.3,405.162v-2.5L645.652,426.9Z"
                transform="translate(272.609 -12.332)"
                fill="#454545"
              />
              <path
                id="Path_592"
                data-name="Path 592"
                d="M645.652,452.6v1.144l.368-.161L701.3,429.506v-1.144l-.367.161Z"
                transform="translate(272.609 -13.119)"
                fill="#454545"
              />
              <path
                id="Path_593"
                data-name="Path 593"
                d="M606.522,537.068l55.651-24.234V489.971l-55.651,24.234Z"
                transform="translate(256.087 -15.006)"
                fill="#454545"
              />
              <path
                id="Path_594"
                data-name="Path 594"
                d="M606.522,564.286l55.651-24.234V518.546L606.522,542.78Z"
                transform="translate(256.087 -15.881)"
                fill="#454545"
              />
              <path
                id="Path_595"
                data-name="Path 595"
                d="M606.522,591.5l55.651-24.234V547.121l-55.651,24.234Z"
                transform="translate(256.087 -16.756)"
                fill="#454545"
              />
              <path
                id="Path_596"
                data-name="Path 596"
                d="M661.714,575.7l-54.1,23.559h43.15l10.953-4.768Z"
                transform="translate(256.547 -17.632)"
                fill="#454545"
              />
              <path
                id="Path_597"
                data-name="Path 597"
                d="M148.517,0H136.956V5.034Z"
                transform="translate(57.826 0)"
                fill="#454545"
              />
              <path
                id="Path_598"
                data-name="Path 598"
                d="M136.956,24.876,192.609.641V0H182.1L136.956,19.661Z"
                transform="translate(57.826 0)"
                fill="#454545"
              />
              <path
                id="Path_599"
                data-name="Path 599"
                d="M136.956,45.243l55.653-24.235V17.151L136.956,41.386Z"
                transform="translate(57.826 -0.525)"
                fill="#454545"
              />
              <path
                id="Path_600"
                data-name="Path 600"
                d="M136.956,65.755,192.609,41.52v-2.5L136.956,63.255Z"
                transform="translate(57.826 -1.195)"
                fill="#454545"
              />
              <path
                id="Path_601"
                data-name="Path 601"
                d="M136.956,85.124v1.144l.368-.161,55.285-24.073V60.889l-.368.161Z"
                transform="translate(57.826 -1.865)"
                fill="#454545"
              />
              <path
                id="Path_602"
                data-name="Path 602"
                d="M880.435,449.488l27.826-12.117V411.6l-27.826,12.117Z"
                transform="translate(371.739 -12.606)"
                fill="#454545"
              />
              <path
                id="Path_603"
                data-name="Path 603"
                d="M880.435,477.186l27.826-12.117V442.206l-27.826,12.117Z"
                transform="translate(371.739 -13.543)"
                fill="#454545"
              />
              <path
                id="Path_604"
                data-name="Path 604"
                d="M880.435,506.435l27.826-12.117V472.812l-27.826,12.117Z"
                transform="translate(371.739 -14.481)"
                fill="#454545"
              />
              <path
                id="Path_605"
                data-name="Path 605"
                d="M880.435,535.683l27.826-12.117V503.418l-27.826,12.117Z"
                transform="translate(371.739 -15.418)"
                fill="#454545"
              />
              <path
                id="Path_606"
                data-name="Path 606"
                d="M880.435,564.932l27.826-12.117v-18.79l-27.826,12.117Z"
                transform="translate(371.739 -16.355)"
                fill="#454545"
              />
              <path
                id="Path_607"
                data-name="Path 607"
                d="M880.435,594.181l27.826-12.117V564.63l-27.826,12.117Z"
                transform="translate(371.739 -17.293)"
                fill="#454545"
              />
              <path
                id="Path_608"
                data-name="Path 608"
                d="M903.148,599.854v-4.618l-10.6,4.618Z"
                transform="translate(376.852 -18.23)"
                fill="#454545"
              />
              <path
                id="Path_609"
                data-name="Path 609"
                d="M371.739,491.845l55.652-24.234V456.965L371.739,481.2Z"
                transform="translate(156.957 -13.995)"
                fill="#454545"
              />
              <path
                id="Path_610"
                data-name="Path 610"
                d="M371.739,512.356l55.652-24.233v-9.289l-55.652,24.234Z"
                transform="translate(156.957 -14.665)"
                fill="#454545"
              />
              <path
                id="Path_611"
                data-name="Path 611"
                d="M371.739,532.868l55.652-24.234V500.7l-55.652,24.233Z"
                transform="translate(156.957 -15.335)"
                fill="#454545"
              />
              <path
                id="Path_612"
                data-name="Path 612"
                d="M371.739,553.381l55.652-24.234v-6.573l-55.652,24.234Z"
                transform="translate(156.957 -16.004)"
                fill="#454545"
              />
              <path
                id="Path_613"
                data-name="Path 613"
                d="M371.739,573.892l55.652-24.234v-5.215l-55.652,24.234Z"
                transform="translate(156.957 -16.674)"
                fill="#454545"
              />
              <path
                id="Path_614"
                data-name="Path 614"
                d="M371.739,594.4l55.652-24.235v-3.857l-55.652,24.234Z"
                transform="translate(156.957 -17.344)"
                fill="#454545"
              />
              <path
                id="Path_615"
                data-name="Path 615"
                d="M418.68,588.181l-26.31,11.457h5.742l20.568-8.957Z"
                transform="translate(165.668 -18.014)"
                fill="#454545"
              />
              <path
                id="Path_616"
                data-name="Path 616"
                d="M880.435,228.17l27.826-12.117v-6.573L880.435,221.6Z"
                transform="translate(371.739 -6.415)"
                fill="#454545"
              />
              <path
                id="Path_617"
                data-name="Path 617"
                d="M880.435,257.418,908.26,245.3v-5.215L880.435,252.2Z"
                transform="translate(371.739 -7.353)"
                fill="#454545"
              />
              <path
                id="Path_618"
                data-name="Path 618"
                d="M880.435,286.667,908.26,274.55v-3.858l-27.826,12.117Z"
                transform="translate(371.739 -8.29)"
                fill="#454545"
              />
              <path
                id="Path_619"
                data-name="Path 619"
                d="M880.435,315.915,908.26,303.8v-2.5l-27.826,12.117Z"
                transform="translate(371.739 -9.228)"
                fill="#454545"
              />
              <path
                id="Path_620"
                data-name="Path 620"
                d="M880.435,345.164,880.8,345l27.457-11.956V331.9L880.435,344.02Z"
                transform="translate(371.739 -10.165)"
                fill="#454545"
              />
              <path
                id="Path_621"
                data-name="Path 621"
                d="M897.657,0,880.435,7.5V23.575l27.826-12.117V0Z"
                transform="translate(371.739 0)"
                fill="#454545"
              />
              <path
                id="Path_622"
                data-name="Path 622"
                d="M880.435,52.678,908.26,40.561V25.843L880.435,37.96Z"
                transform="translate(371.739 -0.791)"
                fill="#454545"
              />
              <path
                id="Path_623"
                data-name="Path 623"
                d="M880.435,81.926,908.26,69.809V56.448L880.435,68.566Z"
                transform="translate(371.739 -1.729)"
                fill="#454545"
              />
              <path
                id="Path_624"
                data-name="Path 624"
                d="M880.435,111.174,908.26,99.057v-12L880.435,99.172Z"
                transform="translate(371.739 -2.666)"
                fill="#454545"
              />
              <path
                id="Path_625"
                data-name="Path 625"
                d="M880.435,140.424l27.826-12.117V117.661l-27.826,12.117Z"
                transform="translate(371.739 -3.603)"
                fill="#454545"
              />
              <path
                id="Path_626"
                data-name="Path 626"
                d="M880.435,169.672l27.826-12.117v-9.288l-27.826,12.117Z"
                transform="translate(371.739 -4.541)"
                fill="#454545"
              />
              <path
                id="Path_627"
                data-name="Path 627"
                d="M880.435,198.921,908.26,186.8v-7.93l-27.826,12.116Z"
                transform="translate(371.739 -5.478)"
                fill="#454545"
              />
              <path
                id="Path_628"
                data-name="Path 628"
                d="M489.13,275.281l55.651-24.234V228.184L489.13,252.418Z"
                transform="translate(206.522 -6.988)"
                fill="#454545"
              />
              <path
                id="Path_629"
                data-name="Path 629"
                d="M489.13,299.624l55.651-24.234V253.884L489.13,278.118Z"
                transform="translate(206.522 -7.775)"
                fill="#454545"
              />
              <path
                id="Path_630"
                data-name="Path 630"
                d="M489.13,323.966l55.651-24.234V279.584L489.13,303.819Z"
                transform="translate(206.522 -8.563)"
                fill="#454545"
              />
              <path
                id="Path_631"
                data-name="Path 631"
                d="M489.13,348.309l55.651-24.234V305.285L489.13,329.519Z"
                transform="translate(206.522 -9.35)"
                fill="#454545"
              />
              <path
                id="Path_632"
                data-name="Path 632"
                d="M489.13,372.652l55.651-24.234V330.984L489.13,355.219Z"
                transform="translate(206.522 -10.137)"
                fill="#454545"
              />
              <path
                id="Path_633"
                data-name="Path 633"
                d="M489.13,396.994l55.651-24.234V356.685L489.13,380.919Z"
                transform="translate(206.522 -10.924)"
                fill="#454545"
              />
              <path
                id="Path_634"
                data-name="Path 634"
                d="M489.13,421.337,544.782,397.1V382.385L489.13,406.618Z"
                transform="translate(206.522 -11.711)"
                fill="#454545"
              />
              <path
                id="Path_635"
                data-name="Path 635"
                d="M489.13,445.68l55.651-24.234V408.085L489.13,432.319Z"
                transform="translate(206.522 -12.498)"
                fill="#454545"
              />
              <path
                id="Path_636"
                data-name="Path 636"
                d="M489.13,470.022l55.651-24.234v-12L489.13,458.019Z"
                transform="translate(206.522 -13.285)"
                fill="#454545"
              />
              <path
                id="Path_637"
                data-name="Path 637"
                d="M489.13,494.365l55.651-24.234V459.486L489.13,483.72Z"
                transform="translate(206.522 -14.072)"
                fill="#454545"
              />
              <path
                id="Path_638"
                data-name="Path 638"
                d="M489.13,518.707l55.651-24.233v-9.289L489.13,509.42Z"
                transform="translate(206.522 -14.859)"
                fill="#454545"
              />
              <path
                id="Path_639"
                data-name="Path 639"
                d="M489.13,543.051l55.651-24.234v-7.93L489.13,535.119Z"
                transform="translate(206.522 -15.646)"
                fill="#454545"
              />
              <path
                id="Path_640"
                data-name="Path 640"
                d="M489.13,567.394l55.651-24.234v-6.573L489.13,560.82Z"
                transform="translate(206.522 -16.434)"
                fill="#454545"
              />
              <path
                id="Path_641"
                data-name="Path 641"
                d="M489.13,591.736,544.782,567.5v-5.215L489.13,586.521Z"
                transform="translate(206.522 -17.221)"
                fill="#454545"
              />
              <path
                id="Path_642"
                data-name="Path 642"
                d="M536.2,587.986l-26.743,11.646h8.859l17.885-7.789Z"
                transform="translate(215.104 -18.008)"
                fill="#454545"
              />
              <path
                id="Path_643"
                data-name="Path 643"
                d="M176.087,387.206l55.652-24.234V340.109l-55.652,24.234Z"
                transform="translate(74.348 -10.416)"
                fill="#454545"
              />
              <path
                id="Path_644"
                data-name="Path 644"
                d="M176.087,411.549l55.652-24.234V365.809l-55.652,24.234Z"
                transform="translate(74.348 -11.203)"
                fill="#454545"
              />
              <path
                id="Path_645"
                data-name="Path 645"
                d="M176.087,435.892l55.652-24.234V391.51l-55.652,24.234Z"
                transform="translate(74.348 -11.99)"
                fill="#454545"
              />
              <path
                id="Path_646"
                data-name="Path 646"
                d="M176.087,460.235,231.739,436V417.211l-55.652,24.233Z"
                transform="translate(74.348 -12.778)"
                fill="#454545"
              />
              <path
                id="Path_647"
                data-name="Path 647"
                d="M176.087,484.577l55.652-24.234V442.91l-55.652,24.235Z"
                transform="translate(74.348 -13.565)"
                fill="#454545"
              />
              <path
                id="Path_648"
                data-name="Path 648"
                d="M176.087,508.92l55.652-24.234V468.61l-55.652,24.234Z"
                transform="translate(74.348 -14.352)"
                fill="#454545"
              />
              <path
                id="Path_649"
                data-name="Path 649"
                d="M176.087,533.263l55.652-24.234V494.311l-55.652,24.234Z"
                transform="translate(74.348 -15.139)"
                fill="#454545"
              />
              <path
                id="Path_650"
                data-name="Path 650"
                d="M176.087,557.605l55.652-24.234V520.01l-55.652,24.234Z"
                transform="translate(74.348 -15.926)"
                fill="#454545"
              />
              <path
                id="Path_651"
                data-name="Path 651"
                d="M176.087,581.948l55.652-24.234v-12l-55.652,24.234Z"
                transform="translate(74.348 -16.713)"
                fill="#454545"
              />
              <path
                id="Path_652"
                data-name="Path 652"
                d="M231.739,571.411l-55.652,24.235v3.478h16.457l39.2-17.068Z"
                transform="translate(74.348 -17.5)"
                fill="#454545"
              />
              <path
                id="Path_653"
                data-name="Path 653"
                d="M210.7,599.912h6.43v-2.8Z"
                transform="translate(88.961 -18.287)"
                fill="#454545"
              />
              <path
                id="Path_654"
                data-name="Path 654"
                d="M293.478,477.41l55.652-24.234V438.458l-55.652,24.233Z"
                transform="translate(123.913 -13.428)"
                fill="#454545"
              />
              <path
                id="Path_655"
                data-name="Path 655"
                d="M293.478,497.921l55.652-24.234V460.326L293.478,484.56Z"
                transform="translate(123.913 -14.098)"
                fill="#454545"
              />
              <path
                id="Path_656"
                data-name="Path 656"
                d="M293.478,518.433,349.13,494.2v-12L293.478,506.43Z"
                transform="translate(123.913 -14.768)"
                fill="#454545"
              />
              <path
                id="Path_657"
                data-name="Path 657"
                d="M293.478,538.946l55.652-24.234V504.066L293.478,528.3Z"
                transform="translate(123.913 -15.438)"
                fill="#454545"
              />
              <path
                id="Path_658"
                data-name="Path 658"
                d="M293.478,559.456l55.652-24.233v-9.288l-55.652,24.234Z"
                transform="translate(123.913 -16.108)"
                fill="#454545"
              />
              <path
                id="Path_659"
                data-name="Path 659"
                d="M293.478,579.969l55.652-24.234V547.8l-55.652,24.233Z"
                transform="translate(123.913 -16.777)"
                fill="#454545"
              />
              <path
                id="Path_660"
                data-name="Path 660"
                d="M349.13,569.674l-55.652,24.234v5.164h3.237l52.415-22.825Z"
                transform="translate(123.913 -17.447)"
                fill="#454545"
              />
              <path
                id="Path_661"
                data-name="Path 661"
                d="M338.2,591.543l-18.827,8.2h11.978l6.849-2.983Z"
                transform="translate(134.845 -18.117)"
                fill="#454545"
              />
              <path
                id="Path_662"
                data-name="Path 662"
                d="M27.826,399.1,0,411.217v25.771l27.826-12.117Z"
                transform="translate(0 -12.223)"
                fill="#454545"
              />
              <path
                id="Path_663"
                data-name="Path 663"
                d="M27.826,429.706,0,441.823v22.863l27.826-12.117Z"
                transform="translate(0 -13.16)"
                fill="#454545"
              />
              <path
                id="Path_664"
                data-name="Path 664"
                d="M27.826,460.312,0,472.43v21.506l27.826-12.117Z"
                transform="translate(0 -14.098)"
                fill="#454545"
              />
              <path
                id="Path_665"
                data-name="Path 665"
                d="M27.826,490.918,0,503.036v20.147l27.826-12.117Z"
                transform="translate(0 -15.035)"
                fill="#454545"
              />
              <path
                id="Path_666"
                data-name="Path 666"
                d="M27.826,521.524,0,533.642v18.79l27.826-12.117Z"
                transform="translate(0 -15.972)"
                fill="#454545"
              />
              <path
                id="Path_667"
                data-name="Path 667"
                d="M27.826,552.131,0,564.248v17.433l27.826-12.117Z"
                transform="translate(0 -16.91)"
                fill="#454545"
              />
              <path
                id="Path_668"
                data-name="Path 668"
                d="M27.826,582.737,0,594.854v4.617H26.311l1.515-.659Z"
                transform="translate(0 -17.847)"
                fill="#454545"
              />
              <path
                id="Path_669"
                data-name="Path 669"
                d="M489.13,16.447,526.9,0H518.04L489.13,12.59Z"
                transform="translate(206.522 0)"
                fill="#454545"
              />
              <path
                id="Path_670"
                data-name="Path 670"
                d="M489.13,40.421l55.651-24.234v-2.5L489.13,37.921Z"
                transform="translate(206.522 -0.419)"
                fill="#454545"
              />
              <path
                id="Path_671"
                data-name="Path 671"
                d="M489.13,63.621v1.144l.368-.161L544.782,40.53V39.386l-.367.161Z"
                transform="translate(206.522 -1.206)"
                fill="#454545"
              />
              <path
                id="Path_672"
                data-name="Path 672"
                d="M410.87,5.057,422.482,0h-8.859L410.87,1.2Z"
                transform="translate(173.478 0)"
                fill="#454545"
              />
              <path
                id="Path_673"
                data-name="Path 673"
                d="M410.87,31.546,466.521,7.312v-2.5L410.87,29.046Z"
                transform="translate(173.478 -0.147)"
                fill="#454545"
              />
              <path
                id="Path_674"
                data-name="Path 674"
                d="M410.87,57.621v1.144l.368-.161L466.521,34.53V33.386l-.367.161Z"
                transform="translate(173.478 -1.022)"
                fill="#454545"
              />
              <path
                id="Path_675"
                data-name="Path 675"
                d="M192.545,0H176.087V7.167Z"
                transform="translate(74.348 0)"
                fill="#454545"
              />
              <path
                id="Path_676"
                data-name="Path 676"
                d="M176.087,30.722,231.739,6.488V0h-6.43L176.087,21.434Z"
                transform="translate(74.348 0)"
                fill="#454545"
              />
              <path
                id="Path_677"
                data-name="Path 677"
                d="M176.087,54.976l55.652-24.234v-7.93L176.087,47.045Z"
                transform="translate(74.348 -0.698)"
                fill="#454545"
              />
              <path
                id="Path_678"
                data-name="Path 678"
                d="M176.087,79.319l55.652-24.234V48.512L176.087,72.746Z"
                transform="translate(74.348 -1.485)"
                fill="#454545"
              />
              <path
                id="Path_679"
                data-name="Path 679"
                d="M176.087,103.662l55.652-24.234V74.212L176.087,98.446Z"
                transform="translate(74.348 -2.273)"
                fill="#454545"
              />
              <path
                id="Path_680"
                data-name="Path 680"
                d="M176.087,128l55.652-24.235V99.912l-55.652,24.234Z"
                transform="translate(74.348 -3.06)"
                fill="#454545"
              />
              <path
                id="Path_681"
                data-name="Path 681"
                d="M176.087,152.347l55.652-24.234v-2.5l-55.652,24.234Z"
                transform="translate(74.348 -3.847)"
                fill="#454545"
              />
              <path
                id="Path_682"
                data-name="Path 682"
                d="M176.087,175.546v1.144c.6-.264,55.03-23.963,55.652-24.234v-1.144l-.367.161Z"
                transform="translate(74.348 -4.634)"
                fill="#454545"
              />
              <path
                id="Path_683"
                data-name="Path 683"
                d="M296.717,0h-3.238V1.41Z"
                transform="translate(123.913 0)"
                fill="#454545"
              />
              <path
                id="Path_684"
                data-name="Path 684"
                d="M293.478,21.252,342.28,0H330.3L293.478,16.036Z"
                transform="translate(123.913 0)"
                fill="#454545"
              />
              <path
                id="Path_685"
                data-name="Path 685"
                d="M293.478,41.5,349.13,17.27V13.413L293.478,37.647Z"
                transform="translate(123.913 -0.41)"
                fill="#454545"
              />
              <path
                id="Path_686"
                data-name="Path 686"
                d="M293.478,62.016,349.13,37.782v-2.5L293.478,59.516Z"
                transform="translate(123.913 -1.08)"
                fill="#454545"
              />
              <path
                id="Path_687"
                data-name="Path 687"
                d="M293.478,81.385v1.144l.368-.161L349.13,58.294V57.151l-.367.161Z"
                transform="translate(123.913 -1.75)"
                fill="#454545"
              />
              <path
                id="Path_688"
                data-name="Path 688"
                d="M371.739,153.393v14.718l40.189-17.5,15.462-6.734V129.159l-49.263,21.452Z"
                transform="translate(156.957 -3.955)"
                fill="#454545"
              />
              <path
                id="Path_689"
                data-name="Path 689"
                d="M371.739,195.838,427.391,171.6v-13.36l-55.652,24.233Z"
                transform="translate(156.957 -4.846)"
                fill="#454545"
              />
              <path
                id="Path_690"
                data-name="Path 690"
                d="M371.739,223.565l55.652-24.234v-12l-55.652,24.234Z"
                transform="translate(156.957 -5.737)"
                fill="#454545"
              />
              <path
                id="Path_691"
                data-name="Path 691"
                d="M371.739,251.294l55.652-24.234V216.414l-55.652,24.234Z"
                transform="translate(156.957 -6.628)"
                fill="#454545"
              />
              <path
                id="Path_692"
                data-name="Path 692"
                d="M371.739,279.02l55.652-24.233V245.5l-55.652,24.234Z"
                transform="translate(156.957 -7.519)"
                fill="#454545"
              />
              <path
                id="Path_693"
                data-name="Path 693"
                d="M371.739,306.748l55.652-24.234v-7.93l-55.652,24.233Z"
                transform="translate(156.957 -8.409)"
                fill="#454545"
              />
              <path
                id="Path_694"
                data-name="Path 694"
                d="M371.739,334.475l55.652-24.234v-6.573L371.739,327.9Z"
                transform="translate(156.957 -9.3)"
                fill="#454545"
              />
              <path
                id="Path_695"
                data-name="Path 695"
                d="M371.739,362.2l55.652-24.234v-5.215l-55.652,24.234Z"
                transform="translate(156.957 -10.191)"
                fill="#454545"
              />
              <path
                id="Path_696"
                data-name="Path 696"
                d="M371.739,389.93l55.652-24.235v-3.857l-55.652,24.234Z"
                transform="translate(156.957 -11.082)"
                fill="#454545"
              />
              <path
                id="Path_697"
                data-name="Path 697"
                d="M371.739,417.656l55.652-24.234v-2.5l-55.652,24.234Z"
                transform="translate(156.957 -11.972)"
                fill="#454545"
              />
              <path
                id="Path_698"
                data-name="Path 698"
                d="M371.739,444.241v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(156.957 -12.863)"
                fill="#454545"
              />
              <path
                id="Path_699"
                data-name="Path 699"
                d="M371.739,84.929l55.652-24.234V41.905L371.739,66.139Z"
                transform="translate(156.957 -1.283)"
                fill="#454545"
              />
              <path
                id="Path_700"
                data-name="Path 700"
                d="M371.739,112.657l55.652-24.234V70.989L371.739,95.223Z"
                transform="translate(156.957 -2.174)"
                fill="#454545"
              />
              <path
                id="Path_701"
                data-name="Path 701"
                d="M371.739,140.383l55.652-24.234V100.074l-55.652,24.234Z"
                transform="translate(156.957 -3.065)"
                fill="#454545"
              />
              <path
                id="Path_702"
                data-name="Path 702"
                d="M293.478,236.926l55.652-24.234V202.045L293.478,226.28Z"
                transform="translate(123.913 -6.188)"
                fill="#454545"
              />
              <path
                id="Path_703"
                data-name="Path 703"
                d="M293.478,259.106l55.652-24.234v-9.288l-55.652,24.234Z"
                transform="translate(123.913 -6.909)"
                fill="#454545"
              />
              <path
                id="Path_704"
                data-name="Path 704"
                d="M293.478,281.289l55.652-24.234v-7.93l-55.652,24.233Z"
                transform="translate(123.913 -7.63)"
                fill="#454545"
              />
              <path
                id="Path_705"
                data-name="Path 705"
                d="M293.478,303.471l55.652-24.234v-6.573L293.478,296.9Z"
                transform="translate(123.913 -8.351)"
                fill="#454545"
              />
              <path
                id="Path_706"
                data-name="Path 706"
                d="M293.478,325.652l55.652-24.234V296.2l-55.652,24.234Z"
                transform="translate(123.913 -9.071)"
                fill="#454545"
              />
              <path
                id="Path_707"
                data-name="Path 707"
                d="M293.478,347.835,349.13,323.6v-3.857l-55.652,24.234Z"
                transform="translate(123.913 -9.792)"
                fill="#454545"
              />
              <path
                id="Path_708"
                data-name="Path 708"
                d="M293.478,370.016l55.652-24.234v-2.5l-55.652,24.234Z"
                transform="translate(123.913 -10.513)"
                fill="#454545"
              />
              <path
                id="Path_709"
                data-name="Path 709"
                d="M293.478,391.055V392.2l.368-.161,55.283-24.073V366.82l-.367.161Z"
                transform="translate(123.913 -11.234)"
                fill="#454545"
              />
              <path
                id="Path_710"
                data-name="Path 710"
                d="M293.478,148.2l55.652-24.234V107.888l-55.652,24.234Z"
                transform="translate(123.913 -3.304)"
                fill="#454545"
              />
              <path
                id="Path_711"
                data-name="Path 711"
                d="M293.478,170.38l55.652-24.234V131.428l-55.652,24.233Z"
                transform="translate(123.913 -4.025)"
                fill="#454545"
              />
              <path
                id="Path_712"
                data-name="Path 712"
                d="M293.478,192.561l55.652-24.234V154.966L293.478,179.2Z"
                transform="translate(123.913 -4.746)"
                fill="#454545"
              />
              <path
                id="Path_713"
                data-name="Path 713"
                d="M293.478,214.743l55.652-24.234v-12L293.478,202.74Z"
                transform="translate(123.913 -5.467)"
                fill="#454545"
              />
              <path
                id="Path_714"
                data-name="Path 714"
                d="M763.044,528.75l55.65-24.234V485.726c-52.991,23.075-42.964,18.708-55.65,24.233Z"
                transform="translate(322.174 -14.876)"
                fill="#454545"
              />
              <path
                id="Path_715"
                data-name="Path 715"
                d="M763.044,555.968l55.651-24.234V514.3l-55.651,24.235Z"
                transform="translate(322.174 -15.751)"
                fill="#454545"
              />
              <path
                id="Path_716"
                data-name="Path 716"
                d="M763.044,583.185l55.651-24.234V542.876L763.044,567.11Z"
                transform="translate(322.174 -16.626)"
                fill="#454545"
              />
              <path
                id="Path_717"
                data-name="Path 717"
                d="M818.7,571.451l-55.651,24.234v3.44h25.9L818.7,586.169Z"
                transform="translate(322.174 -17.501)"
                fill="#454545"
              />
              <path
                id="Path_718"
                data-name="Path 718"
                d="M763.044,447.1l55.651-24.234V400l-55.651,24.234Z"
                transform="translate(322.174 -12.25)"
                fill="#454545"
              />
              <path
                id="Path_719"
                data-name="Path 719"
                d="M763.044,474.315l55.651-24.234V428.575l-55.651,24.234Z"
                transform="translate(322.174 -13.126)"
                fill="#454545"
              />
              <path
                id="Path_720"
                data-name="Path 720"
                d="M763.044,501.532,818.695,477.3V457.15l-55.651,24.234Z"
                transform="translate(322.174 -14.001)"
                fill="#454545"
              />
              <path
                id="Path_721"
                data-name="Path 721"
                d="M788.942,0h-25.9V11.278Z"
                transform="translate(322.174 0)"
                fill="#454545"
              />
              <path
                id="Path_722"
                data-name="Path 722"
                d="M763.044,37.621l55.651-24.234V.026L763.044,24.26Z"
                transform="translate(322.174 -0.001)"
                fill="#454545"
              />
              <path
                id="Path_723"
                data-name="Path 723"
                d="M763.044,64.838,818.695,40.6v-12L763.044,52.835Z"
                transform="translate(322.174 -0.876)"
                fill="#454545"
              />
              <path
                id="Path_724"
                data-name="Path 724"
                d="M763.044,92.057l55.651-24.234V57.177L763.044,81.411Z"
                transform="translate(322.174 -1.751)"
                fill="#454545"
              />
              <path
                id="Path_725"
                data-name="Path 725"
                d="M763.044,119.273l55.651-24.234V85.751l-55.651,24.234Z"
                transform="translate(322.174 -2.626)"
                fill="#454545"
              />
              <path
                id="Path_726"
                data-name="Path 726"
                d="M763.044,146.491l55.651-24.234v-7.93L763.044,138.56Z"
                transform="translate(322.174 -3.501)"
                fill="#454545"
              />
              <path
                id="Path_727"
                data-name="Path 727"
                d="M763.044,173.71l55.651-24.234V142.9l-55.651,24.234Z"
                transform="translate(322.174 -4.376)"
                fill="#454545"
              />
              <path
                id="Path_728"
                data-name="Path 728"
                d="M763.044,200.927l55.651-24.234v-5.215l-55.651,24.234Z"
                transform="translate(322.174 -5.252)"
                fill="#454545"
              />
              <path
                id="Path_729"
                data-name="Path 729"
                d="M763.044,228.145l55.651-24.235v-3.857l-55.651,24.234Z"
                transform="translate(322.174 -6.127)"
                fill="#454545"
              />
              <path
                id="Path_730"
                data-name="Path 730"
                d="M763.044,255.362l55.651-24.234v-2.5l-55.651,24.234Z"
                transform="translate(322.174 -7.002)"
                fill="#454545"
              />
              <path
                id="Path_731"
                data-name="Path 731"
                d="M763.044,281.436v1.144l.368-.161,55.283-24.073V257.2l-.367.161Z"
                transform="translate(322.174 -7.877)"
                fill="#454545"
              />
              <path
                id="Path_732"
                data-name="Path 732"
                d="M802.174,96.348l55.651-24.234V49.251L802.174,73.485Z"
                transform="translate(338.696 -1.508)"
                fill="#454545"
              />
              <path
                id="Path_733"
                data-name="Path 733"
                d="M802.174,120.691l55.651-24.234V74.951L802.174,99.185Z"
                transform="translate(338.696 -2.295)"
                fill="#454545"
              />
              <path
                id="Path_734"
                data-name="Path 734"
                d="M802.174,145.033,857.825,120.8V100.651l-55.651,24.234Z"
                transform="translate(338.696 -3.082)"
                fill="#454545"
              />
              <path
                id="Path_735"
                data-name="Path 735"
                d="M802.174,169.376l55.651-24.234v-18.79l-55.651,24.234Z"
                transform="translate(338.696 -3.869)"
                fill="#454545"
              />
              <path
                id="Path_736"
                data-name="Path 736"
                d="M802.174,193.719l55.651-24.234V152.051l-55.651,24.235Z"
                transform="translate(338.696 -4.657)"
                fill="#454545"
              />
              <path
                id="Path_737"
                data-name="Path 737"
                d="M802.174,218.061l55.651-24.234V177.752l-55.651,24.234Z"
                transform="translate(338.696 -5.444)"
                fill="#454545"
              />
              <path
                id="Path_738"
                data-name="Path 738"
                d="M802.174,242.4l55.651-24.234V203.452l-55.651,24.233Z"
                transform="translate(338.696 -6.231)"
                fill="#454545"
              />
              <path
                id="Path_739"
                data-name="Path 739"
                d="M802.174,266.747l55.651-24.234v-13.36l-55.651,24.233Z"
                transform="translate(338.696 -7.018)"
                fill="#454545"
              />
              <path
                id="Path_740"
                data-name="Path 740"
                d="M802.174,291.089l55.651-24.234v-12l-55.651,24.234Z"
                transform="translate(338.696 -7.805)"
                fill="#454545"
              />
              <path
                id="Path_741"
                data-name="Path 741"
                d="M802.174,315.433,857.825,291.2V280.553l-55.651,24.234Z"
                transform="translate(338.696 -8.592)"
                fill="#454545"
              />
              <path
                id="Path_742"
                data-name="Path 742"
                d="M802.174,339.775l55.651-24.234v-9.288l-55.651,24.234Z"
                transform="translate(338.696 -9.379)"
                fill="#454545"
              />
              <path
                id="Path_743"
                data-name="Path 743"
                d="M802.174,364.118l55.651-24.234v-7.93l-55.651,24.233Z"
                transform="translate(338.696 -10.166)"
                fill="#454545"
              />
              <path
                id="Path_744"
                data-name="Path 744"
                d="M802.174,388.461l55.651-24.234v-6.573l-55.651,24.234Z"
                transform="translate(338.696 -10.954)"
                fill="#454545"
              />
              <path
                id="Path_745"
                data-name="Path 745"
                d="M802.174,412.8l55.651-24.234v-5.215l-55.651,24.234Z"
                transform="translate(338.696 -11.741)"
                fill="#454545"
              />
              <path
                id="Path_746"
                data-name="Path 746"
                d="M802.174,437.146l55.651-24.235v-3.857l-55.651,24.234Z"
                transform="translate(338.696 -12.528)"
                fill="#454545"
              />
              <path
                id="Path_747"
                data-name="Path 747"
                d="M802.174,461.488l55.651-24.234v-2.5l-55.651,24.234Z"
                transform="translate(338.696 -13.315)"
                fill="#454545"
              />
              <path
                id="Path_748"
                data-name="Path 748"
                d="M802.174,484.688v1.144l.368-.161L857.825,461.6v-1.144l-.367.161Z"
                transform="translate(338.696 -14.102)"
                fill="#454545"
              />
              <path
                id="Path_749"
                data-name="Path 749"
                d="M58.7,224.9l55.652-24.234V181.881L58.7,206.114Z"
                transform="translate(24.783 -5.57)"
                fill="#454545"
              />
              <path
                id="Path_750"
                data-name="Path 750"
                d="M58.7,252.123l55.652-24.234V210.455L58.7,234.691Z"
                transform="translate(24.783 -6.445)"
                fill="#454545"
              />
              <path
                id="Path_751"
                data-name="Path 751"
                d="M58.7,279.34l55.652-24.234V239.031L58.7,263.265Z"
                transform="translate(24.783 -7.32)"
                fill="#454545"
              />
              <path
                id="Path_752"
                data-name="Path 752"
                d="M58.7,306.559l55.652-24.234V267.606L58.7,291.84Z"
                transform="translate(24.783 -8.196)"
                fill="#454545"
              />
              <path
                id="Path_753"
                data-name="Path 753"
                d="M58.7,333.776l55.652-24.234v-13.36L58.7,320.415Z"
                transform="translate(24.783 -9.071)"
                fill="#454545"
              />
              <path
                id="Path_754"
                data-name="Path 754"
                d="M58.7,360.993l55.652-24.234v-12L58.7,348.99Z"
                transform="translate(24.783 -9.946)"
                fill="#454545"
              />
              <path
                id="Path_755"
                data-name="Path 755"
                d="M58.7,388.212l55.652-24.234V353.332L58.7,377.566Z"
                transform="translate(24.783 -10.821)"
                fill="#454545"
              />
              <path
                id="Path_756"
                data-name="Path 756"
                d="M58.7,415.428,114.347,391.2v-9.289L58.7,406.141Z"
                transform="translate(24.783 -11.696)"
                fill="#454545"
              />
              <path
                id="Path_757"
                data-name="Path 757"
                d="M58.7,442.647l55.652-24.234v-7.93L58.7,434.715Z"
                transform="translate(24.783 -12.571)"
                fill="#454545"
              />
              <path
                id="Path_758"
                data-name="Path 758"
                d="M58.7,469.865l55.652-24.234v-6.573L58.7,463.292Z"
                transform="translate(24.783 -13.447)"
                fill="#454545"
              />
              <path
                id="Path_759"
                data-name="Path 759"
                d="M58.7,497.082l55.652-24.234v-5.215L58.7,491.867Z"
                transform="translate(24.783 -14.322)"
                fill="#454545"
              />
              <path
                id="Path_760"
                data-name="Path 760"
                d="M58.7,524.3l55.652-24.235v-3.857L58.7,520.442Z"
                transform="translate(24.783 -15.197)"
                fill="#454545"
              />
              <path
                id="Path_761"
                data-name="Path 761"
                d="M58.7,551.517l55.652-24.234v-2.5L58.7,549.017Z"
                transform="translate(24.783 -16.072)"
                fill="#454545"
              />
              <path
                id="Path_762"
                data-name="Path 762"
                d="M58.7,577.591v1.144l.368-.161L114.347,554.5v-1.144l-.367.161Z"
                transform="translate(24.783 -16.947)"
                fill="#454545"
              />
              <path
                id="Path_763"
                data-name="Path 763"
                d="M58.7,143.252l55.652-24.234V96.155L58.7,120.389Z"
                transform="translate(24.783 -2.945)"
                fill="#454545"
              />
              <path
                id="Path_764"
                data-name="Path 764"
                d="M58.7,170.47l55.652-24.234V124.73L58.7,148.964Z"
                transform="translate(24.783 -3.82)"
                fill="#454545"
              />
              <path
                id="Path_765"
                data-name="Path 765"
                d="M58.7,197.687l55.652-24.234V153.305L58.7,177.54Z"
                transform="translate(24.783 -4.695)"
                fill="#454545"
              />
              <path
                id="Path_766"
                data-name="Path 766"
                d="M27.826,227.585,0,239.7v5.215L27.826,232.8Z"
                transform="translate(0 -6.97)"
                fill="#454545"
              />
              <path
                id="Path_767"
                data-name="Path 767"
                d="M27.826,258.191,0,270.309v3.858l27.826-12.118Z"
                transform="translate(0 -7.907)"
                fill="#454545"
              />
              <path
                id="Path_768"
                data-name="Path 768"
                d="M27.826,288.8,0,300.915v2.5L27.826,291.3Z"
                transform="translate(0 -8.845)"
                fill="#454545"
              />
              <path
                id="Path_769"
                data-name="Path 769"
                d="M27.826,319.4l-.367.161L0,331.521v1.143l27.826-12.116Z"
                transform="translate(0 -9.782)"
                fill="#454545"
              />
              <path
                id="Path_770"
                data-name="Path 770"
                d="M0,0V11.458L26.311,0Z"
                transform="translate(0 0)"
                fill="#454545"
              />
              <path
                id="Path_771"
                data-name="Path 771"
                d="M27.826,13.343,0,25.46V40.178L27.826,28.061Z"
                transform="translate(0 -0.408)"
                fill="#454545"
              />
              <path
                id="Path_772"
                data-name="Path 772"
                d="M27.826,43.949,0,56.066V69.427L27.826,57.31Z"
                transform="translate(0 -1.346)"
                fill="#454545"
              />
              <path
                id="Path_773"
                data-name="Path 773"
                d="M27.826,74.555,0,86.672v12L27.826,86.558Z"
                transform="translate(0 -2.283)"
                fill="#454545"
              />
              <path
                id="Path_774"
                data-name="Path 774"
                d="M27.826,105.161,0,117.278v10.646l27.826-12.117Z"
                transform="translate(0 -3.22)"
                fill="#454545"
              />
              <path
                id="Path_775"
                data-name="Path 775"
                d="M27.826,135.767,0,147.884v9.288l27.826-12.117Z"
                transform="translate(0 -4.158)"
                fill="#454545"
              />
              <path
                id="Path_776"
                data-name="Path 776"
                d="M27.826,166.373,0,178.49v7.93L27.826,174.3Z"
                transform="translate(0 -5.095)"
                fill="#454545"
              />
              <path
                id="Path_777"
                data-name="Path 777"
                d="M27.826,196.979,0,209.1v6.573l27.826-12.117Z"
                transform="translate(0 -6.033)"
                fill="#454545"
              />
              <path
                id="Path_778"
                data-name="Path 778"
                d="M371.739,15.278,406.822,0h-5.742L371.739,12.778Z"
                transform="translate(156.957 0)"
                fill="#454545"
              />
              <path
                id="Path_779"
                data-name="Path 779"
                d="M371.739,34.284v1.144l.368-.161,55.283-24.073V10.05l-.367.161Z"
                transform="translate(156.957 -0.308)"
                fill="#454545"
              />
              <path
                id="Path_780"
                data-name="Path 780"
                d="M136.956,481.148l55.653-24.234V442.2L136.956,466.43Z"
                transform="translate(57.826 -13.543)"
                fill="#454545"
              />
              <path
                id="Path_781"
                data-name="Path 781"
                d="M136.956,501.66l55.653-24.234V464.065L136.956,488.3Z"
                transform="translate(57.826 -14.213)"
                fill="#454545"
              />
              <path
                id="Path_782"
                data-name="Path 782"
                d="M136.956,522.171l55.653-24.234v-12l-55.653,24.234Z"
                transform="translate(57.826 -14.882)"
                fill="#454545"
              />
              <path
                id="Path_783"
                data-name="Path 783"
                d="M136.956,542.684l55.653-24.234V507.8l-55.653,24.234Z"
                transform="translate(57.826 -15.552)"
                fill="#454545"
              />
              <path
                id="Path_784"
                data-name="Path 784"
                d="M136.956,563.2l55.653-24.234v-9.288l-55.653,24.234Z"
                transform="translate(57.826 -16.222)"
                fill="#454545"
              />
              <path
                id="Path_785"
                data-name="Path 785"
                d="M136.956,583.707l55.653-24.234v-7.93l-55.653,24.233Z"
                transform="translate(57.826 -16.892)"
                fill="#454545"
              />
              <path
                id="Path_786"
                data-name="Path 786"
                d="M192.609,573.412l-55.653,24.234v1.539h11.561l44.092-19.2Z"
                transform="translate(57.826 -17.562)"
                fill="#454545"
              />
              <path
                id="Path_787"
                data-name="Path 787"
                d="M168.7,599.855h10.5v-4.574Z"
                transform="translate(71.229 -18.231)"
                fill="#454545"
              />
              <path
                id="Path_788"
                data-name="Path 788"
                d="M537.864,0h-9.6V4.182Z"
                transform="translate(223.044 0)"
                fill="#454545"
              />
              <path
                id="Path_789"
                data-name="Path 789"
                d="M528.261,27.737,583.912,3.5V0H579.98L528.261,22.522Z"
                transform="translate(223.044 0)"
                fill="#454545"
              />
              <path
                id="Path_790"
                data-name="Path 790"
                d="M528.261,52.026l55.651-24.235V23.934L528.261,48.169Z"
                transform="translate(223.044 -0.733)"
                fill="#454545"
              />
              <path
                id="Path_791"
                data-name="Path 791"
                d="M528.261,76.368l55.651-24.234v-2.5L528.261,73.868Z"
                transform="translate(223.044 -1.52)"
                fill="#454545"
              />
              <path
                id="Path_792"
                data-name="Path 792"
                d="M528.261,99.568v1.144l.368-.161,55.283-24.073V75.333l-.367.161Z"
                transform="translate(223.044 -2.307)"
                fill="#454545"
              />
              <path
                id="Path_793"
                data-name="Path 793"
                d="M528.261,311.228l55.651-24.234V264.131l-55.651,24.234Z"
                transform="translate(223.044 -8.089)"
                fill="#454545"
              />
              <path
                id="Path_794"
                data-name="Path 794"
                d="M528.261,335.571l55.651-24.234V289.831l-55.651,24.234Z"
                transform="translate(223.044 -8.876)"
                fill="#454545"
              />
              <path
                id="Path_795"
                data-name="Path 795"
                d="M528.261,359.913l55.651-24.234V315.531l-55.651,24.234Z"
                transform="translate(223.044 -9.663)"
                fill="#454545"
              />
              <path
                id="Path_796"
                data-name="Path 796"
                d="M528.261,384.256l55.651-24.234v-18.79l-55.651,24.234Z"
                transform="translate(223.044 -10.451)"
                fill="#454545"
              />
              <path
                id="Path_797"
                data-name="Path 797"
                d="M528.261,408.6l55.651-24.234V366.931l-55.651,24.234Z"
                transform="translate(223.044 -11.238)"
                fill="#454545"
              />
              <path
                id="Path_798"
                data-name="Path 798"
                d="M528.261,432.941l55.651-24.234V392.632l-55.651,24.234Z"
                transform="translate(223.044 -12.025)"
                fill="#454545"
              />
              <path
                id="Path_799"
                data-name="Path 799"
                d="M528.261,457.285l55.651-24.234V418.332l-55.651,24.233Z"
                transform="translate(223.044 -12.812)"
                fill="#454545"
              />
              <path
                id="Path_800"
                data-name="Path 800"
                d="M528.261,481.627l55.651-24.234V444.032l-55.651,24.234Z"
                transform="translate(223.044 -13.599)"
                fill="#454545"
              />
              <path
                id="Path_801"
                data-name="Path 801"
                d="M528.261,505.969l55.651-24.234v-12l-55.651,24.234Z"
                transform="translate(223.044 -14.386)"
                fill="#454545"
              />
              <path
                id="Path_802"
                data-name="Path 802"
                d="M528.261,530.313l55.651-24.234V495.433l-55.651,24.234Z"
                transform="translate(223.044 -15.173)"
                fill="#454545"
              />
              <path
                id="Path_803"
                data-name="Path 803"
                d="M528.261,554.654l55.651-24.233v-9.289l-55.651,24.234Z"
                transform="translate(223.044 -15.96)"
                fill="#454545"
              />
              <path
                id="Path_804"
                data-name="Path 804"
                d="M528.261,579l55.651-24.234v-7.93l-55.651,24.233Z"
                transform="translate(223.044 -16.748)"
                fill="#454545"
              />
              <path
                id="Path_805"
                data-name="Path 805"
                d="M583.913,572.533l-55.652,24.234v2.391h9.6l46.049-20.053Z"
                transform="translate(223.044 -17.535)"
                fill="#454545"
              />
              <path
                id="Path_806"
                data-name="Path 806"
                d="M564.626,599.946h3.932v-1.712Z"
                transform="translate(238.398 -18.322)"
                fill="#454545"
              />
              <path
                id="Path_807"
                data-name="Path 807"
                d="M491.371,0H450V18.016Z"
                transform="translate(190 0)"
                fill="#454545"
              />
              <path
                id="Path_808"
                data-name="Path 808"
                d="M450,44.358l55.652-24.234V0H502.48L450,22.853Z"
                transform="translate(190 0)"
                fill="#454545"
              />
              <path
                id="Path_809"
                data-name="Path 809"
                d="M450,71.532,505.652,47.3V27.15L450,51.385Z"
                transform="translate(190 -0.831)"
                fill="#454545"
              />
              <path
                id="Path_810"
                data-name="Path 810"
                d="M450,98.75l55.652-24.234V55.726L450,79.959Z"
                transform="translate(190 -1.706)"
                fill="#454545"
              />
              <path
                id="Path_811"
                data-name="Path 811"
                d="M450,125.968l55.652-24.234V84.3L450,108.536Z"
                transform="translate(190 -2.582)"
                fill="#454545"
              />
              <path
                id="Path_812"
                data-name="Path 812"
                d="M450,153.185l55.652-24.234V112.876L450,137.11Z"
                transform="translate(190 -3.457)"
                fill="#454545"
              />
              <path
                id="Path_813"
                data-name="Path 813"
                d="M450,180.4l55.652-24.234V141.451L450,165.686Z"
                transform="translate(190 -4.332)"
                fill="#454545"
              />
              <path
                id="Path_814"
                data-name="Path 814"
                d="M450,207.621l55.652-24.234v-13.36L450,194.26Z"
                transform="translate(190 -5.207)"
                fill="#454545"
              />
              <path
                id="Path_815"
                data-name="Path 815"
                d="M450,234.838,505.652,210.6v-12L450,222.835Z"
                transform="translate(190 -6.082)"
                fill="#454545"
              />
              <path
                id="Path_816"
                data-name="Path 816"
                d="M450,262.057l55.652-24.234V227.177L450,251.411Z"
                transform="translate(190 -6.957)"
                fill="#454545"
              />
              <path
                id="Path_817"
                data-name="Path 817"
                d="M450,289.273l55.652-24.234v-9.288L450,279.986Z"
                transform="translate(190 -7.833)"
                fill="#454545"
              />
              <path
                id="Path_818"
                data-name="Path 818"
                d="M450,316.492l55.652-24.234v-7.93L450,308.56Z"
                transform="translate(190 -8.708)"
                fill="#454545"
              />
              <path
                id="Path_819"
                data-name="Path 819"
                d="M450,343.71l55.652-24.234V312.9L450,337.136Z"
                transform="translate(190 -9.583)"
                fill="#454545"
              />
              <path
                id="Path_820"
                data-name="Path 820"
                d="M450,370.927l55.652-24.234v-5.215L450,365.712Z"
                transform="translate(190 -10.458)"
                fill="#454545"
              />
              <path
                id="Path_821"
                data-name="Path 821"
                d="M450,398.145l55.652-24.235v-3.857L450,394.288Z"
                transform="translate(190 -11.333)"
                fill="#454545"
              />
              <path
                id="Path_822"
                data-name="Path 822"
                d="M450,425.362l55.652-24.234v-2.5L450,422.862Z"
                transform="translate(190 -12.208)"
                fill="#454545"
              />
              <path
                id="Path_823"
                data-name="Path 823"
                d="M450,451.436v1.144l.368-.161,55.283-24.073V427.2l-.367.161Z"
                transform="translate(190 -13.084)"
                fill="#454545"
              />
              <path
                id="Path_824"
                data-name="Path 824"
                d="M410.87,15.562,446.606,0h-33.8L410.87.843Z"
                transform="translate(173.478 0)"
                fill="#454545"
              />
              <path
                id="Path_825"
                data-name="Path 825"
                d="M410.87,42.55l55.651-24.234V4.955L410.87,29.189Z"
                transform="translate(173.478 -0.151)"
                fill="#454545"
              />
              <path
                id="Path_826"
                data-name="Path 826"
                d="M410.87,70.276l55.651-24.234v-12L410.87,58.274Z"
                transform="translate(173.478 -1.042)"
                fill="#454545"
              />
              <path
                id="Path_827"
                data-name="Path 827"
                d="M410.87,98l55.651-24.234V63.125L410.87,87.359Z"
                transform="translate(173.478 -1.933)"
                fill="#454545"
              />
              <path
                id="Path_828"
                data-name="Path 828"
                d="M410.87,125.731,466.521,101.5V92.209L410.87,116.443Z"
                transform="translate(173.478 -2.824)"
                fill="#454545"
              />
              <path
                id="Path_829"
                data-name="Path 829"
                d="M410.87,153.459l55.651-24.234v-7.93L410.87,145.527Z"
                transform="translate(173.478 -3.715)"
                fill="#454545"
              />
              <path
                id="Path_830"
                data-name="Path 830"
                d="M410.87,181.187l55.651-24.234v-6.573L410.87,174.613Z"
                transform="translate(173.478 -4.605)"
                fill="#454545"
              />
              <path
                id="Path_831"
                data-name="Path 831"
                d="M410.87,208.913l55.651-24.234v-5.215L410.87,203.7Z"
                transform="translate(173.478 -5.496)"
                fill="#454545"
              />
              <path
                id="Path_832"
                data-name="Path 832"
                d="M410.87,236.641l55.651-24.235v-3.857L410.87,232.784Z"
                transform="translate(173.478 -6.387)"
                fill="#454545"
              />
              <path
                id="Path_833"
                data-name="Path 833"
                d="M410.87,264.368l55.651-24.234v-2.5L410.87,261.868Z"
                transform="translate(173.478 -7.278)"
                fill="#454545"
              />
              <path
                id="Path_834"
                data-name="Path 834"
                d="M410.87,290.952V292.1l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(173.478 -8.168)"
                fill="#454545"
              />
              <path
                id="Path_835"
                data-name="Path 835"
                d="M215.217,181.022l55.652-24.234V133.925L215.217,158.16Z"
                transform="translate(90.87 -4.101)"
                fill="#454545"
              />
              <path
                id="Path_836"
                data-name="Path 836"
                d="M215.217,205.365l55.652-24.234V159.625L215.217,183.86Z"
                transform="translate(90.87 -4.889)"
                fill="#454545"
              />
              <path
                id="Path_837"
                data-name="Path 837"
                d="M215.217,229.708l55.652-24.234V185.326L215.217,209.56Z"
                transform="translate(90.87 -5.676)"
                fill="#454545"
              />
              <path
                id="Path_838"
                data-name="Path 838"
                d="M215.217,254.051l55.652-24.234v-18.79L215.217,235.26Z"
                transform="translate(90.87 -6.463)"
                fill="#454545"
              />
              <path
                id="Path_839"
                data-name="Path 839"
                d="M215.217,278.393l55.652-24.234V236.726L215.217,260.96Z"
                transform="translate(90.87 -7.25)"
                fill="#454545"
              />
              <path
                id="Path_840"
                data-name="Path 840"
                d="M215.217,302.736,270.869,278.5V262.426l-55.652,24.234Z"
                transform="translate(90.87 -8.037)"
                fill="#454545"
              />
              <path
                id="Path_841"
                data-name="Path 841"
                d="M215.217,327.079l55.652-24.234V288.127L215.217,312.36Z"
                transform="translate(90.87 -8.824)"
                fill="#454545"
              />
              <path
                id="Path_842"
                data-name="Path 842"
                d="M215.217,351.421l55.652-24.234V313.826l-55.652,24.234Z"
                transform="translate(90.87 -9.611)"
                fill="#454545"
              />
              <path
                id="Path_843"
                data-name="Path 843"
                d="M215.217,375.764l55.652-24.234v-12l-55.652,24.234Z"
                transform="translate(90.87 -10.398)"
                fill="#454545"
              />
              <path
                id="Path_844"
                data-name="Path 844"
                d="M215.217,400.107l55.652-24.234V365.227l-55.652,24.234Z"
                transform="translate(90.87 -11.186)"
                fill="#454545"
              />
              <path
                id="Path_845"
                data-name="Path 845"
                d="M215.217,424.449l55.652-24.233v-9.289l-55.652,24.234Z"
                transform="translate(90.87 -11.973)"
                fill="#454545"
              />
              <path
                id="Path_846"
                data-name="Path 846"
                d="M215.217,448.792l55.652-24.234v-7.93l-55.652,24.233Z"
                transform="translate(90.87 -12.76)"
                fill="#454545"
              />
              <path
                id="Path_847"
                data-name="Path 847"
                d="M215.217,473.135,270.869,448.9v-6.573l-55.652,24.234Z"
                transform="translate(90.87 -13.547)"
                fill="#454545"
              />
              <path
                id="Path_848"
                data-name="Path 848"
                d="M215.217,497.478l55.652-24.234v-5.215l-55.652,24.234Z"
                transform="translate(90.87 -14.334)"
                fill="#454545"
              />
              <path
                id="Path_849"
                data-name="Path 849"
                d="M215.217,521.821l55.652-24.235v-3.857l-55.652,24.234Z"
                transform="translate(90.87 -15.121)"
                fill="#454545"
              />
              <path
                id="Path_850"
                data-name="Path 850"
                d="M215.217,546.163l55.652-24.234v-2.5l-55.652,24.234Z"
                transform="translate(90.87 -15.908)"
                fill="#454545"
              />
              <path
                id="Path_851"
                data-name="Path 851"
                d="M215.217,569.362v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(90.87 -16.695)"
                fill="#454545"
              />
              <path
                id="Path_852"
                data-name="Path 852"
                d="M254.348,61.624,310,37.389V14.527L254.348,38.761Z"
                transform="translate(107.391 -0.445)"
                fill="#454545"
              />
              <path
                id="Path_853"
                data-name="Path 853"
                d="M254.348,85.966,310,61.732V40.227L254.348,64.461Z"
                transform="translate(107.391 -1.232)"
                fill="#454545"
              />
              <path
                id="Path_854"
                data-name="Path 854"
                d="M254.348,110.309,310,86.074V65.927L254.348,90.161Z"
                transform="translate(107.391 -2.019)"
                fill="#454545"
              />
              <path
                id="Path_855"
                data-name="Path 855"
                d="M254.348,134.652,310,110.417V91.627l-55.653,24.234Z"
                transform="translate(107.391 -2.806)"
                fill="#454545"
              />
              <path
                id="Path_856"
                data-name="Path 856"
                d="M254.348,158.994,310,134.76V117.327l-55.653,24.235Z"
                transform="translate(107.391 -3.593)"
                fill="#454545"
              />
              <path
                id="Path_857"
                data-name="Path 857"
                d="M254.348,183.337,310,159.1V143.027l-55.653,24.234Z"
                transform="translate(107.391 -4.38)"
                fill="#454545"
              />
              <path
                id="Path_858"
                data-name="Path 858"
                d="M254.348,207.68,310,183.446V168.728l-55.653,24.233Z"
                transform="translate(107.391 -5.167)"
                fill="#454545"
              />
              <path
                id="Path_859"
                data-name="Path 859"
                d="M254.348,232.023,310,207.788v-13.36l-55.653,24.233Z"
                transform="translate(107.391 -5.954)"
                fill="#454545"
              />
              <path
                id="Path_860"
                data-name="Path 860"
                d="M254.348,256.365,310,232.131v-12l-55.653,24.234Z"
                transform="translate(107.391 -6.742)"
                fill="#454545"
              />
              <path
                id="Path_861"
                data-name="Path 861"
                d="M254.348,280.708,310,256.474V245.828l-55.653,24.234Z"
                transform="translate(107.391 -7.529)"
                fill="#454545"
              />
              <path
                id="Path_862"
                data-name="Path 862"
                d="M254.348,305.05,310,280.816v-9.288l-55.653,24.234Z"
                transform="translate(107.391 -8.316)"
                fill="#454545"
              />
              <path
                id="Path_863"
                data-name="Path 863"
                d="M254.348,329.393,310,305.159v-7.93l-55.653,24.233Z"
                transform="translate(107.391 -9.103)"
                fill="#454545"
              />
              <path
                id="Path_864"
                data-name="Path 864"
                d="M254.348,353.736,310,329.5v-6.573l-55.653,24.234Z"
                transform="translate(107.391 -9.89)"
                fill="#454545"
              />
              <path
                id="Path_865"
                data-name="Path 865"
                d="M254.348,378.079,310,353.844v-5.215l-55.653,24.234Z"
                transform="translate(107.391 -10.677)"
                fill="#454545"
              />
              <path
                id="Path_866"
                data-name="Path 866"
                d="M254.348,402.422,310,378.186v-3.857l-55.653,24.234Z"
                transform="translate(107.391 -11.464)"
                fill="#454545"
              />
              <path
                id="Path_867"
                data-name="Path 867"
                d="M254.348,426.764,310,402.53v-2.5l-55.653,24.234Z"
                transform="translate(107.391 -12.251)"
                fill="#454545"
              />
              <path
                id="Path_868"
                data-name="Path 868"
                d="M254.348,449.963v1.144l.368-.161L310,426.873v-1.144l-.368.161Z"
                transform="translate(107.391 -13.038)"
                fill="#454545"
              />
              <path
                id="Path_869"
                data-name="Path 869"
                d="M841.3,271.226l55.651-24.234V232.273L841.3,256.507Z"
                transform="translate(355.217 -7.114)"
                fill="#454545"
              />
              <path
                id="Path_870"
                data-name="Path 870"
                d="M841.3,291.737,896.956,267.5V254.142L841.3,278.376Z"
                transform="translate(355.217 -7.783)"
                fill="#454545"
              />
              <path
                id="Path_871"
                data-name="Path 871"
                d="M841.3,312.249l55.651-24.234v-12L841.3,300.246Z"
                transform="translate(355.217 -8.453)"
                fill="#454545"
              />
              <path
                id="Path_872"
                data-name="Path 872"
                d="M841.3,332.762l55.651-24.234V297.882L841.3,322.116Z"
                transform="translate(355.217 -9.123)"
                fill="#454545"
              />
              <path
                id="Path_873"
                data-name="Path 873"
                d="M841.3,353.272l55.651-24.233V319.75L841.3,343.985Z"
                transform="translate(355.217 -9.793)"
                fill="#454545"
              />
              <path
                id="Path_874"
                data-name="Path 874"
                d="M841.3,373.785l55.651-24.234v-7.93L841.3,365.853Z"
                transform="translate(355.217 -10.462)"
                fill="#454545"
              />
              <path
                id="Path_875"
                data-name="Path 875"
                d="M841.3,394.3l55.651-24.234V363.49L841.3,387.724Z"
                transform="translate(355.217 -11.132)"
                fill="#454545"
              />
              <path
                id="Path_876"
                data-name="Path 876"
                d="M841.3,414.808l55.651-24.234v-5.215L841.3,409.593Z"
                transform="translate(355.217 -11.802)"
                fill="#454545"
              />
              <path
                id="Path_877"
                data-name="Path 877"
                d="M841.3,435.321l55.651-24.235v-3.857L841.3,431.463Z"
                transform="translate(355.217 -12.472)"
                fill="#454545"
              />
              <path
                id="Path_878"
                data-name="Path 878"
                d="M841.3,455.832,896.956,431.6v-2.5L841.3,453.332Z"
                transform="translate(355.217 -13.142)"
                fill="#454545"
              />
              <path
                id="Path_879"
                data-name="Path 879"
                d="M841.3,475.2v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(355.217 -13.811)"
                fill="#454545"
              />
              <path
                id="Path_880"
                data-name="Path 880"
                d="M862.905,0H841.3V9.407Z"
                transform="translate(355.217 0)"
                fill="#454545"
              />
              <path
                id="Path_881"
                data-name="Path 881"
                d="M841.3,30.868,896.956,6.634V0h-9.212L841.3,20.223Z"
                transform="translate(355.217 0)"
                fill="#454545"
              />
              <path
                id="Path_882"
                data-name="Path 882"
                d="M841.3,52.922l55.651-24.234V19.4L841.3,43.635Z"
                transform="translate(355.217 -0.594)"
                fill="#454545"
              />
              <path
                id="Path_883"
                data-name="Path 883"
                d="M841.3,75.1,896.956,50.87V42.94L841.3,67.173Z"
                transform="translate(355.217 -1.315)"
                fill="#454545"
              />
              <path
                id="Path_884"
                data-name="Path 884"
                d="M841.3,97.287l55.651-24.234V66.479L841.3,90.714Z"
                transform="translate(355.217 -2.036)"
                fill="#454545"
              />
              <path
                id="Path_885"
                data-name="Path 885"
                d="M841.3,119.468l55.651-24.234V90.019L841.3,114.253Z"
                transform="translate(355.217 -2.757)"
                fill="#454545"
              />
              <path
                id="Path_886"
                data-name="Path 886"
                d="M841.3,141.65l55.651-24.235v-3.857L841.3,137.793Z"
                transform="translate(355.217 -3.478)"
                fill="#454545"
              />
              <path
                id="Path_887"
                data-name="Path 887"
                d="M841.3,163.832,896.956,139.6v-2.5L841.3,161.332Z"
                transform="translate(355.217 -4.199)"
                fill="#454545"
              />
              <path
                id="Path_888"
                data-name="Path 888"
                d="M841.3,184.87v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(355.217 -4.919)"
                fill="#454545"
              />
              <path
                id="Path_889"
                data-name="Path 889"
                d="M841.3,542.013l55.651-24.234V501.7L841.3,525.938Z"
                transform="translate(355.217 -15.365)"
                fill="#454545"
              />
              <path
                id="Path_890"
                data-name="Path 890"
                d="M841.3,564.2l55.651-24.234V525.244L841.3,549.477Z"
                transform="translate(355.217 -16.086)"
                fill="#454545"
              />
              <path
                id="Path_891"
                data-name="Path 891"
                d="M841.3,586.377l55.651-24.234v-13.36L841.3,573.016Z"
                transform="translate(355.217 -16.807)"
                fill="#454545"
              />
              <path
                id="Path_892"
                data-name="Path 892"
                d="M896.956,572.322,841.3,596.556v2.6h21.6l34.051-14.828Z"
                transform="translate(355.218 -17.528)"
                fill="#454545"
              />
              <path
                id="Path_893"
                data-name="Path 893"
                d="M873.957,599.873h9.213v-4.012Z"
                transform="translate(369.004 -18.249)"
                fill="#454545"
              />
              <path
                id="Path_894"
                data-name="Path 894"
                d="M332.609,271.226l55.652-24.234V232.273l-55.652,24.233Z"
                transform="translate(140.435 -7.114)"
                fill="#454545"
              />
              <path
                id="Path_895"
                data-name="Path 895"
                d="M332.609,291.737,388.26,267.5V254.142l-55.652,24.234Z"
                transform="translate(140.435 -7.783)"
                fill="#454545"
              />
              <path
                id="Path_896"
                data-name="Path 896"
                d="M332.609,312.249l55.652-24.234v-12l-55.652,24.234Z"
                transform="translate(140.435 -8.453)"
                fill="#454545"
              />
              <path
                id="Path_897"
                data-name="Path 897"
                d="M332.609,332.762l55.652-24.234V297.882l-55.652,24.234Z"
                transform="translate(140.435 -9.123)"
                fill="#454545"
              />
              <path
                id="Path_898"
                data-name="Path 898"
                d="M332.609,353.272l55.652-24.233V319.75l-55.652,24.234Z"
                transform="translate(140.435 -9.793)"
                fill="#454545"
              />
              <path
                id="Path_899"
                data-name="Path 899"
                d="M332.609,373.785,388.26,349.55v-7.93l-55.652,24.233Z"
                transform="translate(140.435 -10.462)"
                fill="#454545"
              />
              <path
                id="Path_900"
                data-name="Path 900"
                d="M332.609,394.3l55.652-24.234V363.49l-55.652,24.234Z"
                transform="translate(140.435 -11.132)"
                fill="#454545"
              />
              <path
                id="Path_901"
                data-name="Path 901"
                d="M332.609,414.808l55.652-24.234v-5.215l-55.652,24.234Z"
                transform="translate(140.435 -11.802)"
                fill="#454545"
              />
              <path
                id="Path_902"
                data-name="Path 902"
                d="M332.609,435.321l55.652-24.235v-3.857l-55.652,24.234Z"
                transform="translate(140.435 -12.472)"
                fill="#454545"
              />
              <path
                id="Path_903"
                data-name="Path 903"
                d="M332.609,455.832,388.26,431.6v-2.5l-55.652,24.234Z"
                transform="translate(140.435 -13.142)"
                fill="#454545"
              />
              <path
                id="Path_904"
                data-name="Path 904"
                d="M332.609,475.2v1.144l.368-.161L388.26,452.11v-1.144l-.367.161Z"
                transform="translate(140.435 -13.811)"
                fill="#454545"
              />
              <path
                id="Path_905"
                data-name="Path 905"
                d="M505.652,570,450,594.233v4.848h41.371l14.281-6.219Z"
                transform="translate(190 -17.457)"
                fill="#454545"
              />
              <path
                id="Path_906"
                data-name="Path 906"
                d="M486.9,599.956h3.172v-1.381Z"
                transform="translate(205.58 -18.332)"
                fill="#454545"
              />
              <path
                id="Path_907"
                data-name="Path 907"
                d="M410.87,533.64l55.651-24.234v-18.79L410.87,514.85Z"
                transform="translate(173.478 -15.026)"
                fill="#454545"
              />
              <path
                id="Path_908"
                data-name="Path 908"
                d="M410.87,561.368l55.651-24.234V519.7L410.87,543.936Z"
                transform="translate(173.478 -15.917)"
                fill="#454545"
              />
              <path
                id="Path_909"
                data-name="Path 909"
                d="M410.87,589.095l55.651-24.234V548.785L410.87,573.02Z"
                transform="translate(173.478 -16.807)"
                fill="#454545"
              />
              <path
                id="Path_910"
                data-name="Path 910"
                d="M464.625,577.87l-49.263,21.452h33.8l15.464-6.734Z"
                transform="translate(175.375 -17.698)"
                fill="#454545"
              />
              <path
                id="Path_911"
                data-name="Path 911"
                d="M354.21,0h-21.6V9.407Z"
                transform="translate(140.435 0)"
                fill="#454545"
              />
              <path
                id="Path_912"
                data-name="Path 912"
                d="M332.609,30.868,388.26,6.634V0h-9.212l-46.44,20.223Z"
                transform="translate(140.435 0)"
                fill="#454545"
              />
              <path
                id="Path_913"
                data-name="Path 913"
                d="M332.609,52.922,388.26,28.688V19.4L332.609,43.635Z"
                transform="translate(140.435 -0.594)"
                fill="#454545"
              />
              <path
                id="Path_914"
                data-name="Path 914"
                d="M332.609,75.1,388.26,50.87V42.94L332.609,67.173Z"
                transform="translate(140.435 -1.315)"
                fill="#454545"
              />
              <path
                id="Path_915"
                data-name="Path 915"
                d="M332.609,97.287,388.26,73.053V66.479L332.609,90.714Z"
                transform="translate(140.435 -2.036)"
                fill="#454545"
              />
              <path
                id="Path_916"
                data-name="Path 916"
                d="M332.609,119.468,388.26,95.234V90.019l-55.652,24.234Z"
                transform="translate(140.435 -2.757)"
                fill="#454545"
              />
              <path
                id="Path_917"
                data-name="Path 917"
                d="M332.609,141.65l55.652-24.235v-3.857l-55.652,24.235Z"
                transform="translate(140.435 -3.478)"
                fill="#454545"
              />
              <path
                id="Path_918"
                data-name="Path 918"
                d="M332.609,163.832,388.26,139.6v-2.5l-55.652,24.234Z"
                transform="translate(140.435 -4.199)"
                fill="#454545"
              />
              <path
                id="Path_919"
                data-name="Path 919"
                d="M332.609,184.87v1.144l.368-.161L388.26,161.78v-1.144l-.367.161Z"
                transform="translate(140.435 -4.919)"
                fill="#454545"
              />
              <path
                id="Path_920"
                data-name="Path 920"
                d="M332.609,542.013l55.652-24.234V501.7l-55.652,24.234Z"
                transform="translate(140.435 -15.365)"
                fill="#454545"
              />
              <path
                id="Path_921"
                data-name="Path 921"
                d="M332.609,564.2l55.652-24.234V525.244l-55.652,24.233Z"
                transform="translate(140.435 -16.086)"
                fill="#454545"
              />
              <path
                id="Path_922"
                data-name="Path 922"
                d="M332.609,586.377l55.652-24.234v-13.36l-55.652,24.233Z"
                transform="translate(140.435 -16.807)"
                fill="#454545"
              />
              <path
                id="Path_923"
                data-name="Path 923"
                d="M388.261,572.322l-55.652,24.234v2.6h21.6l34.051-14.828Z"
                transform="translate(140.435 -17.528)"
                fill="#454545"
              />
              <path
                id="Path_924"
                data-name="Path 924"
                d="M365.261,599.873h9.213v-4.012Z"
                transform="translate(154.221 -18.249)"
                fill="#454545"
              />
              <path
                id="Path_925"
                data-name="Path 925"
                d="M97.826,19.465,142.527,0H99.376l-1.55.675Z"
                transform="translate(41.304 0)"
                fill="#454545"
              />
              <path
                id="Path_926"
                data-name="Path 926"
                d="M97.826,45.939,153.478,21.7V4.271L97.826,28.507Z"
                transform="translate(41.304 -0.131)"
                fill="#454545"
              />
              <path
                id="Path_927"
                data-name="Path 927"
                d="M97.826,73.156l55.652-24.234V32.847L97.826,57.081Z"
                transform="translate(41.304 -1.006)"
                fill="#454545"
              />
              <path
                id="Path_928"
                data-name="Path 928"
                d="M97.826,100.375,153.478,76.14V61.422L97.826,85.656Z"
                transform="translate(41.304 -1.881)"
                fill="#454545"
              />
              <path
                id="Path_929"
                data-name="Path 929"
                d="M97.826,127.592l55.652-24.234V90L97.826,114.231Z"
                transform="translate(41.304 -2.756)"
                fill="#454545"
              />
              <path
                id="Path_930"
                data-name="Path 930"
                d="M97.826,154.809l55.652-24.234v-12L97.826,142.806Z"
                transform="translate(41.304 -3.631)"
                fill="#454545"
              />
              <path
                id="Path_931"
                data-name="Path 931"
                d="M97.826,182.028l55.652-24.234V147.148L97.826,171.382Z"
                transform="translate(41.304 -4.506)"
                fill="#454545"
              />
              <path
                id="Path_932"
                data-name="Path 932"
                d="M97.826,209.244l55.652-24.233v-9.289L97.826,199.957Z"
                transform="translate(41.304 -5.382)"
                fill="#454545"
              />
              <path
                id="Path_933"
                data-name="Path 933"
                d="M97.826,236.463l55.652-24.234V204.3L97.826,228.531Z"
                transform="translate(41.304 -6.257)"
                fill="#454545"
              />
              <path
                id="Path_934"
                data-name="Path 934"
                d="M97.826,263.681l55.652-24.234v-6.573L97.826,257.107Z"
                transform="translate(41.304 -7.132)"
                fill="#454545"
              />
              <path
                id="Path_935"
                data-name="Path 935"
                d="M97.826,290.9l55.652-24.234v-5.215L97.826,285.683Z"
                transform="translate(41.304 -8.007)"
                fill="#454545"
              />
              <path
                id="Path_936"
                data-name="Path 936"
                d="M97.826,318.116l55.652-24.235v-3.857L97.826,314.259Z"
                transform="translate(41.304 -8.882)"
                fill="#454545"
              />
              <path
                id="Path_937"
                data-name="Path 937"
                d="M97.826,345.333,153.478,321.1v-2.5L97.826,342.833Z"
                transform="translate(41.304 -9.757)"
                fill="#454545"
              />
              <path
                id="Path_938"
                data-name="Path 938"
                d="M97.826,371.408v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(41.304 -10.633)"
                fill="#454545"
              />
              <path
                id="Path_939"
                data-name="Path 939"
                d="M136.956,137.285l55.653-24.234V94.26l-55.653,24.234Z"
                transform="translate(57.826 -2.887)"
                fill="#454545"
              />
              <path
                id="Path_940"
                data-name="Path 940"
                d="M136.956,161.628l55.653-24.234V119.96l-55.653,24.234Z"
                transform="translate(57.826 -3.674)"
                fill="#454545"
              />
              <path
                id="Path_941"
                data-name="Path 941"
                d="M136.956,185.97l55.653-24.234V145.661l-55.653,24.234Z"
                transform="translate(57.826 -4.461)"
                fill="#454545"
              />
              <path
                id="Path_942"
                data-name="Path 942"
                d="M136.956,210.313l55.653-24.234V171.361l-55.653,24.233Z"
                transform="translate(57.826 -5.248)"
                fill="#454545"
              />
              <path
                id="Path_943"
                data-name="Path 943"
                d="M136.956,234.656l55.653-24.234V197.06l-55.653,24.234Z"
                transform="translate(57.826 -6.035)"
                fill="#454545"
              />
              <path
                id="Path_944"
                data-name="Path 944"
                d="M136.956,259l55.653-24.234v-12L136.956,247Z"
                transform="translate(57.826 -6.822)"
                fill="#454545"
              />
              <path
                id="Path_945"
                data-name="Path 945"
                d="M136.956,283.342l55.653-24.234V248.462L136.956,272.7Z"
                transform="translate(57.826 -7.609)"
                fill="#454545"
              />
              <path
                id="Path_946"
                data-name="Path 946"
                d="M136.956,307.683l55.653-24.233v-9.289L136.956,298.4Z"
                transform="translate(57.826 -8.396)"
                fill="#454545"
              />
              <path
                id="Path_947"
                data-name="Path 947"
                d="M136.956,332.026l55.653-24.234v-7.93l-55.653,24.233Z"
                transform="translate(57.826 -9.184)"
                fill="#454545"
              />
              <path
                id="Path_948"
                data-name="Path 948"
                d="M136.956,356.37l55.653-24.234v-6.573L136.956,349.8Z"
                transform="translate(57.826 -9.971)"
                fill="#454545"
              />
              <path
                id="Path_949"
                data-name="Path 949"
                d="M136.956,380.712l55.653-24.234v-5.215L136.956,375.5Z"
                transform="translate(57.826 -10.758)"
                fill="#454545"
              />
              <path
                id="Path_950"
                data-name="Path 950"
                d="M136.956,405.055l55.653-24.235v-3.857L136.956,401.2Z"
                transform="translate(57.826 -11.545)"
                fill="#454545"
              />
              <path
                id="Path_951"
                data-name="Path 951"
                d="M136.956,429.4l55.653-24.234v-2.5L136.956,426.9Z"
                transform="translate(57.826 -12.332)"
                fill="#454545"
              />
              <path
                id="Path_952"
                data-name="Path 952"
                d="M136.956,452.6v1.144l.368-.161,55.285-24.073v-1.144l-.368.161Z"
                transform="translate(57.826 -13.119)"
                fill="#454545"
              />
              <path
                id="Path_953"
                data-name="Path 953"
                d="M97.826,537.068l55.652-24.234V489.971L97.826,514.205Z"
                transform="translate(41.304 -15.006)"
                fill="#454545"
              />
              <path
                id="Path_954"
                data-name="Path 954"
                d="M97.826,564.286l55.652-24.234V518.546L97.826,542.78Z"
                transform="translate(41.304 -15.881)"
                fill="#454545"
              />
              <path
                id="Path_955"
                data-name="Path 955"
                d="M97.826,591.5l55.652-24.234V547.121L97.826,571.356Z"
                transform="translate(41.304 -16.756)"
                fill="#454545"
              />
              <path
                id="Path_956"
                data-name="Path 956"
                d="M153.018,575.7l-54.1,23.559h43.15l10.953-4.768Z"
                transform="translate(41.764 -17.632)"
                fill="#454545"
              />
              <path
                id="Path_957"
                data-name="Path 957"
                d="M19.565,21.885,69.821,0H54.727L19.565,15.312Z"
                transform="translate(8.261 0)"
                fill="#454545"
              />
              <path
                id="Path_958"
                data-name="Path 958"
                d="M19.565,50.851,75.218,26.617V21.4L19.565,45.636Z"
                transform="translate(8.261 -0.655)"
                fill="#454545"
              />
              <path
                id="Path_959"
                data-name="Path 959"
                d="M19.565,80.1,75.218,55.865V52.007L19.565,76.242Z"
                transform="translate(8.261 -1.593)"
                fill="#454545"
              />
              <path
                id="Path_960"
                data-name="Path 960"
                d="M19.565,109.348,75.218,85.113v-2.5L19.565,106.848Z"
                transform="translate(8.261 -2.53)"
                fill="#454545"
              />
              <path
                id="Path_961"
                data-name="Path 961"
                d="M19.565,137.453V138.6l.368-.161,55.285-24.073v-1.144l-.368.161Z"
                transform="translate(8.261 -3.467)"
                fill="#454545"
              />
              <path
                id="Path_962"
                data-name="Path 962"
                d="M19.565,242.921l55.653-24.234V192.916L19.565,217.15Z"
                transform="translate(8.261 -5.908)"
                fill="#454545"
              />
              <path
                id="Path_963"
                data-name="Path 963"
                d="M19.565,270.619l55.653-24.234V223.522L19.565,247.756Z"
                transform="translate(8.261 -6.845)"
                fill="#454545"
              />
              <path
                id="Path_964"
                data-name="Path 964"
                d="M19.565,299.868l55.653-24.234V254.128L19.565,278.362Z"
                transform="translate(8.261 -7.783)"
                fill="#454545"
              />
              <path
                id="Path_965"
                data-name="Path 965"
                d="M19.565,329.116l55.653-24.234V284.734L19.565,308.969Z"
                transform="translate(8.261 -8.72)"
                fill="#454545"
              />
              <path
                id="Path_966"
                data-name="Path 966"
                d="M19.565,358.365,75.218,334.13V315.34L19.565,339.574Z"
                transform="translate(8.261 -9.658)"
                fill="#454545"
              />
              <path
                id="Path_967"
                data-name="Path 967"
                d="M19.565,387.614l55.653-24.234V345.946L19.565,370.181Z"
                transform="translate(8.261 -10.595)"
                fill="#454545"
              />
              <path
                id="Path_968"
                data-name="Path 968"
                d="M19.565,416.862l55.653-24.234V376.552L19.565,400.787Z"
                transform="translate(8.261 -11.532)"
                fill="#454545"
              />
              <path
                id="Path_969"
                data-name="Path 969"
                d="M19.565,446.111l55.653-24.234V407.159L19.565,431.392Z"
                transform="translate(8.261 -12.47)"
                fill="#454545"
              />
              <path
                id="Path_970"
                data-name="Path 970"
                d="M19.565,475.359l55.653-24.234V437.764L19.565,462Z"
                transform="translate(8.261 -13.407)"
                fill="#454545"
              />
              <path
                id="Path_971"
                data-name="Path 971"
                d="M19.565,504.608l55.653-24.234v-12L19.565,492.6Z"
                transform="translate(8.261 -14.344)"
                fill="#454545"
              />
              <path
                id="Path_972"
                data-name="Path 972"
                d="M19.565,533.857l55.653-24.234V498.977L19.565,523.212Z"
                transform="translate(8.261 -15.282)"
                fill="#454545"
              />
              <path
                id="Path_973"
                data-name="Path 973"
                d="M19.565,563.1,75.218,538.87v-9.288L19.565,553.817Z"
                transform="translate(8.261 -16.219)"
                fill="#454545"
              />
              <path
                id="Path_974"
                data-name="Path 974"
                d="M19.565,592.354,75.218,568.12v-7.93L19.565,584.422Z"
                transform="translate(8.261 -17.157)"
                fill="#454545"
              />
              <path
                id="Path_975"
                data-name="Path 975"
                d="M64.779,590.795l-20.491,8.923h15.1l5.4-2.35Z"
                transform="translate(18.699 -18.094)"
                fill="#454545"
              />
              <path
                id="Path_976"
                data-name="Path 976"
                d="M258.918,597.66v-4.574l-10.5,4.574-4.888,2.129H255.5l3.415-1.487Z"
                transform="translate(102.822 -18.164)"
                fill="#454545"
              />
              <path
                id="Path_977"
                data-name="Path 977"
                d="M270.869,577.789v-6.573L215.218,595.45v3.668h6.672l4.888-2.129Z"
                transform="translate(90.87 -17.494)"
                fill="#454545"
              />
              <path
                id="Path_978"
                data-name="Path 978"
                d="M215.217,22.747,267.454,0H255.478l-40.26,17.532Z"
                transform="translate(90.87 0)"
                fill="#454545"
              />
              <path
                id="Path_979"
                data-name="Path 979"
                d="M215.217,43.048l55.652-24.235V14.955L215.217,39.191Z"
                transform="translate(90.87 -0.458)"
                fill="#454545"
              />
              <path
                id="Path_980"
                data-name="Path 980"
                d="M215.217,63.559l55.652-24.234v-2.5L215.217,61.059Z"
                transform="translate(90.87 -1.128)"
                fill="#454545"
              />
              <path
                id="Path_981"
                data-name="Path 981"
                d="M215.217,82.928v1.144l.368-.161,55.283-24.073V58.693l-.367.161Z"
                transform="translate(90.87 -1.797)"
                fill="#454545"
              />
              <path
                id="Path_982"
                data-name="Path 982"
                d="M221.89,0h-6.673V2.905Z"
                transform="translate(90.869 0)"
                fill="#454545"
              />
              <path
                id="Path_983"
                data-name="Path 983"
                d="M779.565,570,723.914,594.23v4.851H734.1l45.464-19.8Z"
                transform="translate(305.652 -17.457)"
                fill="#454545"
              />
              <path
                id="Path_984"
                data-name="Path 984"
                d="M750.31,599.751H768.42v-7.886Z"
                transform="translate(316.798 -18.127)"
                fill="#454545"
              />
              <path
                id="Path_985"
                data-name="Path 985"
                d="M723.913,59.838v5.215l55.651-24.234V35.6l-10.5,4.575Z"
                transform="translate(305.652 -1.09)"
                fill="#454545"
              />
              <path
                id="Path_986"
                data-name="Path 986"
                d="M723.913,85.566l55.651-24.235V57.474L723.913,81.709Z"
                transform="translate(305.652 -1.76)"
                fill="#454545"
              />
              <path
                id="Path_987"
                data-name="Path 987"
                d="M723.913,106.077l55.651-24.234v-2.5l-55.651,24.234Z"
                transform="translate(305.652 -2.43)"
                fill="#454545"
              />
              <path
                id="Path_988"
                data-name="Path 988"
                d="M723.913,125.446v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(305.652 -3.1)"
                fill="#454545"
              />
              <path
                id="Path_989"
                data-name="Path 989"
                d="M734.1,0H723.913V4.437Z"
                transform="translate(305.652 0)"
                fill="#454545"
              />
              <path
                id="Path_990"
                data-name="Path 990"
                d="M723.913,24.279,779.565.045V0H761.455L723.913,16.348Z"
                transform="translate(305.652 0)"
                fill="#454545"
              />
              <path
                id="Path_991"
                data-name="Path 991"
                d="M723.913,39.508v5.034l11.56-5.034,44.092-19.2V13.735L723.913,37.969Z"
                transform="translate(305.652 -0.42)"
                fill="#454545"
              />
              <path
                id="Path_992"
                data-name="Path 992"
                d="M623.043,561.9l-55.652,24.234v12.694h14L623.043,580.7Z"
                transform="translate(239.565 -17.209)"
                fill="#454545"
              />
              <path
                id="Path_993"
                data-name="Path 993"
                d="M592.418,599.724h20.058v-8.735Z"
                transform="translate(250.132 -18.1)"
                fill="#454545"
              />
              <path
                id="Path_994"
                data-name="Path 994"
                d="M567.391,532.416v2.5l35.083-15.277,20.568-8.957v-2.5l-26.31,11.457Z"
                transform="translate(239.565 -15.564)"
                fill="#454545"
              />
              <path
                id="Path_995"
                data-name="Path 995"
                d="M567.391,554.284v1.144l.368-.161,55.283-24.073V530.05l-.367.161Z"
                transform="translate(239.565 -16.233)"
                fill="#454545"
              />
              <path
                id="Path_996"
                data-name="Path 996"
                d="M567.391,411.845l55.651-24.234V376.965L567.391,401.2Z"
                transform="translate(239.565 -11.545)"
                fill="#454545"
              />
              <path
                id="Path_997"
                data-name="Path 997"
                d="M567.391,432.356l55.651-24.233v-9.289l-55.651,24.234Z"
                transform="translate(239.565 -12.215)"
                fill="#454545"
              />
              <path
                id="Path_998"
                data-name="Path 998"
                d="M567.391,452.868l55.651-24.234V420.7l-55.651,24.233Z"
                transform="translate(239.565 -12.885)"
                fill="#454545"
              />
              <path
                id="Path_999"
                data-name="Path 999"
                d="M567.391,473.381l55.651-24.234v-6.573l-55.651,24.234Z"
                transform="translate(239.565 -13.554)"
                fill="#454545"
              />
              <path
                id="Path_1000"
                data-name="Path 1000"
                d="M567.391,493.892l55.651-24.234v-5.215l-55.651,24.234Z"
                transform="translate(239.565 -14.224)"
                fill="#454545"
              />
              <path
                id="Path_1001"
                data-name="Path 1001"
                d="M567.391,514.4l55.651-24.235v-3.857l-55.651,24.234Z"
                transform="translate(239.565 -14.894)"
                fill="#454545"
              />
              <path
                id="Path_1002"
                data-name="Path 1002"
                d="M567.391,73.393V88.111l40.189-17.5,15.462-6.734V49.159L573.78,70.611Z"
                transform="translate(239.565 -1.505)"
                fill="#454545"
              />
              <path
                id="Path_1003"
                data-name="Path 1003"
                d="M567.391,115.838,623.043,91.6V78.244l-55.651,24.233Z"
                transform="translate(239.565 -2.396)"
                fill="#454545"
              />
              <path
                id="Path_1004"
                data-name="Path 1004"
                d="M567.391,143.565l55.651-24.234v-12l-55.651,24.234Z"
                transform="translate(239.565 -3.287)"
                fill="#454545"
              />
              <path
                id="Path_1005"
                data-name="Path 1005"
                d="M567.391,171.294l55.651-24.234V136.414l-55.651,24.234Z"
                transform="translate(239.565 -4.178)"
                fill="#454545"
              />
              <path
                id="Path_1006"
                data-name="Path 1006"
                d="M567.391,199.02l55.651-24.233V165.5l-55.651,24.234Z"
                transform="translate(239.565 -5.068)"
                fill="#454545"
              />
              <path
                id="Path_1007"
                data-name="Path 1007"
                d="M567.391,226.748l55.651-24.234v-7.93l-55.651,24.233Z"
                transform="translate(239.565 -5.959)"
                fill="#454545"
              />
              <path
                id="Path_1008"
                data-name="Path 1008"
                d="M567.391,254.475l55.651-24.234v-6.573L567.391,247.9Z"
                transform="translate(239.565 -6.85)"
                fill="#454545"
              />
              <path
                id="Path_1009"
                data-name="Path 1009"
                d="M567.391,282.2l55.651-24.234v-5.215l-55.651,24.234Z"
                transform="translate(239.565 -7.741)"
                fill="#454545"
              />
              <path
                id="Path_1010"
                data-name="Path 1010"
                d="M567.391,309.93l55.651-24.235v-3.857l-55.651,24.235Z"
                transform="translate(239.565 -8.631)"
                fill="#454545"
              />
              <path
                id="Path_1011"
                data-name="Path 1011"
                d="M567.391,337.656l55.651-24.234v-2.5l-55.651,24.234Z"
                transform="translate(239.565 -9.522)"
                fill="#454545"
              />
              <path
                id="Path_1012"
                data-name="Path 1012"
                d="M567.391,364.241v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(239.565 -10.413)"
                fill="#454545"
              />
              <path
                id="Path_1013"
                data-name="Path 1013"
                d="M581.389,0h-14V6.1Z"
                transform="translate(239.565 0)"
                fill="#454545"
              />
              <path
                id="Path_1014"
                data-name="Path 1014"
                d="M567.391,32.933,623.043,8.7V0H602.985L567.391,15.5Z"
                transform="translate(239.565 0)"
                fill="#454545"
              />
              <path
                id="Path_1015"
                data-name="Path 1015"
                d="M567.391,60.383l55.651-24.234V20.074L567.391,44.308Z"
                transform="translate(239.565 -0.615)"
                fill="#454545"
              />
              <path
                id="Path_1016"
                data-name="Path 1016"
                d="M410.87,418.372v5.215l55.651-24.233v-5.216l-10.5,4.574Z"
                transform="translate(173.478 -12.071)"
                fill="#454545"
              />
              <path
                id="Path_1017"
                data-name="Path 1017"
                d="M410.87,444.1l55.651-24.235v-3.857L410.87,440.243Z"
                transform="translate(173.478 -12.741)"
                fill="#454545"
              />
              <path
                id="Path_1018"
                data-name="Path 1018"
                d="M410.87,464.612l55.651-24.234v-2.5L410.87,462.112Z"
                transform="translate(173.478 -13.411)"
                fill="#454545"
              />
              <path
                id="Path_1019"
                data-name="Path 1019"
                d="M410.87,483.98v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(173.478 -14.08)"
                fill="#454545"
              />
              <path
                id="Path_1020"
                data-name="Path 1020"
                d="M410.87,362.052l55.651-24.233V328.53L410.87,352.764Z"
                transform="translate(173.478 -10.062)"
                fill="#454545"
              />
              <path
                id="Path_1021"
                data-name="Path 1021"
                d="M410.87,382.564l55.651-24.234V350.4L410.87,374.633Z"
                transform="translate(173.478 -10.731)"
                fill="#454545"
              />
              <path
                id="Path_1022"
                data-name="Path 1022"
                d="M410.87,398.043v5.034l11.56-5.034,44.092-19.2v-6.573L410.87,396.5Z"
                transform="translate(173.478 -11.401)"
                fill="#454545"
              />
              <path
                id="Path_1023"
                data-name="Path 1023"
                d="M528.261,184.871v5.215l55.651-24.233v-5.216l-10.5,4.574Z"
                transform="translate(223.044 -4.919)"
                fill="#454545"
              />
              <path
                id="Path_1024"
                data-name="Path 1024"
                d="M528.261,210.6l55.651-24.235v-3.857l-55.651,24.235Z"
                transform="translate(223.044 -5.589)"
                fill="#454545"
              />
              <path
                id="Path_1025"
                data-name="Path 1025"
                d="M528.261,231.11l55.651-24.234v-2.5L528.261,228.61Z"
                transform="translate(223.044 -6.259)"
                fill="#454545"
              />
              <path
                id="Path_1026"
                data-name="Path 1026"
                d="M528.261,250.479v1.144l.368-.161,55.283-24.073v-1.144l-.367.161Z"
                transform="translate(223.044 -6.929)"
                fill="#454545"
              />
              <path
                id="Path_1027"
                data-name="Path 1027"
                d="M528.261,128.55l55.651-24.234V95.028l-55.651,24.234Z"
                transform="translate(223.044 -2.91)"
                fill="#454545"
              />
              <path
                id="Path_1028"
                data-name="Path 1028"
                d="M528.261,149.062l55.651-24.234V116.9l-55.651,24.233Z"
                transform="translate(223.044 -3.58)"
                fill="#454545"
              />
              <path
                id="Path_1029"
                data-name="Path 1029"
                d="M528.261,164.541v5.034l11.56-5.034,44.092-19.2v-6.573L528.261,163Z"
                transform="translate(223.044 -4.25)"
                fill="#454545"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
