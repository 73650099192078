import React from "react";
import { Icon, Popconfirm, Card } from "antd";
import FUpload from "../../commons/formFields/FUpload";
import LoadingModal from "../../commons/LoadingModal";
import {
  deleteMembershipCard,
  getMembershipCard,
  saveMembershipCard,
} from "../../../services/firebaseService/endPoints/clubAdmin/membership";
import { connect } from "react-redux";

class ClubMembershipCard extends React.Component {
  constructor() {
    super();
    this.state = {
      imageFile: {},
      image: "",
      isLoading: false,
      membershipCard: "",
    };
  }

  uploadAction = (file) => {
    this.setState({
      isLoading: true,
      loadingTitle: "Uploading Membership Card",
    });
    saveMembershipCard(file, this.props.currentGroup).then((url) => {
      this.setState({ membershipCard: url, isLoading: false });
    });
  };

  handleImageChange = ({ fileList }) => {
    this.uploadAction(fileList[0].originFileObj);
  };

  handleRemoveImage = () => {
    this.setState({
      isLoading: true,
      loadingTitle: "Removing Membership Card",
    });
    deleteMembershipCard(this.props.currentGroup).then(() => {
      this.setState({ membershipCard: "", isLoading: false });
    });
  };

  // static getDerivedStateFromProps(props, state) {
  //   if (state.isLoading && state.action === "add") {
  //     if (props.membershipCard) {
  //       return { isLoading: false, action: "" };
  //     }
  //   }

  //   if (state.isLoading && state.action === "remove") {
  //     if (!props.membershipCard) {
  //       return { isLoading: false, action: "" };
  //     }
  //   }

  //   return null;
  // }

  render() {
    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div
        className="mx-auto"
        style={{ width: "95%", display: "flex", flexDirection: "column" }}
      >
        <LoadingModal
          loading={this.state.isLoading}
          title={this.state.loadingTitle}
        />
        <div
          style={{
            width: "100%",
            paddingTop: 30,
            paddingBottom: 15,
          }}
        >
          <div className="">
            <ul
              className="nav nav-tabs"
              style={{
                fontSize: "15px",
                color: "#ffffff",
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
              }}
            >
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={{
                  borderBottom: `4px solid ${primaryColor}`,
                }}
              >
                <a
                  name="all"
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                >
                  Club Membership Card
                </a>
              </li>
            </ul>
          </div>
        </div>
        {!this.state.isLoading && (
          <>
            {!this.state.membershipCard && (
              <FUpload
                label=""
                action={this.uploadAction}
                fileList={
                  this.state.imageFile.name ? [this.state.imageFile] : []
                }
                onChange={this.handleImageChange}
                membershipCard={true}
                display="col"
                isAvatar="false"
                aspectRatio={16 / 9}
              />
            )}

            {this.state.membershipCard && (
              <Card
                style={{ width: "80%", maxWidth: "500px" }}
                cover={<img alt="example" src={this.state.membershipCard} />}
                actions={[
                  <Popconfirm
                    title="Are you sure ?"
                    onConfirm={this.handleRemoveImage}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Icon type="delete" />
                  </Popconfirm>,
                ]}
              />
            )}
          </>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    getMembershipCard().then((url) => {
      this.setState({
        membershipCard: url,
        isLoading: false,
      });
    });
  }
}

const mapStateToProps = (state) => {
  let currentGroup = Object.keys(state.user.userRoles).find(
    (key) => state.user.userRoles[key] === "parentAdmin",
  );
  return {
    currentGroup,
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(ClubMembershipCard);
