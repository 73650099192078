const initialValue = {
  isAuthenticated: undefined,
  role: "super-admin",
  profile: { groups: {} },
  userRoles: {},
  disableLoginFlow: false,
};

const userReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "UPDATE_ADMIN":
      return {
        isAuthenticated: true,
        role: "admin",
        profile: action.payload.profile,
        userRoles: action.payload.groups,
      };
    case "UPDATE_SUPERADMIN":
      return { isAuthenticated: true, role: "super-admin" };
    case "LOGOUT_USER":
      return { isAuthenticated: false, role: "" };
    case "UPDATE_USER_GROUP":
      return Object.assign(
        {},
        { isAuthenticated: true, role: "admin" },
        { group: { id: "L2rDYYad4koyrAoBRK1l", data: action.payload } }
      );
    case "LOADING_USER":
      return { isAuthenticated: undefined, role: "", profile: { groups: {} } };

    case "DISABLE_LOGIN_FLOW":
      return { ...state, disableLoginFlow: true };

    case "UNAUTHORIZED_USER":
      return { isAuthenticated: "unauthorized" };
    default:
      return { ...state };
  }
};

export default userReducer;
