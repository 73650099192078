import { db, storage } from "../../connection";
import { store } from "../../../../index";
import { applicationError } from "../../../../redux/actions/error";
import { continents, countries } from "countries-list";
import { fbGetGroup } from "../group";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
  updateDoc,
} from "@firebase/firestore";
import logger from "../../../../utils/logger";

let countrtyNameCodeMap = {};
Object.keys(countries).forEach((key) => {
  countrtyNameCodeMap[key] = countries[key].name;
});

export function fbCreateChant(groupId, data) {
  return new Promise((resolve, reject) => {
    const docRef = doc(collection(db, "group", groupId, "chants"));

    setDoc(docRef, {
      ...data,
      createdOn: parseInt(Date.now() / 1000),
      isPublished: true,
    })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        let message = "There was an error creating the chant for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "high",
            err,
            errorCode: "firebase",
          })
        );
      });

    let groupDocRef = doc(db, "group", groupId);

    getDoc(groupDocRef).then((doc) => {
      if (doc.exists()) {
        let sortOrderChantIds = doc.data().sortOrderChantIds || [];
        sortOrderChantIds.push(docRef.id);

        updateDoc(groupDocRef, { sortOrderChantIds })
          .then(() => {
            logger.log("Document successfully updated!");
          })
          .catch((error) => {
            logger.error("Error updating document: ", error);
          });
      }
    });
  });
}

export function fbGetChants(groupId, isPublished, dashboard) {
  return new Promise(async (resolve, reject) => {
    let groupChants = await getDocs(
      query(
        collection(db, "group", groupId, "chants"),
        where("isPublished", "==", !!isPublished)
      )
    )
      .then((querySnapshot) => {
        let result = [];
        querySnapshot.docs.forEach((doc) => {
          if (
            dashboard === "admin" &&
            (!!!doc.data().type ||
              (doc.data().type !== "global" &&
                Array.isArray(doc.data().groups) &&
                doc.data().groups.includes(groupId)))
          ) {
            result.push({ ...doc.data(), id: doc.id });
          }
          if (
            dashboard === "clubadmin" &&
            !!doc.data().type &&
            doc.data().type === "global"
          ) {
            result.push({ ...doc.data(), id: doc.id });
          }
        });
        return result;
      })
      .catch((err) => {
        let message = "There was an error fetching chants for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          })
        );
      });

    let clubId = await getDoc(doc(db, "group", groupId)).then(
      (doc) => doc.data().clubId
    );

    let clubChants = await getDocs(
      query(
        collection(db, "group"),
        where("clubId", "==", clubId),
        where("groupType", "==", "Club"),
        limit(1)
      )
    )
      .then(async (snap) => {
        if (snap.docs.length === 0) {
          return [];
        }
        let chants = [];

        if (dashboard !== "clubadmin") {
          chants = await getDocs(
            query(
              collection(snap.docs[0].ref, "chants"),
              where("type", "==", "global")
            )
          ).then(async (chantsSnap) => {
            let res = [];
            let promises = chantsSnap.docs.map(async (doc) => {
              if (doc.data().groups.includes("global")) {
                res.push({ ...doc.data(), id: doc.id });
              } else {
                let params = doc.data().groups;
                let { supportersCountry, supportersRegion } =
                  await fbGetGroup(groupId);
                let isAvailableForGroup = false;
                if (supportersCountry) {
                  isAvailableForGroup = params.includes(
                    countrtyNameCodeMap[supportersCountry]
                  );
                }
                if (supportersRegion && !isAvailableForGroup) {
                  isAvailableForGroup = params.includes(
                    continents[supportersRegion]
                  );
                }
                if (!isAvailableForGroup) {
                  isAvailableForGroup = params.includes(groupId);
                }

                if (isAvailableForGroup) {
                  res.push({ ...doc.data(), id: doc.id });
                }
              }
            });

            await Promise.all(promises);

            return res;
          });
        }
        return chants;
      })
      .catch((err) => {
        let message = "There was an error fetching chants for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          })
        );
        return [];
      });

    resolve([...groupChants, ...clubChants]);
  });
}

export function fbGetChant(groupId, docId) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "group", groupId, "chants", docId))
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          resolve({ ...querySnapshot.data() });
        } else {
          resolve();
        }
      })
      .catch((err) => {
        let message = "There was an error fetching chant for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          })
        );
      });
  });
}

export function fbUpdateChant(groupId, docId, data) {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId, "chants", docId);
    setDoc(docRef, data, { merge: true })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        let message = "There was an error updating chant for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "high",
            err,
            errorCode: "firebase",
          })
        );
      });
  });
}

export function fbDeleteChant(groupId, docId) {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId, "chants", docId);
    deleteDoc(docRef)
      .then(() => {
        // let message = "There was an error deleting chant for the group"
        // store.dispatch(applicationError({ message, intensity: 'low', show: true, err: {}, errorCode: 'firebase-modal' }))
        resolve();
      })
      .catch((err) => {
        let message = "There was an error deleting chant for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            show: true,
            err,
            errorCode: "firebase-modal",
          })
        );
      });
  });
}
