import { collection, getDocs } from "firebase/firestore";
import { db } from "../../connection";
import { getGroups } from "./groups";

export async function fbGetClubAdminMembers(groupId) {
  let groups = await getGroups();

  let membersPromises = [];

  if (groupId !== null) {
    groups = groups.filter((group) => group.id === groupId);
  }

  groups.forEach((group) => {
    let memberPromise = getDocs(
      collection(db, "userGroup", group.id, "members"),
    )
      .then((snap) => {
        return snap.docs;
      })
      .then((docs) => {
        return new Promise((resolve) => {
          resolve({
            docs: docs,
            groupId: group.id,
            groupName: group.groupName,
            country: group.supportersCountry,
            region: group.supportersRegion,
          });
        });
      });
    membersPromises.push(memberPromise);
  });

  return await Promise.all(membersPromises).then((snap) => {
    let members = [];
    snap.forEach(({ docs, groupName, country, region }) => {
      docs.forEach((memberDoc) => {
        members.push({
          ...memberDoc.data(),
          id: memberDoc.id,
          groupName,
          country,
          region,
        });
      });
    });

    return members;
  });
}
