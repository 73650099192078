import { Result } from "antd";
import React from "react";
import { Redirect } from "react-router-dom";
import CreateContent from "../admin/content/Create";
import ListContent from "../admin/content/List";
// import Faq from "../admin/Faq";
import GroupSettings from "../admin/groupConfig/GroupSettings";
import PaymentDetails from "../admin/groupConfig/PaymentDetails";
import RegistrationCode from "../admin/groupConfig/RegistrationCode";
import HelpPage from "../admin/help/HelpPage";
import ListMember from "../admin/members/List";
import MembershipCard from "../admin/membershipCard/MembershipCard";
import CreateChant from "../admin/ourChants/Create";
import ListChant from "../admin/ourChants/List";
import CreateTicket from "../admin/tickets/Create";
import ListTicket from "../admin/tickets/List";
import ViewTicket from "../admin/tickets/View";
import AdminLogin from "../authentication/AdminLogin";
import AdminRegister from "../authentication/AdminRegister";
import Register from "../authentication/Register";
import Chants from "../clubAdmin/chants/Chants";
import CreateChants from "../clubAdmin/chants/CreateChants";
import Content from "../clubAdmin/contents/Content";
import CreateClubContent from "../clubAdmin/contents/CreateClubContent";
import Gallery from "../clubAdmin/gallery/Gallery";
import ListGiveaways from "../clubAdmin/giveaways/ListGiveaways";
import ListGroups from "../clubAdmin/groups/ListGroups";
import ListMembers from "../clubAdmin/members/List";
import ClubMembershipCard from "../clubAdmin/membership/ClubMembershipCard";
import CreatePoll from "../clubAdmin/polls/Create";
import Polls from "../clubAdmin/polls/List";
import Membership from "../member/Membership";
import CreateGroup from "../superAdmin/group/Create";
import ListGroup from "../superAdmin/group/List";
import SuperAdminPolls from "../superAdmin/polls/List";
import CreateSuperAdminPoll from "../superAdmin/polls/Create";
import CreateGiveaway from "../clubAdmin/giveaways/Create";
import MembershipForm from "../authentication/MembershipForm";
import AdminGallery from "../admin/gallery/Gallery";
import Container from "../admin/website/Container";
import MemberPackageForm from "../admin/members/MemberPackageForm";
import CreateItem from "../admin/store/create";
import StoreContainer from "../admin/store/Container";
import AppSettings from "../admin/groupConfig/AppSetting";
import DirectoryAdd from "../admin/global/directory";
import TransactionReport from "../superAdmin/report/transactions";
import Deactivated from "../superAdmin/deactivated/Deactivated";
import Data from "../superAdmin/data/Data";
import Leaderboard from "../admin/content/Leaderboard";
import LocationTable from "../admin/locations/LocationTable";
import PartnersList from "../admin/partners/PartnersList";
import CreatePartners from "../admin/partners/CreatePartner";
import Welcome from "../admin/content/Welcome";
import DirectoryReview from "../superAdmin/group/Directory";
import StadiumContainer from "../admin/stadium/Container";
import StatusReport from "../superAdmin/status/Report";
// import PurchaseReport from "../admin/purchases/PurchaseReport";
import Logos from "../superAdmin/logos/logos";
import CreateLocations from "../admin/locations/CreateLocation/CreateLocations";
import Adoption from "../superAdmin/adoption/Adoption";
import AdoptionClass from "../superAdmin/adoption/AdoptionClass";
import TicketsReport from "../superAdmin/ticketsReport/TicketsReport";

export const adminRoutes = [
  { path: "/admin/content", component: ListContent, exact: true },
  { path: "/admin/content/leaderboard", component: Leaderboard, exact: true },
  { path: "/admin/content/create", component: CreateContent, exact: true },
  {
    path: "/admin/content/edit/:group/:id",
    component: CreateContent,
    exact: true,
  },
  {
    path: "/admin/content/view/:group/:id",
    component: CreateContent,
    exact: true,
  },
  {
    path: "/admin/directory/groupListing",
    component: DirectoryAdd,
    exact: true,
  },
  // { path: "/admin/promote", component: Promote, exact: true },
  { path: "/admin/membershipcard", component: MembershipCard, exact: true },
  // {
  //   path: "/admin/members/purchaseReport",
  //   component: PurchaseReport,
  //   exact: true,
  // },
  { path: "/admin/members/:list", component: ListMember, exact: true },
  { path: "/admin/members", component: ListMember, exact: true },
  {
    path: "/admin/members/acceptRequest/:id/:group",
    component: ListMember,
    exact: true,
  },
  { path: "/admin/store/create", component: CreateItem, exact: true },
  { path: "/admin/store/:tab", component: StoreContainer, exact: true },
  { path: "/admin/store/edit/:id", component: CreateItem, exact: true },
  { path: "/admin/registrationCode", component: RegistrationCode, exact: true },
  { path: "/admin/groupSettings", component: GroupSettings, exact: true },
  // { path: "/admin/faq", component: Faq, exact: true },
  { path: "/admin/chants/create", component: CreateChant, exact: true },
  { path: "/admin/appSettings", component: AppSettings, exact: true },
  { path: "/admin/help", component: HelpPage, exact: true },
  {
    path: "/admin/chants/edit/:group/:id",
    component: CreateChant,
    exact: true,
  },
  { path: "/admin/gallery", component: AdminGallery, exact: true },
  { path: "/admin/welcome", component: Welcome, exact: true },
  { path: "/admin/content/locations", component: LocationTable, exact: true },
  {
    path: "/admin/content/locations/create",
    component: CreateLocations,
    exact: true,
  },
  {
    path: "/admin/content/locations/edit/:id",
    component: CreateLocations,
    exact: true,
  },
  { path: "/admin/chants", component: ListChant, exact: true },
  { path: "/admin/partners/add", component: CreatePartners, exact: true },
  { path: "/admin/partners/edit/:id", component: CreatePartners, exact: true },
  { path: "/admin/partners/:path", component: PartnersList, exact: true },
  { path: "/admin/tickets/create", component: CreateTicket, exact: true },
  { path: "/admin/tickets/:tab", component: ListTicket, exact: true },
  { path: "/admin/website/:tab", component: Container, exact: true },
  {
    path: "/admin/tickets/edit/:group/:id",
    component: CreateTicket,
    exact: true,
  },
  {
    path: "/admin/tickets/:group/:id",
    component: ViewTicket,
    exact: true,
  },
  { path: "/admin/paymentDetails", component: PaymentDetails, exact: true },
  {
    path: "/admin/members/:groupName/add",
    component: MembershipForm,
    exact: true,
  },

  {
    path: "/admin/members/addPackage",
    component: MemberPackageForm,
    exact: true,
  },
  { path: "/admin/stadium/:tab", component: StadiumContainer, exact: true },
  {
    path: "/login",
    render: () => <Redirect to="/admin/content" />,
    exact: true,
  },
  { path: "/", render: () => <Redirect to="/admin/content" />, exact: true },
];

export const superAdminRoutes = [
  { path: "/superadmin/groups/create", component: CreateGroup, exact: true },
  { path: "/superadmin/groups", component: ListGroup, exact: true },
  { path: "/superadmin/groups/edit/:id", component: CreateGroup, exact: true },
  { path: "/superadmin/polls", component: SuperAdminPolls, exact: true },
  {
    path: "/superadmin/polls/create",
    component: CreateSuperAdminPoll,
    exact: true,
  },
  {
    path: "/superadmin/polls/edit/:id",
    component: CreateSuperAdminPoll,
    exact: true,
  },
  {
    path: "/superadmin/report/transactions",
    component: TransactionReport,
    exact: true,
  },
  { path: "/superadmin/deactivated", component: Deactivated, exact: true },
  { path: "/superadmin/data", component: Data, exact: true },
  { path: "/superadmin/directory", component: DirectoryReview, exact: true },
  { path: "/superadmin/adoption", component: AdoptionClass, exact: true },
  {
    path: "/superadmin/missing-transactions",
    component: TicketsReport,
    exact: true,
  },

  { path: "/superadmin/logos", component: Logos, exact: true },
  { path: "/superadmin/status/report", component: StatusReport, exact: true },

  {
    path: "/login",
    render: () => <Redirect to="/superadmin/groups" />,
    exact: true,
  },
  {
    path: "/",
    render: () => <Redirect to="/superadmin/groups" />,
    exact: true,
  },
];

export const unauthenticatedRoutes = [
  { path: "/login", component: AdminLogin, exact: true },
  { path: "/adminlogin", exact: true },
  { path: "/", render: () => <Redirect to="/login" />, exact: true },
  { path: "/admin/:path", render: () => <Redirect to="/login" /> },
];

export const memberRoutes = [
  { path: "/membership", component: Membership, exact: true },
];

export const commonRoutes = [
  { path: "/admin/register/:email", component: AdminRegister, exact: true },
  { path: "/register", component: Register, exact: true },
  { path: "/:groupName/join", component: MembershipForm, exact: true },
  {
    component: () => {
      return (
        <Result status="404" title="404" subTitle="This page is not found" />
      );
    },
  },
];

export const clubAdminRoutes = [
  { path: "/clubadmin/groups", component: ListGroups, exact: true },
  {
    path: "/clubadmin/groups/:id/members",
    component: ListMembers,
    exact: true,
  },
  { path: "/clubadmin/members", component: ListMembers, exact: true },
  { path: "/clubadmin/content", component: Content, exact: true },
  {
    path: "/clubadmin/content/create",
    component: CreateClubContent,
    exact: true,
  },
  {
    path: "/clubadmin/content/edit/:group/:id",
    component: CreateClubContent,
    exact: true,
  },
  { path: "/clubadmin/gallery", component: Gallery, exact: true },
  { path: "/clubadmin/giveaways", component: ListGiveaways, exact: true },
  {
    path: "/clubadmin/giveaways/create",
    component: CreateGiveaway,
    exact: true,
  },
  {
    path: "/clubadmin/giveaways/edit/:group/:id",
    component: CreateGiveaway,
    exact: true,
  },
  { path: "/clubadmin/predictions", component: ListGiveaways, exact: true },
  { path: "/clubadmin/polls", component: Polls, exact: true },
  { path: "/clubadmin/polls/create", component: CreatePoll, exact: true },
  {
    path: "/clubadmin/polls/edit/:group/:id",
    component: CreatePoll,
    exact: true,
  },
  { path: "/clubadmin/var", component: ListGiveaways, exact: true },
  { path: "/clubadmin/chants", component: Chants, exact: true },
  { path: "/clubadmin/help", component: HelpPage, exact: true },
  { path: "/clubadmin/chants/create", component: CreateChants, exact: true },
  {
    path: "/clubadmin/chants/edit/:group/:id",
    component: CreateChants,
    exact: true,
  },
  { path: "/clubadmin/approvals", component: ListGiveaways, exact: true },
  { path: "/clubadmin/membership", component: ClubMembershipCard, exact: true },
  {
    path: "/login",
    render: () => <Redirect to="/clubadmin/groups" />,
    exact: true,
  },
  { path: "/", render: () => <Redirect to="/clubadmin/groups" />, exact: true },
];
