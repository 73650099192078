import Axios from "../connection";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";

export const afGetSeasons = () => {
  return new Promise((resolve, reject) => {
    Axios.get("/leagues/seasons")
      .then((response) => {
        const seasons = [
          response.data.response.pop(),
          response.data.response.pop(),
          response.data.response.pop(),
          response.data.response.pop(),
          response.data.response.pop(),
        ];
        resolve(seasons);
      })
      .catch((err) => {
        store.dispatch(
          applicationError({
            err,
            message: "Unable to fetch seasons",
            errorCode: "api-football",
          }),
        );
      });
  });
};
