import { Popconfirm, Table, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  closeEntries,
  deleteGiveaway,
  fbGetGiveaways,
  markPrizeDelivered,
  postLive,
  selectWinner,
  sendWarning,
} from "../../../services/firebaseService/endPoints/clubAdmin/giveaway";
import { getDefaultClub } from "../../../services/firebaseService/endPoints/clubAdmin/groups";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";

class ListGiveaways extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      isLoading: true,
    };
  }

  handleEditGiveaway(data) {
    let clubId = getDefaultClub();
    this.props.history.push(`/clubadmin/giveaways/edit/${clubId}/${data.id}`);
  }

  handleDeleteGiveaway(data) {
    this.setState({
      isLoading: true,
    });
    deleteGiveaway(data.id).then(() => {
      fbGetGiveaways(this.props.currentGroup).then((tableData) => {
        this.setState({
          tableData,
          isLoading: false,
        });
      });
    });
  }

  postLive = (id) => {
    postLive(id).then(() => {
      fbGetGiveaways(this.props.currentGroup).then((tableData) => {
        this.setState({
          tableData,
          isLoading: false,
        });
      });
    });
  };

  sendWarning = (id) => {
    sendWarning(id).then(() => {
      fbGetGiveaways(this.props.currentGroup).then((tableData) => {
        this.setState({
          tableData,
          isLoading: false,
        });
      });
    });
  };

  closeEntries = (id) => {
    closeEntries(id).then(() => {
      fbGetGiveaways(this.props.currentGroup).then((tableData) => {
        this.setState({
          tableData,
          isLoading: false,
        });
      });
    });
  };

  selectWinner = (id) => {
    selectWinner(id).then(() => {
      fbGetGiveaways(this.props.currentGroup).then((tableData) => {
        this.setState({
          tableData,
          isLoading: false,
        });
      });
    });
  };

  markPrizeDelivered = (id) => {
    markPrizeDelivered(id).then(() => {
      fbGetGiveaways(this.props.currentGroup).then((tableData) => {
        this.setState({
          tableData,
          isLoading: false,
        });
      });
    });
  };

  render() {
    const columns = [
      {
        title: window.screen.width > 500 ? "Action" : "",
        className: "header-white",
        render: (data) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {data.isDraft && (
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  this.postLive(data.id);
                }}
                onCancel={() => {}}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger mt-3 mb-2"
                >
                  Post Live
                </button>
              </Popconfirm>
            )}
            {data.isLive && !data.isWarningDone && (
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  this.sendWarning(data.id);
                }}
                onCancel={() => {}}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger mt-3 mb-2"
                >
                  Send 'Closing Soon'
                </button>
              </Popconfirm>
            )}
            {data.isWarningDone && !data.isEntriesClosed && (
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  this.closeEntries(data.id);
                }}
                onCancel={() => {}}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger mt-3 mb-2"
                >
                  Close Entries
                </button>
              </Popconfirm>
            )}
            {((data.isEntriesClosed &&
              data.winners &&
              data.winners.length < data.quantity) ||
              !data.winners) && (
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  this.selectWinner(data.id);
                }}
                onCancel={() => {}}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger mt-3 mb-2"
                >
                  {`Select Winner ${data.winners && Array.isArray(data.winners) ? data.winners.length : 1}`}
                </button>
              </Popconfirm>
            )}
            {data.winners &&
              Array.isArray(data.winners) &&
              data.winners.length === data.quantity &&
              !data.isCompleted && (
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    this.markPrizeDelivered(data.id);
                  }}
                  onCancel={() => {}}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger mt-3 mb-2"
                  >
                    Mark Prize Delivered
                  </button>
                </Popconfirm>
              )}
            {!data.isCompleted && (
              <>
                <Tooltip title="edit">
                  <i
                    className="fa fa-pencil cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      this.handleEditGiveaway(data);
                    }}
                  />
                </Tooltip>
                <Tooltip title="delete">
                  <i
                    className="fa fa-trash cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      this.handleDeleteGiveaway(data);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        ),
      },
      {
        title: "Status",
        key: "status",
        className: "header-white",
        render: (data) => {
          let status;
          if (data.isDraft) {
            status = "Pending";
          } else if (data.isLive) {
            status = "Live";
          } else if (data.isEntriesClosed && !data.winners) {
            status = "Entries Closed";
          } else if (
            data.winners.length === data.quantity &&
            !data.isCompleted
          ) {
            status = "Deliver Prize";
          } else {
            status = "Completed";
          }

          return <span>{status}</span>;
        },
      },
      {
        title: "Post Date",
        key: "postDate",
        className: "header-white",
        dataIndex: "addedOn",
        render: (date) => <span>{moment.unix(date).format("MM/DD/YY")}</span>,
      },
      {
        title: "Close Date",
        key: "closeDate",
        className: "header-white",
        dataIndex: "eventDate",
        render: (date) =>
          date && <span>{moment.unix(date).format("MM/DD/YY")}</span>,
      },
      {
        title: "Name/Item",
        key: "itemName",
        className: "header-white",
        dataIndex: "itemName",
        render: (itemName) => <span>{itemName}</span>,
      },
      {
        title: "Sponsor",
        key: "sponsor",
        className: "header-white",
        dataIndex: "donatedByText",
        render: (sponsor) => <span>{sponsor}</span>,
      },
      {
        title: "#\nWinners",
        key: "winners",
        className: "header-white",
        render: (winners) => (Array.isArray(winners) ? winners.length : 0),
      },
      {
        title: "#\nEntrants",
        key: "entrants",
        className: "header-white",
        dataIndex: "participants",
        render: (entrants) => (Array.isArray(entrants) ? entrants.length : 0),
      },
      {
        title: "Winners",
        key: "winners",
        className: "header-white",
        dataIndex: "winners",
        render: (winners) => {
          return (
            <ul style={{ listStyle: "none" }}>
              {Array.isArray(winners) &&
                winners.map((winner) => <li>{winner.userName}</li>)}
            </ul>
          );
        },
      },
    ];
    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div
        className="mx-auto"
        style={{ width: "95%", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            width: "100%",
            paddingTop: 30,
            display: "flex",
            flexDirection: "column",
            paddingBottom: 15,
          }}
        >
          <div className="">
            <ul
              className="nav nav-tabs"
              style={{ color: "#ffffff", position: "relative" }}
            >
              <li
                className="nav-item"
                style={{ borderBottom: `4px solid ${primaryColor}` }}
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="current"
                >
                  Giveaways
                </a>
              </li>
              <li
                className="ml-auto align-items-center collapsible-horizontal tab-menu"
                id="clubadmin-giveaways-utilities"
              >
                <a
                  class="icon"
                  style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                  onClick={() =>
                    switchCollapseHorizontal("clubadmin-giveaways-utilities")
                  }
                >
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm mr-1 .button-drop-shadow"
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: primaryColor || "#999999",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      this.props.history.push("/clubadmin/giveaways/create");
                    }}
                  >
                    Create New
                  </button>
                </div>
                <div className="show-for-mobile">
                  <div className="menu-list">
                    <div
                      style={{
                        backgroundColor: primaryColor || "#999999",
                        padding: "5px 10px",
                        color: "#000000",
                      }}
                      onClick={() => {
                        this.props.history.push("/clubadmin/polls/create");
                      }}
                    >
                      Create New
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ width: "100%", overflow: "scroll" }}>
          <Table
            columns={columns}
            className={`table-backdrop mt-3 mb-3`}
            bodyStyle={{
              fontSize: window.screen.width > 500 ? "18px" : "12px",
              backgroundColor: "#ffffff",
              // boxShadow: "0px 3px 10px 1px",
            }}
            dataSource={this.state.tableData}
            loading={this.state.isLoading}
            overflow="hidden"
            bordered
          />
        </div>
      </div>
    );
  }

  componentDidMount() {
    fbGetGiveaways(this.props.currentGroup).then((tableData) => {
      this.setState({
        tableData,
        isLoading: false,
      });
    });
  }
}

const mapStateToProps = (state) => {
  let currentGroup = Object.keys(state.user.userRoles).find(
    (key) => state.user.userRoles[key] === "parentAdmin"
  );
  return {
    adminData: state.adminData,
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
    user: state.user,
    currentGroup,
  };
};

export default connect(mapStateToProps)(ListGiveaways);
