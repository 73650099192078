import { continents, countries } from "countries-list";
import moment from "moment";
import { db, storage } from "../../connection";
import { getDefaultClub } from "./groups";
import { fbGetClubAdminMembers } from "./members";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

let countrtyNameCodeMap = {};
Object.keys(countries).forEach((key) => {
  countrtyNameCodeMap[key] = countries[key].name;
});

export function fbCreateClubPoll(data, pollImage, sponsorImage) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = doc(collection(db, "polls", `${clubId}_club_polls`, "poll"));

    let pollImageUrl;
    let sponsorImageUrl;
    if (pollImage) {
      try {
        pollImageUrl = await fbUploadPollImage(pollImage, clubId, docRef);
        data.pollImage = pollImageUrl || "";
      } catch (e) {
        let message =
          "Error uploading poll image, operation could not be completed, contact admin";
        reject({ message });
        return;
      }
    }
    if (sponsorImage) {
      try {
        sponsorImageUrl = await fbUploadSponsorImage(
          sponsorImage,
          clubId,
          docRef,
        );
        data.sponsorImage = sponsorImageUrl || "";
      } catch (e) {
        let message =
          "Error uploading sponsor image, operation could not be completed, contact admin";
        reject({ message });
        return;
      }
    }

    await setDoc(
      docRef,
      {
        ...data,
      },
      {
        merge: true,
      },
    )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function fbUpdateClubPoll(data, pollImage, sponsorImage, id) {
  return new Promise(async (resolve, reject) => {
    let clubId = getDefaultClub();
    let docRef = doc(db, "polls", `${clubId}_club_polls`, "poll", id);

    let pollImageUrl;
    let sponsorImageUrl;
    if (pollImage) {
      try {
        pollImageUrl = await fbUploadPollImage(pollImage, clubId, docRef);
        data.pollImage = pollImageUrl || "";
      } catch (e) {
        let message =
          "Error uploading poll image, operation could not be completed, contact admin";
        reject({ message });
        return;
      }
    }
    if (sponsorImage) {
      try {
        sponsorImageUrl = await fbUploadSponsorImage(
          sponsorImage,
          clubId,
          docRef,
        );
        data.sponsorImage = sponsorImageUrl || "";
      } catch (e) {
        let message =
          "Error uploading sponsor image, operation could not be completed, contact admin";
        reject({ message });
        return;
      }
    }

    await setDoc(
      docRef,
      {
        ...data,
      },
      {
        merge: true,
      },
    )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function fbGetPolls() {
  let clubId = getDefaultClub();
  let members = await fbGetClubAdminMembers(null);

  let polls = await getDocs(
    collection(db, "polls", `${clubId}_club_polls`, "poll"),
  ).then((snap) => {
    let result = [];
    snap.docs.forEach((doc) => {
      let stats = {};
      let optKeys = ["op1", "op2", "op3", "op4", "op5"];
      optKeys
        .filter((key) => doc.data()[key] && doc.data()[key] !== null)
        .forEach((key) => {
          stats[doc.data()[key].name] =
            (doc.data()[key].votes * 100) / doc.data().noOfPolls;
          if (isNaN(stats[doc.data()[key].name])) {
            stats[doc.data()[key].name] = 0;
          }
        });
      let groups = doc.data().groups;
      let targetMembersCount;

      if (groups.includes("global")) {
        targetMembersCount = members.length;
      } else {
        targetMembersCount = members.filter(
          (member) =>
            groups.includes(member.groupId) ||
            groups.includes(continents[member.region]) ||
            groups.includes(countrtyNameCodeMap[member.country]),
        ).length;
      }

      result.push({ ...doc.data(), id: doc.id, stats, targetMembersCount });
    });
    return result;
  });

  // let entriesPromises = polls.map((poll) => {
  //     return db
  //         .collection('polls')
  //         .doc(`${clubId}_polls`)
  //         .collection('poll')
  //         .doc(poll.id)
  //         .collection('entries')
  //         .get()
  //         .then(snap => {
  //             let entries = [];
  //             snap.docs.forEach(entry => {
  //                 entries.push({ ...entry.data(), id: entry.id });
  //             });
  //             return {
  //                 ...poll,
  //                 entries
  //             }
  //     })
  // })

  let filteredPolls = polls.filter((poll) => !poll.isActive);
  polls = [
    ...polls
      .filter((poll) => poll.isActive && moment().unix() < poll.closesOn)
      .sort((a, b) => b.addedOn - a.addedOn),
    ...polls
      .filter((poll) => moment().unix() > poll.closesOn)
      .sort((a, b) => b.addedOn - a.addedOn),
    ...filteredPolls.sort((a, b) => b.addedOn - a.addedOn),
  ];
  return polls;
}

function fbUploadPollImage(image, id, ref) {
  let path = `/polls/${id}/${ref.id}/pollImage`;
  return uploadImage(image, path);
}
function fbUploadSponsorImage(image, id, ref) {
  let path = `/polls/${id}/${ref.id}/sponsorImage`;
  return uploadImage(image, path);
}

async function uploadImage(image, path) {
  let storageRef = ref(storage, path);
  let url = await uploadBytes(storageRef, image)
    .then((snap) => {
      return getDownloadURL(snap.ref);
    })
    .then((url) => url);
  return url;
}

export async function getPoll(id) {
  let clubId = getDefaultClub();
  let data = await getDoc(
    doc(db, "polls", `${clubId}_club_polls`, "poll", id),
  ).then((doc) => {
    return doc.data();
  });
  return data;
}

export async function deletePolls(id) {
  let clubId = getDefaultClub();
  await deleteDoc(doc(db, "polls", `${clubId}_club_polls`, "poll", id));
}

export async function postLive(id) {
  let clubId = getDefaultClub();
  let docRef = doc(db, "polls", `${clubId}_club_polls`, "poll", id);

  let doc = await getDoc(docRef).then((doc) => doc.data());

  let data = {
    isActive: true,
    postedOn: moment().unix(),
    closesOn:
      moment().unix() +
      doc.duration.days * 24 * 60 * 60 +
      doc.duration.hours * 60 * 60,
  };

  await docRef.update({ ...data });
}
