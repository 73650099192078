import Axios from "axios";

export const setTicketsReportLoading = (loading) => ({
  type: "SET_MISSING_TICKET_TXNS_LOADING",
  payload: loading,
});

export const setMissingTransactions = (clubId) => {
  return async function (dispatch) {
    try {
      dispatch(setTicketsReportLoading(true));

      const { data } = await Axios.post(
        "https://us-central1-chant2019.cloudfunctions.net/findMissingPurchases",
        { clubId: clubId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const missingTransactions =
        data?.data?.flatMap((item) =>
          item.tickets?.flatMap(
            (ticket) =>
              ticket?.missingTransactions?.map((txn) => ({
                ...txn,
                groupName: item.groupName,
              })) || []
          )
        ) || [];

      const payload = {
        id: clubId,
        data: missingTransactions,
      };

      dispatch({ type: "SET_MISSING_TICKET_TXNS", payload });
      dispatch(setTicketsReportLoading(false));
    } catch (error) {
      dispatch(setTicketsReportLoading(false));
    }
  };
};
