import axios from "axios";

const Axios = axios.create({
  // baseURL: "https://api-football-v1.p.rapidapi.com/v2",
  // headers: {
  //     "X-RapidAPI-Host": "api-football-v1.p.rapidapi.com",
  //     "X-RapidAPI-Key": "b75bc1f847msh9fbf60e650fefbcp16ea78jsn2dc550f609de"
  // }
  baseURL: "https://v3.football.api-sports.io",
  headers: {
    "X-RapidAPI-Key": "b5f53138c1b07d0bf42b14dac5171ace",
  },
});

export default Axios;
