import { Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import { startUpdateUser } from "../../../redux/actions/user";
import { db } from "../../../services/firebaseService/connection";
import { doc, updateDoc } from "@firebase/firestore";
import {
  GUIDE_ENGAGE,
  TOPIC_ADD_LEADERBOARD_REWARDS,
} from "../../../constants/gettingStarted";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";

class Leaderboard extends React.Component {
  state = {
    rewards: {
      eventLeaderboard: "",
      scoreLeaderboard: "",
      statsLeaderboard: "",
    },
    loading: false,
    pageStatus: "loading",
  };

  save = async () => {
    this.setState({
      loading: true,
    });
    let appSettings = { ...this.state };
    delete appSettings.loading;
    await updateDoc(
      doc(db, "group", this.props.currentGroup),
      appSettings
    ).then(() => {
      this.setState({
        loading: false,
      });
    });
    this.props.dispatch(startUpdateUser());
  };

  handleInputChange = (e) => {
    this.setState({
      rewards: {
        ...this.state.rewards,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div style={{ width: "100%", height: "100%", padding: "30px 26px" }}>
        <ul className="nav nav-tabs">
          <li
            className="nav-item"
            style={{
              fontSize: 22,
              fontWeight: "bold",
              borderBottom: `4px solid ${primaryColor}`,
              color: "#ffffff",
            }}
          >
            <a>Leaderboard</a>
          </li>
        </ul>
        <form
          className="mb-4 p-3 px-md-5 py-md-4 mx-md-auto"
          style={{
            maxWidth: "1400px",
            width: "100%",
            border: "1px solid #dee2e6",
            borderRadius: "0.25rem",
            marginTop: 30,
            backgroundColor: "#ffffff",
            // boxShadow: "0px 3px 10px 1px",
          }}
        >
          <div className="row">
            <p>
              Groups can offer awards for event attendance, score predictions
              and (when available) stat predictions. Add rewards below to appear
              in the Leaderboard cards. Consider end of season prizes for the
              top 5-10 members (or enter the top 'x' into an end of season
              drawing).
            </p>
          </div>
          {/*Event leaderboard*/}
          <div className="row mt-4">
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {" "}
              Event Leaderboard Rewards{" "}
            </span>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="row">
                Set points by event when creating watch parties, tailgates,
                volunteer, travel and special events. Enter rewards offered to
                leaders at the end of the season.
              </div>
            </div>
            <div className="col-md-8">
              <textarea
                rows={3}
                style={{
                  width: "100%",
                  borderRadius: 5,
                  borderColor: "#ced4da",
                  padding: 5,
                }}
                maxLength={250}
                value={this.state.rewards.eventLeaderboard}
                onChange={this.handleInputChange}
                name="eventLeaderboard"
                display="col"
                placeholder="Max 250 characters."
                tabIndex="2"
              />
            </div>
          </div>

          {/*Score leaderboard*/}
          <div className="row mt-4">
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {" "}
              Score Leaderboard Rewards{" "}
            </span>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="row">
                Enter rewards offered to leaders at the end of the season.
              </div>
            </div>
            <div className="col-md-8">
              <textarea
                rows={3}
                style={{
                  width: "100%",
                  borderRadius: 5,
                  borderColor: "#ced4da",
                  padding: 5,
                }}
                maxLength={250}
                value={this.state.rewards.scoreLeaderboard}
                onChange={this.handleInputChange}
                name="scoreLeaderboard"
                display="col"
                placeholder="Max 250 characters."
                tabIndex="2"
              />
            </div>
          </div>

          {/*Stats leaderboard*/}
          <div className="row mt-4">
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {" "}
              Stats Leaderboard Rewards{" "}
            </span>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="row">
                Available for leagues that provide full match stats (possession,
                shots, etc). Enter rewards offered to leaders at the end of the
                season.
              </div>
            </div>
            <div className="col-md-8">
              <textarea
                rows={3}
                style={{
                  width: "100%",
                  borderRadius: 5,
                  borderColor: "#ced4da",
                  padding: 5,
                }}
                maxLength={250}
                value={this.state.rewards.statsLeaderboard}
                onChange={this.handleInputChange}
                name="statsLeaderboard"
                display="col"
                placeholder="Max 250 characters."
                tabIndex="2"
              />
            </div>
          </div>
        </form>
        <div className="row justify-content-center mt-4">
          <button
            className="btn btn-success default-text-color"
            style={{ backgroundColor: primaryColor, borderColor: primaryColor }}
            tabIndex="14"
            onClick={this.save}
          >
            {this.state.loading ? (
              <Spin size="large" spinning={true} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    );
  }
  static getDerivedStateFromProps(props, state) {
    if (state.pageStatus === "loading") {
      let groupData = props.adminData.data;
      let appSettings = {
        rewards: {
          eventLeaderboard: (groupData.rewards || {}).eventLeaderboard || "",
          scoreLeaderboard: (groupData.rewards || {}).scoreLeaderboard || "",
          statsLeaderboard: (groupData.rewards || {}).statsLeaderboard || "",
        },
        loading: false,
      };
      return { ...appSettings, pageStatus: "done" };
    }
    return null;
  }

  setGettingStartedItemStatus() {
    let groupData = this.props.adminData.data;
    if (groupData?.rewards && Object.values(groupData?.rewards)?.length > 0) {
      this.props.dispatch(
        setGettingStartedItemStatus(
          this.props.currentGroup,
          GUIDE_ENGAGE,
          TOPIC_ADD_LEADERBOARD_REWARDS,
          true
        )
      );
    }
  }

  componentDidMount() {
    this.setGettingStartedItemStatus();
  }

  componentDidUpdate(prevProps) {
    let groupData = this.props.adminData.data;

    if (this.props.currentGroup !== prevProps.currentGroup) {
      let appSettings = {
        rewards: {
          eventLeaderboard: (groupData.rewards || {}).eventLeaderboard || "",
          scoreLeaderboard: (groupData.rewards || {}).scoreLeaderboard || "",
          statsLeaderboard: (groupData.rewards || {}).statsLeaderboard || "",
        },
        loading: false,
      };
      this.setState({ ...appSettings });
      this.setGettingStartedItemStatus();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(Leaderboard);
