import { Alert } from "antd";
import { continents, countries } from "countries-list";
import React from "react";
import { connect } from "react-redux";
import Poll from "../../../models/clubadmin/Polls";
import { applicationError } from "../../../redux/actions/error";
import { getGroups } from "../../../services/firebaseService/endPoints/clubAdmin/groups";
import {
  fbCreateClubPoll,
  fbUpdateClubPoll,
  getPoll,
} from "../../../services/firebaseService/endPoints/clubAdmin/polls";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import FUpload from "../../commons/formFields/FUpload";
import LoadingModal from "../../commons/LoadingModal";
import Search from "../../commons/Search";

let countrtyNameCodeMap = {};
Object.keys(countries).forEach((key) => {
  countrtyNameCodeMap[key] = countries[key].name;
});

class CreatePoll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: !!props.match.params.id,
      editMode: !!this.props.match.params.id,
      groups: [],
      count: "0 groups",
      params: [],
      optionKeys: ["op1", "op2"],
      imageList: [],
      allGroups: [],
      errorInForm: {
        isError: false,
        visible: false,
        message: "",
      },
      sponsorImage: undefined,
      imageFile: undefined,
      editConfigurations: {
        pollImageChanged: false,
        sponsorImageChanged: false,
      },
      sponsorImageList: [],
    };
  }

  handleAdd = () => {
    let optionKeys = this.state.optionKeys;
    optionKeys.push(`op${optionKeys.length + 1}`);
    this.setState({
      optionKeys,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOptionChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleImageChange = ({ fileList }) => {
    let result = {};
    if (fileList.length === 0) {
      result.imageFile = {};
    } else {
      result.imageFile = fileList[0].originFileObj;
    }
    result.imageList = fileList;

    this.setState((prevState) => ({
      ...result,
      editConfigurations: {
        ...prevState.editConfigurations,
        pollImageChanged: true,
      },
    }));
  };

  uploadAction = (file) => {
    this.setState((prevState) => ({
      imageFile: file,
      editConfigurations: {
        ...prevState.editConfigurations,
        pollImageChanged: true,
      },
    }));
  };

  handleSearchParamChange = (list) => {
    let params = [];
    list.forEach((param) => {
      if (
        Object.values(continents).includes(param) ||
        Object.values(countrtyNameCodeMap).includes(param)
      ) {
        params.push(param);
      } else {
        let group = this.state.allGroups.find(
          (group) => group.groupName === param
        );
        if (group) {
          params.push(group.id);
        }
      }
    });
    this.setState((state) => {
      let groupCount =
        list.length > 0
          ? list.includes("global")
            ? this.state.allGroups.length
            : this.state.allGroups.filter(
                (group) =>
                  params.includes(group.id) ||
                  params.includes(
                    countrtyNameCodeMap[group.supportersCountry]
                  ) ||
                  params.includes(continents[group.supportersRegion])
              ).length
          : this.state.allGroups.length;
      return { groups: params, count: `${groupCount} groups` };
    });
  };

  handleSponsorImageChange = ({ fileList }) => {
    let result = {};
    if (fileList.length === 0) {
      result.sponsorImageList = [];
    } else {
      result.sponsorImage = fileList[0].originFileObj;
    }
    result.sponsorImageList = fileList;

    this.setState((prevState) => ({
      ...result,
      editConfigurations: {
        ...prevState.editConfigurations,
        sponsorImageChanged: true,
      },
    }));
  };

  sponsorUploadAction = (file) => {
    this.setState((prevState) => ({
      sponsorImage: file,
      editConfigurations: {
        ...prevState.editConfigurations,
        sponsorImageChanged: true,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      isLoading: true,
    });

    let data = new Poll();
    data.toDataset({
      ...this.state,
      createdByDocId: this.props.user.uid,
      createdByDocName: this.props.user.displayName,
    });
    data.type = this.props.currentGroup;

    if (data.hasError) {
      window.scrollTo(0, 0);
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: data.hasError.message,
        },
        isLoading: false,
      });
      window.scrollTo(0, 0);
      setTimeout(this.handleErrorMessageClose, 5000);
    } else {
      let promise;
      if (!this.state.editMode) {
        promise = fbCreateClubPoll(
          data,
          this.state.imageFile,
          this.state.sponsorImage
        );
      } else {
        promise = fbUpdateClubPoll(
          data,
          this.state.editConfigurations.pollImageChanged
            ? this.state.imageFile
            : undefined,
          this.state.editConfigurations.sponsorImageChanged
            ? this.state.sponsorImage
            : undefined,
          this.props.match.params.id
        );
      }

      promise
        .then(() => {
          this.props.history.push("/clubadmin/polls/");
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.setState({
            errorInForm: {
              isError: true,
              visible: true,
              message: err.message,
            },
            isLoading: false,
          });
          window.scrollTo(0, 0);
          setTimeout(this.handleErrorMessageClose, 5000);
        });
    }
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  render() {
    let duration = {
      hours: Array.from({ length: 24 }, (_, k) => k),
      days: Array.from({ length: 10 }, (_, k) => k),
    };
    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <>
        <LoadingModal loading={this.state.isLoading} />
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}
        <div className="mx-md-5 p-2 border-box">
          <div
            style={{
              width: "100%",
              paddingTop: 30,
            }}
          >
            <ul
              className="nav nav-tabs"
              style={{ color: "#ffffff", position: "relative" }}
            >
              <li
                className="nav-item"
                style={{ borderBottom: `4px solid ${primaryColor}` }}
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="current"
                >
                  Create Poll
                </a>
              </li>
            </ul>
          </div>
          <form
            onSubmit={this.handleSubmit}
            className="border rounded p-3 px-3 mt-2"
            style={{
              background: "#ffffff",
            }}
          >
            <div
              style={{
                margin: "-5px 15px 5px",
              }}
            >
              <Search
                options={[
                  ...this.state.allGroups.map((group) => group.groupName),
                  ...Array.from(
                    new Set(
                      this.state.allGroups.map(
                        (group) => group.supportersRegion
                      )
                    )
                  ).map((code) => continents[code]),
                  ...Array.from(
                    new Set(
                      this.state.allGroups.map(
                        (group) => group.supportersCountry
                      )
                    )
                  ).map((code) => countrtyNameCodeMap[code]),
                ]}
                onSearchParamsChange={this.handleSearchParamChange}
                label="Select Groups"
                count={this.state.count}
                params={this.state.editMode && this.state.params}
                placeholder={
                  !(
                    Array.isArray(this.state.groups) &&
                    this.state.groups.length > 0
                  ) &&
                  "Default is 'worldwide'. Select groups by region (Europe) and/or name (Atlanta) to target"
                }
                placeholderStyleClass="default"
              />
            </div>
            <div className="col-md-6">
              <div className="border rounded p-3 px-3">
                <FInput
                  label="Poll Question"
                  name="desc"
                  display="col"
                  placeholder="Enter your question here..."
                  onChange={this.handleInputChange}
                  value={this.state.desc}
                  required
                />
                <FUpload
                  label="Image(Optional)"
                  action={this.uploadAction}
                  fileList={this.state.imageList}
                  onChange={this.handleImageChange}
                  display="col"
                  isAvatar="false"
                  membershipCard={true}
                  aspectRatio={16 / 9}
                />
                <div style={{ padding: "0px 15px" }}>
                  <div
                    className="border rounded p-3 px-3"
                    style={{ backgroundColor: "rgba(0,0,0,0.02)" }}
                  >
                    <h6>Answers</h6>
                    {this.state.optionKeys.map((key, index) => (
                      <FInput
                        label=""
                        name={key}
                        display="col"
                        placeholder={`Answer #${index + 1}`}
                        onChange={this.handleInputChange}
                        value={this.state[key]}
                      />
                    ))}

                    <div style={{ padding: "0px 15px" }}>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-info mt-3 mb-2"
                        onClick={this.handleAdd}
                        disabled={this.state.optionKeys.length >= 5}
                      >
                        +Add New
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{ padding: "0px 15px", display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    Poll Length:
                  </div>
                  <div
                    className="col-md-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 24,
                        marginBottom: "1rem",
                        marginRight: -10,
                      }}
                    >
                      Days
                    </div>
                    <div style={{ marginTop: 24 }}>
                      <FSelect
                        label=""
                        value={this.state.days}
                        onChange={this.handleOptionChange}
                        name="days"
                        dataList={duration.days}
                        dataOnlyValue
                        placeholder="Days"
                        display="col"
                        tabIndex="1"
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 24,
                        marginBottom: "1rem",
                        marginRight: -10,
                      }}
                    >
                      Hours
                    </div>
                    <div style={{ marginTop: 24 }}>
                      <FSelect
                        label=""
                        value={this.state.hours}
                        onChange={this.handleOptionChange}
                        name="hours"
                        dataList={duration.hours}
                        dataOnlyValue
                        placeholder="Hours"
                        display="col"
                        tabIndex="1"
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ marginLeft: -15, marginTop: 15 }}>
                <FUpload
                  label="Sponsor Image"
                  action={this.sponsorUploadAction}
                  fileList={this.state.sponsorImageList}
                  onChange={this.handleSponsorImageChange}
                  display="col"
                  isAvatar="false"
                  membershipCard={true}
                  aspectRatio={16 / 9}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 24,
                    marginBottom: "1rem",
                  }}
                >
                  Sponsor Name (optional)
                </div>
                <FInput
                  label=""
                  name="sponsorName"
                  display="col"
                  placeholder="For Internal Reference Only"
                  onChange={this.handleInputChange}
                  value={this.state.sponsorName}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success" tabIndex="14" type="success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  handleEditMode() {
    getPoll(this.props.match.params.id).then((doc) => {
      if (!doc) {
        this.props.dispatch(
          applicationError({
            err: {},
            errorCode: "404",
          })
        );
        return;
      }

      let data = new Poll();
      data.fromDataSet(doc);

      let groupCount = data.groups.includes("global")
        ? this.state.allGroups.length
        : this.state.allGroups.filter(
            (group) =>
              data.groups.includes(group.id) ||
              data.groups.includes(
                countrtyNameCodeMap[group.supportersCountry]
              ) ||
              data.groups.includes(continents[group.supportersRegion])
          ).length;
      let params = [];
      data.groups.forEach((param) => {
        let group = this.state.allGroups.find((group) => group.id === param);
        if (group) {
          params.push(group.groupName);
        } else {
          params.push(param);
        }
      });

      this.setState({
        ...data,
        isLoading: false,
        count: `${groupCount} groups`,
        params,
      });
    });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      if (this.props.match.params.group.includes(this.props.clubId)) {
        getGroups().then((groups) => {
          this.setState(
            { allGroups: groups, count: `${groups.length} groups` },
            () => {
              this.handleEditMode();
            }
          );
        });
      }
    } else {
      getGroups().then((groups) => {
        this.setState({
          allGroups: groups,
          count: `${groups.length} groups`,
          isLoading: false,
        });
      });
    }
  }
}

const mapStateToProps = (state) => {
  let currentGroup = Object.keys(state.user.userRoles).find(
    (key) => state.user.userRoles[key] === "parentAdmin"
  );
  return {
    user: state.user,
    currentGroup,
    clubId: state.adminData.allGroups[currentGroup].data.clubId,
    groupData: state.adminData.allGroups[currentGroup],
  };
};

export default connect(mapStateToProps)(CreatePoll);
