import React from "react";
import { Modal } from "antd";
import CreateContentForm from "./CreateContentForm";
import { withRouter } from "react-router-dom";
import "./CopyEventModal.css";

class CopyEventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tableData: [],
      showModal: false,
      locationData: null,
      loadingDocument: !!props.match.params.id,
      loadingMatches: true,
      errorInForm: {
        isError: false,
        visible: false,
        message: "",
      },
    };
  }

  handleChildStateUpdate = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  moveRow = (dragIndex, hoverIndex) => {
    const newData = JSON.parse(JSON.stringify(this.state.tableData));
    newData[dragIndex].sortIndex = hoverIndex + 1;
    let sortIndexChangeStep = hoverIndex - dragIndex > 0 ? -1 : 1;
    let i = sortIndexChangeStep > 0 ? hoverIndex : dragIndex + 1;
    let finalStep = sortIndexChangeStep < 0 ? hoverIndex : dragIndex - 1;
    for (; i <= finalStep; i++) {
      newData[i].sortIndex += sortIndexChangeStep;
    }

    this.setState(() => {
      return { tableData: newData };
    });
  };

  render() {
    return (
      <Modal
        title="Create News/Event"
        width={1000}
        centered
        visible={this.props.visible}
        footer={null}
        onCancel={() => this.props.handleModalOpen()}
        className="copy-event-modal"
        style={{
          "--copy-modal-header-bg-color": this.props.primaryColor,
          "--copy-modal-header-text-color": "white",
        }}
      >
        <div className="copy-event-modal-container">
          <p className="copy-event-modal-subtitle">
            Update and submit this form to create a new event/news. Changing the
            match will automatically update the event date/time, show in home
            feed and notification dates.
          </p>

          <CreateContentForm
            isLoading={this.state.isLoading}
            loadingMatches={this.state.loadingMatches}
            loadingDocument={this.state.loadingDocument}
            showModal={this.state.showModal}
            errorInForm={this.state.errorInForm}
            handleChildStateUpdate={this.handleChildStateUpdate.bind(this)}
            currentContentId={this.props.currentContentId}
            handleModalOpen={this.props.handleModalOpen}
          />
        </div>
      </Modal>
    );
  }
}

export default withRouter(CopyEventModal);
