import moment from "moment";

class Poll {
  constructor() {
    this.desc = "";
    this.sponsorName = "";
  }

  toDataset(data) {
    this.addedOn = data.addedOn || moment().unix();
    this.isActive = data.isActive || false;
    this.isCancelled = data.isCancelled || false;
    this.noOfPolls = data.noOfPolls || 0;
    this.updatedOn = moment().unix();
    this.groups = ["global"];

    if (data.postedOn) {
      this.postedOn = data.postedOn;
    }

    if (data.desc) {
      this.desc = data.desc;
    } else {
      let message = "Poll Question not found";
      this.hasError = { message };
      return;
    }

    if (data.days || data.hours) {
      this.duration = {
        days: data.days ? parseInt(data.days) : 0,
        hours: data.hours ? parseInt(data.hours) : 0,
      };
    } else {
      let message = "Poll length is not set";
      this.hasError = { message };
      return;
    }

    if (data.closesOn) {
      this.closesOn =
        this.postedOn + data.days * 24 * 60 * 60 + data.hours * 60 * 60;
    }

    if (
      data.optionKeys &&
      data.optionKeys.filter((key) => data[key]).length >= 2
    ) {
      let count = 1;
      data.optionKeys
        .filter((key) => data[key])
        .forEach((key, index) => {
          this[`op${index + 1}`] = {
            id: index + 1,
            name: data[key],
            votes:
              data.options && data.options[key] ? data.options[key].votes : 0,
          };
          count++;
        });
      if (count < 5) {
        for (var i = count; i <= 5; i++) {
          this[`op${i}`] = null;
        }
      }
    } else {
      let message = "Poll must have at least 2 options";
      // store.dispatch(applicationError({ message, intensity: 'high', show: 'true' }));
      this.hasError = { message };
      return;
    }

    if (data.sponsorName) {
      this.sponsorName = data.sponsorName;
    }

    if (data.createdByDocId) {
      this.createdByDocId = data.createdByDocId;
    }

    if (data.createdByName) {
      this.createdByName = data.createdByName;
    }

    if (Array.isArray(data.groups) && data.groups.length > 0) {
      this.groups = data.groups;
    }
  }

  fromDataSet(data) {
    this.editConfigurations = {
      pollImageChanged: false,
      sponsorImageChanged: false,
    };
    this.imageList = data.pollImage ? [{ uid: "-1", url: data.pollImage }] : [];
    this.sponsorImageList = data.sponsorImage
      ? [{ uid: "-1", url: data.sponsorImage }]
      : [];
    this.imageFile = { name: "-1" };
    this.isActive = data.isActive;
    this.isCancelled = data.isCancelled;
    this.noOfPolls = data.noOfPolls;
    this.addedOn = data.addedOn;

    if (data.postedOn) {
      this.postedOn = data.postedOn;
    }

    if (data.desc) {
      this.desc = data.desc;
    }
    if (data.sponsorName) {
      this.sponsorName = data.sponsorName;
    }

    if (data.createdByDocId) {
      this.createdByDocId = data.createdByDocId;
    }

    if (data.createdByName) {
      this.createdByName = data.createdByName;
    }

    if (Array.isArray(data.groups) && data.groups.length > 0) {
      this.groups = data.groups;
    }
    if (data.duration) {
      const { days, hours } = data.duration;
      this.days = days;
      this.hours = hours;
    }
    if (data.op1) {
      let optionKeys = Array.from({ length: 5 }, (_, k) => k + 1)
        .map((index) => `op${index}`)
        .filter((key) => data[key] && data[key] !== null);
      this.optionKeys = optionKeys;
      this.options = {};
      optionKeys.forEach((key) => {
        this[key] = data[key].name;
        this.options[key] = data[key];
      });
    }

    if (data.postedOn && data.closesOn) {
      this.postedOn = data.postedOn;
      this.closesOn = data.closesOn;
    }
  }
}

export default Poll;
