import { Table } from "antd";
import React from "react";

import useGetStartedDetails from "../../gettingStarted/MaximizedView/hooks/useGetStartedDetails";

const AdoptionNestedTable = ({ data }) => {
  const { gettingStartedTitlesMap, gettingStartedTopicsCount } =
    useGetStartedDetails();

  const columns = [
    {
      title: "Group",
      // dataIndex: "groupName",
      key: "groupName",
      className: "header-white",
      width: 200,
    },
    {
      title: "% Reg",
      width: window.screen.width > 500 ? 100 : 50,
      className: "header-white",
      key: "percentageRegistered",
    },
    {
      title: `Reg`,
      width: window.screen.width > 500 ? 105 : 80,
      key: "registeredMembers",
      className: "header-white",
    },
    {
      align: "center",
      title: `Adoption %`,
      width: 150,
      key: "adoptionPercentage",
      className: "header-white",
    },
    {
      title: "Setup",
      dataIndex: "setup",
      key: "setup",
      className: "header-white",
      width: 300,
    },
    {
      title: "Onboard",
      dataIndex: "onboard",
      key: "onboard",
      className: "header-white",
      width: 300,
    },
    {
      title: "Engage",
      dataIndex: "engage",
      key: "engage",
      className: "header-white",
      width: 300,
    },
    {
      title: "Grow",
      dataIndex: "grow",
      key: "grow",
      className: "header-white",
      width: 300,
    },
    {
      align: "center",
      title: "Owner",
      key: "owner",
      className: "header-white",
      width: window.screen.width > 500 ? 100 : 50,
    },
    {
      align: "center",
      title: "Club Name",
      key: "clubName",
      className: "header-white",
      width: window.screen.width > 500 ? 200 : 150,
    },
  ];

  const nestedTable = [
    Object.entries(gettingStartedTitlesMap)?.reduce(
      (acc, [sectionKey, sectionValue]) => {
        acc[sectionKey] = (
          <div className="adoption-report-nested-table">
            {Object.entries(sectionValue)?.map(([key, label], index) => (
              <div>
                <div
                  style={{
                    gap: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className="flex-center"
                    style={{ fontSize: "12px", marginBottom: "0px" }}
                  >
                    {label}
                  </p>
                  {data?.gettingStarted?.[sectionKey]?.topics?.[key] ? (
                    <div className="flex-center">
                      <img
                        height={20}
                        width={20}
                        src={require("../../../images/tick-borderless.svg")}
                      />
                    </div>
                  ) : (
                    <div className="flex-center">
                      <img
                        height={20}
                        width={20}
                        src={require("../../../images/close-borderless.svg")}
                      />
                    </div>
                  )}
                </div>
                {index !== Object.entries(sectionValue).length - 1 && (
                  <hr
                    style={{
                      border: "none",
                      height: "1px",
                      backgroundColor: "#dbdbdb",
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        );
        return acc;
      },
      {}
    ),
  ];

  return (
    <Table
      showHeader={false}
      columns={columns}
      dataSource={nestedTable}
      pagination={false}
      bodyStyle={{
        fontSize: window.screen.width > 500 ? "18px" : "12px",
        backgroundColor: "#ffffff",
      }}
      className="table-backdrop"
    />
  );
};

export default AdoptionNestedTable;
