const initialState = {
  missingTicketsTxns: undefined,
  missingTicketsTxnsLoading: false,
};

const ticketsReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MISSING_TICKET_TXNS": {
      const { id, data } = action.payload;
      return {
        ...state,
        missingTicketsTxns: {
          ...state.missingTicketsTxns,
          [id]: data,
        },
      };
    }
    case "SET_MISSING_TICKET_TXNS_LOADING":
      return { ...state, missingTicketsTxnsLoading: action.payload };

    default:
      return { ...state };
  }
};

export default ticketsReportReducer;
