const initialState = {
  intensity: "",
  message: "",
  err: {},
  show: false,
  errorCode: "500",
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "APPLICATION_ERROR":
      return { intensity: "low", show: true, ...action.payload };
    case "RESET_ERROR":
      return { intensity: "", message: "", err: {}, show: false };
    default:
      return { ...state };
  }
};

export default errorReducer;
