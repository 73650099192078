import { db, fbAuth, Firebase, storage } from "../connection";
import moment from "moment";
import { fbCompleteAdminRegisteration } from "./authentication";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";
import Axios from "axios";
import {
  arrayUnion,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import logger from "../../../utils/logger";

// End point for User Authentication

// Getting group details, user is part of.
export function fbGetGroup(id) {
  return new Promise((resolve, reject) => {
    let docRef = doc(db, "group", id);
    getDoc(docRef)
      .then((querySnapshot) => {
        resolve({
          ...(querySnapshot.data() || {}),
          id: querySnapshot.id,
        });
      })
      .catch((err) => {
        let message = "Error fetching group details, contact admin";
        store.dispatch(
          applicationError({
            err,
            message,
            intensity: "high",
            errorCode: "firebase",
          })
        );
        reject({
          message,
        });
        return;
      });
  });
}

export function fbGetAdminGroups() {
  return new Promise((resolve, reject) => {
    const uid = fbAuth.currentUser.uid;
    let docRef = doc(db, "users", uid);
    getDoc(docRef)
      .then((user) => {
        let result = {};
        if (user.exists()) {
          const data = user.data() || {};
          let promises = [];
          data.groupIds.forEach((groupId) => {
            let docRef = doc(db, "userGroup", groupId, "members", uid);
            promises.push(getDoc(docRef));
          });
          Promise.all(promises).then((values) => {
            values.forEach((querySnapshot) => {
              if (querySnapshot.exists()) {
                let profileData = querySnapshot.data() || {};
                if (profileData) {
                  profileData.groupId = querySnapshot.ref.parent.parent.id;
                  if (profileData.userRole === "admin") {
                    result[profileData.groupId] = "admin";
                  }
                  if (profileData.userRole === "parentAdmin") {
                    result[profileData.groupId] = "parentAdmin";
                  }
                }
              }
            });

            resolve(result);
          });
        } else {
          reject("no user");
        }
      })
      .catch((err) => {
        logger.error(err);
        let message = "Error fetching group details, contact admin";
        store.dispatch(
          applicationError({
            err,
            message,
            intensity: "high",
            errorCode: "firebase",
          })
        );
        reject({
          message,
        });
        return;
      });
  });
}

export function fbRegisterAdmin(email) {
  return new Promise(async (resolve, reject) => {
    // get all admin groups from admins collection
    getDoc(doc(db, "admins", email))
      .then(async (querySnapshot) => {
        let group = querySnapshot.data();

        // used to update admins collection
        let unRegGroups = {};

        // used for user profile and user group profile
        let name;
        let phoneNumber = fbAuth.currentUser.phoneNumber;

        // used to push userGroup profile ids in user doc
        let userGroupIds = [];

        // setting all the unregistered groups to unRegGroups
        for (let key in group) {
          if (group[key].role === "admin") {
            unRegGroups[key] = deleteField();
            if (!name) name = group[key].name;
          }
        }

        let batch = writeBatch(db);

        let userData = {};

        // if user is not new, get his details from users collection
        try {
          querySnapshot = await getDoc(
            doc(db, "users", fbAuth.currentUser.uid)
          );
        } catch (err) {
          let message = "Error fetching admin details, contact admin";
          store.dispatch(
            applicationError({
              err,
              message,
              intensity: "high",
              errorCode: "firebase",
            })
          );
          reject({
            message,
          });
          return;
        }

        if (querySnapshot.exists()) {
          userData = querySnapshot.data() || {};
        } else {
          userData.name = name || "";
          userData.email = email;
          userData.phoneNumber = phoneNumber;
          userData.birthDay = {
            month: "",
            day: "",
          };
          userData.homeTown = "";
          userData.fcmToken = [];

          const userRef = doc(db, "users", fbAuth.currentUser.uid);
          batch.set(userRef, userData);
        }

        // replacing name for userGroup file.
        userData.name = name || userData.name;

        if (Object.keys(unRegGroups).length > 0) {
          // making and setting userGroupProfiles for all the unregistered groups

          Object.keys(unRegGroups).forEach((unRegGroup) => {
            // userGroup profile create
            const userGroupRef = doc(
              db,
              "userGroup",
              unRegGroup,
              "members",
              fbAuth.currentUser.uid
            );

            // if user is already part of the group, just change the role to admin
            if (userData.groupIds) {
              if (userData.groupIds.find((groupId) => groupId === unRegGroup)) {
                batch.set(
                  userGroupRef,
                  {
                    userRole: "admin",
                  },
                  {
                    merge: true,
                  }
                );
                return;
              }
            }
            let clubName;

            let name = userData.name
              ? userData.name
                  .toLowerCase()
                  .split(" ")
                  .map((str) => str[0].toUpperCase() + str.slice(1))
                  .join(" ")
              : "";

            const userGroupData = {
              // groupId: unRegGroup,
              userRole: "admin",
              activityCount: {
                capMe: 0,
                tailgating: 0,
                travel: 0,
                volunteer: 0,
                watchParty: 0,
              },
              chatHandle: name.split(" ").join("-").toLowerCase(),
              favouriteMoment: "",
              favouritePlayer: "",
              lockStatus: false,
              memberSince: "",
              name: name,
              pics: [],
              preferredLanguage: "",
              preferredPaymentMethod: "",
              preferredPaymentHandle: "",
              profileImage: "",
              registrationDate: parseInt(Date.now() / 1000),
              updatedOn: parseInt(Date.now() / 1000),
              title: "",
              email: email,
              hideEmail: true,
              hidePaymentId: false,
            };
            batch.set(userGroupRef, userGroupData);
            userGroupIds.push(unRegGroup);
            const groupRef = doc(db, "group", unRegGroup);

            getDoc(groupRef)
              .then((querySnapshot) => {
                clubName = querySnapshot.data().groupName;
              })
              .then(() => {
                Axios.get(
                  `https://us-central1-chant2019.cloudfunctions.net/sendNewAdminAddedNotification?groupName=${clubName}&groupDocId=${unRegGroup}&newAdminName=${userData.name}`
                );
              });
          });

          // setting userGroupProfiles in user document
          const userRef = doc(db, "users", fbAuth.currentUser.uid);
          if (userGroupIds.length > 0) {
            batch.update(userRef, {
              groupIds: arrayUnion(...userGroupIds),
            });
          }

          // changing all the unregisterd groups to registered in admin collection
          const adminDocRef = doc(db, "admins", email);
          batch.update(adminDocRef, unRegGroups);
        }

        batch
          .commit()
          .then(() => {
            resolve({});
            // Axios.get(
            //   `https://us-central1-chant2019.cloudfunctions.net/sendNewAdminAddedNotification?groupName=${clubName}&groupDocId=${unRegGroup}&newAdminName=${
            //     userData.name
            //   }`
            // );
          })
          .catch(reject);
      })
      .catch((err) => {
        let message = "Error fetching admin details, contact admin";
        store.dispatch(
          applicationError({
            err,
            message,
            intensity: "high",
            errorCode: "firebase",
          })
        );
        reject({
          message,
        });
        return;
      });
  });
}

export function fbUploadMembershipCard(imageFile, groupId, field) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    // const imageRef = storageRef.child(imageName)
    // const folderRef = storageRef.child(`groups/logo/${groupName.toLowerCase()}-${file.name}`)
    const folderRef = ref(storageRef, `groups/${field}/${groupId}`);

    uploadBytes(folderRef, imageFile)
      .then(async (snapshot) => {
        const downloadUrl = await getDownloadURL(snapshot.ref);
        const docRef = doc(db, "group", groupId);
        setDoc(
          docRef,
          {
            [field]: downloadUrl,
          },
          {
            merge: true,
          }
        ).then(() => {
          resolve(downloadUrl);
        });
        // resolve(downloadUrl, snapshot.metadata.fullPath)
      })
      .catch(reject);
  });
}

export function fbDeleteMembershipCard(groupId, field) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    const imageRef = ref(storageRef, `groups/${field}/${groupId}`);
    deleteObject(imageRef)
      .then(() => {
        const docRef = doc(db, "group", groupId);
        setDoc(
          docRef,
          {
            [field]: "",
          },
          {
            merge: true,
          }
        ).then(() => {
          resolve();
        });
      })
      .catch((err) => {
        let message = "Error deleting membership card, contact admin";
        store.dispatch(
          applicationError({
            err,
            message,
            intensity: "high",
            errorCode: "firebase",
          })
        );
        reject({
          message,
        });
        return;
      });
  });
}

export function fbLogoutUser() {
  return new Promise((resolve, reject) => {
    fbAuth.signOut().then(() => {
      resolve();
      localStorage.clear();
    });
  });
}

export function getDeactivatedUsers() {
  return new Promise((res, rej) => {
    getDocs(query(collection(db, "users"), where("isDeactivated", "==", true)))
      .then((snap) => {
        let users = snap.docs.map((doc) => {
          let data = doc.data();
          data.id = doc.id;
          return data;
        });

        res(users);
      })
      .catch(rej);
  });
}

export function fbGetUserByPhone(phone) {
  return new Promise((resolve, reject) => {
    getDocs(query(collection(db, "users"), where("phoneNumber", "==", phone)))
      .then((snap) => {
        const docs = snap.docs;
        let data;
        if (docs.length !== 0) {
          data = docs[0].data();
          data.id = docs[0].id;
        }

        resolve(data);
      })
      .catch(reject);
  });
}

// export function fbGetUserByEmail(email) {
//     return new Promise((resolve, reject) => {
//         db.collection("users").where("email", "==", email).get()
//             .then((querySnapshot) => {
//                 const docs = querySnapshot.docs
//                 let data
//                 if (docs.length !== 0) {
//                     data = docs[0].data()
//                     data.id = docs[0].id
//                 }

//                 resolve(data)

//             })
//     })
// }
