import Axios from "../connection";
import { store } from "../../../index";
import { applicationError } from "../../../redux/actions/error";

export async function fetchPlayersData(country, season, league) {
  let endpoint = `/teams?season=${season}&league=${league}`;
  if (country !== "World") {
    endpoint = `${endpoint}&country=${country}`;
  }
  let response = await Axios.get(endpoint);
  let teams = [];
  if (response.data.response) {
    for (var data of response.data.response) {
      let teamInfo = data.team || {};
      let teamData = {
        clubId: teamInfo.id,
        clubName: teamInfo.name,
        logo: teamInfo.logo,
      };
      let squadResponse = await Axios.get(`players/squads?team=${teamInfo.id}`);
      let players = [];
      if (squadResponse.data.response && squadResponse.data.response[0]) {
        players = squadResponse.data.response[0].players.map((player) => {
          return {
            playerId: player.id,
            playerName: player.name,
            photo: player.photo,
          };
        });
      }
      teamData.players = players;
      teams.push(teamData);
    }
  }

  return teams;
}

export const fetchLeagues = (country, season) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/leagues?country=${country}&season=${season}`)
      .then((response) => {
        if (response.data.response) {
          const data = response.data.response;
          let res = [];

          data.forEach((leagueData) => {
            let league = {};
            league["league_id"] = leagueData.league.id;
            league.name = leagueData.league.name;

            res.push(league);
          });
          resolve(res);
        } else {
          store.dispatch(
            applicationError({
              err: {},
              message: "Unable to fetch leagues",
              errorCode: "api-football",
            })
          );
        }
      })
      .catch((err) => {
        store.dispatch(
          applicationError({
            err,
            message: "Unable to fetch leagues",
            errorCode: "api-football",
          })
        );
      });
  });
};
