import { store } from "../..";
import converter from "json-2-csv";
import moment from "moment";
import { applicationError } from "../../redux/actions/error";
import { openTransaction } from "./connection";
import { db } from "../firebaseService/connection";
import { doc, updateDoc } from "firebase/firestore";
import logger from "../../utils/logger";

export function addMembersToLocal(memberList, groupId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    const members = openTransaction(
      indexDb,
      `members-${groupId}`,
      `members-${groupId}`,
      "readwrite"
    );
    if (!members) return;

    memberList.forEach((member, index) => {
      const addRequest = members.add(member);
      addRequest.onsuccess = function (event) {
        if (index === memberList.length - 1) {
          resolve();
        }
      };
      addRequest.onerror = function (event) {
        reject("Error adding members");
      };
    });
  });
}

export function clearMembersFromLocal(groupId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    const members = openTransaction(
      indexDb,
      `members-${groupId}`,
      `members-${groupId}`,
      "readwrite"
    );
    if (!members) return;

    const clearRequest = members.clear();
    clearRequest.onsuccess = function (event) {
      resolve();
    };

    clearRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message:
            "Error clearing members from local db, try re-login to solve this issue",
          errorCode: "indexDb",
        })
      );
    };
  });
}

// export function editContentInLocal(content, tab, groupId) {
//     return new Promise((resolve, reject) => {
//         const indexDb = store.getState().adminData.connection
//         const cursorRequest = indexDb.transaction(`${tab}Contents-${groupId}`, 'readwrite').objectStore(`${tab}Contents-${groupId}`).openCursor(content.id)
//         cursorRequest.onsuccess = function (event) {
//             let cursor = cursorRequest.result
//             if (cursor) {
//                 let data = cursor.value
//                 cursor.update({ ...data, ...content })
//             }
//         }
//     })
// }

export function getFilteredMembersFromLocal(
  groupId,
  filters,
  skip,
  packages,
  requireMembershipPackage,
  orderBy,
  order
) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    let getRequest;
    let contents = openTransaction(
      indexDb,
      `members-${groupId}`,
      `members-${groupId}`
    );
    if (!contents) return;
    if (filters.joinedSince) {
      contents = contents.index("registrationDate");
      getRequest = contents.openCursor(
        IDBKeyRange.lowerBound(filters.joinedSince)
      );
    } else if (orderBy) {
      if (contents.indexNames.contains(orderBy)) {
        contents = contents.index(orderBy);
        getRequest = contents.openCursor(null, order);
      }
    } else {
      contents = contents.index("pageId");
      getRequest = contents.openCursor();
    }
    const result = [];
    if (getRequest) {
      getRequest.onsuccess = function (event) {
        let cursor = getRequest.result;
        if (cursor) {
          let value = cursor.value;
          try {
            let predicate = true;
            if (
              !value.name.match(new RegExp(filters.search, "i")) &&
              !value.email.match(new RegExp(filters.search, "i"))
            ) {
              predicate = false;
            }
            if (filters.package && value.package !== filters.package) {
              predicate = false;
            }
            if (filters.chapter && value.chapter !== filters.chapter) {
              predicate = false;
            }
            if (
              filters.other === "Official Club Member" &&
              !value.officialClubMember
            ) {
              predicate = false;
            }
            if (
              filters.other === "Season Ticket Holder" &&
              (!value.seasonTicketHolder ||
                Object.keys(value.seasonTicketHolder || {}).length === 0 ||
                Object.keys(value.seasonTicketHolder || {})
                  .map((key) => value.seasonTicketHolder[key])
                  .includes(""))
            ) {
              predicate = false;
            }
            if (
              filters.other === "Volunteer (yes)" &&
              !value.shareVolunteerOpportunities
            ) {
              predicate = false;
            }
            if (
              filters.other === "Volunteer (no)" &&
              value.shareVolunteerOpportunities
            ) {
              predicate = false;
            }
            if (
              filters.other === "Receive emails (yes)" &&
              !value.joinedEmailList
            ) {
              predicate = false;
            }
            if (
              filters.other === "Receive emails (no)" &&
              value.joinedEmailList
            ) {
              predicate = false;
            }
            if (
              filters.other === "Donated" &&
              (!value.donationAmount ||
                !value.donationAmount.value ||
                value.donationAmount.value === "0")
            ) {
              predicate = false;
            }
            if (!filters.status && value.lockStatus) {
              predicate = false;
            }
            if (filters.status === "Members (all)") {
              if (value.lockStatus) {
                predicate = false;
              } else {
                if (requireMembershipPackage) {
                  let packId = value.package;
                  let packDetails = !packId
                    ? null
                    : packages.filter(
                        (pack) => pack.id.trim() === packId.trim()
                      );

                  if (
                    !(
                      packId &&
                      packDetails &&
                      packDetails.length > 0 &&
                      packDetails[0].packageExpiryDate >= moment().unix() &&
                      value.userRole !== "fan"
                    )
                  ) {
                    predicate = false;
                  }
                } else {
                  if (value.userRole === "fan") {
                    predicate = false;
                  }
                }
              }
            }
            if (filters.status === "Members (on Chant)") {
              if (value.lockStatus) {
                predicate = false;
              } else {
                if (requireMembershipPackage) {
                  let packId = value.package;
                  let packDetails = !packId
                    ? null
                    : packages.filter(
                        (pack) => pack.id.trim() === packId.trim()
                      );

                  if (
                    !(
                      packId &&
                      packDetails &&
                      packDetails.length > 0 &&
                      packDetails[0].packageExpiryDate >= moment().unix() &&
                      value.userRole !== "fan" &&
                      value.joinedChant &&
                      value.joinedChant !== "No"
                    )
                  ) {
                    predicate = false;
                  }
                } else {
                  if (
                    value.userRole === "fan" ||
                    !value.joinedChant ||
                    value.joinedChant === "No"
                  ) {
                    predicate = false;
                  }
                }
              }
            }
            if (filters.status === "Members (not on Chant)") {
              if (value.lockStatus) {
                predicate = false;
              } else {
                if (requireMembershipPackage) {
                  let packId = value.package;
                  let packDetails = !packId
                    ? null
                    : packages.filter(
                        (pack) => pack.id.trim() === packId.trim()
                      );

                  if (
                    !(
                      packId &&
                      packDetails &&
                      packDetails.length > 0 &&
                      packDetails[0].packageExpiryDate >= moment().unix() &&
                      value.userRole !== "fan" &&
                      (!value.joinedChant || value.joinedChant === "No")
                    )
                  ) {
                    predicate = false;
                  }
                } else {
                  if (
                    value.userRole === "fan" ||
                    value.joinedChant ||
                    value.joinedChant !== "No"
                  ) {
                    predicate = false;
                  }
                }
              }
            }
            if (
              filters.status === "Leaders" &&
              (value.lockStatus ||
                value.userRole === "member" ||
                value.userRole === "fan")
            ) {
              predicate = false;
            }
            if (
              filters.status === "Fans (non-members)" &&
              (value.lockStatus || value.userRole !== "fan")
            ) {
              predicate = false;
            }
            if (filters.status === "Inactive") {
              if (value.lockStatus) {
                predicate = false;
              } else {
                if (requireMembershipPackage) {
                  let packId = value.package;
                  let packDetails = !packId
                    ? null
                    : packages.filter(
                        (pack) => pack.id.trim() === packId.trim()
                      );

                  if (
                    packId &&
                    packDetails &&
                    packDetails.length > 0 &&
                    packDetails[0].packageExpiryDate >= moment().unix() &&
                    value.userRole !== "fan"
                  ) {
                    predicate = false;
                  }
                } else {
                  predicate = false;
                }
              }
            }
            if (filters.status === "Locked/Archived" && !value.lockStatus) {
              predicate = false;
            }
            if (predicate) {
              result.push(value);
            }
          } catch (err) {
            logger.error(value.email, err);
          }

          cursor.continue();
        } else {
          if (skip) {
            result.slice(skip);
          }
          resolve(result);
        }
      };

      getRequest.onerror = function (event) {
        store.dispatch(
          applicationError({
            err: {},
            message: "Error fetching members from local db",
            errorCode: "indexDb",
          })
        );
      };
    }
  });
}

export function getMyName(myUid, groupId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    let contents = openTransaction(
      indexDb,
      `members-${groupId}`,
      `members-${groupId}`
    );
    if (!contents) return;
    contents = contents.index("pageId");
    const getRequest = contents.openCursor();
    const result = [];
    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        try {
          if (cursor.value.id === myUid) {
            result.push(cursor.value);
          }
        } catch (err) {}
        cursor.continue();
      } else {
        let myName = "";
        if (result && result.length > 0) {
          let myDoc = result[0];
          myName = myDoc.fullName || myDoc.name;
        }
        resolve(myName);
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching members from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}

export function editMemberInLocal(content, groupId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    const members = openTransaction(
      indexDb,
      `members-${groupId}`,
      `members-${groupId}`,
      "readwrite"
    );
    if (!members) return;
    const cursorRequest = members.openCursor(content.id);
    cursorRequest.onsuccess = function (event) {
      let cursor = cursorRequest.result;
      if (cursor) {
        let data = cursor.value;
        cursor.update({ ...data, ...content });
        resolve();
      }
    };
  });
}

export function getFilteredMembersAsCSV(
  groupId,
  filters,
  packages,
  requireMembershipPackage,
  customFields,
  groupCurrency
) {
  return new Promise((resolve, reject) => {
    let result = [];

    getFilteredMembersFromLocal(
      groupId,
      filters,
      null,
      packages,
      requireMembershipPackage
    ).then((res) => {
      if (res) {
        res.forEach((value) => {
          let registrationDate = moment.unix(value.registrationDate);
          let registeredOnChantDate = value.registeredOnChantDate
            ? moment.unix(value.registeredOnChantDate)
            : "";
          let packageName = "";
          let packageExpiryDate = 0;
          let packStatus = "";
          let packageObj = null;
          if (value.package) {
            packageObj = packages.find(
              (pack) => pack.id.trim() === value.package.trim()
            );
            if (packageObj) {
              packageName = packageObj.name;
              packageExpiryDate = packageObj.packageExpiryDate;
              packStatus = packageObj.status;
            }
          }

          let name = value.name + "";
          let lastName = name.split(" ").pop();
          let firstName = name.substring(0, name.indexOf(lastName)).trim();

          let row = {
            Name: value.name,
            "First Name": firstName,
            "Last Name": lastName,
            Email: value.email,
            Phone: value.phone,
            // 'Chat Handle': value.chatHandle,
            // Role: value.userRole,
            "Street Name": value.address,
            Address2: value.address2,
            City: value.city,
            State: value.state,
            Zip: value.zip,
            Country: value.country,
            Package: packageName,
            "Membership Number": value.membershipNumber,
            "Member Since": value.memberSince,
            "Joined Chant": value.joinedChant,
            "Registration Date(MM-DD-YY)": registrationDate.isValid()
              ? registrationDate.format("MM-DD-YY")
              : "",
            "Registered Date for Chant(MM-DD-YY)":
              registeredOnChantDate && registeredOnChantDate.isValid()
                ? registeredOnChantDate.format("MM-DD-YY")
                : "",
            "Official Club Member": value.officialClubMember,
            "Joined Email List?": value.joinedEmailList,
            "T-shirt Size": value.tshirtSize,
            "Season Ticket Section":
              (value.seasonTicketHolder && value.seasonTicketHolder.season) ||
              "",
            "Section Ticket Count":
              (value.seasonTicketHolder && value.seasonTicketHolder.seats) ||
              "",
          };

          if (value.children && Array.isArray(value.children)) {
            value.children.forEach((child, index) => {
              row[`child${index + 1} Name`] = child.name;
              row[`child${index + 1} T-shirt Size`] = child.tshirtSize;
              row[`child${index + 1} Membership Number`] =
                child.membershipNumber;
            });
          }

          row = {
            ...row,
            "Family Membership ID": value.familyMembershipId,
            [`Membership Amount${groupCurrency}`]:
              value.membershipPacakageAmount
                ? `${value.membershipPacakageAmount.value || value.membershipPacakageAmount.amount}`
                : "",
            [`Shipping Amount${groupCurrency}`]: value.shippingCharge
              ? `${value.shippingCharge.value || value.shippingCharge.replace("$", "")}`
              : value.shipping && value.shipping.value !== "0"
                ? `$${value.shipping.value}`
                : "",
            [`Donation Amount${groupCurrency}`]: value.donationAmount
              ? `${value.donationAmount.value}`
              : "",
            "Notify for Volunteer Opportunities":
              value.shareVolunteerOpportunities ? "Yes" : "No",
            Capme: value.activityCount && value.activityCount.capMe,
            Tailgating: value.activityCount && value.activityCount.tailgating,
            Travel: value.activityCount && value.activityCount.travel,
            Volunteer: value.activityCount && value.activityCount.volunteer,
            "Watch Party":
              value.activityCount && value.activityCount.watchParty,
            "Lock Status": value.lockStatus,
            "Referred By": value.referredBy,
            Chapter: value.chapter,
          };
          (customFields || []).forEach((field) => {
            row[field.fieldTitle] = value[field.fieldName];
          });

          result.push(row);
        });
      }

      converter.json2csv(
        result,
        (err, csv) => {
          if (err) reject(err);
          else resolve(csv);
        },
        { emptyFieldValue: "" }
      );
    });
  });
}

export function setRegCodeRole(role, groupId) {
  return new Promise((resolve, reject) => {
    let updateObj = {
      regCodeRole: role,
    };

    if (role === "fan") {
      updateObj.fanJoin = true;
    }

    updateDoc(doc(db, "group", groupId), updateObj)
      .then(resolve)
      .catch(reject);
  });
}

export function getLinkedMember(groupId, familyMembershipId, userId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    let getRequest;
    let contents = openTransaction(
      indexDb,
      `members-${groupId}`,
      `members-${groupId}`
    );
    if (!contents) return;

    contents = contents.index("pageId");
    getRequest = contents.openCursor();
    let result = null;
    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        let value = cursor.value;
        if (
          value.familyMembershipId === familyMembershipId &&
          value.id !== userId
        ) {
          result = value;
        }
        if (result) {
          cursor.advance(10000);
        } else {
          cursor.continue();
        }
      } else {
        resolve(result);
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching linked members from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}

export function getSingleMemberFromLocal(groupId, userId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    let getRequest;
    let contents = openTransaction(
      indexDb,
      `members-${groupId}`,
      `members-${groupId}`
    );
    if (!contents) return;

    contents = contents.index("pageId");
    getRequest = contents.openCursor();
    let result = null;
    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        let value = cursor.value;
        if (value.id === userId) {
          result = value;
        }
        if (result) {
          cursor.advance(10000);
        } else {
          cursor.continue();
        }
      } else {
        resolve(result);
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching linked members from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}
