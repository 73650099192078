import React from "react";

import { startUpdateUser, startLogoutUser } from "../../redux/actions/user";

import { Alert, Steps, Icon } from "antd";

import { connect } from "react-redux";
import { FInput } from "../commons/formFields/FInput";
import { fbAuth, Firebase } from "../../services/firebaseService/connection";
// import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { FSelect } from "../commons/formFields/FSelect";

import {
  fbGetCurrentLeagues,
  fbRegisterMember,
} from "../../services/firebaseService/endPoints/member";
import { afGetClubs } from "../../services/apiFootballService/endPoints/clubs";
import LoadingModal from "../commons/LoadingModal";

import validator from "validator";
import { fbLogoutUser } from "../../services/firebaseService/endPoints/user";
import Payment from "../commons/payment/Payment";
import Axios from "axios";
import { isValidPhoneNumber } from "libphonenumber-js";
import PhoneNumberInput from "../commons/PhoneNumberInput";
// import googleBadget from '../../images/google-play-badget.png'

class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      isSubmitted: false,
      recaptchaVerifier: "",

      otp: "",
      isVerified: false,
      confirmResult: "",

      leaguesList: [],
      league: "",
      clubList: [{ name: "Select league first" }],
      club: "",
      registrationCode: "",

      showAlert: { show: false, type: "success", message: "" },

      loginStep: "process",
      verificationStep: "wait",
      registrationStep: "wait",
      doneStep: "wait",

      invalidFeedback: {},
      loadingDocument: true,
      // phoneFocus: false
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleRequestOtp = () => {
    this.setState({
      isLoading: true,
      showAlert: { show: false },
      invalidFeedback: {},
    });

    let invalidFeedback = {};
    if (!validator.isEmail(this.state.email))
      invalidFeedback.email = "Enter a valid email";
    if (!this.state.name) invalidFeedback.name = "Name is required";

    if (Object.keys(invalidFeedback).length > 0) {
      this.setState({ invalidFeedback, isLoading: false });
      return;
    }

    if (!isValidPhoneNumber(this.state.phoneNumber)) {
      this.setState({
        showAlert: {
          show: true,
          type: "error",
          message: "Enter a valid phone number",
        },
        isLoading: false,
      });
      return;
    }

    fbAuth
      .signInWithPhoneNumber(this.state.phoneNumber, window.recaptchaVerifier)
      .then((confirmResult) => {
        this.setState({
          confirmResult,
          showAlert: { show: false },
          isSubmitted: true,
          isLoading: false,
          verificationStep: "process",
          loginStep: "finish",
        });
      })
      .catch((err) => {
        this.setState({
          showAlert: { show: true, type: "error", message: err.message },
          isLoading: false,
        });
      });
  };

  handleVerifyOtp = () => {
    this.setState({ isLoading: true });
    this.state.confirmResult
      .confirm(this.state.otp)
      .then((res) => {
        this.setState({
          isLoading: false,
          isVerified: true,
          showAlert: { show: false },
          verificationStep: "finish",
          registrationStep: "process",
        });
        // this.handleAlertClose()
        this.handleMemberRegistration();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          showAlert: { show: true, type: "error", message: err.message },
        });
      });
  };

  // getLeagues = () => {
  //     fbGetCurrentLeagues()
  //         .then((leagues) => {
  //             this.setState({ leaguesList: leagues })
  //         })
  // }

  // handleLeagueChange = (e) => {
  //     e.persist()
  //     this.setState({ league: e.target.value, clubList: [] })
  //     afGetClubs(e.target.value)
  //         .then((clubs) => {
  //             this.setState({ clubList: clubs })
  //         })
  // }

  // handleClubChange = (e) => {
  //     e.persist()
  //     this.setState({ club: e.target.value })
  // }

  handleMemberRegistration = async () => {
    // e.preventDefault()

    this.setState({ isLoading: true, showAlert: { show: false } });

    Axios.post(
      "https://us-central1-chant2019.cloudfunctions.net/widgets/charge",
      {
        regCode: this.state.registrationCode,
        uid: fbAuth.currentUser.uid,
        name: this.state.name,
        email: this.state.email,
        phoneNumber: fbAuth.currentUser.phoneNumber,
        // token: token,
        dontProcess: true,
      }
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          showAlert: {
            show: false,
            type: "success",
            message: `You have successfully joined ${response.data.groupName}`,
          },
          registrationStep: "finish",
          doneStep: "finish",
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          showAlert: {
            show: true,
            type: "error",
            message: `${err.response.data.message || "Cant process"} `,
          },
        });
      });

    // fbRegisterMember(this.state.league, this.state.club, Number(this.state.registrationCode), this.state)
    //     .then((group) => {
    //         this.setState({ isLoading: false, showAlert: { show: false, type: "success", message: `You have successfully joined ${ group }` }, registrationStep: 'finish', doneStep: 'finish' })
    //     })
    //     .catch((err) => {
    //         this.setState({ showAlert: { show: true, message: err.message, type: 'error' }, isLoading: false })
    //     })
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePhoneChange = (phoneNumber) => {
    if (this.state.loginStep === "finish") {
      this.setState({ loginStep: "process", verificationStep: "wait" });
      document.getElementById("phoneNumber").focus();
    }
    this.setState({ phoneNumber });
  };

  handleAlertClose = () => {
    this.setState({ showAlert: { show: false } });
  };

  render() {
    return (
      <div className="container mt-5" style={{ color: "#ffffff" }}>
        {this.state.showAlert.show && (
          <div className="alert alert-danger" role="alert">
            {this.state.showAlert.message}
          </div>
        )}

        <LoadingModal
          loading={this.state.isLoading || this.state.loadingDocument}
        />

        <form
          onSubmit={this.handleSubmit}
          className="border rounded p-2 px-md-5 py-md-4 mx-auto"
          style={{ maxWidth: "700px" }}
        >
          <h6 className="">Supporters Group Registration</h6>
          <Steps className="my-4">
            <Steps.Step
              status={this.state.loginStep}
              title="Profile"
              icon={<Icon type="user" />}
            />
            <Steps.Step
              status={this.state.verificationStep}
              title="Verification"
              icon={<Icon type="solution" />}
            />
            {/* <Steps.Step
              status={this.state.registrationStep}
              title="Payment"
              icon={<Icon type="wallet-o" />}
            /> */}
            {/* <Steps.Step
              status={this.state.doneStep}
              title="Done"
              icon={<Icon type="check-circle-o" />}
            /> */}
          </Steps>

          {this.state.loginStep === "process" && (
            <>
              <div className="form-group">
                <FInput
                  label="Full Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  placeholder="Jessica Smith"
                  display="col"
                  invalidFeedback={this.state.invalidFeedback.name}
                  required
                />
              </div>

              <div className="form-group">
                <FInput
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  placeholder="jessica@gmail.com"
                  display="col"
                  invalidFeedback={this.state.invalidFeedback.email}
                  required
                />
              </div>
            </>
          )}

          {this.state.verificationStep !== "finish" && (
            <div className="form-group">
              <div className="col">
                <label>
                  Phone Number<span style={{ color: "red" }}>*</span>
                </label>
                {/* <PhoneInput
                  id="phoneNumber"
                  className={`form-control form-control-sm`}
                  placeholder="Enter phone number with country code"
                  value={this.state.phoneNumber}
                  onChange={this.handlePhoneChange}
                  country="US"
                /> */}
                <PhoneNumberInput onChange={this.handlePhoneChange} />
                {this.state.verificationStep !== "process" && (
                  <div>
                    <span style={{ fontSize: "13px" }}>
                      Note: Phone number will only be used for app
                      authentication as Chant does not use passwords. Message
                      and data rates may apply.
                    </span>
                  </div>
                )}

                {/* <div className="invalid">
                                    <div className="invalid-feedback">
                                        {this.state.invalidFeedback.phoneNumber}
                                    </div>
                                </div> */}
              </div>
            </div>
          )}

          {this.state.verificationStep !== "finish" && (
            <div
              className={`${
                this.state.verificationStep === "process" && "d-none"
              } row justify-content-end m-2`}
              //  style={{ display: `${ this.state.verificationStep === 'process' ? 'none' : 'initial' }` }}
            >
              <button
                className="btn btn-primary"
                onClick={this.handleRequestOtp}
                id={"sign-in-button"}
              >
                Request phone verification code{" "}
              </button>
            </div>
          )}

          {this.state.verificationStep === "process" && (
            <>
              {/* <div className="form-group">
                            <div className="col">
                                <label>Phone Number</label>
                                <PhoneInput
                                    className={`form - control form - control - sm`}
                                    placeholder="Enter phone number"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneChange}
                                />
                            </div>
                        </div> */}

              <div className="form-group">
                <FInput
                  label="Enter verification code"
                  value={this.state.otp}
                  onChange={this.handleInputChange}
                  name="otp"
                  placeholder="Enter code sent via text message"
                  display="col"
                  required
                />
                {/* <div>
                  <span style={{ fontSize: "13px" }}>
                    *Message and data rates may apply.
                  </span>
                </div> */}
              </div>

              <div className="row justify-content-end m-2">
                <button
                  className="btn btn-primary"
                  onClick={this.handleVerifyOtp}
                >
                  Verify
                </button>
              </div>
            </>
          )}

          {/* {this.state.registrationStep === "process" && (
            <>
              {/* <FSelect
                            label="Leagues"
                            value={this.state.league}
                            onChange={this.handleLeagueChange}
                            placeholder={this.state.leaguesList.length > 0 ? "--Select League--" : "Loading..."}
                            dataList={this.state.leaguesList}
                            dataKey="id"
                            dataValue="name"
                            display="col"
                        />
                        <FSelect
                            label="Club"
                            value={this.state.club}
                            onChange={this.handleClubChange}
                            placeholder={this.state.clubList.length > 0 ? "--Select Club--" : "Loading..."}
                            dataList={this.state.clubList}
                            dataKey="team_id"
                            dataValue="name"
                            display="col"
                        /> */}
          {/* <div className="form-group">
                <div>
                  <span style={{ fontSize: "13px" }}>
                    Chant is only $5 per season. Renewal will take place when
                    you renew with your supporters group and is not automatic.
                  </span>
                </div> */}
          {/* <FInput
                  label="Registration Code"
                  value={this.state.registrationCode}
                  onChange={this.handleInputChange}
                  name="registrationCode"
                  placeholder="Enter your 6 digit registration code"
                  display="col"
                /> */}
          {/* <Payment
                  registrationCode={this.state.registrationCode}
                  name={this.state.name}
                  email={this.state.email}
                  handleMemberRegistration={this.handleMemberRegistration}
                />
              </div>
            </>
          )} */}

          {this.state.registrationStep === "finish" &&
            (this.state.showAlert.type !== "success" ? (
              <h6>{this.state.showAlert.message}</h6>
            ) : (
              <div>
                <h5 style={{ color: "red" }}>Thank You</h5>
                <p>
                  Thanks for registering for Chant. You can now download the app
                  and join your supporters group.
                </p>
                <div className="container-links">
                  <a
                    href="https://apps.apple.com/us/app/chant/id1477159385?ls=1"
                    style={{ width: "30%" }}
                  >
                    <img
                      style={{
                        padding: "0",
                        margin: "auto",
                        width: "100%",

                        // height: "100%"
                      }}
                      src={require("../../images/appstore-badge.png")}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.geekyants.services.chant"
                    style={{ width: "30%" }}
                  >
                    <img
                      style={{
                        padding: "0",
                        margin: "0",
                        width: "100%",
                      }}
                      src={require("../../images/google-play-badge.png")}
                    />
                  </a>
                </div>
              </div>
            ))}
        </form>
      </div>
    );
  }
  //  {
  //     if (this.state.isVerified) {
  //         if (this.state.leaguesList.length===0) {
  //             this.getLeagues()
  //         }
  //     }

  // this.state.isVerified
  // window.recaptchaWidgetId = widgetId

  componentDidUpdate() {
    if (!this.state.loadingDocument && !window.recaptchaVerifier) {
      window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: () => {
            // this.handleSubmit()
          },
        }
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.loadingDocument && props.user.isAuthenticated === false) {
      return { loadingDocument: false };
    }
  }

  getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    let results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  componentDidMount = () => {
    if (this.props.user.isAuthenticated) {
      fbLogoutUser();
    } else {
      // this.setState({loadingDocument: false})
    }

    const code = this.getUrlParameter("code");
    if (code) this.setState({ registrationCode: code });
  };

  componentWillUnmount() {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = "";
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Register);
