export default class StoreItem {
  constructor() {
    this.status = "";
    this.displayOn = [];
    this.name = "";
    this.desc = "";
    this.hasSize = false;
    this.productPrimaryImage = "";
    this.productOptionalImages = [];
    this.sortIndex = 0;
  }

  toDataSet(data) {
    if (data.status) {
      this.status = data.status;
    } else {
      this.hasError = { message: "Status field is required." };
    }

    if (Array.isArray(data.displayOn) && data.displayOn.length > 0) {
      this.displayOn = data.displayOn;
    } else {
      if (data.status === "live") {
        this.hasError = {
          message: "Display on field is required since status is live.",
        };
      } else {
        this.displayOn = [];
      }
    }

    if (data.name) {
      this.name = data.name;
    } else {
      this.hasError = { message: "Name is required." };
    }

    this.desc = data.desc;

    this.quantityPriceOption = data.quantityPriceOption;
    // this.sku = data.sku;

    if (
      data.comboValues &&
      Array.isArray(data.comboValues) &&
      data.comboValues.length > 0
    ) {
      this.hasSize = data.hasSize;
      this.hasColor = data.hasColor;
      this.comboValues = [];

      data.comboValues.forEach((combo) => {
        let comboValue = {};
        if (this.hasSize || this.quantityPriceOption === "perCombination") {
          comboValue.availableSizes = [];

          if (
            Array.isArray(combo.availableSizes) &&
            combo.availableSizes.length > 0
          ) {
            comboValue.availableSizes = combo.availableSizes;
          }
        } else {
          comboValue.availableSizes = [];
        }

        if (this.hasColor || this.quantityPriceOption === "perCombination") {
          comboValue.availableColors = [];

          if (
            Array.isArray(combo.availableColors) &&
            combo.availableColors.length > 0
          ) {
            comboValue.availableColors = combo.availableColors;
          }
        } else {
          comboValue.availableColors = [];
        }

        if (combo.quantity) {
          comboValue.quantity = Number(combo.quantity);
        }

        if (combo.price) {
          comboValue.price = combo.price;
        } else {
          this.hasError = { message: "Price field is required." };
        }

        comboValue.nonMemberPrice = combo.nonMemberPrice;
        comboValue.salePrice = combo.salePrice;
        comboValue.shippingCost = combo.shippingCost;
        comboValue.unitCost = combo.unitCost;
        comboValue.nonMemberSalePrice = combo.nonMemberSalePrice;
        comboValue.sold = combo.sold;

        this.comboValues.push(comboValue);
      });
    }

    if (data.productPrimaryImage) {
      this.productPrimaryImage = data.productPrimaryImage;
    } else {
      this.hasError = { message: "Add primary image for product." };
    }

    this.productOptionalImages = data.productOptionalImages;
    this.sortIndex = data.sortIndex;
  }

  fromDataSet(data) {
    this.status = data.status;
    this.displayOn = data.displayOn;
    this.name = data.name;
    this.desc = data.desc;
    this.hasSize = data.hasSize;
    this.quantityPriceOption = data.quantityPriceOption;
    this.hasColor = data.hasColor;
    this.sku = data.sku;
    this.comboValues = data.comboValues;
    this.productPrimaryImage = data.productPrimaryImage;
    this.productOptionalImages = data.productOptionalImages;
    this.sortIndex = data.sortIndex;
  }
}
