import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ticketsReportLoading,
  ticketsReportMissingTxns,
} from "../../../redux/selectors/superadmin";
import { setMissingTransactions } from "../../../redux/actions/superadmin/tickets-report";
import { fetchAllClubsAndLeagues } from "../../../services/indexedDb/groups";
import moment from "moment";

const useTicketsReportData = () => {
  const dispatch = useDispatch();

  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState("");

  const allMissingTransactions = useSelector(ticketsReportMissingTxns);
  const dataLoading = useSelector(ticketsReportLoading);

  const [clubsLoading, setClubsLoading] = useState(false);

  const columns = [
    {
      title: "Time",
      key: "timestamp",
      className: "header-white",
      dataIndex: "timestamp",
      render: (timestamp) =>
        timestamp ? moment(timestamp).format("Do MMM, YYYY") : "-",
      width: window.screen.width > 500 ? 150 : 100,
      sorter: true,
    },
    {
      title: "Group Name",
      key: "groupName",
      className: "header-white",
      dataIndex: "groupName",
      width: window.screen.width > 500 ? 150 : 100,
    },
    {
      title: "Group ID",
      key: "groupId",
      className: "header-white",
      dataIndex: "groupId",
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "Ticket ID",
      key: "ticketId",
      className: "header-white",
      dataIndex: "ticketId",
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "Payment ID",
      key: "paymentId",
      className: "header-white",
      dataIndex: "payment_intent",
      width: window.screen.width > 500 ? 300 : 250,
    },
    {
      title: "Total Cost",
      key: "totalCost",
      className: "header-white",
      dataIndex: "totalCost",
      width: window.screen.width > 500 ? 150 : 100,
    },
  ];

  const onClubChange = (e) => {
    setSelectedClub(e.target.value);
    if (!allMissingTransactions?.[e.target.value]) {
      dispatch(setMissingTransactions(e.target.value));
    }
  };

  const fetchAllClubsAndLeaguesData = () => {
    setClubsLoading(true);
    fetchAllClubsAndLeagues().then(({ allClubs }) => {
      setClubs(allClubs);
    });

    setClubsLoading(false);
  };

  useEffect(() => {
    fetchAllClubsAndLeaguesData();
  }, []);

  return {
    clubs,
    columns,
    dataLoading,
    clubsLoading,
    selectedClub,
    allMissingTransactions,
    onClubChange,
  };
};

export default useTicketsReportData;
