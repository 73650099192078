import React from "react";
import "./VideoPlayer.css";

const VideoPlayer = ({ videoUrl }) => {
  return (
    <div className="video-responsive">
      <iframe
        width="100%"
        height="250"
        src={videoUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube"
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
