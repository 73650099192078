import moment from "moment";
import { db } from "../../connection";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "@firebase/firestore";

export function getStoreItems(groupId) {
  return new Promise((resolve, reject) => {
    getDocs(collection(db, "store", groupId, "items"))
      .then((snap) => {
        let items = [];
        snap.docs.forEach((doc) => {
          let item = doc.data() || {};
          item.id = doc.id;
          items.push(item);
        });
        resolve(items);
      })
      .catch(reject);
  });
}

export async function getStoreItemsForRegistration(groupId) {
  let storeItems = await getStoreItems(groupId);
  let storeItemsForRegistration = [];

  if (storeItems) {
    storeItemsForRegistration = storeItems.filter(
      (item) =>
        item.displayOn &&
        Array.isArray(item.displayOn) &&
        item.displayOn.includes("registration"),
    );
  }

  return storeItemsForRegistration;
}

export function addItem(groupId, data) {
  return new Promise((resolve, reject) => {
    addDoc(collection(db, "store", groupId, "items"), data)
      .then(resolve)
      .catch(reject);
  });
}

export function updateItem(itemId, groupId, data) {
  return new Promise((resolve, reject) => {
    let docRef = doc(db, "store", groupId, "items", itemId);
    updateDoc(docRef, data).then(resolve).catch(reject);
  });
}

export function getItem(itemId, groupId) {
  return new Promise((resolve, reject) => {
    let docRef = doc(db, "store", groupId, "items", itemId);

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          resolve(doc.data() || {});
        }
      })
      .catch(reject);
  });
}

export function setSortOrder(idOrderMap, groupId) {
  return new Promise((resolve, reject) => {
    let collectionRef = collection(db, "store", groupId, "items");

    let writePromises = Object.entries(idOrderMap).map((entry) =>
      updateDoc(doc(collectionRef, entry[0]), { sortIndex: entry[1] }),
    );
    Promise.all(writePromises)
      .then(() => resolve())
      .catch(reject);
  });
}

export function getOrders(groupId) {
  return new Promise((resolve, reject) => {
    let collectionRef = collection(db, "store", groupId, "orders");

    getDocs(collectionRef)
      .then((snap) => {
        let orders = snap.docs.map((doc) => {
          let data = doc.data() || {};
          let dataObj = {};
          let address = "";
          if (data.shippingOption === "shipping") {
            if (data.buyerInfo.address1) {
              address += data.buyerInfo.address1 + ",";
              dataObj.address1 = data.buyerInfo.address1;
            }
            if (data.buyerInfo.address2) {
              address += data.buyerInfo.address2 + ",";
              dataObj.address2 = data.buyerInfo.address2;
            }
            if (data.buyerInfo.city) {
              address += data.buyerInfo.city + ",";
              dataObj.city = data.buyerInfo.city;
            }
            if (data.buyerInfo.state) {
              address += data.buyerInfo.state + ",";
              dataObj.state = data.buyerInfo.state;
            }
            if (data.buyerInfo.country) {
              address += data.buyerInfo.country + " ";
              dataObj.country = data.buyerInfo.country;
            }

            let addressArr = address.split("");
            addressArr.pop();
            address = addressArr.join("") + " ";
            address = address.replace(/,/g, ", ");

            if (data.buyerInfo.zip) {
              address += data.buyerInfo.zip;
              dataObj.zip = data.buyerInfo.zip;
            }
          }

          dataObj.id = doc.id;
          dataObj.customerName =
            data.buyerInfo.name || data.buyerInfo.fullName || "";
          dataObj.address = address;
          dataObj.email = data.buyerInfo.email || "";
          dataObj.phone = data.buyerInfo.phone || "";
          dataObj.shippingOption = data.shippingOption || "";
          dataObj.shippingAmount = data.shippingAmount || "0";
          dataObj.timestamp = data.timestamp || "";
          dataObj.items = data.items || [];
          dataObj.paymentIntentId = data.paymentIntentId || "";
          return dataObj;
        });
        resolve(orders || []);
      })
      .catch(reject);
  });
}

export function fbUpdateStatus(groupId, editedRecords) {
  let collectionRef = collection(db, "store", groupId, "orders");

  let promises = Object.keys(editedRecords).map(async (orderId) => {
    let docRef = doc(collectionRef, orderId);
    const document = await getDoc(docRef);
    if (document.exists()) {
      let data = document.data() || {};
      let items = data.items;
      Object.keys(editedRecords[orderId]).forEach((index) => {
        items[index].status = editedRecords[orderId][index];
        items[index].statusUpdateTimestamp = moment().unix();
      });

      return new Promise((res, rej) => {
        updateDoc(docRef, { items: items })
          .then(() => res())
          .catch(rej);
      });
    }
    return new Promise((res) => {
      res();
    });
  });

  return Promise.all(promises);
}
