import { Popconfirm, Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";
import {
  getStoreItems,
  setSortOrder,
} from "../../../services/firebaseService/endPoints/admin/store";
import Orders from "./orders";
import Store from "./store";
import StoreSettings from "./StoreSettings";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_GROW,
  TOPIC_SELL_MERCHANDISE,
} from "../../../constants/gettingStarted";

class StoreContainer extends React.Component {
  state = {
    currentTab: "",
    needsUpdate: false,
    tableData: [],
    ordersDownload: false,
  };

  changeTab = (e) => {
    this.setState({
      currentTab: e.target.name,
    });
    this.props.history.replace(`/admin/store/${e.target.name}`);
  };

  mandateUpdate = () => {
    this.setState({ needsUpdate: true });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div
        className="col"
        style={{ width: "100%", minHeight: "100vh", margin: 0 }}
      >
        <div
          className="mx-auto"
          style={{
            padding: "30px 16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <ul
                className="nav nav-tabs"
                style={{
                  fontSize: "15px",
                  color: "#ffffff",
                  position: "relative",
                }}
              >
                {this.props.match.params.tab === "merch" && (
                  <li
                    className="nav-item"
                    onClick={this.changeTab}
                    style={{
                      borderBottom: `4px solid ${primaryColor}`,
                    }}
                  >
                    <a
                      name="merch"
                      style={{
                        height: "100%",
                        fontWeight: "bold",
                        fontSize: "22px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Merchandise
                      </span>
                    </a>
                  </li>
                )}

                {this.props.match.params.tab === "orders" && (
                  <li
                    className="nav-item"
                    onClick={this.changeTab}
                    style={{
                      borderBottom: `4px solid ${primaryColor}`,
                    }}
                  >
                    <a
                      name="orders"
                      style={{
                        height: "100%",
                        fontWeight: "bold",
                        fontSize: "22px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Orders
                      </span>
                    </a>
                  </li>
                )}

                {this.props.match.params.tab === "settings" && (
                  <li
                    className="nav-item"
                    onClick={this.changeTab}
                    style={{
                      borderBottom: `4px solid ${primaryColor}`,
                    }}
                  >
                    <a
                      name="settings"
                      style={{
                        height: "100%",
                        fontWeight: "bold",
                        fontSize: "22px",
                      }}
                    >
                      Settings
                    </a>
                  </li>
                )}

                {
                  <li
                    className="ml-auto align-items-center collapsible-horizontal tab-menu"
                    id="store-utilities"
                  >
                    {(this.props.match.params.tab === "orders" ||
                      this.props.match.params.tab === "merch") && (
                      <a
                        class="icon"
                        style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                        onClick={() =>
                          switchCollapseHorizontal("store-utilities")
                        }
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                    )}
                    <div className="d-flex justify-content-end">
                      {this.props.match.params.tab === "orders" && (
                        <Popconfirm
                          title={"Download all orders list?"}
                          onConfirm={() => {
                            this.setState({
                              ordersDownload: true,
                            });
                          }}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button
                            style={{
                              backgroundColor: primaryColor,
                              borderRadius: 5,
                              border: "0px none",
                              boxShadow: "transparent 0px 0px 0px",
                              outline: "none",
                              padding: "5px 10px",
                              width: "150px",
                              alignSelf: "flex-end",
                            }}
                            className="mr-1 default-text-color"
                          >
                            {this.state.ordersDownload ? <Spin /> : "Download"}
                          </button>
                        </Popconfirm>
                      )}

                      {this.state.needsUpdate && (
                        <button
                          className="mr-2 default-text-color"
                          style={{
                            backgroundColor: primaryColor,
                            borderRadius: 5,
                            border: "0px none",
                            boxShadow: "transparent 0px 0px 0px",
                            outline: "none",
                            padding: "5px 10px",
                            width: "150px",
                            alignSelf: "flex-end",
                          }}
                          onClick={() => {
                            let idOrderMap = this.state.tableData.reduce(
                              (acc, val, index) => {
                                acc[val.id] = index || val.sortIndex;
                                return acc;
                              },
                              {}
                            );

                            setSortOrder(
                              idOrderMap,
                              this.props.currentGroup
                            ).then(() => {
                              this.setState({ needsUpdate: false });
                            });
                          }}
                        >
                          Update Changes
                        </button>
                      )}
                      {this.props.match.params.tab === "merch" && (
                        <button
                          className="default-text-color"
                          style={{
                            backgroundColor: primaryColor,
                            borderRadius: 5,
                            border: "0px none",
                            boxShadow: "transparent 0px 0px 0px",
                            outline: "none",
                            padding: "5px 10px",
                            width: "150px",
                            alignSelf: "flex-end",
                          }}
                          onClick={() => {
                            this.props.history.push("/admin/store/create");
                          }}
                        >
                          Add Item
                        </button>
                      )}
                    </div>

                    <div className="show-for-mobile">
                      <div className="menu-list">
                        {this.props.match.params.tab === "orders" && (
                          <Popconfirm
                            title={"Download all orders list?"}
                            onConfirm={() => {
                              this.setState({
                                ordersDownload: true,
                              });
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div
                              style={{
                                backgroundColor: "#ffffff",
                                padding: "5px 10px",
                                color: "#000000",
                              }}
                            >
                              {this.state.ordersDownload ? (
                                <Spin />
                              ) : (
                                "Download"
                              )}
                            </div>
                          </Popconfirm>
                        )}

                        {this.state.needsUpdate && (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              padding: "5px 10px",
                              color: "#000000",
                            }}
                            onClick={() => {
                              let idOrderMap = this.state.tableData.reduce(
                                (acc, val, index) => {
                                  acc[val.id] = index || val.sortIndex;
                                  return acc;
                                },
                                {}
                              );

                              setSortOrder(
                                idOrderMap,
                                this.props.currentGroup
                              ).then(() => {
                                this.setState({ needsUpdate: false });
                              });
                            }}
                          >
                            Update Changes
                          </div>
                        )}
                        {this.props.match.params.tab === "merch" && (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              padding: "5px 10px",
                              color: "#000000",
                            }}
                            onClick={() => {
                              this.props.history.push("/admin/store/create");
                            }}
                          >
                            Add Item
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                }
              </ul>
            </div>
            {/* <div className='col-md-6'>
                    <span style={{
                        fontSize: 18,
                        fontWeight: 'bold'
                    }}>Merchandise</span>
                </div> */}
          </div>
          {this.props.match.params.tab === "merch" && (
            <Store
              goToPath={(path) => this.props.history.push(path)}
              mandateUpdate={this.mandateUpdate}
              tableData={this.state.tableData}
              updateTable={this.updateTable}
            />
          )}
          {this.props.match.params.tab === "settings" && <StoreSettings />}
          {this.props.match.params.tab === "orders" && (
            <Orders
              download={this.state.ordersDownload}
              setDownloading={(isDownloading) => {
                this.setState({
                  ordersDownload: isDownloading,
                });
              }}
            />
          )}
        </div>
      </div>
    );
  }

  updateTable = (tableData) => {
    this.setState({
      tableData,
    });
  };

  fetchData = () => {
    getStoreItems(this.props.currentGroup).then((items) => {
      this.setState({ tableData: items });
      if (items?.length) {
        this.props.dispatch(
          setGettingStartedItemStatus(
            this.props.currentGroup,
            GUIDE_GROW,
            TOPIC_SELL_MERCHANDISE,
            true
          )
        );
      }
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(StoreContainer);
