class Giveaway {
  constructor() {
    this.itemName = "";
    this.itemDesc = "";
    this.quantity = "";
    this.goal = "";
    this.proceedsSupportName = "";
    this.donatedByText = "";
    this.paypalEmailId = "";
  }

  toDataSet(data) {
    this.itemDesc = data.itemDesc;
    this.quantity = data.quantity;
    this.goal = data.goal;
    this.proceedsSupportName = data.proceedsSupportName;
    this.donatedByText = data.donatedByText;
    this.paypalEmailId = data.paypalEmailId;
    this.groups = ["global"];

    if (data.itemName) {
      this.itemName = data.itemName;
    } else {
      let message = "Item name not found";
      this.hasError = { message };
      return;
    }

    if (data.groups.length > 0) {
      this.groups = data.groups;
    }
  }

  fromDataSet(data) {
    this.itemDesc = data.itemDesc;
    this.quantity = data.quantity;
    this.goal = data.goal;
    this.itemName = data.itemName;
    this.proceedsSupportName = data.proceedsSupportName;
    this.donatedByText = data.donatedByText;
    this.paypalEmailId = data.paypalEmailId;
    this.imageFile = { name: "-1" };
    this.imageList = data.itemImage ? [{ uid: "-1", url: data.itemImage }] : [];
    this.proceedsImageFile = { name: "-1" };
    this.proceedSupportImageList = data.proceedsSupportImage
      ? [{ uid: "-1", url: data.proceedsSupportImage }]
      : [];
    this.donatedByImage = { name: "-1" };
    this.donatedByImageList = data.donatedByImage
      ? [{ uid: "-1", url: data.donatedByImage }]
      : [];
    this.groups = data.groups;
  }
}

export default Giveaway;
