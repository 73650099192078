import React from "react";
import { connect } from "react-redux";
import { startAddGroups } from "../../../redux/actions/group";
import { Table } from "antd";
import {
  fetchAllClubsAndLeagues,
  getGroupsFromLocal,
  getStats,
} from "../../../services/indexedDb/groups";
import { fbGetGroupsForClub } from "../../../services/firebaseService/endPoints/group";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import AdoptionNestedTable from "./AdoptionNestedTable";

const OWNER = ["Abby", "Birdie", "Justin", "Tom"];

const DEFAULT_PAGE_SIZE = 100;

class AdoptionClass extends React.Component {
  constructor() {
    super();
    this.timeout = null;
    this.state = {
      isLoading: true,
      pagination: {
        defaultPageSize: DEFAULT_PAGE_SIZE,
        pageSize: DEFAULT_PAGE_SIZE,
      },
      tableData: [],
      sorter: { field: "", order: "" },
      search: "",
      stats: {
        regMembers: 0,
        preRegMembers: 0,
        totalMembers: 0,
      },

      filters: {
        search: "",
        club: "",
        league: "",
        status: "",
        adoption: "",
        owner: "",
      },
      allClubs: [],
      allLeagues: [],
      currentGroupId: "",
      currentGroupNotes: "",
      hasFilter: false,
      filterState: "stable",
    };
  }

  gettingStartedTopicsCount = {
    totalCount: 24,
    setup: 7,
    onboard: 4,
    engage: 8,
    grow: 5,
  };

  handleTableChange = (pagination, filters, sorter) => {
    if (!this.state.search) {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
        pagination: pager,
        sorter,
      });
      this.fetchGroups((pager.current - 1) * DEFAULT_PAGE_SIZE, sorter);
      document.querySelector(".ant-table-body").scrollTo(0, 0);
    }
  };

  fetchGroups = (skip, sorter = this.state.sorter) => {
    let orderBy = sorter.field;
    let order = sorter.order;
    this.setState({ isLoading: true });

    let promise1 = getGroupsFromLocal(
      skip || 0,
      DEFAULT_PAGE_SIZE,
      orderBy,
      order === "ascend" ? "next" : "prev",
      this.state.filters
    ).then((data) => {
      return new Promise(async (resolve, reject) => {
        let promises = [];
        data.forEach(async (group) => {
          if (group.children) {
            if (group.children.includes("All")) {
              promises.push(
                new Promise(async (res, rej) => {
                  group.children = await fbGetGroupsForClub(group.clubId).then(
                    (groups) => {
                      return groups.filter((doc) => doc.id !== group.id);
                    }
                  );
                  res();
                })
              );
            } else if (group.children.includes("Official Groups")) {
              promises.push(
                new Promise(async (res, rej) => {
                  group.children = await fbGetGroupsForClub(group.clubId).then(
                    (groups) => {
                      let res = [];
                      groups.forEach((childGrp) => {
                        if (
                          childGrp.groupType === "Official Supporters Group" &&
                          childGrp.id !== group.id
                        ) {
                          res.push(childGrp);
                        }
                      });

                      return res;
                    }
                  );
                  res();
                })
              );
            } else {
              promises.push(
                new Promise(async (res, rej) => {
                  let children = [...group.children];
                  group.children = await fbGetGroupsForClub(group.clubId).then(
                    (groups) => {
                      return groups.filter((doc) => children.includes(doc.id));
                    }
                  );
                  res();
                })
              );
            }
          }
        });
        await Promise.all(promises);
        let result = {
          tableData: data,
          isLoading: false,
        };

        resolve(result);
      });
    });

    let promise2 = getStats(this.state.filters);

    Promise.all([promise1, promise2]).then((res) => {
      this.setState({
        ...res[0],
        stats: {
          ...res[1],
        },
      });
    });
  };

  fetchAllClubsAndLeaguesData = () => {
    fetchAllClubsAndLeagues().then(({ allClubs, allLeagues }) => {
      this.setState({
        allClubs,
        allLeagues,
      });
    });
  };

  onFilterChange = () => {
    let filters = this.state.filters;
    let hasFilter =
      Object.keys(filters).filter((key) => `${filters[key]}`.length > 0)
        .length > 0;
    this.setState(
      {
        tableData: [],
        hasFilter: hasFilter,
        pagination: hasFilter
          ? false
          : { defaultPageSize: DEFAULT_PAGE_SIZE, pageSize: DEFAULT_PAGE_SIZE },
      },
      () => {
        if (this.state.filterState === "unstable") {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.setState({
              filterState: "stable",
            });
          }, 500);
        } else {
          this.fetchGroups();
        }
      }
    );
  };

  render() {
    const columns = [
      {
        align: "center",
        title: "Group",
        dataIndex: "groupName",
        key: "groupName",
        className: "header-white",
        width: 200,
      },
      {
        align: "center",
        title: "% Reg",
        width: window.screen.width > 500 ? 100 : 50,
        className: "header-white",
        key: "percentageRegistered",
        render: (data) => {
          let rm = data.registeredMembers || 0;
          let tm = data.totalMembers || 0;
          let val = (rm / tm) * 100;
          return (Number.isNaN(val) ? 0 : Math.round(val)) + "%";
        },
      },
      {
        align: "center",
        title: `Reg\n(${this.state.stats.regMembers})`,
        width: window.screen.width > 500 ? 105 : 80,
        key: "registeredMembers",
        className: "header-white",
        dataIndex: "registeredMembers",
        render: (registeredMembers) => registeredMembers || 0,
        sorter: (a, b) => {
          const regA = a.registeredMembers || 0;
          const regB = b.registeredMembers || 0;
          return regA - regB;
        },
      },
      {
        align: "center",
        title: `Adoption %`,
        width: 150,
        key: "adoptionPercentage",
        className: "header-white",
        dataIndex: "gettingStarted",
        render: (registeredMembers) => {
          const totalCompletedItems = Object?.values(
            registeredMembers || {}
          )?.reduce((acc, curr) => {
            return acc + (curr?.completedItems || 0);
          }, 0);
          return `${Math.floor((totalCompletedItems / this.gettingStartedTopicsCount.totalCount) * 100) || 0}%`;
        },
      },
      {
        align: "center",
        title: "Setup",
        dataIndex: "gettingStarted.setup.completedItems",
        key: "setup",
        render: (data) =>
          `${Math.floor((data / this.gettingStartedTopicsCount.setup) * 100) || 0}%`,
        className: "header-white",
        width: 300,
      },
      {
        align: "center",
        title: "Onboard",
        dataIndex: "gettingStarted.onboard.completedItems",
        render: (data) =>
          `${Math.floor((data / this.gettingStartedTopicsCount.onboard) * 100) || 0}%`,
        key: "onboard",
        className: "header-white",
        width: 300,
      },
      {
        align: "center",
        title: "Engage",
        dataIndex: "gettingStarted.engage.completedItems",
        render: (data) =>
          `${Math.floor((data / this.gettingStartedTopicsCount.engage) * 100) || 0}%`,
        key: "engage",
        className: "header-white",
        width: 300,
      },
      {
        align: "center",
        title: "Grow",
        dataIndex: "gettingStarted.grow.completedItems",
        render: (data) =>
          `${Math.floor((data / this.gettingStartedTopicsCount.grow) * 100) || 0}%`,
        key: "grow",
        className: "header-white",
        width: 300,
      },
      {
        align: "center",
        title: "Owner",
        dataIndex: "owner",
        render: (data) => data || "-",
        key: "owner",
        className: "header-white",
        width: window.screen.width > 500 ? 100 : 50,
      },
      {
        align: "center",
        title: "Club Name",
        dataIndex: "clubName",
        render: (data) => data || "-",
        key: "clubName",
        className: "header-white",
        width: window.screen.width > 500 ? 200 : 150,
      },
    ];

    let dataCopy = JSON.parse(JSON.stringify(this.state.tableData));

    return (
      <div className="container p-5">
        <div className="row" style={{ marginLeft: "16px", gap: "24px" }}>
          <FInput
            value={this.state.filters.search}
            onChange={(e) => {
              let filters = this.state.filters;
              filters.search = e.target.value;
              this.setState(
                {
                  filters,
                  filterState: "unstable",
                },
                () => {
                  this.onFilterChange();
                }
              );
            }}
            name="search"
            display="row"
            placeholder="Search by group"
            tabIndex="2"
            style={{
              backgroundColor: "transparent",
              border: "0px none transparent",
              borderBottom: "2px solid white",
              color: "white",
              borderRadius: 0,
              fontSize: 16,
            }}
            className="placeholder-white"
          />

          <div className="ml-2" style={{ padding: "0px 15px" }}>
            <FSelect
              value={this.state.filters.club}
              onChange={(e) => {
                let filters = this.state.filters;
                filters.club = e.target.value;
                this.setState(
                  {
                    filters,
                  },
                  () => {
                    this.onFilterChange();
                  }
                );
              }}
              dataList={this.state.allClubs}
              dataKey="id"
              dataValue="label"
              style={{
                backgroundColor: "transparent",
                border: "0px none transparent",
                borderBottom: "2px solid white",
                color: "white",
                borderRadius: 0,
                fontSize: 16,
              }}
              name="status"
              display="row"
              placeholder="Club"
              tabIndex="2"
            />
          </div>

          <div className="ml-2" style={{ padding: "0px 15px" }}>
            <FSelect
              value={this.state.filters.owner}
              onChange={(e) => {
                let filters = this.state.filters;
                filters.owner = e.target.value;
                this.setState(
                  {
                    filters,
                  },
                  () => {
                    this.onFilterChange();
                  }
                );
              }}
              dataList={OWNER}
              dataOnlyValue={true}
              style={{
                backgroundColor: "transparent",
                border: "0px none transparent",
                borderBottom: "2px solid white",
                color: "white",
                borderRadius: 0,
                fontSize: 16,
              }}
              name="status"
              display="row"
              placeholder="Owner"
              tabIndex="2"
            />
          </div>
        </div>
        <Table
          columns={columns}
          bordered={false}
          dataSource={[...dataCopy]}
          loading={this.state.isLoading}
          expandedRowRender={(record) => <AdoptionNestedTable data={record} />}
          bodyStyle={{
            backgroundColor: "#ffffff",
          }}
          onChange={this.handleTableChange}
          scroll={{ x: true }}
          pagination={this.state.pagination}
          className="superadmin-logos-table table-backdrop mb-3"
        />
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.hasFilter &&
      this.props.adminData.groups.records !== this.state.pagination.total &&
      this.props.adminData.groups.records !== undefined
    ) {
      this.setState(
        (prevState) => {
          let pagination = { ...prevState.pagination };
          pagination.total = this.props.adminData.groups.records;
          pagination.defaultPageSize = DEFAULT_PAGE_SIZE;
          pagination.pageSize = DEFAULT_PAGE_SIZE;
          return { pagination, tableData: [] };
        },
        () => {
          this.fetchGroups(this.state.pagination.current || undefined);
          this.fetchAllClubsAndLeaguesData();
        }
      );
    }

    if (this.state.filterState !== prevState.filterState) {
      if (this.state.hasFilter && this.state.filterState === "stable") {
        this.fetchGroups();
      }
    }
  }

  componentDidMount() {
    let config = localStorage.getItem("groupsConfiguration");
    if (config) {
      config = JSON.parse(config);
      this.fetchGroups(
        (config.pagination.current - 1) * DEFAULT_PAGE_SIZE,
        config.sorter,
        config.filters
      );
      this.fetchAllClubsAndLeaguesData();
      this.setState({ ...config, isLoading: false });
    } else {
      this.props.dispatch(startAddGroups());
    }
    localStorage.setItem("groupsConfiguration", "");
  }
}
const mapStateToProps = (state) => {
  return {
    adminData: { groups: { ...state.groups } },
  };
};

export default connect(mapStateToProps)(AdoptionClass);
