import React from "react";
import "./DigitBadge.css";

const DigitBadge = ({ digit, badgeColor, bgColor, digitColor }) => {
  return (
    <div
      className="digit-badge-container"
      style={{ "--digit-badge-color": badgeColor, backgroundColor: bgColor }}
    >
      <span
        className="digit-badge-text"
        style={{ "--digit-badge-color": digitColor, backgroundColor: bgColor }}
      >
        {digit}
      </span>
    </div>
  );
};

export default DigitBadge;
