import React from "react";
import {
  fbGetGroupPaymentDetails,
  fbChangeGroupPaymentDetails,
} from "../../../services/firebaseService/endPoints/admin/groupConfig";
import { connect } from "react-redux";
import Axios from "axios";
import LoadingModal from "../../commons/LoadingModal";
import {
  GUIDE_SETUP,
  TOPIC_SET_GENERAL_SETTINGS,
} from "../../../constants/gettingStarted";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import { isEmailValid } from "../../../utils/validation";

class PaymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeAccountId: "",
      isLoading: true,
      updated: false,
      showAlert: {
        show: false,
        type: "error",
        message: "Invalid email address",
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleChangePaymentDetails = () => {
    this.setState({ showAlert: { show: false } });
    if (this.state.paypalAddress) {
      // validate email address
      const validEmail = isEmailValid(this.state.paypalAddress);

      if (validEmail) {
        this.setState({ isLoading: true });
        let paymentDetails = { paypalAddress: this.state.paypalAddress };
        fbChangeGroupPaymentDetails(
          this.props.currentGroup,
          paymentDetails
        ).then(() => {
          this.setState({ isLoading: false, updated: true });
          this.sendNotificationOnPaymentAddressChange(
            this.props.groupDocId,
            this.props.groupName
          );
        });
      } else {
        // invalid email message
        this.setState({
          showAlert: {
            show: true,
            type: "error",
            message: "Invalid email address",
          },
        });
      }
    } else {
      this.setState({ isLoading: true });
      let paymentDetails = { paypalAddress: "" };
      fbChangeGroupPaymentDetails(this.props.currentGroup, paymentDetails).then(
        () => {
          this.setState({ isLoading: false, updated: true });
          this.sendNotificationOnPaymentAddressChange(
            this.props.groupDocId,
            this.props.groupName
          );
        }
      );
    }
  };

  sendNotificationOnPaymentAddressChange(groupDocId, groupName) {
    var url =
      "https://us-central1-chant2019.cloudfunctions.net/sendNotificationOnPaymentAddressChange?groupDocId=" +
      groupDocId +
      "&groupName=" +
      groupName;
    // TRigger the call here using above url
    Axios.get(url);
  }

  handleInputChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value, updated: false });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    return (
      <div className="col">
        <LoadingModal loading={this.state.isLoading} />
        {/* {this.state.showAlert.show && (
                    <div className="container mt-5">
                        <div className="alert alert-danger" role="alert">
                            {this.state.showAlert.message}
                        </div>
                    </div>
                )}
                <form
                    onSubmit={this.handleSubmit}
                    className="border rounded mt-4 p-2 px-md-5 py-md-4  mx-md-auto mx-2"
                    style={{ 'maxWidth': '700px' }}
                >
                    <FInput
                        name="paypalAddress"
                        onChange={this.handleInputChange}
                        value={this.state.paypalAddress}
                        label={`Paypal business email for ${this.props.groupName}`}
                        display="col"
                    />
                    <p style={{ fontSize: '14px', fontStyle: 'italic', color: '#777777', marginLeft: '14px' }}><span style={{
                        fontsize: '50px', color: '#FF0000'
                    }}>*</span> Must be an email for a Paypal business account</p>

                    < div className="d-flex justify-content-center mb-2" >
                        <button
                            onClick={!this.state.updated ? this.handleChangePaymentDetails : () => { }}
                            className={`btn btn-sm btn-danger ${this.state.updated ? "disabled" : ""}`}>{this.state.updated ? "Updated" : "Update"}</button>
                    </div>

                    <p style={{ fontSize: '16px', marginLeft: "14px" }}>Enter your supporters group payment details for Paypal. This account is where payment will be routed for in-app member donations and purchases. It must be the email for your Paypal business account.</p>

                </form> */}
        <div style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "#ffffff",
              }}
            >
              <a>Payments</a>
            </li>
          </ul>

          <div
            className="border rounded mt-4 p-2 px-md-5 py-md-4  mx-md-auto mx-2"
            style={{
              backgroundColor: "#ffffff",
              // boxShadow: "0px 3px 10px 1px",
            }}
          >
            <p>
              Groups can set up a Stripe account through Chant to enable
              payments for donations, store, membership packages, group tickets
              and giveaway entries. Stripe allows members to pay with Apple Pay,
              Google Pay and credit card. Payments are sent directly to your
              associated bank account. Each group is provided access to a
              private Stripe dashboard.
            </p>
            <p style={{ marginBottom: 0 }}>
              Stripe Status:{" "}
              {this.state.stripeAccountId ? (
                <span style={{ marginLeft: 40 }}>
                  Active (
                  <a
                    href="https://dashboard.stripe.com/login"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dashboard
                  </a>
                  )
                </span>
              ) : (
                <span style={{ marginLeft: 40 }}>Inactive</span>
              )}
            </p>
            <p>
              Currency: <span>{groupCurrencyISO}</span>
            </p>

            <p>
              Questions? Reach out to{" "}
              <a href="mailto:support@chant.fan">support@chant.fan</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }

  getPaymentDetails() {
    fbGetGroupPaymentDetails(this.props.currentGroup).then((paymentDetails) => {
      let result = { isLoading: false };
      if (paymentDetails) {
        result.stripeAccountId = paymentDetails.stripeAccountId;
        result.updated = true;

        this.props.setGettingStartedStatus(this.props.currentGroup);
      } else {
        result.stripeAccountId = "";
        result.updated = false;
      }

      this.setState(result);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({ isLoading: true });
      this.getPaymentDetails();
    }
  }

  componentDidMount() {
    this.getPaymentDetails();
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    groupName:
      state.adminData.allGroups[state.adminData.currentGroup].data.groupName,
    groupDocId: state.adminData.allGroups[state.adminData.currentGroup].data.id,
    paymentDetails:
      state.adminData.allGroups[state.adminData.currentGroup].data
        .paymentDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGettingStartedStatus: (groupId) =>
      dispatch(
        setGettingStartedItemStatus(
          groupId,
          GUIDE_SETUP,
          TOPIC_SET_GENERAL_SETTINGS,
          true
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
