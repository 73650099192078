// import md5 from "md5";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "@firebase/storage";
import { db, storage } from "../../connection";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "@firebase/firestore";
import logger from "../../../../utils/logger";

export function uploadImage(file, path, index) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    const folderRef = ref(storageRef, `${path}`);
    // compressImageToMaxSize(file, 200).then(resizedFile =>
    uploadBytes(folderRef, file, {
      contentType: "image/png",
    })
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((downloadUrl) => {
        resolve({ downloadUrl, index });
      });
    // ).catch(reject);
  });
}

async function convertImageToDataUrl(file) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const img = document.createElement("img");

  const promise = new Promise((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = reject;
  });

  img.src = file;

  await promise;

  context.drawImage(img, 0, 0);

  return canvas.toDataURL();
}

async function compressImageToMaxSize(
  originalFile,
  targetFileSizeKb,
  maxDeviation = 50
) {
  // let originalFile = await urltoFile(dataUrl, 'test.png', 'image/png');
  let dataUrl = convertImageToDataUrl(originalFile);
  if (originalFile.size / 1000 < targetFileSizeKb) return dataUrl; // File is already smaller

  let low = 0.0;
  let middle = 0.5;
  let high = 1.0;

  let result = dataUrl;

  let file = originalFile;

  while (Math.abs(file.size / 1000 - targetFileSizeKb) > maxDeviation) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = document.createElement("img");

    const promise = new Promise((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = reject;
    });

    img.src = file;

    await promise;

    canvas.width = Math.round(img.width * middle);
    canvas.height = Math.round(img.height * middle);
    context.scale(canvas.width / img.width, canvas.height / img.height);
    context.drawImage(img, 0, 0);
    file = await urltoFile(canvas.toDataURL(), "test.jpg", "image/png");

    if (file.size / 1000 < targetFileSizeKb - maxDeviation) {
      low = middle;
    } else if (file.size / 1000 > targetFileSizeKb) {
      high = middle;
    }

    middle = (low + high) / 2;
    result = canvas.toDataURL();
  }

  let resizedFile = await urltoFile(result, "final.jpg", "image/png");

  return resizedFile;
}

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export function deleteImage(path, pathInDoc, groupId, url) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    deleteObject(ref(storageRef, path)).then(() => {
      let pathParts = pathInDoc.split(".");
      let docRef = doc(db, "group", groupId);
      getDoc(docRef).then((doc) => {
        if (doc.exists()) {
          let data = doc.data() || {};
          let websiteConfig = data.websiteConfig;
          let ref = getRefForPath(
            websiteConfig,
            pathParts.slice(0, pathParts.length - 1)
          );
          if (Array.isArray(ref[pathParts[pathParts.length - 1]])) {
            ref[pathParts[pathParts.length - 1]].splice(
              ref[pathParts[pathParts.length - 1]].indexOf(url),
              1
            );
          } else {
            ref[pathParts[pathParts.length - 1]] = "";
          }
          updateDoc(docRef, { websiteConfig: websiteConfig })
            .then(() => {
              resolve();
            })
            .catch(reject);
        }
      });
    });
  });
}

function getRefForPath(parent, pathParts) {
  if (pathParts.length === 1) {
    return parent[pathParts[0]];
  } else {
    return getRefForPath(parent[pathParts[0]], pathParts.slice(1));
  }
}

export function getWebsiteConfig(groupId) {
  return new Promise((res, rej) => {
    getDoc(doc(db, "website", groupId))
      .then((doc) => {
        if (doc.exists()) {
          res({ ...(doc.data() || {}), id: doc.id });
        } else {
          res({
            id: groupId,
          });
        }
      })
      .catch(rej);
  });
}

//hideGroupNumber
//kcp0w1Pksf1LqAQtDC8K

// export async function addMembersToInviteEmail(groupId) {
//     let newEmails = [];
//     let newUserDetails = {};
//     for (var member of data) {
//         newEmails.push(member.email.toLowerCase());
//         let fullName = member.firstName + " " + member.lastName;
//         let memberDetail = { ...member, fullName: fullName, email: member.email.toLowerCase() };
//         newUserDetails[md5(member.email.toLowerCase())] = memberDetail;
//     }
//     let docRef = db.collection('invite_emails').doc(groupId);
//     await docRef.get().then(doc => {
//         if (doc.exists) {
//             let emails = doc.data().emails || [];
//             let userDetails = doc.data().userDetails || {};

//             emails = [ ...emails, ...newEmails ];
//             userDetails = { ...userDetails, ...newUserDetails };

//             docRef.update({ emails, userDetails });
//         } else {
//             docRef.set({ emails: newEmails, userDetails: newUserDetails });
//         }
//     });

// }

export function getPaymentIntentIdsForTicket() {
  let ticketId = "f8uNQH9X9C7Ls7qehx4x";
  let transactionCollectionRef = collection(
    db,
    "transaction_record",
    "kcp0w1Pksf1LqAQtDC8K",
    "records"
  );
  return new Promise((res, rej) => {
    getDocs(transactionCollectionRef)
      .then((snap) => {
        let paymentIntentIds = [];
        for (var doc of snap.docs) {
          let data = doc.data() || {};
          let ticketParams = data.ticketParams;
          if (data.ticketParams) {
            ticketParams = JSON.parse(ticketParams);
            if (ticketParams.ticketId === ticketId) {
              paymentIntentIds.push(doc.id);
            }
          }
        }
        res(paymentIntentIds);
      })
      .catch(rej);
  });
}

export async function updateTicketDataForMissingSubticket() {
  let groupId = "kcp0w1Pksf1LqAQtDC8K";
  let ticketId = "f8uNQH9X9C7Ls7qehx4x";
  let transactionDocRef = doc(
    db,
    "transaction_record",
    "kcp0w1Pksf1LqAQtDC8K",
    "records",
    "pi_3NYyijBqZ4jd1vQC3AjCPrAO"
  );
  let docRef = await getDoc(transactionDocRef);
  if (docRef.exists()) {
    let data = docRef.data() || {};
    let ticketParams = data.ticketParams;
    if (ticketParams) {
      ticketParams = JSON.parse(ticketParams);
      let customerId = data.customerDocId;
      if (customerId) {
        let purchaseDocRef = doc(
          db,
          "tickets",
          groupId,
          "ticket",
          ticketId,
          "purchases",
          customerId
        );
        let purchaseDoc = await getDoc(purchaseDocRef);
        if (purchaseDoc.exists()) {
          let purchasedSubTickets = (purchaseDoc.data() || {})
            .purchasedSubTickets;
          let transactionSubTickets = ticketParams.purchasedSubTickets;
          for (var key of Object.keys(transactionSubTickets)) {
            if (!purchasedSubTickets[key]) {
              purchasedSubTickets[key] = transactionSubTickets[key];
            }
          }
          purchaseDocRef
            .update({
              purchasedSubTickets: purchasedSubTickets,
            })
            .then(() => {
              logger.log("done");
            });
        }
      }
    }
  }
}
