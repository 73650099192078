import React, { useState } from "react";
import "./Accordion.css";
import AccordionCollapsed from "./AccordionCollapsed";
import AccordionExpanded from "./AccordionExpanded";

const Accordion = ({ title, isComplete, statusText, expanded, children }) => {
  const [accordionExpanded, setAccordionExpanded] = useState(expanded || false);

  const handleAccordionSize = (value) => {
    setAccordionExpanded(value);
  };

  return (
    <div
      className={`accordion ${accordionExpanded ? "accordion--expanded" : "accordion--collapsed"}`}
      onClick={() => !accordionExpanded && setAccordionExpanded(true)}
    >
      <AccordionCollapsed
        title={title}
        isComplete={isComplete}
        statusText={statusText}
        expanded={accordionExpanded}
        handleAccordionSize={handleAccordionSize}
      />

      {accordionExpanded && <AccordionExpanded>{children}</AccordionExpanded>}
    </div>
  );
};

export default Accordion;
