import React from "react";
import { GithubPicker } from "react-color";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: true,
      // color: props.color
    };
  }

  handleColorInputChange = (e) => {
    const prevColor = { rgb: {} };
    prevColor.rgb = { ...this.props.color };
    prevColor.rgb[e.target.name] = Number(e.target.value);
    this.handleChangeRGB(prevColor, this.props.colorType);
  };

  convertToRGBA(color) {
    return `rgba(${color.r},${color.g},${color.b}, ${color.a})`;
  }

  handleChangeRGB = (color, type) => {
    let newValue = Object.assign({}, color.rgb);
    newValue.a = this.props.color.a;
    this.props.handleColorChange(newValue, type);
  };

  // handleChangeAlpha = (color, type) => {
  //     let newValue = Object.assign({}, this.props.color)
  //     newValue.a = color.rgb.a
  //     this.props.handleColorChange(newValue, type)
  // };

  render() {
    return (
      <div
        className={`row ${window.screen.width < 500 ? "justify-content-end pr-3" : ""}`}
      >
        {/* <Card style={{
                    width: window.screen.width > 500 ? 300 : 200,
                    backgroundColor: this.convertToRGBA(this.props.color),
                    border: '8px solid #F2F4F5', padding: '5px'
                }}>

                    {
                        this.props.textName &&
                        <div className="form-group mt-2">
                            <h6 style={{ color: /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(this.props.textColor) ? this.props.textColor : 'black' }}>{this.props.text}</h6>
                            <input
                                placeholder="#FFFFFF"
                                className="form-control form-control-sm"
                                name={this.props.textName}
                                onChange={this.props.handleTextColorChange}
                                value={this.props.textColor}
                                disabled={this.props.textColorLimitOptions}
                            />

                            {this.props.textColorLimitOptions || window.screen.width < 500 ?
                                <CirclePicker
                                    className="mt-2"
                                    colors={['#000000', '#FFFFFF']}
                                    width='220px'
                                    triangle={"hide"}
                                    onChange={(color) => {
                                        // this.handleChangeRGB(color, this.props.colorType)
                                        this.props.handleTextColorChange({ target: { name: this.props.textName, value: color.hex }, persist: () => { } })
                                        // this.handleChangeAlpha(color, this.props.colorType)
                                    }}
                                /> :

                                <HuePicker
                                    className="mt-1"
                                    width="220px"
                                    color={this.props.textColor}
                                    onChange={(color) => {
                                        this.props.handleTextColorChange({ target: { name: this.props.textName, value: color.hex }, persist: () => { } })
                                    }} />


                            }

                        </div>
                    }
                </Card> */}

        <div style={{ padding: "10px", backgroundColor: "#ffffff" }}>
          <div
            style={{
              display: window.screen.width > 500 ? "" : "none",
              marginLeft: "-15px !important",
            }}
          >
            {/* <HuePicker
                            color={this.props.color}
                            onChange={(color) => { this.handleChangeRGB(color, this.props.colorType) }} />
                        <AlphaPicker
                            color={this.props.color}
                            onChange={(color) => { this.handleChangeAlpha(color, this.props.colorType) }} /> */}

            <GithubPicker
              className="mt-2"
              colors={this.props.colorOptions}
              width="220px"
              triangle={"hide"}
              onChange={(color) => {
                this.handleChangeRGB(color, this.props.colorType);
                // this.handleChangeAlpha(color, this.props.colorType)
              }}
            />
          </div>

          <div
            className={`row mt-2 ${window.screen.width > 500 ? "mx-2" : ""}`}
          >
            <div
              className={`row ${window.screen.width < 500 ? "justify-content-end" : ""}`}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  className=" form-control form-control-sm"
                  name="r"
                  onChange={this.handleColorInputChange}
                  value={this.props.color.r}
                />
                <label htmlFor="r">R</label>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  className=" form-control form-control-sm"
                  name="g"
                  onChange={this.handleColorInputChange}
                  value={this.props.color.g}
                />
                <label htmlFor="g">G</label>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  className=" form-control form-control-sm"
                  name="b"
                  onChange={this.handleColorInputChange}
                  value={this.props.color.b}
                />
                <label htmlFor="b">B</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ColorPicker;
