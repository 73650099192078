import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";

class Payment extends React.Component {
  render() {
    return (
      <StripeProvider apiKey="pk_live_Fd3EUqqRps0lrU5HS3p91UUn00mKYwBZRw">
        <div className="form-group col">
          <Elements>
            <CheckoutForm {...this.props} />
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}

export default Payment;
