import {
  Alert,
  Button,
  DatePicker,
  Icon,
  Input,
  Modal,
  Select,
  TimePicker,
  Tooltip,
} from "antd";
import { continents, countries } from "countries-list";
// 3rd party helper libraries
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import Content from "../../../models/admin/Content";
// redux store actions
import {
  startAddContent,
  startEditContent,
} from "../../../redux/actions/adminData";
import { applicationError } from "../../../redux/actions/error";
// api end points
import Axios from "../../../services/apiFootballService/connection";
import {
  fbCheckChatChannelName,
  fbCreateContent,
  fbGetChatChannel,
  fbGetContent,
  fbUpdateContent,
  fbUpdateNotification,
} from "../../../services/firebaseService/endPoints/admin/content";
import { getGroups } from "../../../services/firebaseService/endPoints/clubAdmin/groups";
//
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import FUpload from "../../commons/formFields/FUpload";
import Map from "../../commons/formFields/Map";
// common components
import LoadingModal from "../../commons/LoadingModal";
import Search from "../../commons/Search";
import DefaultSettingsImage from "../../../images/Chant_Event_Default_Settings.png";
import logger from "../../../utils/logger";

// constants
const { Option } = Select;

let defaultDateConfig = {
  "Watch Party": {
    homeFeed: 7,
    notifications: [1, 3],
  },
  Tailgate: {
    homeFeed: 7,
    notifications: [1, 3],
  },
  Travel: {
    homeFeed: 14,
    notifications: [7, 14],
  },
  Volunteer: {
    homeFeed: 14,
    notifications: [1, 7],
  },
  News: {
    homeFeed: -7,
    notifications: [0],
  },
  Update: {
    homeFeed: -7,
    notifications: [0],
  },
  "Special Event": {
    homeFeed: 14,
    notifications: [3, 14],
  },
};

const { RangePicker } = DatePicker;
const timeFormat = "HH:mm";
const dateFormat = "YYYY-MM-DD";
const options = [
  {
    value: "on submit",
    label: "On Submit",
  },
  {
    value: "day of event",
    label: "Day of Event",
  },
  {
    value: "1 day before event",
    label: "1 day before event",
  },
];
for (let i = 2; i <= 28; i++) {
  options.push({
    value: `${i} day before event`,
    label: `${i} day's before event`,
  });
}

let countrtyNameCodeMap = {};
Object.keys(countries).forEach((key) => {
  countrtyNameCodeMap[key] = countries[key].name;
});

class CreateClubContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      author: "",
      location: "",
      locationData: {
        placeId: "",
        address: "",
        latitude: 33.7676338,
        longitude: -84.5606888,
      },
      matchId: "",
      title: "",
      imageFile: {},
      imageList: [],
      eventStartDate: "",
      eventEndDate: "",
      eventStartTime: "",
      eventEndTime: "",
      details: "",
      chatChannelName: "",
      channelArchiveDateStart: "",
      channelArchiveDateEnd: "",
      notifications: [null],
      oldNotifications: [null],
      showOnHomePageStart: "",
      showOnHomePageEnd: "",
      previewVisible: false,
      previewImage: "",
      errorInForm: {
        isError: false,
        visible: false,
        message: "",
      },
      showModal: false,
      memberResponseState: 1,
      isLinkTicket: false,
      ticketId: "",
      ticketName: "",
      ticketMatchId: "",
      loadingDocument: !!props.match.params.id,
      editMode: !!props.match.params.id,
      editConfiguration: {
        imageChanged: false,
      },
      events: [
        "Watch Party",
        "Tailgate",
        "Travel",
        "Volunteer",
        "News",
        "Update",
        "Special Event",
      ],
      matchList: [],
      tokenList: [],
      clubId: props.clubId,
      groupId: `${props.clubId}_club_contents`,
      isLoading: false,
      loadingMatches: true,
      groups: [],
      allGroups: [],
      params: [],
      count: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      isLoading: true,
    });

    if (
      this.state.eventEndDate &&
      this.state.showOnHomePageEnd > this.state.eventEndDate
    ) {
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message:
            "Show on home screen end date should not be after event end date",
        },
        isLoading: false,
      });
      window.scrollTo(0, 0);
      return;
    }

    if (
      this.state.isLinkTicket === true &&
      (this.state.ticketId === "" || this.state.ticketName === "")
    ) {
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: "Link proper ticket for member response",
        },
        isLoading: false,
      });
      window.scrollTo(0, 0);
      return;
    }

    let data = new Content();
    data.toDataSet({
      ...this.state,
    });
    data = JSON.parse(JSON.stringify(data));
    data.parentId = this.props.currentGroup;

    if (data.hasError) {
      window.scrollTo(0, 0);
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: data.hasError.message,
        },
        isLoading: false,
      });
      window.scrollTo(0, 0);
      setTimeout(this.handleErrorMessageClose, 5000);
    } else {
      this.setState({
        isLoading: true,
        loadingPercentage: 10,
      });
      let promise;
      // new content
      if (!this.state.editMode) {
        promise = fbCreateContent(data, this.state.imageFile);
        fbUpdateNotification(data, this.state.oldNotifications, data.groupId);
      } else {
        // edit content
        // data.chatChannel.id = this.state.editConfiguration.channelId
        let content = JSON.parse(JSON.stringify(data));
        promise = fbUpdateContent(
          content,
          this.props.match.params.id,
          this.state.editConfiguration.imageChanged
            ? this.state.imageFile
            : undefined,
          this.state.editConfiguration.channelChange &&
            this.state.chatChannelName,
          this.state.editConfiguration.newChannel && this.state.chatChannelName,
          this.state.editConfiguration.imageChanged &&
            !this.state.imageFile.name
        );
        fbUpdateNotification(data, this.state.oldNotifications, data.groupId);
      }
      promise.then((content) => {
        // 	if (!this.state.editMode) {
        // 		this.props.dispatch(startAddContent(content, this.props.currentGroup));
        // 	} else {
        // 		this.props.dispatch(startEditContent(content, this.props.currentGroup));
        // 	}
        this.setState({
          loading: false,
        });
        this.props.history.replace("/clubadmin/content");
      });
    }
  };
  locationClear = (e) => {
    this.setState((prevState) => {
      const locationData = {
        ...prevState.locationData,
      };
      locationData.placeId = "";
      locationData.address = "";
      return {
        location: "",
        locationData,
      };
    });
  };
  mapUpdate = (e) => {
    this.setState((prevState) => {
      const locationData = {
        ...prevState.locationData,
      };
      // placeId: e.placeId,
      locationData.address = e.address;
      locationData.placeId = e.placeId;
      locationData.longitude = e.lang;
      locationData.latitude = e.lat;
      return { locationData };
    });
  };
  handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };
  handleTicketListChange = (e) => {
    let ticketId, ticketMatchId;
    this.state.tokenList.forEach((ticket, index) => {
      if (ticket.name === e) {
        ticketId = ticket.id;
        ticketMatchId = ticket.matchId;
      }
    });
    this.setState({
      ticketName: e,
      ticketId: ticketId,
      ticketMatchId: ticketMatchId,
    });
  };

  // Called on form select options changes
  handleOptionChange = (e) => {
    e.persist();
    let startDate;

    if (e.target.name === "matchId") {
      this.state.matchList.forEach((match, _) => {
        if (String(match.fixture_id) === String(e.target.value)) {
          startDate = match.event_timestamp;
        }
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
      eventStartDate: startDate,
    });
  };
  handleRadioChange = (e) => {
    e.persist();

    if (Number(e.target.value) === 2) {
      this.setState({
        memberResponseState: e.target.value,
        isLinkTicket: true,
      });
    } else if (Number(e.target.value) === 1) {
      this.setState({
        ticketName: "",
        ticketId: "",
        ticketMatchId: "",
        memberResponseState: e.target.value,
        isLinkTicket: false,
      });
    }
  };
  handleMapOK = (e) => {
    e.persist();
    this.state.showModal = !this.state.showModal;
    //setState is called inn below funnction so no need too call above
    this.child.updateFormMapData();
  };
  handleModalChange = (e) => {
    e.persist();
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleDateChange = (name, value) => {
    // checking for chat channel dates change in edit mode.
    if (!Array.isArray(value)) value = moment(value).isValid() ? value : "";
    let hours = 0,
      minutes = 0,
      seconds = 59;
    if (name.includes("End")) {
      hours = 23;
      minutes = 59;
    }

    if (this.state.editMode && name === "channelArchiveDate") {
      this.setState((prevState) => {
        let editConfiguration = {
          ...prevState.editConfiguration,
        };
        editConfiguration.channelChange = true;
        return {
          editConfiguration,
        };
      });
    }

    if (name === "eventStartDate" && !value) {
      this.setState({
        eventStartDate: "",
        eventEndDate: "",
      });
    } else if (name.includes("Time")) {
      let date = this.state[name.replace("Time", "Date")];
      if (date) {
        if (value) {
          hours = value.hours();
          minutes = value.minutes();
          seconds = "00";
        }
        let time = moment
          .unix(date)
          .set({
            h: hours,
            m: minutes,
            second: seconds,
          })
          .unix();
        this.setState({
          [name.replace("Time", "Date")]: time,
        });
      }
    } else if (name === "showOnHomePage") {
      let showOnHomePageStart = "",
        showOnHomePageEnd = "";
      if (value.length === 2) {
        showOnHomePageStart = moment(value[0])
          .set({
            h: "00",
            m: "00",
          })
          .unix();
        showOnHomePageEnd = moment(value[1])
          .set({
            h: "23",
            m: "59",
          })
          .unix();
      }
      this.setState({
        showOnHomePageStart,
        showOnHomePageEnd,
      });
    } else if (name === "channelArchiveDate") {
      let channelArchiveDateStart = "",
        channelArchiveDateEnd = "";
      if (value.length === 2) {
        channelArchiveDateStart = moment(value[0])
          .set({
            h: hours,
            m: minutes,
          })
          .unix();
        channelArchiveDateEnd = moment(value[1])
          .set({
            h: hours,
            m: minutes,
          })
          .unix();
      }
      this.setState({
        channelArchiveDateStart,
        channelArchiveDateEnd,
      });
    } else {
      if (value) {
        this.setState({
          [name]: moment(value)
            .set({
              h: hours,
              m: minutes,
              second: seconds,
            })
            .unix(),
        });
      } else {
        this.setState({
          [name]: "",
        });
      }
    }
  };

  handleChatChannelChange = (e) => {
    const regex = /^[a-z0-9\-]*$/;
    this.setState({
      isChatChannelValid: undefined,
    });

    if (this.state.editMode) {
      if (this.state.editConfiguration.hasChannel) {
        this.setState((prevState) => {
          let editConfiguration = {
            ...prevState.editConfiguration,
          };
          editConfiguration.channelChange = true;
          return {
            editConfiguration,
          };
        });
      } else {
        this.setState((prevState) => {
          let editConfiguration = {
            ...prevState.editConfiguration,
          };
          editConfiguration.newChannel = true;
          return {
            editConfiguration,
          };
        });
      }
    }
    let value = e.target.value.toLowerCase();
    // if (value.length === 1 && value[0] !== '#') {
    //     value = "#" + value
    // }
    let valid = regex.test(value);

    if (e.target.value.length === 0) {
      valid = true;
    }
    if (valid) {
      // if (this.state.editMode && this.state.editConfiguration.hasChannel && e.target.value.length === 1) {
      //     this.setState({ errorInForm: { isError: true, visible: true, message: "Wholla you cant do that !\n Name is required for chat-channel" } })
      // } else {
      this.setState({
        chatChannelName: value,
        errorInForm: {
          isError: false,
          visible: false,
          message: "",
        },
      });
      // }
    } else {
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message:
            "Wholla you cant add that !\n Chat channel name should be in this format, #chat-cannel-name",
        },
      });
    }
  };

  handleChatFocusChange = (e) => {
    if (this.state.chatChannelName) {
      const regex = /^[a-z0-9\-]*[a-z0-9]$/;
      let valid = regex.test(this.state.chatChannelName);
      if (valid) {
        fbCheckChatChannelName(
          this.props.currentGroup,
          this.state.chatChannelName,
          this.props.match.params.id
        )
          .then(() => {
            this.setState({
              isChatChannelValid: true,
            });
          })
          .catch(() => {
            this.setState({
              isChatChannelValid: false,
            });
          });
      }
    }
  };

  handleNotificationsChange = (value, index) => {
    if (value) value = moment(value).unix();
    this.setState((prevState) => {
      let notifications = [...prevState.notifications];
      notifications[index] = value;
      return {
        notifications,
      };
    });
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  // Called whenever a new image is uploaded to deleted
  handleImageChange = ({ fileList }) => {
    let result = {};
    if (fileList.length === 0) {
      result.imageFile = {};
    } else {
      result.imageFile = fileList[0].originFileObj;
    }

    if (this.state.editMode && !this.state.editConfiguration.imageChanged) {
      this.setState((prevState) => {
        let editConfiguration = Object.assign({}, prevState.editConfiguration);
        editConfiguration.imageChanged = true;
        return {
          editConfiguration,
        };
      });
    }

    result.imageList = fileList;

    this.setState(() => result);
  };

  uploadAction = (file, fileList) => {
    // fbUploadGroupLogo("testing", file)
    // this.handleImageChange({fileList})
    this.setState(() => ({
      imageFile: file,
    }));

    // return true
  };

  handleAddNotifications = () => {
    this.setState((prevState) => {
      let notifications = [...prevState.notifications];
      notifications.push(null);
      return {
        notifications,
      };
    });
  };

  handleSearchParamChange = (list) => {
    let params = [];
    list.forEach((param) => {
      if (
        Object.values(continents).includes(param) ||
        Object.values(countrtyNameCodeMap).includes(param)
      ) {
        params.push(param);
      } else {
        let group = this.state.allGroups.find(
          (group) => group.groupName === param
        );
        if (group) {
          params.push(group.id);
        }
      }
    });
    this.setState((state) => {
      let groupCount =
        list.length > 0
          ? this.state.allGroups.filter(
              (group) =>
                params.includes(group.id) ||
                params.includes(countrtyNameCodeMap[group.supportersCountry]) ||
                params.includes(continents[group.supportersRegion])
            ).length
          : this.state.allGroups.length;
      return { groups: params, count: `${groupCount} groups` };
    });
  };

  render() {
    let eventStartDate = "",
      eventEndDate = "",
      eventStartTime = "",
      eventEndTime = "",
      showOnHomePage = [],
      channelArchiveDate = [];
    if (this.state.eventStartDate) {
      eventStartDate = moment.unix(this.state.eventStartDate);
    }

    if (this.state.eventEndDate) {
      eventEndDate = moment.unix(this.state.eventEndDate);
    }

    if (eventStartDate) {
      eventStartTime =
        moment.unix(this.state.eventStartDate).seconds() !== 59
          ? moment(
              `${eventStartDate.hours()}:${eventStartDate.minutes()}`,
              timeFormat
            )
          : null;
    }

    if (eventStartDate) {
      eventEndTime =
        moment.unix(this.state.eventStartDate).seconds() !== 59
          ? moment(
              `${eventStartDate.hours()}:${eventStartDate.minutes()}`,
              timeFormat
            )
          : null;
    }

    if (this.state.showOnHomePageStart) {
      showOnHomePage = [
        moment.unix(this.state.showOnHomePageStart),
        moment.unix(this.state.showOnHomePageEnd),
      ];
    }
    if (this.state.channelArchiveDateStart) {
      channelArchiveDate = [
        moment.unix(this.state.channelArchiveDateStart),
        moment.unix(this.state.channelArchiveDateEnd),
      ];
    }

    const layout2 = this.state.type === "News" || this.state.type === "Update";
    let loadingTitle = "",
      loadingMessage = "";
    if (this.state.loadingDocument) {
      loadingTitle = "Loading Document";
      loadingMessage = "Fetching data";
    } else if (this.state.loadingMatches) {
      loadingTitle = "Loading Matches";
      loadingMessage = "Fetching match details";
    } else if (this.state.isLoading) {
      loadingTitle = this.state.editMode
        ? "Updating Content"
        : "Creating Content";
      loadingMessage = this.state.editMode
        ? "Updating content"
        : "Creating new content";
    }
    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div>
        {this.state.showModal ? (
          <div>
            <Modal
              visible={this.state.showModal}
              // closable={true}
              width="150vh"
              onOk={this.handleMapOK}
              onCancel={this.handleModalChange}
              // onClose={this.handleModalChange}
            >
              {
                <Map
                  onRef={(ref) => (this.child = ref)}
                  mapUpdate={this.mapUpdate}
                  lat={this.state.locationData.latitude}
                  long={this.state.locationData.longitude}
                  place_id={this.state.locationData.placeId}
                />
              }
            </Modal>
          </div>
        ) : (
          <div></div>
        )}
        <LoadingModal
          title={loadingTitle}
          message={loadingMessage}
          // percentage={this.state.loadingPercentage}
          loading={
            this.state.loadingDocument ||
            this.state.loadingMatches ||
            this.state.isLoading
          }
        />
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              // className="mb-3"
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}
        {/* <div style={{ width: '100vw', display: 'grid', placeItems: 'center' }}>
                    
                </div> */}
        <div className="mx-md-5 p-2 border-box">
          <div
            style={{
              width: "100%",
              paddingTop: 30,
            }}
          >
            <ul
              className="nav nav-tabs"
              style={{ color: "#ffffff", position: "relative" }}
            >
              <li
                className="nav-item"
                style={{ borderBottom: `4px solid ${primaryColor}` }}
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="current"
                >
                  Create Content
                </a>
              </li>
            </ul>
          </div>
          <form
            onSubmit={this.handleSubmit}
            className="border rounded p-3 px-3 mt-2"
            style={{
              background: "#ffffff",
            }}
          >
            <div className="row">
              <div>
                <div className="row mb-2">
                  <div className={`form-group`}>
                    <div
                      style={{
                        margin: "-5px 0px -5px 45px",
                      }}
                    >
                      <Search
                        options={[
                          ...this.state.allGroups.map(
                            (group) => group.groupName
                          ),
                          ...Array.from(
                            new Set(
                              this.state.allGroups.map(
                                (group) => group.supportersRegion
                              )
                            )
                          ).map((code) => continents[code]),
                          ...Array.from(
                            new Set(
                              this.state.allGroups.map(
                                (group) => group.supportersCountry
                              )
                            )
                          ).map((code) => countrtyNameCodeMap[code]),
                        ]}
                        params={this.state.editMode && this.state.params}
                        count={this.state.count}
                        onSearchParamsChange={this.handleSearchParamChange}
                        label="Select Groups"
                        placeholder={
                          !(
                            Array.isArray(this.state.groups) &&
                            this.state.groups.length > 0
                          ) &&
                          "Default is 'worldwide'. Select groups by region (Europe) and/or name (Atlanta) to target"
                        }
                        placeholderStyleClass="default"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row mb-2">
                  <div
                    className={`form-group ${layout2 ? "col-md-4" : "col-md-6"}`}
                  >
                    <FSelect
                      label="Content Type"
                      value={this.state.type}
                      onChange={this.handleOptionChange}
                      name="type"
                      dataList={this.state.events}
                      dataOnlyValue
                      placeholder="--Select Event Type--"
                      display="col"
                      tabIndex="1"
                      required
                    />
                  </div>

                  <div
                    className={`form-group ${layout2 ? "col-md-4" : "col-md-6"}`}
                  >
                    <FInput
                      label="Title"
                      value={this.state.title}
                      onChange={this.handleInputChange}
                      name="title"
                      display="col"
                      tooltip="Not Required"
                      placeholder="Season Kickoff Party"
                      tabIndex="2"
                    />
                  </div>

                  {layout2 && (
                    <div className="form-group col-md-4">
                      <FInput
                        label="Author Name"
                        value={this.state.author}
                        onChange={this.handleInputChange}
                        name="author"
                        display="col"
                        placeholder="Lisa Smith"
                        tabIndex="3"
                        // required
                      />
                    </div>
                  )}
                </div>
                <div className="row mb-2">
                  <div className="form-group col-md-6">
                    <FInput
                      label="Location (name, map)"
                      value={this.state.location}
                      onChange={this.handleInputChange}
                      name="location"
                      display="col"
                      tooltip="List location of event (or city)"
                      placeholder="Fado Irish Pub"
                      tabIndex="5"
                    />
                    <div
                      style={{
                        marginLeft: "15px",
                        marginRight: "15px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid grey",
                      }}
                    >
                      <a
                        onClick={this.handleModalChange}
                        tabIndex="5"
                        style={{ textDecoration: "none" }}
                      >
                        {this.state.locationData.address}
                      </a>
                    </div>
                    <Button
                      style={{ marginLeft: "15px", marginTop: "10px" }}
                      onClick={this.locationClear}
                    >
                      Clear
                    </Button>
                    {/* <Map /> */}
                    {/* <FInput value={
											this.state.locationData.address
										}
											onChange={
												this.handleInputChange
											}
											display="col"
											placeholder=""
											tabIndex="5" /> */}
                  </div>

                  <div className="form-group col-md-6">
                    <FSelect
                      label="Match"
                      name="matchId"
                      display="col"
                      tabIndex="5"
                      tooltip="If selected, event will appear in Match Day immediately upon submission and not expire. Opponent logo will appear on content card."
                      value={this.state.matchId}
                      onChange={this.handleOptionChange}
                      placeholder={
                        this.state.matchList.length === 0
                          ? "Loading Matches..."
                          : "--Select Match--"
                      }
                      dataList={this.state.matchList}
                      listBuilder={(match, index) => {
                        if (new Date(match.event_date).getTime() > Date.now()) {
                          return (
                            <option key={index} value={match.fixture_id}>
                              {match.homeTeam.team_name + " "}
                              vs {match.awayTeam.team_name}{" "}
                              {`(${moment(match.event_date).format("Do-MMM-YY")})`}
                            </option>
                          );
                        }
                      }}
                    />
                  </div>
                </div>{" "}
                {/* <div className="row mb-2">
											<div className="form-group col-md-6">
												<div className="col">
													<label className="">
														Show on Home Screen &nbsp;
														<Tooltip
															placement="topLeft"
															title="Will show through selected end date"
														>
															<span className="border-0">
																<i className="fa fa-question-circle-o" />
															</span>
														</Tooltip>
													</label>
													<RangePicker
														tabIndex="13"
														value={showOnHomePage}
														onChange={(value) => {
															this.handleDateChange('showOnHomePage', value);
														}}
														disabledDate={(current) => {
															return (
																current &&
																current > moment.unix(this.state.eventEndDate || 9999999999)
															);
														}}
														className="form-control form-control-sm"
														size={10}
													/>
												</div>
											</div>
										</div> */}
                <div class="row">
                  <div className="form-group col-md-4">
                    <FUpload
                      label="Image"
                      action={this.uploadAction}
                      fileList={this.state.imageList}
                      onChange={this.handleImageChange}
                      display="col"
                      isAvatar="false"
                      aspectRatio={16 / 9}
                      tooltip="If no image uploaded, default image will appear. Image dimensions are 800x450 (16:9 ratio)"
                    />
                  </div>

                  <div className="col-lg-8 col-md-12 pl-md-0">
                    <div className="row pl-md-0">
                      <div className="form-group col">
                        <label className="">
                          Event Date
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            {" "}
                            *{" "}
                          </span>
                          <Tooltip placement="topLeft" title="Required">
                            <span className="border-0 m-0 p-0 ml-2">
                              <i className="fa fa-question-circle-o" />
                            </span>
                          </Tooltip>
                        </label>
                        <DatePicker
                          tabIndex="6"
                          value={eventStartDate}
                          onChange={(value) => {
                            this.handleDateChange("eventStartDate", value);
                          }}
                          disabledDate={(current) => {
                            return current && current < moment().startOf("day");
                          }}
                          className="form-control form-control-sm"
                          size={10}
                        />
                      </div>
                      <div className="form-group col" />{" "}
                      {/* <label className="">
														Event End Date
														<Tooltip placement="topLeft" title="Leave blank if single-day event">
															<span className="border-0 m-0 p-0 ml-2">
																&nbsp;
																<i className="fa fa-question-circle-o" />
															</span>
														</Tooltip>
													</label>
													<DatePicker
														tabIndex="7"
														value={eventEndDate}
														onChange={(value) => {
															this.handleDateChange('eventEndDate', value);
														}}
														defaultPickerValue={moment.unix(
															this.state.eventStartDate || parseInt(Date.now() / 1000)
														)}
														disabledDate={(current) => {
															return current && current < moment.unix(this.state.eventStartDate);
														}}
														className="form-control form-control-sm"
														size={10}
													/>
													</div> */}
                    </div>{" "}
                    {/* </div> */}
                    <div className="row pl-md-0">
                      <div className="form-group col">
                        <label className=""> Event Time </label>
                        <TimePicker
                          tabIndex="8"
                          className="form-control form-control-sm"
                          value={eventStartTime}
                          defaultOpenValue={moment().set({
                            hour: "00",
                            minute: "00",
                          })}
                          // defaultValue={moment(Date.now(), timeFormat)}
                          onChange={(value) => {
                            this.handleDateChange("eventStartTime", value);
                          }}
                          format={timeFormat}
                        />
                      </div>

                      <div className="form-group col">
                        {" "}
                        {/* <label className="form-group col">
													End Time
													<Tooltip
														placement="topLeft"
														title="Leave blank unless specific end time"
													>
														<span className="border-0 m-0 p-0 ml-2">
															&nbsp;
															<i className="fa fa-question-circle-o" />
														</span>
													</Tooltip>
												</label>
												<TimePicker
													tabIndex="9"
													value={eventEndTime}
													defaultOpenValue={moment().set({
														hour: '00',
														minute: '00'
													})}
													onChange={(value) => {
														this.handleDateChange('eventEndTime', value);
													}}
													className="form-control form-control-sm"
													format={timeFormat}
												/> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* </div> */}
              {
                <div className="col-md-4">
                  <div className="form-group m-md-0">
                    <label className=""> Details </label>{" "}
                    {/* <input className="form-control form-control-sm" placeholder="Join us for a season kickoff party at Fado!"></input> */}{" "}
                    <Input.TextArea
                      tabIndex="4"
                      style={{
                        fontSize: `18px`,
                      }}
                      placeholder={
                        layout2
                          ? "Write an article for the news.."
                          : "Join us for a season kickoff party at Fado! "
                      }
                      value={this.state.details}
                      name="details"
                      onChange={this.handleInputChange}
                      rows={layout2 ? 13 : 3}
                    />
                  </div>
                </div>
              }
            </div>
            {(this.state.type === "Watch Party" ||
              this.state.type === "Tailgate" ||
              this.state.type === "Travel" ||
              this.state.type === "Volunteer" ||
              this.state.type === "Special Event") && (
              <div className="row mb-2">
                <div className="form-group col-md-4">
                  <div className="col">
                    <label className="">
                      Member Response &nbsp;
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        {" "}
                        *{" "}
                      </span>
                      <Tooltip
                        placement="topLeft"
                        title="Select RSVPs to allow members to respond in the event card. There are no limits to the number of responses. Select 'Link to Ticket' if you would like to offer tickets for the event rather than an RSVP. You must first create the ticket in 'Group Tickets' of the dashboard. This allows for attendance limits and barcode scanning."
                      >
                        <span className="border-0">
                          <i className="fa fa-question-circle-o" />
                        </span>
                      </Tooltip>
                    </label>
                    <br></br>
                    <div onChange={this.handleRadioChange}>
                      <input
                        style={{ padding: "10px" }}
                        type="radio"
                        value="1"
                        name="response"
                        checked={
                          Number(this.state.memberResponseState) === 1
                            ? "checked"
                            : ""
                        }
                      />{" "}
                      RSVP{" "}
                      <span style={{ fontSize: "12px" }}>
                        (unlimited, appears in event card)
                      </span>
                      <br />
                      <input
                        style={{ padding: "10px" }}
                        type="radio"
                        value="2"
                        name="response"
                        required
                      />{" "}
                      Link to Ticket{" "}
                      <span style={{ fontSize: "12px" }}>
                        (must first create ticket in Group Tickets)
                      </span>
                      {Number(this.state.memberResponseState) === 2 ? (
                        <div style={{ flex: "bottom" }}>
                          {
                            <div style={{ padding: "15px" }}>
                              <Select
                                // style={{ borderColor: '#D3D3D3', borderRadius: '3px', paddingHorizontal: '2px', border: `${this.props.disabled ? 0 : 1}` }}
                                style={{ width: `285px` }}
                                value={this.state.ticketName}
                                // name={this.state.ticket}
                                onChange={this.handleTicketListChange}
                                options={
                                  this.state.tokenList.length > 0 &&
                                  this.state.tokenList.map((data) => {
                                    return { data, data };
                                  })
                                }
                                tabIndex={0}
                              >
                                {this.state.tokenList.length > 0 &&
                                  this.state.tokenList.map((data) => {
                                    return (
                                      <Option value={data.code}>
                                        {data.code} {data.name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          }
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            )}
            <div className="row mb-2">
              <div className="form-group col-md-6">
                <div className="col">
                  <label className="">
                    Show on Home Screen &nbsp;
                    <Tooltip
                      placement="topLeft"
                      title="Will show through selected end date"
                    >
                      <span className="border-0">
                        <i className="fa fa-question-circle-o" />
                      </span>
                    </Tooltip>
                  </label>
                  <RangePicker
                    tabIndex="13"
                    value={showOnHomePage}
                    onChange={(value) => {
                      this.handleDateChange("showOnHomePage", value);
                    }}
                    disabledDate={(current) => {
                      return (
                        current &&
                        current >
                          moment.unix(this.state.eventEndDate || 9999999999)
                      );
                    }}
                    className="form-control form-control-sm"
                    size={10}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-md-2">
              {" "}
              {/* Chat Channel */}{" "}
              <div className="form-group col-md-4">
                {" "}
                {/* <FInput
								label="Chat Channel Name"
								display="col"
								tabIndex="10"
								tooltip="Leave blank if no chat channel should be created"
								onChange={this.handleChatChannelChange}
								value={this.state.chatChannelName}
								className="form-control form-control-sm"
								placeholder="#kickoff-party"
								onBlur={this.handleChatFocusChange}
								validFeedback={
									this.state.isChatChannelValid && "Looks Good !"
								}
								invalidFeedback={
									this.state.isChatChannelValid===false &&
									"Channel name already taken, Choose a different one"
								}
								/> */}{" "}
              </div>
              {/* Archive dates, shown only after chat channel name entered */}{" "}
              {this.state.chatChannelName && (
                <div className="form-group col-md-4">
                  {" "}
                  {/* <label className="">
												Chat Channel Creation and Archive Dates
												<Tooltip
												placement="topLeft"
												title="Set date channel should be created / archived to help keep chat organized."
												>
												<span className="border-0">
													&nbsp;
													<i className="fa fa-question-circle-o" />
												</span>
												</Tooltip>
											</label>
											<RangePicker
												tabIndex="11"
												value={channelArchiveDate}
												onChange={value => {
												this.handleDateChange("channelArchiveDate", value);
												}}
												className="form-control form-control-sm"
											/> */}{" "}
                </div>
              )}{" "}
            </div>

            <div class="form-group">
              <div className="form-group col">
                <label className="" for="exampleInputEmail1">
                  Notifications &nbsp;
                  <Tooltip
                    placement="topLeft"
                    title={() => (
                      <div
                        style={{
                          height: "fit-content",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p>
                          Important: Will send app notification to all members
                        </p>
                        <p>
                          Notifications scheduled for today are sent upon
                          'submit'. Scheduled notifications are sent at 1:00PM
                          ET.
                        </p>
                        <img src={DefaultSettingsImage} alt="" />
                      </div>
                    )}
                  >
                    <span className="border-0">
                      <i className="fa fa-question-circle-o" />
                    </span>
                  </Tooltip>
                </label>
                <div className="form-group row">
                  {" "}
                  {this.state.notifications.map((element, index) => {
                    return (
                      <div className="col-md-3 mr-3">
                        <DatePicker
                          // showTime={
                          // 	{
                          // 		format: 'HH:mm',
                          // 		defaultValue: moment().set({
                          // 			hour: '01',
                          // 			minute: '00'
                          // 		})
                          // 	}
                          // }
                          // format="YYYY-MM-DD HH:mm"
                          format="YYYY-MM-DD"
                          value={element ? moment.unix(element) : null}
                          placeholder="Start"
                          className="form-control form-control-sm"
                          onChange={(value) => {
                            this.handleNotificationsChange(value, index);
                          }}
                          //   onOpenChange={this.handleStartOpenChange}
                        />{" "}
                        {/* <FInput
													label={`Admin-${index + 1} Name`}
													onChange={(e) => {
														this.handleAdminChange("name", e.target.value, index)
													}}
													value={this.state.admins[index].name}
													required={!!this.state.admins[index].email}
												/> */}{" "}
                      </div>
                    );
                  })}{" "}
                  {this.state.notifications.length < 3 && (
                    <div className="mb-4 col-md-4 align-self-center">
                      <span
                        className={`btn btn-sm btn-info`}
                        onClick={this.handleAddNotifications}
                      >
                        Add notification <Icon type="bell" />
                      </span>
                    </div>
                  )}
                </div>

                {/* <DatePicker
									showTime={{ format: "HH:mm" }}
									format="YYYY-MM-DD HH:mm"
									value={null}
									placeholder="Start"
								//   onChange={this.onStartChange}
								//   onOpenChange={this.handleStartOpenChange}
								/>
								<Select
									tabIndex="12"
									value={this.state.notifications}
									onChange={this.handleNotificationsChange}
									className="form-control form-control-sm disabled p-0"
									isMulti options={options}
									placeholder={!this.state.eventStartDate ? "Select event start date to select notifications" : "Select.."}
									isDisabled={!this.state.eventStartDate}
								/> */}
              </div>
            </div>

            {/* Show on home screen */}

            <div className="d-flex justify-content-end">
              <button className="btn btn-success" tabIndex="14" type="success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  // ? Life cycle methods and helper functions

  handleEditMode = () => {
    fbGetContent(
      this.props.match.params.id,
      this.props.match.params.group
    ).then(async (doc) => {
      if (!doc) {
        this.props.dispatch(
          applicationError({
            err: {},
            errorCode: "404",
          })
        );
        return;
      }

      const data = new Content();
      data.fromDataSet(doc);
      let chatChannel;
      if (data.chatChannelName) {
        chatChannel = await fbGetChatChannel(
          this.props.match.params.group,
          this.props.match.params.id
        );
      }
      if (chatChannel) {
        data.chatChannelName = chatChannel.channelName;
        data.channelArchiveDateStart = chatChannel.startDate;
        data.channelArchiveDateEnd = chatChannel.endDate;
      }

      let groupCount = data.groups.includes("global")
        ? this.state.allGroups.length
        : this.state.allGroups.filter(
            (group) =>
              data.groups.includes(group.id) ||
              data.groups.includes(
                countrtyNameCodeMap[group.supportersCountry]
              ) ||
              data.groups.includes(continents[group.supportersRegion])
          ).length;
      let params = [];
      data.groups.forEach((param) => {
        let group = this.state.allGroups.find((group) => group.id === param);
        if (group) {
          params.push(group.groupName);
        } else {
          params.push(param);
        }
      });

      this.setState({
        ...data,
        loadingDocument: false,
        oldNotifications: data.notifications,
        memberResponseState: data.isLinkTicket === true ? 2 : 1,
        count: `${groupCount} groups`,
        params,
      });
    });
  };

  getTokenList = () => {
    if (this.props.currentGroup && this.state.tokenList.length === 0) {
      Axios.get(
        `https://us-central1-chant2019.cloudfunctions.net/getAllGroupTicket?groupId=${this.props.currentGroup}`
      )
        .then((tickets) => {
          let tempTicket = [];
          tickets = tickets.data.body.filter((ticket) => {
            return (
              moment
                .unix(ticket.data.groupTicket.eventDate)
                .utc()
                .local()
                .format("YYYY-MM-DD") >= new Date().toISOString().slice(0, 10)
            );
          });
          tickets.forEach((ticket, index) => {
            tempTicket.push({
              matchId: ticket.data.match.fixtureId,
              id: ticket.id,
              code: ticket.data.groupTicket.eventCode,
              name: ticket.data.groupTicket.eventName,
            });
          });
          this.setState({
            tokenList: tempTicket,
            isLoading: false,
            // pagination: { total: tickets.length }
          });
        })
        .catch((error) => {
          logger.error(error);
        });
    }
  };
  getMatchList = () => {
    if (this.props.clubId && this.state.matchList.length === 0) {
      Axios.get(`fixtures?team=${this.props.clubId}&next=99`).then(
        (response) => {
          let data = response.data.response;
          let res = [];
          data.forEach((fixtureData) => {
            res.push({
              fixture_id: fixtureData.fixture.id,
              league_id: fixtureData.league.id,
              league: fixtureData.league,
              event_date: fixtureData.fixture.date,
              event_timestamp: fixtureData.fixture.timestamp,
              firstHalfStart: fixtureData.fixture.periods.first,
              secondHalfStart: fixtureData.fixture.periods.second,
              round: fixtureData.league.round,
              status: fixtureData.fixture.status.long,
              statusShort: fixtureData.fixture.status.short,
              elapsed: fixtureData.fixture.status.elapsed,
              venue: fixtureData.fixture.venue.name,
              referee: fixtureData.fixture.referee,
              homeTeam: {
                team_id: fixtureData.teams.home.id,
                team_name: fixtureData.teams.home.name,
                logo: fixtureData.teams.home.logo,
              },
              awayTeam: {
                team_id: fixtureData.teams.away.id,
                team_name: fixtureData.teams.away.name,
                logo: fixtureData.teams.away.logo,
              },
              goalsHomeTeam: fixtureData.goals.home,
              goalsAwayTeam: fixtureData.goals.away,
              score: {
                halftime: fixtureData.score.halftime.home
                  ? `${fixtureData.score.halftime.home}-${fixtureData.score.halftime.away}`
                  : null,
                fulltime: fixtureData.score.fulltime.home
                  ? `${fixtureData.score.fulltime.home}-${fixtureData.score.fulltime.away}`
                  : null,
                extratime: fixtureData.score.extratime.home
                  ? `${fixtureData.score.extratime.home}-${fixtureData.score.extratime.away}`
                  : null,
                penalty: fixtureData.score.penalty.home
                  ? `${fixtureData.score.penalty.home}-${fixtureData.score.penalty.away}`
                  : null,
              },
            });
          });
          this.setState({
            matchList: res,
            loadingMatches: false,
          });
        }
      );
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (state.clubId !== props.clubId || state.groupId !== props.currentGroup) {
      return {
        clubId: props.clubId,
        groupId: `${props.clubId}_club_contents`,
      };
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      if (this.props.match.params.group.includes(this.props.clubId)) {
        getGroups().then((groups) => {
          this.setState(
            { allGroups: groups, count: `${groups.length} groups` },
            () => {
              this.handleEditMode();
            }
          );
        });
      }
    } else {
      getGroups().then((groups) => {
        this.setState({
          allGroups: groups,
          count: `${groups.length} groups`,
          loadingDocument: false,
        });
      });
    }
    /*
     * If we navigate from some other page to this screen we will already have
     * club id and match list will be fetched, if we refresh the page componentdidupdate
     * will do the same
     */
    this.getTokenList();
    this.getMatchList();
  }

  componentDidUpdate(prevProps, prevState) {
    // this.getMatchList()
    if (this.props.currentGroup !== prevProps.currentGroup) {
      if (this.props.match.params.id && this.state.loadingDocument) {
        this.handleEditMode();
      }
      this.getMatchList();
    }

    if (
      this.state.eventStartDate !== prevState.eventStartDate ||
      this.state.type !== prevState.type
    ) {
      let configuration = defaultDateConfig[this.state.type];
      if (configuration) {
        if (this.state.eventStartDate) {
          let startDate = this.state.eventStartDate;
          let showOnHomePageStart =
            configuration.homeFeed >= 0
              ? moment
                  .unix(startDate)
                  .subtract(configuration.homeFeed, "days")
                  .unix()
              : startDate;
          let showOnHomePageEnd =
            configuration.homeFeed >= 0
              ? moment.unix(startDate).set({ h: 23, m: 59, second: 59 }).unix()
              : moment
                  .unix(startDate)
                  .subtract(configuration.homeFeed, "days")
                  .unix();
          let notifications = [];
          configuration.notifications
            .sort((a, b) => b - a)
            .forEach((dateDiff) => {
              let notificationEpoch = moment
                .unix(startDate)
                .subtract(dateDiff, "days")
                .unix();
              notifications.push(
                notificationEpoch > moment().unix()
                  ? notificationEpoch
                  : moment().unix()
              );
            });
          notifications = Array.from(new Set(notifications));

          this.setState({
            showOnHomePageStart,
            showOnHomePageEnd,
            notifications,
          });
        }
      }
    }
  }
}

const mapStateToProps = (state) => {
  let currentGroup = Object.keys(state.user.userRoles).find(
    (key) => state.user.userRoles[key] === "parentAdmin"
  );
  let { clubId } = state.adminData.allGroups[currentGroup].data;
  return {
    user: state.user,
    groupData: state.adminData.allGroups[currentGroup],
    currentGroup,
    clubId,
  };
};

export default connect(mapStateToProps)(CreateClubContent);
