export const applicationError = (payload) => {
  // intensity can be HIGH, MID, LOW
  return {
    type: "APPLICATION_ERROR",
    payload,
  };
};

export const resetError = () => {
  return {
    type: "RESET_ERROR",
  };
};
