import React, { Component } from "react";
import ImageUploadButton from "../website/ImageUploaderButton";

export default class DirectoryImages extends Component {
  render() {
    const { groupImages, handleImageChange } = this.props;

    const imageConfigurations = [
      {
        id: 1,
        aspectRatioText: "16:9",
        aspectRatioValue: 16 / 9,
      },
      {
        id: 2,
        aspectRatioText: "16:9",
        aspectRatioValue: 16 / 9,
      },
      {
        id: 3,
        aspectRatioText: "16:9",
        aspectRatioValue: 16 / 9,
      },
      {
        id: 4,
        aspectRatioText: "1:1",
        aspectRatioValue: 1 / 1,
      },
    ];

    return (
      <div>
        <div className="row mt-4">
          <div className="col">
            <h5 style={{ fontWeight: "bold" }}>Group Images</h5>
          </div>
        </div>

        {imageConfigurations.map((item) => {
          return (
            <div className="col-md-6">
              <div className="row">
                <div className="form-group col-md-12">
                  <ImageUploadButton
                    label={`Image #${item.id}`}
                    text="Upload"
                    aspectRatio={item.aspectRatioValue}
                    images={[groupImages[`image${item.id}`]]}
                    tooltip={`Image will be cropped at an aspect ratio of ${item.aspectRatioText}`}
                    helperText={item.aspectRatioText}
                    onSave={(images) =>
                      handleImageChange(`image${item.id}`, images[0])
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
