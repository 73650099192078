import { fbGetGroups } from "../../services/firebaseService/endPoints/group";
import {
  addGroupsToLocal,
  clearGroupsFromLocal,
  editGroupInLocal,
} from "../../services/indexedDb/groups";

export const startAddGroups = () => {
  return async function (dispatch) {
    let groups = localStorage.getItem(`superadmin-groups`);
    if (groups) groups = JSON.parse(groups);
    // refresh in 6 hours
    if ((groups && Date.now() / 1000 - groups.date > 6 * 3600) || !groups) {
      groups = "";
      await refreshGroups();
    }
    if (groups) {
      dispatch({ type: "UPDATE_GROUPS", payload: { ...groups } });
    } else {
      fbGetGroups().then((groups) => {
        groups = groups.map((contents, index) => {
          return { ...contents, pageId: index };
        });

        let data = {
          records: groups.length,
          status: "available",
          date: parseInt(Date.now() / 1000),
        };

        addGroupsToLocal(groups).then(() => {
          localStorage.setItem(`superadmin-groups`, JSON.stringify(data));
          dispatch({ type: "UPDATE_GROUPS", payload: { ...data } });
        });
      });
    }
  };
};

export const startAddGroup = (content) => {
  return function (dispatch) {
    let data = JSON.parse(localStorage.getItem(`superadmin-groups`));
    content = { ...content, pageId: 0 };
    addGroupsToLocal([content]).then(() => {
      data.records += 1;
      localStorage.setItem(`superadmin-groups`, JSON.stringify(data));
      dispatch({ type: "UPDATE_GROUPS", payload: { ...data } });
    });
  };
};

export const startEditGroup = (content) => {
  return function (dispatch) {
    editGroupInLocal(content);
  };
};

export const startRefreshGroups = () => {
  return function (dispatch) {
    refreshGroups();
    dispatch({
      type: "UPDATE_GROUPS",
      payload: { records: undefined, status: "process" },
    });
  };
};

const refreshGroups = () => {
  localStorage.setItem(`superadmin-groups`, "");
  return clearGroupsFromLocal();
};
