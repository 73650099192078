import { STRIPE_ACCOUNT_ID_ERROR } from "../constants/errors";

export function verifyStripeAccountId(stripeAccountId) {
  if (!stripeAccountId || stripeAccountId.length === 0) {
    alert(STRIPE_ACCOUNT_ID_ERROR);
    return false;
  } else {
    return true;
  }
}
