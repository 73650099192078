import React from "react";
import { downloadImage } from "../../../services/firebaseService/endPoints/clubAdmin/gallery";

class ImageViewer extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDownload = () => {
    downloadImage(this.props.image.imageUrlLg || this.props.image.src);
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "90vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={this.props.onReturn}
          >
            <div
              style={{
                height: 12,
                width: 12,
                borderLeft: "3px solid #000000",
                borderBottom: "3px solid #000000",
                transform: "rotate( 45deg )",
                marginRight: 20,
              }}
            ></div>
            <span>Back</span>
          </div>
          <div
            style={{
              cursor: "pointer",
            }}
          >
            <i className="fa fa-download fa-lg" onClick={this.handleDownload} />
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "70vw",
              height: "80vh",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={this.props.image.src}
              style={{
                maxWidth: "70vw",
                maxHeight: "80vh",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ImageViewer;
