import Axios from "../connection";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";

export const afGetClubs = (leagueId, season) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/teams?league=${leagueId}&season=${season}`)
      .then((response) => {
        let data = response.data.response;
        let res = [];
        if (data.length > 0) {
          data.forEach((teamData) => {
            let team = {};
            team.team_id = teamData.team.id;
            team.name = teamData.team.name;
            team.code = "";
            team.logo = teamData.team.logo;
            team.country = teamData.team.country;
            team.is_national = teamData.team.national;
            team.founded = teamData.team.founded;
            team.venue_name = teamData.venue.name;
            team.venue_surface = teamData.venue.surface;
            team.venue_address = teamData.venue.address;
            team.venue_city = teamData.venue.city;
            team.venue_capacity = teamData.venue.capacity;

            res.push(team);
          });
        }
        resolve(res);
      })
      .catch((err) => {
        store.dispatch(
          applicationError({
            err,
            message: "Unable to fetch teams for the league",
            errorCode: "api-football",
          }),
        );
      });
  });
};
