import { EditorState } from "draft-js";

const Styles = {
  style1: "App",
  style2: "Landscape Photo",
  style3: "Text",
  empty: "Blank",
};

const initialValue = {
  selectedStyle: Styles["style1"],
  hasBorders: true,
  backgroundColor: { r: "0", g: "0", b: "0", a: 1 },
  header: "",
  details: EditorState.createEmpty(),
  aspectRatio: 1,
  files: [],
};
const promoteReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "UPDATE_PROMOTE":
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default promoteReducer;
