import { collection, doc, getDocs, limit, query } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { useEffect } from "react";
import {
  GUIDE_ENGAGE,
  TOPIC_PUBLISH_POLL,
  TOPIC_RUN_GIVEAWAY,
  TOPIC_RUN_PREDICTION_GIVEAWAY,
} from "../../../../constants/gettingStarted";
import { setGettingStartedItemStatus } from "../../../../redux/actions/adminData";

const useFetchData = () => {
  const adminData = useSelector(selectCurrentGroupData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (adminData?.id) {
      const fetchPollsData = async () => {
        const exists = await checkIfPollsExist();

        if (exists) {
          dispatch(
            setGettingStartedItemStatus(
              adminData?.id,
              GUIDE_ENGAGE,
              TOPIC_PUBLISH_POLL,
              true
            )
          );
        }
      };

      const fetchGiveawaysData = async () => {
        const exists = await checkIfGiveawaysExist();

        if (exists) {
          dispatch(
            setGettingStartedItemStatus(
              adminData?.id,
              GUIDE_ENGAGE,
              TOPIC_RUN_GIVEAWAY,
              true
            )
          );
        }
      };

      fetchPollsData();
      fetchGiveawaysData();
    }
  }, [adminData?.id]);

  const checkIfPollsExist = async () => {
    try {
      let docRef = doc(db, "polls", adminData?.id);

      const pollsQuery = query(collection(db, docRef.path, "poll"), limit(2));

      const snap = await getDocs(pollsQuery);

      if (snap?.size > 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const checkIfGiveawaysExist = async () => {
    try {
      let docRef = doc(db, "giveaways", adminData?.id);

      const giveawaysQuery = query(
        collection(db, docRef.path, "events"),
        limit(2)
      );

      const snap = await getDocs(giveawaysQuery);

      if (snap?.size > 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export default useFetchData;
