import Axios from "../../../services/apiFootballService/connection";
import logger from "../../../utils/logger";

export const startUpdateCountry = () => {
  return function (dispatch) {
    const countries = JSON.parse(localStorage.getItem("countries"));

    if (!countries || countries.expiry < Date.now()) {
      Axios.get("/countries")
        .then((response) => {
          let countries = {};
          countries.expiry = Date.now() + 86400000 * 365;
          countries.data = response.data.response;
          dispatch(updateCountry(countries.data));
          localStorage.setItem("countries", JSON.stringify(countries));
        })
        .catch((err) => {
          logger.error(err);
        });
    } else {
      dispatch(updateCountry(countries.data));
    }
  };
};

const updateCountry = (data) => {
  return {
    type: "UPDATE_COUNTRY",
    payload: data,
  };
};
