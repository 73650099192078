import moment from "moment";
import { db, storage } from "../../connection";
import { collection, doc, getDocs, updateDoc } from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
const probe = require("probe-image-size");

export function getImages(groupId) {
  return new Promise((resolve, reject) => {
    getDocs(collection(db, "gallery", groupId, "images"))
      .then((snap) => {
        let data = [];
        for (var doc of snap.docs) {
          let dimensions = (doc.data() || {}).dimensions || {
            height: 320,
            width: 320,
          };
          let { width, height } = dimensions;
          let imageHeight = 0,
            imageWidth = 0;
          if (height > width) {
            imageHeight = 480;
            imageWidth = (width / height) * imageHeight;
          } else {
            imageWidth = 480;
            imageHeight = (height / width) * imageWidth;
          }
          let date = moment
            .unix((doc.data() || {}).imageDate || 0)
            .format("MMM DD YYYY");

          data.push({
            ...(doc.data() || {}),
            src: (doc.data() || {}).imageUrl,
            id: doc.id,
            groupId: groupId,
            width: imageWidth,
            height: imageHeight,
            tags: [{ value: date, title: date }],
          });
        }
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function changeShowOnWeb(show, imageId, groupId) {
  return new Promise((resolve, reject) => {
    updateDoc(doc(db, "gallery", groupId, "images", imageId), {
      showOnWeb: show,
    })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function updateSizeOfImages() {
  let startingId = "g25rBv3js0L18UCSX4tX";
  let collectionRef = collection(db, "gallery");

  let groupId = startingId;
  if (!startingId || groupId === startingId) {
    startingId = "";
    let subCollectionRef = collection(
      db,
      collectionRef.path,
      groupId,
      "images"
    );
    let images = await getDocs(subCollectionRef);
    if (images.docs.length > 0) {
      for (var image of images.docs) {
        let url = (image.data() || {}).imageUrl;
        if (url) {
          let imageData = await probe(url);
          let dimensions = { width: imageData.width, height: imageData.height };
          await updateDoc(image.ref, {
            dimensions,
          });
        }
      }
    }
  }
}

export function uploadImage(file, path, timestamp, imageDate) {
  return new Promise((resolve, reject) => {
    const folderRef = ref(storage, `${path}`);
    uploadBytes(folderRef, file)
      .then(async (snapshot) => {
        const downloadUrl = await getDownloadURL(snapshot.ref);
        resolve({ downloadUrl, imageDate, timestamp });
      })
      .catch(reject);
  });
}

export async function uploadImageSizes(
  smallFile,
  largeFile,
  path,
  timestamp,
  imageDate
) {
  try {
    const smallFolderRef = ref(storage, `${path}-sm`);
    const largeFolderRef = ref(storage, `${path}-lg`);

    const [snapshotSmall, snapshotLarge] = await Promise.all([
      uploadBytes(smallFolderRef, smallFile),
      uploadBytes(largeFolderRef, largeFile),
    ]);

    const [downloadUrlSmall, downloadUrlLarge] = await Promise.all([
      getDownloadURL(snapshotSmall.ref),
      getDownloadURL(snapshotLarge.ref),
    ]);

    return {
      downloadUrlSmall,
      downloadUrlLarge,
      imageDate,
      timestamp,
    };
  } catch (error) {
    throw error;
  }
}
