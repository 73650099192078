import { db, fbAuth } from "../connection";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";
import validator from "validator";
import { sendSignInLinkToEmail } from "firebase/auth";
import {
  arrayUnion,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "@firebase/firestore";
import logger from "../../../utils/logger";

export function fbCreateAdminForGroup(email, groupId, data) {
  return new Promise(async (resolve, reject) => {
    if (!validator.isEmail(email || "")) {
      let message = `${email} is not a valid email, provide a valid email for admin`;
      reject({ message });
      return;
    }

    const docRef = doc(db, "admins", email.toLowerCase());
    let createAdmin = false;
    let user;
    let userInUserCollection;

    try {
      userInUserCollection = await getDocs(
        query(
          collection(db, "users"),
          where("phoneNumber", "==", data.phoneNumber),
          limit(1)
        )
      );
    } catch (err) {
      let message = "Unable to fetch admin document from database";
      store.dispatch(
        applicationError({
          message,
          err,
          intensity: "high",
          show: false,
          errorCode: "firebase-modal",
        })
      );
      return;
    }

    try {
      user = await getDoc(docRef);
    } catch (err) {
      let message = "Unable to fetch admin document from database";
      store.dispatch(
        applicationError({
          message,
          err,
          intensity: "high",
          show: false,
          errorCode: "firebase-modal",
        })
      );
      return;
    }

    if (userInUserCollection.docs.length > 0) {
      let userDocId = userInUserCollection.docs[0].id;
      if (
        userInUserCollection.docs[0].data().groupIds &&
        userInUserCollection.docs[0].data().groupIds.includes(groupId) === true
      ) {
        await fbUpdateUserToAdmin(userDocId, groupId, data.type)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            let message = "There was an error updating user to admin";
            resolve();
            store.dispatch(
              applicationError({
                message,
                err,
                intensity: "low",
                errorCode: "firebase-modal",
              })
            );
          });
      } else {
        logger.log(
          "User is not part of this group. So first add user to this group"
        );
        await fbAddToInviteList(groupId, email.toLowerCase());
        if (user.exists()) {
          if ((user.data() || {})[groupId]) {
            resolve();
            return;
          } else {
            createAdmin = true;
          }
        } else {
          createAdmin = true;
        }
      }
    } else {
      logger.log("User auth is not having any number");
      await fbAddToInviteList(groupId, email.toLowerCase());
      if (user.exists()) {
        if ((user.data() || {})[groupId]) {
          resolve();
          return;
        } else {
          createAdmin = true;
        }
      } else {
        createAdmin = true;
      }
    }

    if (createAdmin) {
      try {
        await setDoc(
          docRef,
          {
            [groupId]: {
              name: data.name || " ",
              role: data.type,
              phoneNumber: data.phoneNumber || "",
            },
          },
          { merge: true }
        );
        resolve();
      } catch (err) {
        let message =
          "There was an error updating the admins collection, invite failed";
        store.dispatch(
          applicationError({
            message,
            err,
            intensity: "high",
            errorCode: "firebase-modal",
          })
        );
        reject({ message });
        return;
      }

      // fbSendSignInLink(email.toLowerCase(), data.phoneNumber || "")
      //   .then(() => {
      //     resolve();
      //   })
      //   .catch(err => {
      //     let message =
      //       "There was an error sending email invite link to the admin's email";
      //     resolve();
      //     store.dispatch(
      //       applicationError({
      //         message,
      //         err,
      //         intensity: "low",
      //         errorCode: "firebase-modal"
      //       })
      //     );
      //   });
    }
  });
}

export function fbAddToInviteList(groupId, email) {
  return new Promise(async (resolve, reject) => {
    var ref = doc(db, "invite_emails", groupId);
    var docSnap = await getDoc(ref);
    let res;
    if (docSnap.exists()) {
      await updateDoc(ref, { emails: arrayUnion(email) })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          logger.error(err);
          let message =
            "Unable to add email to the invite list, contact chant developer";
          store.dispatch(
            applicationError({
              message,
              err,
              intensity: "high",
              errorCode: "firebase-modal",
            })
          );
          reject({ message });
        });
    } else {
      await setDoc(ref, {
        emails: [email],
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          logger.error(err);
          let message =
            "Unable to add email to the invite list, contact chant developer";
          store.dispatch(
            applicationError({
              message,
              err,
              intensity: "high",
              errorCode: "firebase-modal",
            })
          );
          reject({ message });
        });
    }
  });
}

export function fbUpdateUserToAdmin(userGroupId, groupId, type) {
  return new Promise((resolve, reject) => {
    setDoc(
      doc(db, "userGroup", groupId, "members", userGroupId),
      { userRole: type },
      { merge: true }
    )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        logger.error(err);
        let message =
          "Unable to update the user as admin to the group, contact chant developer";
        store.dispatch(
          applicationError({
            message,
            err,
            intensity: "high",
            errorCode: "firebase-modal",
          })
        );
        reject({ message });
      });
  });
}

export function fbRemoveAdminForGroup(email, groupId) {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "admins", email);
    updateDoc(docRef, { [groupId]: deleteField() })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        let message =
          "Unable to delete the admin from the group, contact admin";
        store.dispatch(
          applicationError({
            message,
            err,
            intensity: "high",
            errorCode: "firebase-modal",
          })
        );
        reject({ message });
      });
  });
}

export function fbDemoteAdminForGroup(groupId, userGroupId) {
  return new Promise((resolve, reject) => {
    setDoc(
      doc(db, "userGroup", groupId, "members", userGroupId),
      { userRole: "member" },
      { merge: true }
    )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        let message =
          "Unable to demote the admin from the group, contact chant developer";
        store.dispatch(
          applicationError({
            message,
            err,
            intensity: "high",
            errorCode: "firebase-modal",
          })
        );
        reject({ message });
      });
  });
}

export function fbSendSignInLink(email, phoneNumber) {
  return new Promise((resolve, reject) => {
    let url = `https://register.chant.fan/admin/register/${email}`;
    // let url = `http://localhost:3000/admin/register/${email}`;
    if (phoneNumber) {
      url += `?phoneNumber=${phoneNumber}`;
    }
    // let queryParams = ""
    // if (data.firstName) queryParams += `firstName=${data.firstName}&`
    // if (data.lastName) queryParams += `lastName=${data.lastName}&`
    // if (data.phoneNumber) queryParams += `phoneNumber=${data.phoneNumber}`
    sendSignInLinkToEmail(fbAuth, email, {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: url,
      // This must be true.
      handleCodeInApp: true,
      // iOS: {
      //     bundleId: 'com.example.ios'
      // },
      // android: {
      //     packageName: 'com.example.android',
      //     installApp: true,
      //     minimumVersion: '12'
      // },
      // dynamicLinkDomain: 'example.page.link'
    })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fbChangeAdminRole(role, groupId, userGroupId) {
  return new Promise((resolve, reject) => {
    setDoc(
      doc(db, "userGroup", groupId, "members", userGroupId),
      { userRole: role },
      { merge: true }
    )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        let message =
          "Unable to change role for the admin, contact chant developer";
        store.dispatch(
          applicationError({
            message,
            err,
            intensity: "high",
            errorCode: "firebase-modal",
          })
        );
        reject({ message });
      });
  });
}
