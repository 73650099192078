import React from "react";
import { db } from "../../services/firebaseService/connection";
import { fbGetFormLayout } from "../../services/firebaseService/endPoints/admin/members";
import { FInput } from "../commons/formFields/FInput";
import ChantLogo from "../../images/Chant_Brandmark_Tagline_RGB_Red.png";
import { Alert, Result, Spin, Select, Radio, Button } from "antd";
import moment from "moment";
import HeaderBackground from "../../images/Background_Image.svg";
import UserRegistrationDetails from "../../models/authentication/UserRegistrationDetails";
// import { CardElement, Elements, ElementsConsumer, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { deleteDb, initDb } from "../../services/indexedDb/membershipForm";
import AppstoreBadge from "../../images/appstore-badge.png";
import GooglePlayBadge from "../../images/google-play-badge.png";
import {
  getUidForExistingUser,
  getUserGroups,
} from "../../services/firebaseService/endPoints/membershipForm";
import RadioGroup from "antd/lib/radio/group";
import { countries } from "countries-list";
import logger from "../../utils/logger";

let countryNames = Object.values(countries).map((country) => {
  return country.name;
});

const FIELD_NAME_REFERECE = {
  firstName: {
    name: "First Name",
    type: "text",
    key: "firstName",
    placeholder: "First",
    required: true,
    maxLength: 35,
  },
  lastName: {
    name: "Last Name",
    type: "text",
    key: "lastName",
    placeholder: "Last",
    required: true,
    maxLength: 35,
  },
  email: {
    name: "Email",
    type: "text",
    key: "email",
    placeholder: "example@gmail.com",
    required: true,
    maxLength: 35,
  },
  phone: {
    name: "Phone",
    type: "text",
    key: "phone",
    placeholder: "xxx-xxx-xxxx",
    required: true,
  },
  address: [
    {
      name: "Address1",
      type: "text",
      key: "streetName",
      placeholder: "Street Address",
      required: true,
    },
    {
      name: "Address2",
      type: "text",
      key: "address2",
      placeholder: "Address 2",
    },
    {
      name: "City",
      type: "text",
      key: "city",
      placeholder: "City",
      required: true,
      maxLength: 35,
    },
    {
      name: "State",
      type: "text",
      key: "state",
      placeholder: "ST",
      required: true,
      maxLength: 35,
    },
    {
      name: "Zip",
      type: "text",
      key: "zip",
      placeholder: "xxxxx",
      required: true,
      maxLength: 15,
    },
    {
      name: "Country",
      type: "select",
      key: "country",
      placeholder: "Country",
      required: true,
      options: countryNames,
      defaultValue: "United States",
    },
  ],
  officialClubMember: {
    name: "Official Club Member?",
    type: "text",
    key: "officialClubMember",
    placeholder: "Membership Number",
    maxLength: 35,
  },
  seasonTicketHolder: {
    name: "Season Ticket Holder?",
    type: ["text", "text"],
    key: ["seasonTicketHolderSection", "seasonTicketHolderNoOfSeats"],
    placeholder: ["Section", "# of Seats"],
    maxLength: [25, 2],
  },
  joinMailingList: {
    name: "Join Email List?",
    type: "check",
    key: "joinEmailingList",
  },
  shareVolunteerOpportunities: {
    name: "Get notified of volunteer opportunities?",
    type: "check",
    key: "shareVolunteerOpportunities",
  },
  member2firstName: {
    name: "First Name",
    type: "text",
    key: "member2firstName",
    placeholder: "First",
    required: true,
    maxLength: 35,
  },
  member2lastName: {
    name: "Last Name",
    type: "text",
    key: "member2lastName",
    placeholder: "Last",
    required: true,
    maxLength: 35,
  },
  member2email: {
    name: "Email",
    type: "text",
    key: "member2email",
    placeholder: "example@gmail.com",
    required: true,
    maxLength: 35,
  },
  noOfChildren: {
    name: "No. Of Children",
    type: "text",
    key: "noOfChildren",
    placeholder: "x",
  },
  member2joinMailingList: {
    name: "Join Email List?",
    type: "check",
    key: "member2joinEmailingList",
  },
};

const allKeys = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "streetName",
  "address2",
  "city",
  "state",
  "zip",
  "country",
  "officialClubMember",
  "seasonTicketHolderSection",
  "seasonTicketHolderNoOfSeats",
  "joinEmailingList",
  "shareVolunteerOpportunities",
  "member2firstName",
  "member2lastName",
  "member2email",
  "noOfChildren",
  "member2joinEmailingList",
];

// const stripePromise = loadStripe('pk_live_Fd3EUqqRps0lrU5HS3p91UUn00mKYwBZRw');

class MembershipForm extends React.Component {
  state = {
    groupData: undefined,
    formData: undefined,
    loading: true,
    response: {
      country: "United States",
    },
    errorInForm: {
      isError: false,
      visible: false,
      message: "",
    },
    formScreen: true,
    checkoutScreen: false,
    thankYouScreen: false,
  };

  handleInputChange = (e) => {
    let response = this.state.response;
    let value = e.target.value;
    if (e.target.name.includes("email")) {
      value = value.toLowerCase().trim();
    }
    if (e.target.name.includes("noOfChildren")) {
      value = Math.min(parseInt(value), 10);

      if (isNaN(value)) {
        value = undefined;
      } else {
        value = "" + value;
      }
    }
    response[e.target.name] = value;
    if (e.target.name.includes("noOfChildren")) {
      let formData = this.state.formData;
      let childrenFields = [];
      childrenFields.push(FIELD_NAME_REFERECE["noOfChildren"]);
      childrenFields.push({});
      Array.from({ length: parseInt(value) }).forEach((_, index) => {
        let row = [
          {
            name: `Child # ${index + 1} Name`,
            type: "text",
            key: `child${index + 1}Name`,
            placeholder: "Name",
            required: true,
            maxLength: 35,
          },
        ];

        if (this.state.response.selectedPackage.details.hasChildTshirtSizes) {
          let sizes = this.state.response.selectedPackage.details.sizes;
          let fieldPropObject = {
            name: "T-shirt size",
            type: "select",
            options: sizes,
            key: `child${index + 1}TshirtSize`,
            required: true,
            placeholder: "Select size",
          };
          row.push(fieldPropObject);
        } else {
          row.push({});
        }
        childrenFields.push(...row);
      });
      formData.childrenFields = childrenFields;
      this.setState({ formData, response });
    } else {
      this.setState({ response });
    }
  };

  handleCheckChange = (e) => {
    e.persist();
    let response = this.state.response;
    response[e.target.id] = e.target.checked;
    this.setState({ response });
  };

  scrollTo = async () => {
    let offset = 10;
    let targetY = document.getElementById("form").offsetTop;
    let currentTop;
    let scrollEnd = false;
    let interval = setInterval(() => {
      if (document.body.offsetHeight <= window.scrollY + window.innerHeight) {
        scrollEnd = true;
        clearInterval(interval);
      }
    }, 1000);
    while ((currentTop = window.scrollY) < targetY && !scrollEnd) {
      window.scrollTo(0, currentTop + offset);
      await new Promise((res) => {
        setTimeout(() => {
          res();
        }, 2);
      });
      targetY = document.getElementById("form").offsetTop;
    }
  };

  setPackage = (details, index) => {
    let response = this.state.response;
    response.selectedPackage = { index, details };
    this.setState({ response }, () => {
      let sizes = this.state.response.selectedPackage.details.sizes;
      let fieldPropObject = {
        name: "T-shirt size",
        type: "select",
        options: sizes,
        key: "tshirtSize",
        required: true,
        placeholder: "Select size",
      };
      let formFields = this.state.formData.formFields;
      let countryField = formFields.find((field) => field.key === "country");
      let countryIndex = formFields.indexOf(countryField);
      let tshirtFieldIndex;
      if (countryIndex === -1) {
        let phoneIndex = formFields.indexOf(
          formFields.find((field) => field.key === "phone")
        );
        if (phoneIndex === -1) {
          let emailIndex = formFields.indexOf(
            formFields.find((field) => field.key === "email")
          );
          tshirtFieldIndex = emailIndex + 1;
        } else {
          tshirtFieldIndex = phoneIndex + 1;
        }
      } else {
        tshirtFieldIndex = countryIndex + 1;
      }
      let tshirtFieldExists =
        formFields[tshirtFieldIndex] &&
        formFields[tshirtFieldIndex].key === "tshirtSize";

      let formData = this.state.formData;

      if (this.state.response.selectedPackage.details.includesTshirt) {
        if (!tshirtFieldExists) {
          formFields.splice(tshirtFieldIndex, 0, fieldPropObject);
        } else {
          formFields.splice(tshirtFieldIndex, 1, fieldPropObject);
        }
      } else {
        if (tshirtFieldExists) {
          formFields.splice(tshirtFieldIndex, 1);
        }
      }

      formData.formFields = formFields;

      let response = this.state.response;
      let keys = formFields.map((field) => field.key);
      allKeys.forEach((key) => {
        if (!keys.includes(key)) {
          delete response[key];
        }
      });

      delete response.tshirtSize;
      delete response.member2tshirtSize;

      if (response.children && Array.isArray(response.children)) {
        response.children.forEach((child) => {
          delete child.tshirtSize;
        });
      }

      this.setState({ formData, response }, () => {
        logger.log(this.state);
      });

      if (this.state.response.selectedPackage.details.familyMembership) {
        let additionalFields = [
          FIELD_NAME_REFERECE["member2firstName"],
          FIELD_NAME_REFERECE["member2lastName"],
          FIELD_NAME_REFERECE["member2email"],
        ];

        if (this.state.response.selectedPackage.details.includesTshirt) {
          let sizes = this.state.response.selectedPackage.details.sizes;
          additionalFields.push({
            name: "T-shirt size",
            type: "select",
            options: sizes,
            key: "member2tshirtSize",
            required: true,
            placeholder: "Select size",
          });
        }

        if (this.state.formData.originalFormFields.officialClubMember) {
          additionalFields.push({
            name: "Official Club Member?",
            type: "text",
            key: "member2officialClubMember",
            placeholder: "Membership Number",
            maxLength: 35,
          });
        }

        if (this.state.formData.originalFormFields.joinMailingList) {
          additionalFields.push(FIELD_NAME_REFERECE["member2joinMailingList"]);
        }

        let formData = this.state.formData;

        if (this.state.response.selectedPackage.details.hasNoOfChildren) {
          let childrenFields = [];
          childrenFields.push(FIELD_NAME_REFERECE["noOfChildren"]);
          childrenFields.push({});
          formData.childrenFields = childrenFields;
        } else {
          let childrenFields = [];
          formData.childrenFields = childrenFields;
          let response = this.state.response;
          delete response.noOfChildren;
          this.setState({ response }, () => {
            logger.log(this.state.response);
          });
        }

        formData.member2Fields = additionalFields;
        this.setState({ formData });
      } else {
        let response = this.state.response;
        delete response.noOfChildren;
        delete response.children;
        this.setState({ response }, () => {
          logger.log(this.state.response);
        });
      }
      this.scrollTo();
    });
  };

  getAvailableNumber = (membershipNumbers) => {
    let availableNumber;
    for (let i = 0; i < membershipNumbers.length - 1; i++) {
      if (
        parseInt(membershipNumbers[i + 1].split("-")[1]) -
          parseInt(membershipNumbers[i].split("-")[1]) >
        1
      ) {
        availableNumber = parseInt(membershipNumbers[i].split("-")[1]) + 1;
        break;
      }
    }
    if (membershipNumbers.length === 0) {
      availableNumber = 1;
    } else {
      if (
        !availableNumber &&
        membershipNumbers[membershipNumbers.length - 1].split("-")[1] !==
          "99999"
      ) {
        availableNumber =
          parseInt(
            membershipNumbers[membershipNumbers.length - 1].split("-")[1]
          ) + 1;
      }
    }
    return availableNumber || -1;
  };

  pad = (num) => {
    let str = "00000" + num;
    return str.substr(str.length - 5);
  };

  createMembershipNumber = (key, type, membershipNumbers) => {
    if (this.state.response[key]) {
      return this.state.response[key];
    }
    const groupIndex = this.state.groupData.groupIndex || "";
    const membershipNumber =
      type === "temp"
        ? key
        : `${groupIndex}-${this.pad(this.getAvailableNumber(membershipNumbers))}`;
    return membershipNumber;
  };

  createFamilyMembershipId = () => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  saveData = async () => {
    let emailsWithPackage = [];

    let groupId = this.state.groupData.id;

    await db
      .collection("invite_emails")
      .doc(groupId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let { userDetails } = doc.data();
          if (userDetails && typeof userDetails === "object") {
            let emails = Object.keys(userDetails);
            emailsWithPackage.push(...emails);
          }
        }
      });

    await db
      .collection("userGroup")
      .doc(groupId)
      .collection("members")
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          let data = doc.data();
          if (data.package) {
            emailsWithPackage.push(data.email);
          }
        });
      });

    emailsWithPackage = Array.from(
      new Set(emailsWithPackage.filter((email) => email && email.trim()))
    );

    let stateData = {
      ...this.state.response,
      membershipNumber: this.createMembershipNumber("membershipNumber", "temp"),
      isCheckoutScreen: this.state.checkoutScreen,
    };
    if (this.state.response.selectedPackage.details.familyMembership) {
      stateData.member2membershipNumber = this.createMembershipNumber(
        "member2membershipNumber",
        "temp"
      );
      let familyMembershipId = this.createFamilyMembershipId();
      stateData.familyMembershipId = familyMembershipId;
      stateData.member2familyMembershipId = familyMembershipId;
    }
    this.setState({ response: stateData });
    let data = new UserRegistrationDetails();
    data.toDataSet({ ...stateData }, this.state.formData.originalFormFields);
    data = JSON.parse(JSON.stringify(data));

    if (data.hasError) {
      window.scrollTo(0, 0);
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: data.hasError.message,
        },
        loading: false,
      });
      window.scrollTo(0, 0);

      setTimeout(() => this.handleErrorMessageClose(), 5000);
    } else {
      if (emailsWithPackage.includes(data.email)) {
        window.scrollTo(0, 0);
        this.setState({
          errorInForm: {
            isError: true,
            visible: true,
            message: `${data.email} already exists. Please enter a unique email address.`,
          },
          loading: false,
        });
        window.scrollTo(0, 0);

        setTimeout(() => this.handleErrorMessageClose(), 5000);
        return;
      }

      if (
        data.member2 &&
        data.member2.email &&
        emailsWithPackage.includes(data.member2.email)
      ) {
        window.scrollTo(0, 0);
        this.setState({
          errorInForm: {
            isError: true,
            visible: true,
            message: `${data.member2.email} already exists. Please enter a unique email address.`,
          },
          loading: false,
        });
        window.scrollTo(0, 0);

        setTimeout(() => this.handleErrorMessageClose(), 5000);
        return;
      }

      this.saveUserResponse(stateData);
      this.setState({
        formScreen: false,
        checkoutScreen: true,
        loading: false,
      });
    }

    // let docRef = db.collection('registration_details').doc();
    // docRef.set(data, {
    //     merge: true
    // })
  };

  saveUserResponse = (data) => {
    initDb().then(({ err, indexDb, tx, store }) => {
      if (err) {
        logger.error("Failed to open database:", err);
      } else {
        store.put(data);

        tx.oncomplete = () => {
          indexDb.close();
        };
      }
    });
  };

  handleDonation = (name, value) => {
    let response = this.state.response;
    response[name] = value;
    response.isCustomAmt = false;
    this.setState({ response });
  };

  loadBenefits = () => {
    if (this.state.formData && this.state.formData.benefits) {
      document.getElementById("benefits").innerHTML =
        this.state.formData.benefits;
    }
  };

  handleError = ({ message }) => {
    this.setState({
      errorInForm: {
        isError: true,
        visible: true,
        message: message,
      },
    });

    window.scrollTo(0, 0);
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  getInputBox = (type, name, placeholder, options, defaultValue, maxLength) => {
    let input = undefined;
    if (type === "text") {
      input = (
        <FInput
          value={this.state.response[name]}
          onChange={this.handleInputChange}
          name={name}
          display="col"
          tabIndex="2"
          placeholder={placeholder}
          maxLength={maxLength}
        />
      );
    }
    if (type === "check") {
      input = (
        <div
          style={{
            paddingLeft: 15,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            style={{ height: "20px", width: "20px" }}
            type="checkbox"
            className="form-control-input cursor-pointer"
            id={name}
            checked={this.state.response[name]}
            onChange={this.handleCheckChange}
            tabIndex="2"
          />
        </div>
      );
    }
    if (type === "select") {
      input = (
        <div
          style={{
            paddingLeft: 15,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Select
            onChange={(val) => this.handleOptionChange(name, val)}
            value={this.state.response[name]}
            placeholder={placeholder}
            style={{ width: 250 }}
            defaultValue={defaultValue}
            tabIndex="2"
          >
            {options.map((option, index) => (
              <Select.Option key={`${option}_${index}`} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </div>
      );
    }

    if (Array.isArray(type)) {
      input = (
        <div className="row">
          {type.map((typ, index) => {
            return this.getInputBox(
              typ,
              name[index],
              placeholder[index],
              options ? options[index] : undefined,
              defaultValue ? defaultValue[index] : undefined,
              maxLength !== -1 ? maxLength[index] : [-1, -1]
            );
          })}
        </div>
      );
    }

    return [input];
  };

  handleOptionChange = (key, value) => {
    let response = this.state.response;
    response[key] = value;
    this.setState({ response }, () => {
      logger.log(this.state);
    });
  };

  handleReturn = () => {
    this.setState({
      formScreen: true,
      thankYouScreen: false,
      checkoutScreen: false,
    });
  };

  renderFields = (fields) => {
    let jsxArr = [];
    for (let i = 0; i < fields.length; i += 2) {
      jsxArr.push(
        <div className="row" style={{ width: "100%" }} key={`row #${i / 2}`}>
          <div className="col-md-6">
            <div className="row" style={{ height: "100%" }}>
              <div
                className="col-md-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ color: "#dfdfdf", fontWeight: 600 }}>
                  {fields[i].name}
                  {fields[i].required && (
                    <span style={{ color: "#ff0000" }}>*</span>
                  )}
                </span>
              </div>
              <div className="col-md-8">
                {this.getInputBox(
                  fields[i].type,
                  fields[i].key,
                  fields[i].placeholder,
                  fields[i].options || [],
                  fields[i].defaultValue,
                  fields[i].maxLength || -1
                )}
              </div>
            </div>
          </div>
          {fields[i + 1] && (
            <div className="col-md-6">
              <div className="row" style={{ height: "100%" }}>
                <div
                  className="col-md-4"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ color: "#dfdfdf", fontWeight: 600 }}>
                    {fields[i + 1].name}
                    {fields[i + 1].required && (
                      <span style={{ color: "#ff0000" }}>*</span>
                    )}
                  </span>
                </div>
                <div className="col-md-8">
                  {this.getInputBox(
                    fields[i + 1].type,
                    fields[i + 1].key,
                    fields[i + 1].placeholder,
                    fields[i + 1].options || [],
                    fields[i + 1].defaultValue,
                    fields[i + 1].maxLength || -1
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }

    return jsxArr;
  };

  renderPage() {
    let componentToRender;

    // let renewalDate =
    //   this.state.formData && moment.unix(this.state.formData.renewalDate);
    let backgroundColor = this.state.groupData
      ? `rgba(${this.state.groupData.configuration.primaryColor.r},${this.state.groupData.configuration.primaryColor.g},${this.state.groupData.configuration.primaryColor.b},${this.state.groupData.configuration.primaryColor.a})`
      : "#ffffff";

    if (this.state.formScreen) {
      componentToRender = (
        <>
          {this.state.formData &&
            (this.state.formData.groupImageType === "wide" ||
              !this.state.formData.groupImageType) && (
              <div style={{ width: "100%" }}>
                <img
                  src={
                    this.state.formData
                      ? this.state.formData.groupImageFile
                      : null
                  }
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
            )}
          {this.state.formData &&
            this.state.formData.groupImageType === "regular" && (
              <div className="row">
                <div className="col-md-6" style={{ padding: "10px 50px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="form-title">
                      <h4
                        style={{
                          fontSize: 28,
                          fontWeight: "bolder",
                          marginTop: 40,
                        }}
                      >
                        {this.state.formData && this.state.formData.title}
                      </h4>
                      <div
                        style={{
                          position: "absolute",
                          height: "7px",
                          width: "60%",
                          margin: "0px auto",
                          backgroundColor: backgroundColor,
                          left: 0,
                          right: 0,
                          bottom: -4,
                        }}
                      ></div>
                    </div>
                  </div>
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#454545",
                      fontSize: 19,
                      marginTop: 40,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {this.state.formData && this.state.formData.intro}
                  </p>
                </div>
                <div
                  className="col-md-6 mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      this.state.formData
                        ? this.state.formData.groupImageFile
                        : null
                    }
                    alt=""
                    style={{ width: "95%" }}
                  />
                </div>
              </div>
            )}
          {this.state.formData &&
            (this.state.formData.groupImageType === "wide" ||
              !this.state.formData.groupImageType) && (
              <div className="form-title">
                <h4
                  style={{ fontSize: 28, fontWeight: "bolder", marginTop: 40 }}
                >
                  {this.state.formData && this.state.formData.title}
                </h4>
                <div
                  style={{
                    position: "absolute",
                    height: "7px",
                    width: "60%",
                    margin: "0px auto",
                    backgroundColor: backgroundColor,
                    left: 0,
                    right: 0,
                    bottom: -4,
                  }}
                ></div>
              </div>
            )}
          <div style={{ width: "100%", padding: "0% 15%" }}>
            {this.state.formData &&
              (this.state.formData.groupImageType === "wide" ||
                !this.state.formData.groupImageType) && (
                <div style={{ width: "100%", marginTop: 50 }}>
                  <p
                    style={{
                      fontWeight: "bolder",
                      color: "#333333",
                      fontSize: 19,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {this.state.formData && this.state.formData.intro}
                  </p>
                </div>
              )}
            <div className="mt-3" style={{ width: "100%" }}>
              <div
                id="benefits"
                style={{ color: "#454545", fontSize: 17 }}
                onLoad={this.loadBenefits}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 70,
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {this.state.formData &&
                  this.state.formData.packages.map((pack, index) => (
                    <div
                      className="row"
                      style={{
                        width: "80%",
                        marginTop: 30,
                        borderRadius: 5,
                        padding: "5px 0px",
                        backgroundColor:
                          this.state.response.selectedPackage &&
                          this.state.response.selectedPackage.index === index
                            ? "#cccccc"
                            : "#ffffff",
                      }}
                      key={`pack #${index}`}
                    >
                      <div
                        className="col-md-4"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 150,
                            height: 150,
                            background: `url(${pack.image})`,
                            backgroundSize: "contain",
                            borderRadius: 25,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                      </div>
                      <div
                        className="col-md-8"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 0,
                        }}
                      >
                        <p
                          style={{
                            color: "red",
                            fontWeight: "bolder",
                            fontSize: 17,
                          }}
                        >
                          {pack.name}
                        </p>
                        <p
                          style={{
                            marginTop: -15,
                            whiteSpace: "pre-wrap",
                            fontSize: 17,
                          }}
                        >
                          {pack.desc}
                        </p>
                        <div
                          className="row ml-0 mt-3"
                          style={{ alignItems: "center" }}
                        >
                          <p style={{ marginBottom: 0, fontSize: 17 }}>
                            {pack.price.unit}
                            {pack.price.value}
                          </p>
                          <button
                            className="ml-3 stylised-button"
                            style={{
                              backgroundColor: "#333333",
                              color: "#dfdfdf",
                              height: 40,
                              width: 100,
                              borderRadius: 20,
                              border: "0px none transparent",
                              boxShadow: "0px 0px 0px transparent",
                              outline: "none",
                              outlineOffset: "unset",
                            }}
                            onClick={() => {
                              if (
                                this.state.response.selectedPackage &&
                                this.state.response.selectedPackage.index ===
                                  index
                              ) {
                                let response = this.state.response;
                                delete response.selectedPackage;
                                this.setState({ response });
                              } else {
                                this.setPackage(pack, index);
                              }
                            }}
                          >
                            Select
                          </button>
                          {pack.hasNoOfChildren &&
                            pack.costPerChild &&
                            pack.costPerChild !== "0" && (
                              <div
                                style={{
                                  fontSize: 15,
                                  fontWeight: "bolder",
                                  color: "red",
                                  marginLeft: 15,
                                }}
                              >
                                Plus ${pack.costPerChild || 0} per child
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {this.state.response.selectedPackage && (
            <div
              id="form"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "50px 60px",
                backgroundColor: "#333333",
                marginTop: 60,
              }}
            >
              <div
                className="row justify-content-center ml-0"
                style={{ width: "100%" }}
              >
                <div className="form-title">
                  <span
                    style={{
                      color: "#dfdfdf",
                      fontWeight: "bolder",
                      fontSize: 46,
                    }}
                  >
                    Enter Details
                  </span>
                  <div
                    style={{
                      position: "absolute",
                      height: "7px",
                      width: "60%",
                      margin: "0px auto",
                      backgroundColor: backgroundColor,
                      left: 0,
                      right: 0,
                      bottom: -4,
                    }}
                  ></div>
                </div>
              </div>

              <div className="mt-4">
                {this.state.formData &&
                  this.renderFields(this.state.formData.formFields)}
              </div>
              {this.state.response.selectedPackage &&
                this.state.response.selectedPackage.details
                  .familyMembership && (
                  <>
                    <div
                      className="row justify-content-center mt-6"
                      style={{ width: "100%" }}
                    >
                      <div className="form-title">
                        <span
                          style={{
                            color: "#dfdfdf",
                            fontWeight: "bolder",
                            fontSize: 46,
                          }}
                        >
                          Member #2
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            height: "7px",
                            width: "60%",
                            margin: "0px auto",
                            backgroundColor: backgroundColor,
                            left: 0,
                            right: 0,
                            bottom: -4,
                          }}
                        ></div>
                      </div>
                    </div>
                    {this.renderFields(this.state.formData.member2Fields || [])}
                    {this.state.response.selectedPackage &&
                      this.state.response.selectedPackage.details
                        .familyMembership &&
                      this.state.response.selectedPackage.details
                        .hasNoOfChildren && (
                        <>
                          <div
                            className="row justify-content-center mt-6"
                            style={{ width: "100%" }}
                          >
                            <div className="form-title">
                              <span
                                style={{
                                  color: "#dfdfdf",
                                  fontWeight: "bolder",
                                  fontSize: 46,
                                }}
                              >
                                Children{" "}
                                {this.state.response.selectedPackage.details
                                  .costPerChild &&
                                  this.state.response.selectedPackage.details
                                    .costPerChild !== "0" &&
                                  `($${this.state.response.selectedPackage.details.costPerChild} / per)`}
                              </span>
                              <div
                                style={{
                                  position: "absolute",
                                  height: "7px",
                                  width: "60%",
                                  margin: "0px auto",
                                  backgroundColor: backgroundColor,
                                  left: 0,
                                  right: 0,
                                  bottom: -4,
                                }}
                              ></div>
                            </div>
                          </div>
                          {this.renderFields(
                            this.state.formData.childrenFields || []
                          )}
                        </>
                      )}
                  </>
                )}
              {/* <div className="row justify-content-center mt-4" style={{ flexWrap: 'nowrap' }}>
                                <input
                                    style={{ height: "20px", width: "20px", minWidth: 20 }}
                                    type="checkbox"
                                    className="form-control-input cursor-pointer"
                                    id="autoRenew"
                                    checked={this.state.response.autoRenew}
                                    onChange={this.handleCheckChange}
                                />
                                <p className="ml-4" style={{ color: '#dfdfdf' }}> Skip the hassle next season and set your membership to auto-renew. Next payment {renewalDate.date()} {renewalDate.format('MMM')} {renewalDate.format('YYYY')}.</p>
                            </div>

                            <div className="row justify-content-center" style={{ flexWrap: 'nowrap' }}>
                                <input
                                    style={{ height: "20px", width: "20px", minWidth: 20 }}
                                    type="checkbox"
                                    className="form-control-input cursor-pointer"
                                    id="agreeToTerms"
                                    checked={this.state.response.autoRenew}
                                    onChange={this.handleCheckChange}
                                />
                                <p className="ml-4" style={{ color: '#dfdfdf' }}> I understand this membership is between me and {this.state.groupData && this.state.groupData.groupName}. I have read and accept both the {this.state.formData && this.state.formData.groupTermsURL ? <a href={this.state.formData.groupTermsURL}>{this.state.groupData.groupName}</a> : 'group'} and Chant terms</p>
                            </div> */}

              {!this.state.formData ||
                (!this.state.formData.hasDonations && (
                  <div
                    className="row justify-content-center mt-4"
                    style={{ width: "100%" }}
                  >
                    <button
                      className=" stylised-button"
                      style={{
                        backgroundColor: backgroundColor,
                        color: "#dfdfdf",
                        height: 40,
                        paddingLeft: 50,
                        paddingRight: 50,
                        borderRadius: 20,
                        border: "0px none transparent",
                        marginTop: 40,
                        outline: "none",
                        outlineOffset: "unset",
                        boxShadow: "transparent 0px 0px",
                      }}
                      disabled={this.props.isPreview}
                      onClick={() => {
                        this.setState({
                          errorInForm: {
                            isError: false,
                            visible: false,
                            message: "",
                          },
                        });
                        this.saveData();
                        window.scrollTo(0, 0);
                      }}
                    >
                      Review and Pay
                    </button>
                  </div>
                ))}
            </div>
          )}

          {this.state.formData &&
            this.state.formData.hasDonations &&
            this.state.response.selectedPackage && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "0% 15%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="form-title">
                    <span
                      style={{
                        color: "#333333",
                        fontWeight: "bolder",
                        fontSize: 46,
                      }}
                    >
                      Donations
                    </span>
                    <div
                      style={{
                        position: "absolute",
                        height: "7px",
                        width: "60%",
                        margin: "0px auto",
                        backgroundColor: backgroundColor,
                        left: 0,
                        right: 0,
                        bottom: -4,
                      }}
                    ></div>
                  </div>
                  <div style={{ width: "100%" }} className="row mt-4">
                    <div
                      className="row"
                      style={{ width: "100%", justifyContent: "center" }}
                    >
                      <div className="row" style={{ width: "80%" }}>
                        <div
                          className="col-sm-4 mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 150,
                              height: 150,
                              background: `url(${this.state.formData.donationImage})`,
                              backgroundSize: "contain",
                              borderRadius: 25,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                        <div className="col-sm-6 mt-2">
                          <div className="row">
                            <p
                              style={{
                                color: "#333333",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {this.state.formData.donationDesc}
                            </p>
                          </div>
                          <div
                            className="row"
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {this.state.formData.amount1 && (
                              <button
                                style={{
                                  padding: "5px 10px",
                                  height: "fit-content",
                                  marginTop: 10,
                                  backgroundColor:
                                    this.state.response.donatedAmount ===
                                    parseInt(this.state.formData.amount1)
                                      ? "#cccccc"
                                      : "#ffffff",
                                  border: "2px solid #000000",
                                  borderColor:
                                    this.state.response.donatedAmount ===
                                      parseInt(this.state.formData.amount1) &&
                                    backgroundColor,
                                  outline: "none",
                                  outlineOffset: "unset",
                                }}
                                name="donatedAmount"
                                onClick={() => {
                                  this.handleDonation(
                                    "donatedAmount",
                                    parseInt(this.state.formData.amount1)
                                  );
                                }}
                              >
                                ${this.state.formData.amount1}
                              </button>
                            )}
                            {this.state.formData.amount2 && (
                              <button
                                style={{
                                  padding: "5px 10px",
                                  height: "fit-content",
                                  marginLeft: 10,
                                  marginTop: 10,
                                  backgroundColor:
                                    this.state.response.donatedAmount ===
                                    parseInt(this.state.formData.amount2)
                                      ? "#cccccc"
                                      : "#ffffff",
                                  border: "2px solid #000000",
                                  borderColor:
                                    this.state.response.donatedAmount ===
                                      parseInt(this.state.formData.amount2) &&
                                    backgroundColor,
                                  outline: "none",
                                  outlineOffset: "unset",
                                }}
                                name="donatedAmount"
                                onClick={() => {
                                  this.handleDonation(
                                    "donatedAmount",
                                    parseInt(this.state.formData.amount2)
                                  );
                                }}
                              >
                                ${this.state.formData.amount2}
                              </button>
                            )}
                            {this.state.formData.amount3 && (
                              <button
                                style={{
                                  padding: "5px 10px",
                                  height: "fit-content",
                                  marginLeft: 10,
                                  marginTop: 10,
                                  backgroundColor:
                                    this.state.response.donatedAmount ===
                                    parseInt(this.state.formData.amount3)
                                      ? "#cccccc"
                                      : "#ffffff",
                                  border: "2px solid #000000",
                                  borderColor:
                                    this.state.response.donatedAmount ===
                                      parseInt(this.state.formData.amount3) &&
                                    backgroundColor,
                                  outline: "none",
                                  outlineOffset: "unset",
                                }}
                                name="donatedAmount"
                                onClick={() => {
                                  this.handleDonation(
                                    "donatedAmount",
                                    parseInt(this.state.formData.amount3)
                                  );
                                }}
                              >
                                ${this.state.formData.amount3}
                              </button>
                            )}
                            <div
                              style={{
                                border: "1px solid #333333",
                                borderRadius: 0,
                                marginTop: 10,
                                borderColor:
                                  this.state.response.isCustomAmt &&
                                  backgroundColor,
                                borderWidth: this.state.response.isCustomAmt
                                  ? 2
                                  : 1,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {this.state.response.donatedAmount && "$"}
                              <input
                                value={this.state.response.donatedAmount}
                                onChange={(e) =>
                                  this.handleDonation(
                                    e.target.name,
                                    parseInt(e.target.value) || null
                                  )
                                }
                                prefix="$"
                                name={"donatedAmount"}
                                display="col"
                                tabIndex="2"
                                placeholder="$ custom amount"
                                type="number"
                                style={{
                                  border: "0px none #333333",
                                  borderRadius: 0,
                                  padding: "5px 10px 5px 2px",
                                  boxShadow: "transparent 0px 0px 0px",
                                  outline: "none",
                                  outlineOffset: "unset",
                                  flexGrow: 1,
                                }}
                                onBlur={() => {
                                  let { donatedAmount } = this.state.response;
                                  let { amount1, amount2, amount3 } =
                                    this.state.formData;
                                  if (
                                    ![amount1, amount2, amount3].includes(
                                      `${donatedAmount}`
                                    )
                                  ) {
                                    let response = this.state.response;
                                    response.isCustomAmt = true;
                                    this.setState({ response });
                                  } else {
                                    let response = this.state.response;
                                    response.isCustomAmt = false;
                                    this.setState({ response });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="row mt-2" style={{ height: "50px" }}>
                            {this.state.response &&
                              this.state.response.donatedAmount && (
                                <p>
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: 18,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ${this.state.response.donatedAmount}
                                  </span>{" "}
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: "bold",
                                      marginTop: 4,
                                    }}
                                  >
                                    donation added to cart
                                  </span>
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* <div className="row" style={{ width: '100%', padding: 100, justifyContent: 'center' }}> */}
          {/* <div style={{ width: '90%', wordBreak: 'keep-all', lineBreak: 'auto', textAlign: 'center', fontSize: 18 }}>{this.state.groupData && this.state.groupData.groupName} uses Chant for communication, group chat, tickets and matchday engagement like player of match voting, predictions and giveaways.</div>
                            {this.state.response.firstName && this.state.response.lastName && <div className="mt-2" style={{ display: 'flex' }}>
                                <input
                                    style={{ height: "20px", width: "20px" }}
                                    type="checkbox"
                                    className="form-control-input cursor-pointer"
                                    id={'chantMembership'}
                                    checked={this.state.response.chantMemberShip}
                                    onChange={this.handleCheckChange}
                                />
                                Add Chant access for {this.state.response.firstName} {this.state.response.lastName} ($5)
                            </div>}
                            {this.state.response.selectedPackage && this.state.response.selectedPackage.details.familyMembership && this.state.response.member2firstName && this.state.response.member2lastName && (
                                <div className="mt-2" style={{ display: 'flex' }}>
                                    <input
                                        style={{ height: "20px", width: "20px" }}
                                        type="checkbox"
                                        className="form-control-input cursor-pointer"
                                        id={'member2chantMembership'}
                                        checked={this.state.response.member2chantMemberShip}
                                        onChange={this.handleCheckChange}
                                    />
                                    Add Chant access for {this.state.response.firstName} {this.state.response.lastName} ($5)
                                </div>
                            )}


                        </div> */}
          {this.state.formData &&
            this.state.formData.hasDonations &&
            this.state.response.selectedPackage && (
              <button
                className=" stylised-button"
                style={{
                  backgroundColor: backgroundColor,
                  color: "#dfdfdf",
                  height: 40,
                  paddingLeft: 50,
                  paddingRight: 50,
                  borderRadius: 20,
                  border: "0px none transparent",
                  boxShadow: "0px 0px 0px transparent",
                  outline: "none",
                  outlineOffset: "unset",
                }}
                disabled={this.props.isPreview}
                onClick={() => {
                  this.setState({
                    errorInForm: {
                      isError: false,
                      visible: false,
                      message: "",
                    },
                  });
                  this.saveData();
                  window.scrollTo(0, 0);
                }}
              >
                Review and Pay
              </button>
            )}
        </>
      );
    } else if (this.state.checkoutScreen) {
      componentToRender = (
        <Checkout
          {...this.state}
          handleNavToForm={() => {
            this.setState({
              formScreen: true,
              checkoutScreen: false,
            });
          }}
          onError={this.handleError}
          saveData={this.saveData}
          onChange={(obj) => {
            let response = this.state.response;
            response = { ...response, ...obj };
            this.setState({
              response,
            });
          }}
        />
      );
    } else if (this.state.thankYouScreen) {
      componentToRender = (
        <ThankYouComponent {...this.state} onReturn={this.handleReturn} />
      );
    }

    return componentToRender;
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "0%",
          width: "fill-available",
        }}
      >
        {/* <LoadingModal
                    title="Loading form"
                    message="Hold on while we load the form for you"
                    loading={this.state.loading}
                /> */}

        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2" style={{ width: "100vw" }}>
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              // className="mb-3"
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}

        {!this.state.loading &&
          (this.state.formData === undefined ||
            (!this.state.formData.isPublished && !this.props.isPreview)) && (
            <Result
              status="404"
              title="404"
              subTitle="This page is not found"
            />
          )}
        {!this.state.loading &&
          this.state.formData &&
          (this.state.formData.isPublished || this.props.isPreview) && (
            <>
              <div
                style={{
                  backgroundImage: `url(${HeaderBackground})`,
                  backgroundSize: "content",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  backgroundSize: "cover",
                  paddingTop: 10,
                }}
              >
                <div>
                  <img
                    src={
                      this.state.groupData
                        ? this.state.groupData.configuration.logo
                        : null
                    }
                    alt=""
                    style={{ width: 100, borderRadius: "50%" }}
                  />
                </div>
                <div style={{ margin: 20, marginBottom: 10 }}>
                  <span
                    style={{
                      textTransform: "uppercase",
                      fontSize: 24,
                      color: "#333333",
                      fontWeight: "b",
                    }}
                  >
                    {!this.state.thankYouScreen &&
                      `Join ${this.state.groupData && this.state.groupData.groupName}`}{" "}
                  </span>
                </div>
              </div>
              {this.renderPage()}
            </>
          )}
      </div>
    );
  }

  componentDidMount() {
    const handleOnMount = async () => {
      let groupName =
        this.props.groupName ||
        this.props.match.params.groupName
          .replace("%20", " ")
          .replace("%23", "#");

      groupName = groupName.replace(/ /g, "").toLowerCase();
      let formDataPromise = fbGetFormLayout(this.state.groupData.id).then(
        (formData) => {
          if (formData) {
            let formattedFormData = formData;
            let formFields = formattedFormData.formFields;
            let sortedFormFields = [];
            Object.keys(FIELD_NAME_REFERECE).forEach((key) => {
              if (formFields[key]) {
                if (Array.isArray(FIELD_NAME_REFERECE[key])) {
                  sortedFormFields.push(...FIELD_NAME_REFERECE[key]);
                } else {
                  sortedFormFields.push(FIELD_NAME_REFERECE[key]);
                }
              }
            });
            formattedFormData.originalFormFields = JSON.parse(
              JSON.stringify(formattedFormData.formFields)
            );
            formattedFormData.formFields = sortedFormFields;
            return formattedFormData;
          }
          return undefined;
        }
      );
      let groupDataPromise = formDataPromise.then((res) => {
        if (res) {
          let actualGroupName = res.groupName;
          return db
            .collection("group")
            .where("groupName", "==", actualGroupName)
            .get()
            .then((snap) => {
              if (snap.docs.length > 0 && snap.docs[0].exists) {
                let groupData = snap.docs[0].data();
                return { ...groupData, id: snap.docs[0].id };
              }
              return null;
            });
        }
        return undefined;
      });

      await Promise.all([groupDataPromise, formDataPromise]).then(
        ([groupData, formData]) => {
          this.setState({ groupData, formData });
        }
      );

      if (this.state.formData && this.state.groupData) {
        let urlParams = new URLSearchParams(window.location.search);
        const groupId = this.state.groupData.id;
        let params = Array.from(urlParams.keys());

        if (params.length > 0) {
          initDb().then(({ err, indexDb, tx, store }) => {
            if (err) {
              logger.error("Failed to open database: ", err);
            } else {
              let membershipNumberBase64 = urlParams.get("membershipNumber");
              let membershipNumber = Buffer.from(
                membershipNumberBase64,
                "base64"
              ).toString("ascii");
              let dataRequest = store.get(membershipNumber);

              dataRequest.onsuccess = async () => {
                let data = dataRequest.result;

                if (urlParams.get("success")) {
                  if (!data || typeof data !== "object") {
                    let homeUrl = `${window.location.origin}${window.location.pathname}`;
                    window.location.href = homeUrl;
                    return;
                  }

                  let emails = [data.email];
                  if (data.member2email) {
                    emails.push(data.member2email);
                  }

                  let groupId = this.state.groupData.id;

                  let groupPromisesForEmails = emails.map((email) => {
                    return getUserGroups(groupId, email).then((userId) => {
                      return { [email]: userId };
                    });
                  });

                  let registeredOn = moment().unix();

                  let existingUserIdMapPromises = emails.map((email) => {
                    return getUidForExistingUser(email).then((res) => {
                      return {
                        [email]: res,
                      };
                    });
                  });

                  let results = await Promise.all([...groupPromisesForEmails]);

                  let membershipNumbers = [];
                  await db
                    .collection("userGroup")
                    .doc(groupId)
                    .collection("members")
                    .get()
                    .then((snap) => {
                      snap.docs.map((doc) => {
                        let membershipNumber = doc.data().membershipNumber;
                        membershipNumbers.push(membershipNumber);
                        if (doc.data().children) {
                          let children = doc.data().children;
                          children.forEach((child) => {
                            if (child.membershipNumber) {
                              membershipNumbers.push(child.membershipNumber);
                            }
                          });
                        }
                      });
                    });

                  await db
                    .collection("invite_emails")
                    .doc(groupId)
                    .get()
                    .then((doc) => {
                      if (doc.exists) {
                        let userDetails = doc.data().userDetails;
                        if (userDetails && typeof userDetails === "object") {
                          Object.values(userDetails).map((detail) => {
                            let membershipNumber = detail.membershipNumber;
                            membershipNumbers.push(membershipNumber);

                            if (detail.children) {
                              detail.children.forEach((child) => {
                                if (child.membershipNumber) {
                                  membershipNumbers.push(
                                    child.membershipNumber
                                  );
                                }
                              });
                            }
                          });
                        }
                      }
                    });

                  membershipNumbers = membershipNumbers.filter(
                    (membershipNumber) => membershipNumber
                  );
                  membershipNumbers.sort();

                  let member1Data = new UserRegistrationDetails();
                  member1Data.toDataSet(
                    data,
                    this.state.formData.originalFormFields
                  );

                  member1Data = JSON.parse(JSON.stringify(member1Data));
                  member1Data.registeredOn = registeredOn;

                  let member2Data = undefined;

                  if (member1Data.member2) {
                    member2Data = member1Data.member2;
                    member2Data.registeredOn = registeredOn;
                    delete member1Data.member2;
                  }

                  let emailAvailable = true;
                  let unavailableEmail = [];
                  let userIds = [];
                  let userIdDataMap = {};

                  let groupsForEmail = results;

                  groupsForEmail.forEach((emailMap) => {
                    let email = Object.keys(emailMap)[0];
                    if (emailMap[email]) {
                      emailAvailable = false;
                      unavailableEmail.push(email);
                      userIds.push(emailMap[email]);
                      userIdDataMap[emailMap[email]] =
                        email === member1Data.email ? member1Data : member2Data;
                    }
                  });

                  let existingUserIdMaps = await Promise.all(
                    existingUserIdMapPromises
                  );

                  existingUserIdMaps.forEach((emailIdMap) => {
                    let email = Object.keys(emailIdMap)[0];
                    if (emailIdMap[email]) {
                      emailAvailable = false;
                      unavailableEmail.push(email);
                      userIds.push(emailIdMap[email]);
                      userIdDataMap[emailIdMap[email]] =
                        email === member1Data.email ? member1Data : member2Data;
                    }
                  });

                  userIds = Array.from(new Set(userIds));
                  unavailableEmail = Array.from(new Set(unavailableEmail));

                  if (userIds.length > 0) {
                    let updateDataPromise = userIds.map((userId) => {
                      return new Promise(async (res) => {
                        let docRef = db
                          .collection("userGroup")
                          .doc(groupId)
                          .collection("members")
                          .doc(userId);
                        const { memberSince, membershipNumber, userRole } =
                          await docRef.get().then((doc) => {
                            if (doc.exists) {
                              return doc.data();
                            }
                            return {
                              memberSince: "",
                              membershipNumber: "",
                              userRole: "member",
                            };
                          });
                        let data = userIdDataMap[userId];
                        data.membershipNumber = this.createMembershipNumber(
                          "-1",
                          "",
                          membershipNumbers
                        );
                        membershipNumbers.push(data.membershipNumber);
                        membershipNumbers.sort();

                        if (data.children && Array.isArray(data.children)) {
                          data.children.forEach((child) => {
                            child.membershipNumber =
                              this.createMembershipNumber(
                                "-1",
                                "",
                                membershipNumbers
                              );
                            membershipNumbers.push(child.membershipNumber);
                            membershipNumbers.sort();
                          });
                        }
                        if (!memberSince) {
                          data.memberSince = moment().format("YYYY");
                        }

                        data.membershipNumber =
                          membershipNumber || data.membershipNumber;
                        data.userRole = userRole || data.userRole;

                        docRef.set(data, {
                          merge: true,
                        });

                        let userRef = db.collection("users").doc(userId);
                        await userRef.get().then((doc) => {
                          let data = doc.data();
                          let groupIds = data.groupIds;
                          groupIds.push(groupId);

                          userRef
                            .set(
                              {
                                groupIds,
                              },
                              {
                                merge: true,
                              }
                            )
                            .then(() => {
                              res();
                            });
                        });
                      });
                    });

                    await Promise.all(updateDataPromise);
                  }

                  let newEmails = emails;
                  if (userIds.length < emails.length) {
                    newEmails = newEmails.filter(
                      (email) => !unavailableEmail.includes(email)
                    );

                    let updatedEmails = [],
                      updatedUserDetails = {};
                    let { emails, userDetails } = await db
                      .collection("invite_emails")
                      .doc(groupId)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          let data = doc.data();
                          return { ...data };
                        }
                        return {
                          emails: [],
                          userDetails: {},
                        };
                      });

                    updatedEmails.push(...newEmails);
                    if (emails && Array.isArray(emails)) {
                      updatedEmails.push(...emails);
                    }
                    updatedEmails = Array.from(
                      new Set(
                        updatedEmails.filter((emails) => emails !== undefined)
                      )
                    );

                    newEmails.forEach((email) => {
                      let memberData =
                        member1Data.email === email ? member1Data : member2Data;
                      let membershipNumber = this.createMembershipNumber(
                        "-1",
                        "",
                        membershipNumbers
                      );
                      memberData.membershipNumber = membershipNumber;
                      membershipNumbers.push(membershipNumber);
                      membershipNumbers.sort();

                      if (
                        memberData.children &&
                        Array.isArray(memberData.children)
                      ) {
                        memberData.children.forEach((child) => {
                          child.membershipNumber = this.createMembershipNumber(
                            "-1",
                            "",
                            membershipNumbers
                          );
                          membershipNumbers.push(child.membershipNumber);
                          membershipNumbers.sort();
                        });
                      }
                      updatedUserDetails = {
                        ...updatedUserDetails,
                        [email]: memberData,
                      };
                    });

                    if (userDetails) {
                      updatedUserDetails = {
                        ...updatedUserDetails,
                        ...userDetails,
                      };
                    }

                    await db.collection("invite_emails").doc(groupId).set({
                      emails: updatedEmails,
                      userDetails: updatedUserDetails,
                    });

                    this.setState({
                      thankYouScreen: true,
                      formScreen: false,
                      checkoutScreen: false,
                      loading: false,
                    });
                  } else {
                    this.setState({
                      thankYouScreen: true,
                      formScreen: false,
                      checkoutScreen: false,
                      loading: false,
                    });
                  }

                  deleteDb();
                }

                if (urlParams.get("cancel")) {
                  let sizes = data.selectedPackage.details.sizes;
                  let fieldPropObject = {
                    name: "T-shirt size",
                    type: "select",
                    options: sizes,
                    key: "tshirtSize",
                    required: true,
                    placeholder: "Select size",
                  };
                  let formFields = this.state.formData.formFields;
                  let countryField = formFields.find(
                    (field) => field.key === "country"
                  );
                  let countryIndex = formFields.indexOf(countryField);
                  let tshirtFieldIndex;
                  if (countryIndex === -1) {
                    let phoneIndex = formFields.indexOf(
                      formFields.find((field) => field.key === "phone")
                    );
                    tshirtFieldIndex = phoneIndex + 1;
                  } else {
                    tshirtFieldIndex = countryIndex + 1;
                  }
                  let tshirtFieldExists =
                    formFields[tshirtFieldIndex].key === "tshirtSize";

                  if (data.selectedPackage.details.includesTshirt) {
                    if (!tshirtFieldExists) {
                      formFields.splice(tshirtFieldIndex, 0, fieldPropObject);
                      let formData = this.state.formData;
                      formData.formFields = formFields;
                      this.setState({ formFields });
                    }
                  } else {
                    if (tshirtFieldExists) {
                      formFields.splice(tshirtFieldIndex, 1);
                      let formData = this.state.formData;
                      formData.formFields = formFields;
                      this.setState({ formFields });
                    }
                  }

                  if (data.selectedPackage.details.familyMembership) {
                    let additionalFields = [
                      FIELD_NAME_REFERECE["member2firstName"],
                      FIELD_NAME_REFERECE["member2lastName"],
                      FIELD_NAME_REFERECE["member2email"],
                    ];

                    if (data.selectedPackage.details.includesTshirt) {
                      let sizes = data.selectedPackage.details.sizes;
                      additionalFields.push({
                        name: "T-shirt size",
                        type: "select",
                        options: sizes,
                        key: "member2tshirtSize",
                        required: true,
                        placeholder: "Select size",
                      });
                    }

                    if (
                      this.state.formData.originalFormFields.officialClubMember
                    ) {
                      additionalFields.push({
                        name: "Official Club Member?",
                        type: "text",
                        key: "member2officialClubMember",
                        placeholder: "Membership Number",
                        maxLength: 35,
                      });
                    }

                    if (
                      this.state.formData.originalFormFields.joinMailingList
                    ) {
                      additionalFields.push(
                        FIELD_NAME_REFERECE["member2joinMailingList"]
                      );
                    }

                    let formData = this.state.formData;

                    if (data.selectedPackage.details.hasNoOfChildren) {
                      let childrenFields = [];
                      childrenFields.push(FIELD_NAME_REFERECE["noOfChildren"]);
                      childrenFields.push({});
                      Array.from({
                        length: parseInt(data.noOfChildren),
                      }).forEach((_, index) => {
                        let row = [
                          {
                            name: `Child # ${index + 1} Name`,
                            type: "text",
                            key: `child${index + 1}Name`,
                            placeholder: "Name",
                            required: true,
                            maxLength: 35,
                          },
                        ];

                        if (data.selectedPackage.details.hasChildTshirtSizes) {
                          let sizes = data.selectedPackage.details.sizes;
                          let fieldPropObject = {
                            name: "T-shirt size",
                            type: "select",
                            options: sizes,
                            key: `child${index + 1}TshirtSize`,
                            required: true,
                            placeholder: "Select size",
                          };
                          row.push(fieldPropObject);
                        } else {
                          row.push({});
                        }
                        childrenFields.push(...row);
                      });
                      formData.childrenFields = childrenFields;
                    }

                    formData.member2Fields = additionalFields;
                    this.setState({ formData });
                  }
                  this.setState(
                    {
                      response: data,
                      loading: false,
                      formScreen: false,
                      checkoutScreen: true,
                      thankYouScreen: false,
                    },
                    () => {
                      if (document.getElementById("benefits")) {
                        document.getElementById("benefits").innerHTML =
                          this.state.formData.benefits;
                      }
                    }
                  );
                  tx.oncomplete = () => {
                    indexDb.close();
                  };
                }
              };
            }
          });
        } else {
          this.setState(
            {
              loading: false,
              formScreen: true,
              checkoutScreen: false,
              thankYouScreen: false,
            },
            () => {
              if (document.getElementById("benefits")) {
                document.getElementById("benefits").innerHTML =
                  this.state.formData.benefits;
              }
            }
          );
        }
      } else {
        this.setState({
          loading: false,
          formScreen: true,
          checkoutScreen: false,
          thankYouScreen: false,
        });
      }
    };

    handleOnMount();
  }
}

const SHIPPING_OPTIONS = {
  pickupOnly: "Show Pickup",
  shippingOnly: "Show Shipping",
  options: "Offer choice of pickup or shipping",
};

const SHIPPING_OPTION_RESPONSES = {
  pickup: "pickup",
  shipping: "shipping",
};

class Checkout extends React.Component {
  state = {
    autoRenew: false,
    agreeToTerms: false,
    visible: false,
    loading: false,
  };

  handleCheckChange = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };

  formatResponse = (res) => {
    let response = JSON.parse(JSON.stringify(res));

    if (response.donatedAmount) {
      response.donatedAmount = `$${response.donatedAmount}`;
    }

    if (response.shippingOption === "shipping") {
      response.shippingAmount = `$${response.selectedPackage.details.shippingCharge}`;
    }

    response.selectedPackage = response.selectedPackage.details.id;

    delete response.isCheckoutScreen;
    delete response.isCustomAmt;

    return response;
  };

  handleCheckout = async () => {
    if (!this.state.agreeToTerms) {
      this.props.onError({ message: "Accept terms to proceed." });
      return;
    }

    await this.props.saveData();

    let { formData, response, groupData, onError, errorInForm } = this.props;

    const baseUrl = window.location.origin;
    const pathName = window.location.pathname;

    const encodedMembershipNumber = Buffer.from(
      response.membershipNumber
    ).toString("base64");

    if (/admin\/members\/[A-Za-z0-9%]*\/add/gm.test(pathName)) {
      window.location.href = `${baseUrl}${pathName}?success=true&membershipNumber=${encodedMembershipNumber}`;
    }

    if (errorInForm.isError) {
      return;
    }

    this.setState({
      loading: true,
    });

    let totalAmount = parseFloat(response.selectedPackage.details.price.value);
    let totalAmountToBePaid = 0;
    if (response.noOfChildren) {
      Array.from({ length: parseInt(response.noOfChildren) }).forEach(() => {
        totalAmount += parseFloat(
          response.selectedPackage.details.costPerChild || "0"
        );
      });
    }

    let { shippingOption, shippingCharge } = response.selectedPackage.details;

    const { id, groupName, paymentDetails } = groupData;

    const createSessionUrl =
      "https://us-central1-chant2019.cloudfunctions.net/stripe/createStripeSession";
    // const createSessionUrl = 'http://localhost:5001/chant2019/us-central1/createStripeSession';

    let currency = "usd";

    totalAmountToBePaid += totalAmount;
    let lineItems = [
      {
        name: `${response.selectedPackage.details.name}`,
        amount: totalAmount < 0.5 ? 50 : totalAmount * 100,
        currency: currency,
        quantity: 1,
      },
    ];

    if (
      shippingOption === SHIPPING_OPTIONS.shippingOnly ||
      (shippingOption === SHIPPING_OPTIONS.options &&
        response.shippingOption === SHIPPING_OPTION_RESPONSES.shipping)
    ) {
      let shippingAmount = parseFloat(shippingCharge);
      totalAmountToBePaid += shippingAmount;
      lineItems.push({
        name: "Shipping Charge",
        amount: shippingAmount < 0.5 ? 50 : shippingAmount * 100,
        currency: currency,
        quantity: 1,
      });
    }

    if (formData.hasDonations && response.donatedAmount) {
      totalAmountToBePaid += response.donatedAmount;
      lineItems.push({
        name: "Donation",
        amount:
          response.donatedAmount < 0.5 ? 50 : response.donatedAmount * 100,
        currency: currency,
        quantity: 1,
      });
    }

    if (totalAmountToBePaid === 0) {
      window.location.href = `${baseUrl}${pathName}?success=true&membershipNumber=${encodedMembershipNumber}`;
    } else {
      let reqBody = {
        cancelUrl: `${baseUrl}${pathName}?cancel=true&membershipNumber=${encodedMembershipNumber}`,
        mode: "payment",
        paymentMethodTypes: ["card"],
        successUrl: `${baseUrl}${pathName}?success=true&membershipNumber=${encodedMembershipNumber}`,
        lineItems: lineItems,
        paymentIntentData: {
          // amount: `${totalAmount < 0.50 ? 50 : totalAmount * 100}`,
          currency: currency,
          applicationFee: "0",
          description: `Renewal Date: ${moment.unix(formData.renewalDate).format("ddd MMM DD YYYY")}`,
          customerEmailId: response.email,
          customerName: `${response.firstName} ${response.lastName}`,
          groupDocId: id,
          groupName: groupName,
          merchantAccountId: paymentDetails.stripeAccountId,
        },
        response: this.formatResponse(response),
      };

      let url = await window
        .fetch(createSessionUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqBody),
        })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res.text();
          return null;
        })
        .then((data) => {
          if (data) {
            return data.url;
          }
          return null;
        })
        .catch(async (err) => {
          let message = await err;
          onError({ message });
        });

      this.setState({
        loading: false,
      });

      if (url) {
        window.location.href = url;
      }
    }
  };

  onRadioButtonChange = (e) => {
    this.props.onChange({ shippingOption: e.target.value });
  };

  render() {
    let { formData, response, groupData } = this.props;
    let renewalDate = formData && moment.unix(formData.renewalDate);
    let backgroundColor = groupData
      ? `rgba(${groupData.configuration.primaryColor.r},${groupData.configuration.primaryColor.g},${groupData.configuration.primaryColor.b},${groupData.configuration.primaryColor.a})`
      : "#ffffff";
    let totalAmount =
      parseFloat(response.selectedPackage.details.price.value) +
      (formData.hasDonations && response.donatedAmount
        ? response.donatedAmount
        : 0);
    if (response.noOfChildren) {
      Array.from({ length: parseInt(response.noOfChildren) }).forEach(() => {
        totalAmount += parseFloat(
          response.selectedPackage.details.costPerChild || "0"
        );
      });
    }

    let { shippingOption, shippingCharge, shippingNote, pickupNote } =
      response.selectedPackage.details;

    if (
      shippingOption === SHIPPING_OPTIONS.shippingOnly ||
      (shippingOption === SHIPPING_OPTIONS.options &&
        response.shippingOption === SHIPPING_OPTION_RESPONSES.shipping)
    ) {
      totalAmount = totalAmount + parseFloat(shippingCharge);
    }

    totalAmount = totalAmount.toFixed(2);

    return (
      <div
        style={{
          width: "100%",
          padding: "30px 20%",
          backgroundColor: "#333333",
        }}
      >
        {/* <PaymentModal visible={this.state.visible} cartInfo={cartInfo} onCancel={() => { this.setState({ visible: false }) }} /> */}
        <div className="row justify-content-center">
          <div className="form-title">
            <span
              style={{ color: "#ff4040", fontWeight: "bolder", fontSize: 46 }}
            >
              Checkout
            </span>
          </div>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-md-8">
            <div className="row" style={{ flexWrap: "nowrap" }}>
              <div className="col-md-3" style={{ minWidth: "fit-content" }}>
                <div
                  style={{
                    width: 150,
                    height: 150,
                    background: `url(${response.selectedPackage.details.image})`,
                    backgroundSize: "contain",
                    borderRadius: 25,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              </div>
              <div
                className="col-md-8 ml-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div>
                  <span style={{ color: "#ffffff", fontSize: 22 }}>
                    {response.selectedPackage.details.name}
                  </span>
                </div>
                {/* <div>
                                    <span style={{ color: '#ffffff', fontSize: 18 }}>Price: ${response.selectedPackage.details.price.value}</span>
                                </div> */}
              </div>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span style={{ color: "#ffffff", fontSize: 18 }}>
                  ${response.selectedPackage.details.price.value}
                </span>
              </div>
              {response.noOfChildren && (
                <>
                  {Array.from({ length: parseInt(response.noOfChildren) }).map(
                    (_, index) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <span style={{ color: "#ffffff", fontSize: 18 }}>
                            + Child #{index + 1}
                          </span>
                          <span
                            style={{
                              color: "#ffffff",
                              fontSize: 18,
                              marginLeft: 20,
                            }}
                          >
                            $
                            {response.selectedPackage.details.costPerChild || 0}
                          </span>
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {formData.hasDonations && response.donatedAmount && (
          <div className="row" style={{ marginTop: 40 }}>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-3">
                  <div
                    style={{
                      width: 150,
                      height: 150,
                      background: `url(${formData.donationImage})`,
                      backgroundSize: "contain",
                      borderRadius: 25,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                </div>
                <div
                  className="col-md-8 ml-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <span style={{ color: "#ffffff", fontSize: 22 }}>
                      Donation
                    </span>
                  </div>
                  {/* <div>
                                    <span style={{ color: '#ffffff', fontSize: 18 }}>Amount: ${response.donatedAmount}</span>
                                </div> */}
                </div>
              </div>
            </div>
            <div
              className="col-md-4"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div>
                <span style={{ color: "#ffffff", fontSize: 22 }}>
                  ${response.donatedAmount}
                </span>
              </div>
            </div>
          </div>
        )}

        {shippingOption && (
          <>
            {shippingOption === SHIPPING_OPTIONS.shippingOnly && (
              <div className="row" style={{ justifyContent: "flex-end" }}>
                <div
                  className="col-md-6"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ color: "#ffffff", fontSize: 22 }}>
                      Shipping
                    </span>
                    {
                      <span style={{ color: "#ffffff", fontSize: 14 }}>
                        {shippingNote}
                      </span>
                    }
                  </div>
                  <div>
                    <span style={{ color: "#ffffff", fontSize: 22 }}>
                      ${shippingCharge}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {shippingOption === SHIPPING_OPTIONS.pickupOnly && (
              <div className="row" style={{ justifyContent: "flex-end" }}>
                <div
                  className="col-md-6"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ color: "#ffffff", fontSize: 22 }}>
                      Pickup
                    </span>
                    {
                      <span style={{ color: "#ffffff", fontSize: 14 }}>
                        {pickupNote}
                      </span>
                    }
                  </div>
                </div>
              </div>
            )}
            {shippingOption === SHIPPING_OPTIONS.options && (
              <div className="row" style={{ justifyContent: "flex-end" }}>
                <div className="col-md-6" style={{ padding: 10 }}>
                  <div
                    className="row"
                    style={{ justifyContent: "space-between", marginRight: 0 }}
                  >
                    <div>
                      <span style={{ color: "#ffffff", fontSize: 22 }}>
                        Shipping
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "#ffffff", fontSize: 22 }}>
                        $
                        {response.shippingOption ===
                        SHIPPING_OPTION_RESPONSES.shipping
                          ? shippingCharge
                          : 0}
                      </span>
                    </div>
                  </div>

                  <div style={{ marginLeft: 20 }}>
                    <RadioGroup
                      onChange={this.onRadioButtonChange}
                      value={response.shippingOption}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Radio
                        value={SHIPPING_OPTION_RESPONSES.pickup}
                        style={{ display: "flex", marginTop: 10 }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              color: "#ffffff",
                              fontSize: 16,
                              marginTop: -5,
                            }}
                          >
                            Pickup
                          </span>
                          {
                            <span style={{ color: "#ffffff", fontSize: 14 }}>
                              {pickupNote}
                            </span>
                          }
                        </div>
                      </Radio>

                      <Radio
                        value={SHIPPING_OPTION_RESPONSES.shipping}
                        style={{ display: "flex", marginTop: 10 }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              color: "#ffffff",
                              fontSize: 16,
                              marginTop: -5,
                            }}
                          >
                            Ship to me (${shippingCharge})
                          </span>
                          {
                            <span style={{ color: "#ffffff", fontSize: 14 }}>
                              {shippingNote}
                            </span>
                          }
                        </div>
                      </Radio>
                    </RadioGroup>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <div className="row" style={{ justifyContent: "flex-end" }}>
          <div>
            <span style={{ color: "#ffffff", fontSize: 22 }}>
              Total: ${totalAmount}
            </span>
          </div>
        </div>

        <div style={{ color: "#ffffff", display: "flex" }}>
          {response.firstName} {response.lastName}
          {response.selectedPackage.details.familyMembership && (
            <>
              <div style={{ color: "#ffffff" }}>
                , {response.member2firstName} {response.member2lastName}
              </div>
              {response.noOfChildren && (
                <>
                  {Array.from({ length: parseInt(response.noOfChildren) }).map(
                    (_, index) => {
                      let key = `child${index + 1}Name`;
                      return (
                        <div style={{ color: "#ffffff" }}>
                          , {response[key]}
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </>
          )}
        </div>
        {formData.originalFormFields.address && (
          <div style={{ color: "#ffffff" }}>
            {response.streetName}, {response.city} {response.state},{" "}
            {response.zip}
          </div>
        )}
        {/* <div style={{ display: 'flex' }}> */}
        <span style={{ color: "#ffffff" }}>{response.email}</span>
        {response.selectedPackage.details.familyMembership && (
          <span
            style={{ color: "#ffffff" }}
          >{`, ${response.member2email}`}</span>
        )}
        {/* </div> */}
        <div style={{ color: "#ffffff" }}>{response.phone}</div>

        <br />

        {response.selectedPackage.details.familyMembership && (
          <div style={{ display: "flex" }}>
            <div style={{ color: "#ffffff", flex: 1 }}>No Of Children</div>
            <div style={{ color: "#ffffff", flex: 1, marginLeft: 20 }}>
              {response.noOfChildren || 0}
            </div>
          </div>
        )}
        {response.selectedPackage.details.includesTshirt && (
          <div style={{ display: "flex" }}>
            <div style={{ color: "#ffffff", flex: 1 }}>T-Shirt</div>
            <div style={{ flex: 1, marginLeft: 20 }}>
              <span style={{ color: "#ffffff" }}>{response.tshirtSize}</span>
              {response.selectedPackage.details.familyMembership && (
                <>
                  <span style={{ color: "#ffffff" }}>
                    {" "}
                    {`, ${response.member2tshirtSize}`}
                  </span>

                  {response.noOfChildren &&
                    response.selectedPackage.details.hasChildTshirtSizes && (
                      <>
                        {Array.from({
                          length: parseInt(response.noOfChildren),
                        }).map((_, index) => {
                          let key = `child${index + 1}TshirtSize`;
                          return (
                            <span
                              style={{ color: "#ffffff" }}
                            >{`, ${response[key]}`}</span>
                          );
                        })}
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ color: "#ffffff", flex: 1 }}>
            Membership Renewal Date
          </div>
          <div style={{ color: "#ffffff", flex: 1, marginLeft: 20 }}>
            {renewalDate.format("MMM")} {renewalDate.date()},{" "}
            {renewalDate.format("YYYY")}
          </div>
        </div>
        {formData.originalFormFields.joinMailingList && (
          <div style={{ display: "flex" }}>
            <div style={{ color: "#ffffff", flex: 1 }}>Join Email List?</div>
            <div style={{ color: "#ffffff", flex: 1, marginLeft: 20 }}>
              {response.joinEmailingList ? "Yes" : "No"}
            </div>
          </div>
        )}
        {response.selectedPackage.details.familyMembership &&
          formData.originalFormFields.joinMailingList && (
            <div style={{ display: "flex" }}>
              <div style={{ color: "#ffffff", flex: 1 }}>
                Join Email List for member #2?
              </div>
              <div style={{ color: "#ffffff", flex: 1, marginLeft: 20 }}>
                {response.member2joinEmailingList ? "Yes" : "No"}
              </div>
            </div>
          )}
        {response.officialClubMember && (
          <div style={{ display: "flex" }}>
            <div style={{ color: "#ffffff", flex: 1 }}>
              Official Club Member?
            </div>
            <div style={{ flex: 1, marginLeft: 20 }}>
              <div style={{ color: "#ffffff" }}>
                {response.officialClubMember}
              </div>
              {response.selectedPackage.details.familyMembership &&
                response.member2officialClubMember && (
                  <div style={{ color: "#ffffff" }}>
                    , {response.member2officialClubMember}
                  </div>
                )}
            </div>
          </div>
        )}
        {response.seasonTicketHolderSection && (
          <div className="row">
            <div className="col-md-12">
              <div className="row" style={{ marginLeft: "0px" }}>
                <div style={{ color: "#ffffff" }}>Season Ticket Holder:</div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div style={{ paddingLeft: 20, color: "#ffffff" }}>
                    Section
                  </div>
                </div>
                <div style={{ color: "#ffffff" }}>
                  {response.seasonTicketHolderSection}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div style={{ paddingLeft: 20, color: "#ffffff" }}>
                    No. of seats
                  </div>
                </div>
                <div style={{ color: "#ffffff" }}>
                  {response.seasonTicketHolderNoOfSeats}
                </div>
              </div>
            </div>
          </div>
        )}
        {formData.originalFormFields.shareVolunteerOpportunities && (
          <div style={{ color: "#ffffff", display: "flex" }}>
            <div style={{ flex: 1 }}>Share Volunteer Opportunities?</div>
            <div style={{ color: "#ffffff", flex: 1, marginLeft: 20 }}>
              {response.shareVolunteerOpportunities ? "Yes" : "No"}
            </div>
          </div>
        )}

        <div style={{ width: "100%", marginTop: 40 }}>
          {/* <div className="row justify-content-center" style={{ flexWrap: 'nowrap' }}>
                        <input
                            style={{ height: "20px", width: "20px", minWidth: 20 }}
                            type="checkbox"
                            className="form-control-input cursor-pointer"
                            id="autoRenew"
                            checked={this.state.autoRenew}
                            onChange={this.handleCheckChange}
                        />
                        <p className="ml-4" style={{ color: '#ffffff' }}> Skip the hassle next season and set your membership to auto-renew. Next payment {renewalDate.format('MMM')} {renewalDate.date()}, {renewalDate.format('YYYY')}.</p>
                    </div> */}

          <div
            className="row justify-content-center"
            style={{ flexWrap: "nowrap" }}
          >
            <input
              style={{ height: "20px", width: "20px", minWidth: 20 }}
              type="checkbox"
              className="form-control-input cursor-pointer"
              id="agreeToTerms"
              checked={this.state.agreeToTerms}
              onChange={this.handleCheckChange}
            />
            <p className="ml-4" style={{ color: "#ffffff" }}>
              {" "}
              I understand this membership is with {formData.groupName} and
              expires {renewalDate.format("MMM")} {renewalDate.date()},{" "}
              {renewalDate.format("YYYY")}. I have read and accept the{" "}
              {formData.groupTermsURL ? (
                <>
                  <a
                    href={formData.groupTermsURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formData.groupName}
                  </a>{" "}
                  and
                </>
              ) : (
                ""
              )}{" "}
              <a
                href="http://www.chant.fan/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chant
              </a>{" "}
              terms.
            </p>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="row" style={{ justifyContent: "center" }}>
              <Button
                style={{
                  borderRadius: 5,
                  padding: "5px 25px",
                  color: "#ffffff",
                  backgroundColor: backgroundColor,
                  border: "0px none transparent",
                  outline: "none",
                  outlineOffset: "unset",
                  boxShadow: "transparent 0px 0px",
                }}
                onClick={this.handleCheckout}
                disabled={this.state.loading || !this.state.agreeToTerms}
              >
                {this.state.loading ? (
                  <Spin size="small" spinning={true} />
                ) : totalAmount === "0.00" ? (
                  "Submit"
                ) : (
                  "Pay"
                )}
              </Button>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <div
            style={{
              color: "#ffffff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={this.props.handleNavToForm}
          >
            Back to form
          </div>
        </div>
      </div>
    );
  }
}

class ThankYouComponent extends React.Component {
  getMobileOS = () => {
    let navigator = window.navigator;
    let userAgent = navigator.userAgent;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  getDownloader = () => {
    let nodes = [
      <a
        href="https://apps.apple.com/us/app/chant/id1477159385#?platform=iphone"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={AppstoreBadge}
          style={{ maxWidth: "20vw", maxHeight: "10vh", minWidth: 130 }}
        />
      </a>,
      <a
        href="https://www.google.com/url?q=https://play.google.com/store/apps/details?id%3Dcom.geekyants.services.chant&sa=D&source=editors&ust=1627655534193000&usg=AOvVaw2s_yFiXGPhTWCjmFL1Mrya"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={GooglePlayBadge}
          style={{ maxWidth: "20vw", maxHeight: "10vh", minWidth: 130 }}
        />
      </a>,
    ];

    let userAgent = this.getMobileOS();

    if (userAgent === "iOS") {
      nodes.pop();
    } else if (userAgent === "Android") {
      nodes = [nodes.pop()];
    }

    return nodes;
  };

  render() {
    const { formData } = this.props;
    return (
      <>
        <div className="row" style={{ width: "100vw" }}>
          {(formData.groupImageType === "wide" || !formData.groupImageType) && (
            <div style={{ width: "100%" }}>
              <img
                src={formData ? formData.groupImageFile : null}
                alt=""
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
        <div
          className="row justify-content-center"
          style={{ marginTop: 30, textAlign: "center", width: "100vw" }}
        >
          <div
            className="col-md-8"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="row"
              style={{
                color: "#FF4040",
                fontSize: 38,
                fontWeight: "bold",
                fontFamily: "Open Sans",
                wordBreak: "break-word",
              }}
            >
              {formData.thankYouNote}
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="col-md-12" style={{ fontSize: 20 }}>
                The best way to engage is to download our app, Chant, for
                announcements, group chat and matchday activities like player of
                match voting, predictions and giveaways. You have been
                pre-registered.
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "grid",
            placeItems: "center",
            marginTop: 20,
          }}
        >
          <div>
            <img src={ChantLogo} alt="" style={{ width: "25vw" }} />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="col-md-5">
            <div
              style={{
                marginTop: 40,
                marginBottom: 20,
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {this.getDownloader()}
            </div>
          </div>
          <div className="col-md-12" style={{ fontSize: "20px" }}>
            Questions? Reach out to{" "}
            <a href={`mailto:${formData.supportEmail}`}>
              {formData.supportEmail}
            </a>
          </div>
          <div className="col-md-12" style={{ fontSize: "20px" }}>
            <span className="span-link" onClick={this.props.onReturn}>
              Back to Registration Form
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default MembershipForm;
