import React, { useEffect, useRef } from "react";
import "./GettingStarted.css";
import MinimizedView from "./MinimizedView/MinimizedView";
import MaximizedView from "./MaximizedView/MaximizedView";
import { useDispatch } from "react-redux";
import { setGettingStartedExpanded } from "../../redux/actions/adminData";

const GettingStarted = () => {
  const dispatch = useDispatch();
  const maxizedViewRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      maxizedViewRef.current &&
      !maxizedViewRef.current.contains(event.target)
    ) {
      dispatch(setGettingStartedExpanded(false));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="getting-started" ref={maxizedViewRef}>
      <MaximizedView />
      <MinimizedView />
    </div>
  );
};

export default GettingStarted;
