import React from "react";
import FUpload from "../../commons/formFields/FUpload";
import ColorPicker from "../../commons/ColorPicker";
import { fbGetGroupSettings } from "../../../services/firebaseService/endPoints/admin/groupConfig";
import { connect } from "react-redux";
import LoadingModal from "../../commons/LoadingModal";
import { fbUpdateGroup } from "../../../services/firebaseService/endPoints/group";
import { Alert } from "antd";
import { CirclePicker } from "react-color";
import { rgbToHex } from "../../../helperFunctions/util";
import { db } from "../../../services/firebaseService/connection";
import DemoAppView from "../../../images/demo-app-view.png";
import { doc, getDoc } from "@firebase/firestore";

const initialState = {
  logoList: [],
  primaryColor: { r: "", g: "", b: "", a: "" },
  secondaryColor: { r: "", g: "", b: "", a: "" },
  stripesColor: { r: "", g: "", b: "", a: "" },
  textPrimaryColor: "",
  textSecondaryColor: "",
  isLoading: true,
  imageChanged: false,
  error: { show: false, message: "" },
  updated: true,
  logoFile: {},
  preview: "",
  colors: [
    "#000000",
    "#B80000",
    "#DB3E00",
    "#FCCB00",
    "#008B02",
    "#006B76",
    "#1273DE",
    "#004DCF",
    "#FFFFFF",
  ],
};

class GroupSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  handleTextColorChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value, updated: false });
  };

  handleColorChange = (color, type) => {
    this.setState({ [type]: color, updated: false });
  };

  uploadAction = (file) => {
    this.setState(() => ({ logoFile: file, updated: false }));
  };

  handleImageChange = ({ fileList }) => {
    let result = {};
    if (fileList.length === 0) {
      result.logoFile = {};
    } else {
      result.logoFile = fileList[0].originFileObj;
    }

    result.logoList = fileList;
    result.imageChanged = true;
    result.updated = false;
    this.setState(() => result);
  };

  // handleImageChange = ({ fileList }) => {
  //   let result = { imageChanged: true };

  //   if (fileList.length===0) {
  //     result.logoFile = {};
  //   }

  //   result.logoList = fileList;
  //   result.updated=falsee
  //   this.setState(() => result);
  // };

  handleConfigurationUpdate = (e) => {
    e.preventDefault();
    if (this.state.imageChanged && !this.state.logoFile.name) {
      this.setState({
        error: { show: true, message: "Group Logo is required" },
      });
      return;
    } else {
      this.setState({ isLoading: true, error: { ...initialState.error } });
      e.preventDefault();
      let data = {
        configuration: {
          primaryColor: this.state.primaryColor,
          secondaryColor: this.state.secondaryColor,
          stripesColor: this.state.stripesColor,
        },
      };
      if (
        /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(this.state.textPrimaryColor)
      )
        data.configuration.textPrimaryColor = this.state.textPrimaryColor;
      if (
        /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(this.state.textSecondaryColor)
      )
        data.configuration.textSecondaryColor = this.state.textSecondaryColor;
      fbUpdateGroup(
        data,
        this.props.currentGroup,
        this.state.imageChanged && this.state.logoFile
      ).then(() => {
        this.getGroupSettings({ updated: true });
      });
    }
  };

  render() {
    let colors = this.state.colors;
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div className="col">
        <div style={{ padding: "30px 16px" }}>
          <LoadingModal loading={this.state.isLoading} />
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "#ffffff",
              }}
            >
              <a>Design</a>
            </li>
          </ul>
          <form
            className="mb-4 p-3 px-md-5 py-md-4 mx-md-auto"
            style={{
              maxWidth: "1400px",
              width: "100%",
              border: "1px solid #dee2e6",
              borderRadius: "0.25rem",
              marginTop: 30,
              backgroundColor: "#ffffff",
              // boxShadow: "0px 3px 10px 1px",
            }}
            onSubmit={this.handleConfigurationUpdate}
          >
            {this.state.error.show && (
              <Alert
                message="Error"
                description={this.state.error.message}
                type="error"
              />
            )}
            <div className="col">
              <p style={{ fontWeight: 400 }}>
                To design your app, select from the official club colors for the
                header, border and stripes or enter your own RGB color values.
                Set text values to black or white.
              </p>
            </div>
            <div className="form-group" style={{ display: "flex" }}>
              <div className="col-md-8">
                <FUpload
                  label="Logo"
                  action={this.uploadAction}
                  fileList={this.state.logoList}
                  onChange={this.handleImageChange}
                  aspectRatio={1 / 1}
                  onLoad={(preview) => {
                    this.setState({ preview });
                  }}
                />
              </div>
            </div>

            <div className="col">
              <div style={{ paddingTop: 20 }}>
                <div className="form-group row">
                  <div className="col-md-6">
                    {/* Primary Club Color  */}
                    <div className="form-group border rounded">
                      <label
                        className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                      >
                        Header
                      </label>
                      <div className={"col-12"}>
                        <ColorPicker
                          color={this.state.primaryColor}
                          colorType="primaryColor"
                          handleColorChange={this.handleColorChange}
                          colorOptions={colors}
                        />
                      </div>
                    </div>

                    {/* Secondary Club Color */}
                    <div className="form-group border rounded">
                      <label
                        className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                      >
                        Border/Card Title
                      </label>
                      <div className={"col-12"}>
                        <ColorPicker
                          color={this.state.secondaryColor}
                          colorType="secondaryColor"
                          handleColorChange={this.handleColorChange}
                          colorOptions={colors}
                        />
                      </div>
                    </div>

                    {/* Strips Color */}
                    <div className="form-group border rounded">
                      <label
                        className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                      >
                        Stripes
                      </label>
                      <div className={"col-12"}>
                        <ColorPicker
                          color={this.state.stripesColor}
                          colorType="stripesColor"
                          handleColorChange={this.handleColorChange}
                          colorOptions={colors}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                        style={{ fontFamily: "Open Sans" }}
                      >
                        Chant
                      </label>
                      <CirclePicker
                        colors={["#000000", "#FFFFFF"]}
                        width="220px"
                        triangle={"hide"}
                        className="color-picker-border"
                        onChange={(val) => {
                          this.setState({
                            textPrimaryColor: val.hex,
                            updated: false,
                          });
                        }}
                        color={this.state.textPrimaryColor}
                      />
                    </div>

                    {/* <div className="form-group row" >
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                    >
                      Card Titles
                    </label>
                    <CirclePicker
                      colors={['#000000', '#FFFFFF']}
                      width='220px'
                      triangle={"hide"}
                      onChange={(val) => {
                        this.setState({
                          textSecondaryColor: val.hex
                        })
                      }}
                      color={this.state.textSecondaryColor}
                    />
                  </div> */}
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 30,
                    }}
                  >
                    <div
                      style={{
                        width: "80%",
                        height: "81%",
                        backgroundColor: "#000000",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          flex: "0 0 26%",
                          backgroundColor: `rgba(${this.state.primaryColor.r},${this.state.primaryColor.g},${this.state.primaryColor.b},1)`,
                          borderBottom: `7px solid rgba(${this.state.secondaryColor.r},${this.state.secondaryColor.g},${this.state.secondaryColor.b},1)`,
                          position: "relative",
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#ffffff",
                            width: 150,
                            height: 150,
                            borderRadius: "50%",
                            border: `7px solid rgba(${this.state.secondaryColor.r},${this.state.secondaryColor.g},${this.state.secondaryColor.b},1)`,
                            position: "absolute",
                            left: "50%",
                            top: "10%",
                            transform: "translateX(-50%)",
                            display: "flex",
                            alignItems: "center",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={
                              this.state.preview
                                ? this.state.preview
                                : this.state.logoList[0]
                                  ? this.state.logoList[0].url
                                  : require("../../../images/chantLogo.svg")
                            }
                            alt="logo"
                            style={{
                              width: "100%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            flex: "0 0 5%",
                            backgroundColor: `rgba(${this.state.stripesColor.r},${this.state.stripesColor.g},${this.state.stripesColor.b},1)`,
                            marginRight: "5%",
                          }}
                        ></div>
                        <div
                          style={{
                            flex: "0 0 5%",
                            backgroundColor: `rgba(${this.state.stripesColor.r},${this.state.stripesColor.g},${this.state.stripesColor.b},1)`,
                            marginRight: "5%",
                          }}
                        ></div>
                        <span
                          style={{
                            color: this.state.textPrimaryColor,
                            fontSize: "30px",
                            alignSelf: "flex-end",
                            marginRight: "auto",
                            marginLeft: "15px",
                            fontFamily: "monospace",
                            fontWeight: "bold",
                          }}
                        >
                          Chant
                        </span>
                      </div>
                      <div
                        style={{
                          flex: "0 0 60%",
                          backgroundColor: "#181818",
                          marginTop: 80,
                          width: "94%",
                          alignSelf: "center",
                          borderTopLeftRadius: 5,
                          borderTopRightRadius: 5,
                          padding: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            className="col-md-5 ml-2"
                            style={{
                              color: "#ffffff",
                              fontFamily: "Open Sans",
                              fontWeight: "bolder",
                              padding: "2px 5px",
                              borderBottom: `5px solid rgba(${this.state.secondaryColor.r},${this.state.secondaryColor.g},${this.state.secondaryColor.b},1)`,
                            }}
                          >
                            VAR Poll
                          </span>
                          <i
                            className="fa fa-angle-up"
                            style={{
                              fontSize: 20,
                              color: "#6ac4f1",
                              fontWeight: "bolder",
                            }}
                          />
                        </div>
                        <hr />
                        <div>
                          <img
                            src={DemoAppView}
                            style={{ width: "100%" }}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className={`btn btn-sm btn-primary default-text-color`}
                style={{
                  backgroundColor: primaryColor,
                  borderColor: primaryColor,
                }}
                disabled={this.state.updated}
              >
                {this.state.updated ? "Updated" : "Update changes"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({ isLoading: true });
      this.getGroupSettings();
    }
  }

  getGroupSettings(stateChanges = {}) {
    fbGetGroupSettings(this.props.currentGroup).then((configuration) => {
      let logoList = [];
      if (configuration.logo)
        logoList = [{ uid: "-1", url: configuration.logo }];
      this.setState({
        ...initialState,
        ...configuration,
        isLoading: false,
        logoList,
        ...stateChanges,
      });
    });

    getDoc(doc(db, "clubColors", this.props.clubId)).then((doc) => {
      if (doc.exists()) {
        let data = doc.data() || {};
        let colors = [];
        Object.keys(data).forEach((key) => {
          if (
            key.includes("color") &&
            data[key].r !== "" &&
            data[key].g !== "" &&
            data[key].b !== ""
          ) {
            let color = data[key];
            Object.keys(color).forEach((key) => {
              if (typeof color[key] === "string") {
                color[key] = parseInt(color[key]);
              }
            });
            let hexColor = rgbToHex(color.r, color.g, color.b);
            colors.push(hexColor);
          }
        });
        colors = Array.from(new Set([...colors, "#ffffff", "#000000"]));
        this.setState({ colors });
      }
    });
  }

  componentDidMount() {
    this.getGroupSettings();
  }
}

const mapStateToProps = (state, props) => {
  let { groupName, clubId } =
    state.adminData.allGroups[state.adminData.currentGroup].data;
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    groupName,
    clubId,
  };
};

export default connect(mapStateToProps)(GroupSettings);
