import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { db, storage } from "../../connection";
import { getDefaultClub } from "./groups";
import { doc, updateDoc } from "firebase/firestore";
import logger from "../../../../utils/logger";

export async function getMembershipCard() {
  const clubId = getDefaultClub();

  // fetch from firestore
  // let cardUrl = await db
  //     .collection('group')
  //     .doc(`${clubId}_group`)
  //     .get()
  //     .then(doc => {
  //         if (doc.exists) {
  //         return doc.data().clubMembershipCard
  //         }
  //         return;
  // });

  const path = `/membership-card/`;

  let storageRef = ref(storage, path);
  let card = ref(storageRef, `${clubId}_membercard`);

  let cardUrl = await getDownloadURL(card)
    .then((url) => url)
    .catch((err) => logger.error(err));
  return cardUrl;
}

export async function saveMembershipCard(image, groupId) {
  const clubId = getDefaultClub();
  const path = `/membership-card/${clubId}_membercard`;
  let storageRef = ref(storage, path);
  let url = await uploadBytes(storageRef, image)
    .then((snap) => {
      return getDownloadURL(snap.ref);
    })
    .then((url) => url);

  updateDoc(doc(db, "group", groupId), {
    clubMembershipCard: url,
  }).catch((err) => {
    logger.error(err);
  });

  return url;
}

export async function deleteMembershipCard(groupId) {
  const clubId = getDefaultClub();
  const path = `/membership-card/${clubId}_membercard`;
  let storageRef = ref(storage, path);
  return deleteObject(storageRef).then(() => {
    updateDoc(doc(db, "group", groupId), {
      clubMembershipCard: "",
    }).catch((err) => {
      logger.error(err);
    });
  });
}
