import { store } from "../..";
import { applicationError } from "../../redux/actions/error";

export function createDatabase(groupIds) {
  return new Promise((resolve, reject) => {
    if (groupIds && groupIds.length > 0) {
      window.indexedDB.deleteDatabase("chant");
      const openRequest = window.indexedDB.open("chant", 1);
      openRequest.onupgradeneeded = function (event) {
        const db = openRequest.result;
        const contentsIndexes = [
          { name: "type", keyPath: "type" },
          { name: "title", keyPath: "title" },
          { name: "rsvp.attending", keyPath: "rsvp.attending" },
          { name: "rsvp.maybe", keyPath: "rsvp.maybe" },
          { name: "rsvp.no", keyPath: "rsvp.no" },
          { name: "matchName", keyPath: "matchName" },
          { name: "details.location", keyPath: "details.location" },
          { name: "details.eventStartDate", keyPath: "details.eventStartDate" },
          { name: "details.eventEndDate", keyPath: "details.eventEndDate" },
          { name: "showOnHomePageStart", keyPath: "showOnHomePageStart" },
          { name: "showOnHomePageEnd", keyPath: "showOnHomePageEnd" },
        ];

        groupIds.forEach((groupId) => {
          let currentStore = db.createObjectStore(
            `currentContents-${groupId}`,
            { keyPath: "id", unique: true }
          );
          currentStore.createIndex("pageId", "pageId", { unique: false });

          let pastStore = db.createObjectStore(`pastContents-${groupId}`, {
            keyPath: "id",
            unique: true,
          });
          pastStore.createIndex("pageId", "pageId", { unique: false });

          contentsIndexes.forEach((element) => {
            currentStore.createIndex(element.name, element.keyPath, {
              unique: false,
            });
            pastStore.createIndex(element.name, element.keyPath, {
              unique: false,
            });
          });

          let membersStore = db.createObjectStore(`members-${groupId}`, {
            keyPath: "id",
            unique: true,
          });
          membersStore.createIndex("pageId", "pageId", { unique: false });
          membersStore.createIndex("registrationDate", "registrationDate", {
            unique: false,
          });
          membersStore.createIndex("name", "name", { unique: false });
          membersStore.createIndex("hometown", "hometown", { unique: false });
          membersStore.createIndex("memberSince", "memberSince", {
            unique: false,
          });
          membersStore.createIndex(
            "activityCount.watchParty",
            "activityCount.watchParty",
            { unique: false }
          );
          membersStore.createIndex(
            "activityCount.capMe",
            "activityCount.capMe",
            { unique: false }
          );
          membersStore.createIndex(
            "activityCount.tailgating",
            "activityCount.tailgating",
            { unique: false }
          );
          membersStore.createIndex(
            "activityCount.volunteer",
            "activityCount.volunteer",
            { unique: false }
          );
          membersStore.createIndex("email", "email", { unique: false });
          membersStore.createIndex("membershipNumber", "membershipNumber", {
            unique: false,
          });
          membersStore.createIndex("address", "address", { unique: false });
          membersStore.createIndex("package", "package", { unique: false });
          membersStore.createIndex(
            "registeredOnChantDate",
            "registeredOnChantDate",
            { unique: false }
          );
        });
      };
      openRequest.onsuccess = function (event) {
        const db = openRequest.result;
        db.onversionchange = function () {
          db.close();
        };
        resolve(db);
      };
    }
  });
}

export function openTransaction(
  db,
  transactionParams,
  objectStoreParams,
  transactionPermissions = "readonly"
) {
  try {
    let transaction = db
      .transaction(transactionParams, transactionPermissions)
      .objectStore(objectStoreParams);
    return transaction;
  } catch (err) {
    store.dispatch(
      applicationError({
        err: {},
        message: "Error opening transaction, try re-login to fix the issue",
        errorCode: "indexDb-modal",
      })
    );
    return null;
  }
}

export function createSuperAdminDatabase() {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("chant", 1);

    openRequest.onupgradeneeded = function (event) {
      const db = openRequest.result;
      let groupStore = db.createObjectStore(`superadmin-groups`, {
        keyPath: "id",
        unique: true,
      });
      groupStore.createIndex("pageId", "pageId", { unique: false });
      groupStore.createIndex("groupName", "groupName", { unique: false });
      groupStore.createIndex("leagueName", "leagueName", { unique: false });
      groupStore.createIndex("registeredMembers", "registeredMembers", {
        unique: false,
      });
      groupStore.createIndex("renewalDate", "renewalDate", { unique: false });
      groupStore.createIndex("totalMembers", "totalMembers", { unique: false });
      groupStore.createIndex("clubName", "clubName", { unique: false });
      groupStore.createIndex("groupType", "groupType", { unique: false });
      groupStore.createIndex(
        "preRegisteredMemberCount",
        "preRegisteredMemberCount",
        { unique: false }
      );
    };

    openRequest.onsuccess = function (event) {
      const db = openRequest.result;
      db.onversionchange = function () {
        db.close();
      };
      resolve(db);
    };
  });
}

export function deleteDatabase() {
  return new Promise((resolve, reject) => {
    const deleteRequest = window.indexedDB.deleteDatabase("chant");
    deleteRequest.onsuccess = function (event) {
      resolve();
    };

    deleteRequest.onerror = function (event) {
      reject();
    };
  });
}
