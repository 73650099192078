import React, { Component } from "react";
import {
  CardElement,
  injectStripe,
  Elements,
  StripeProvider,
} from "react-stripe-elements";
import { Icon } from "antd";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit = async (event) => {
    let { token } = await this.props.stripe.createToken({
      name: this.props.name,
    });
    if (token) {
      this.props.handleMemberRegistration(token.id);
    }
  };

  render() {
    return (
      <div className="form-group">
        <div
          className="checkout col border rounded"
          style={{
            background: "#EAF0F6",
            padding: "10px",
            "font-size": "20px",
          }}
        >
          <h6>
            <i class="fa fa-lock" aria-hidden="true" />
            Enter credit card
          </h6>
          {/* <div>
            <span style={{ fontSize: "11px" }}>
              Chant is $5 per season and lasts until the beginning of the next
              season.
            </span>
          </div> */}

          <CardElement className="form-control" />
        </div>
        <div className="row justify-content-end m-2">
          <button onClick={this.submit} className="btn btn-primary">
            Pay $5
          </button>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
