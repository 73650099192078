import { db } from "../../connection";
import { store } from "../../../../index";
import { applicationError } from "../../../../redux/actions/error";
import { doc, getDoc, setDoc, updateDoc } from "@firebase/firestore";

export const fbGetGroupRegistrationCode = (groupId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    getDoc(docRef)
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          resolve((querySnapshot.data() || {}).registrationCode);
        }
      })
      .catch((err) => {
        let message = "There was an error fetching the registration code";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          }),
        );
        reject({ message });
      });
  });
};

export const fbGetGroupPaymentDetails = (groupId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    getDoc(docRef)
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          resolve((querySnapshot.data() || {}).paymentDetails || {});
        }
      })
      .catch((err) => {
        let message = "There was an error fetching payment details";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          }),
        );
        reject({ message });
      });
  });
};

export const fbChangeGroupRegistrationCode = (groupId, registrationCode) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    createRegistrationLink(registrationCode, groupId)
      .then((link) => {
        setDoc(docRef, { registrationCode }, { merge: true })
          .then(() => resolve(link))
          .catch((err) => {
            let message = "There was an error updating the registration code";
            store.dispatch(
              applicationError({
                message,
                intensity: "high",
                err,
                errorCode: "firebase",
              }),
            );
            reject({ message });
          });
      })
      .catch(reject);
  });
};

export const fbChangeGroupPaymentDetails = (groupId, paymentDetails) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    setDoc(docRef, { paymentDetails }, { merge: true })
      .then((result) => {
        resolve();
      })
      .catch((err) => {
        let message = "There was an error updating payment details";
        store.dispatch(
          applicationError({
            message,
            intensity: "high",
            err,
            errorCode: "firebase",
          }),
        );
        reject({ message });
      });
  });
};

export const fbGetGroupSettings = (groupId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    getDoc(docRef)
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          resolve((querySnapshot.data() || {}).configuration);
        }
      })
      .catch((err) => {
        let message = "There was an error fetching group configurations";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          }),
        );
        reject({ message });
      });
  });
};

export function createRegistrationLink(registrationCode, groupId) {
  let body = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://chant.page.link",
      link: `https://chant.fan/reglink?groupId=${groupId}&type=register&registrationCode=${registrationCode}&_imcp=1`,
      androidInfo: {
        androidPackageName: "com.geekyants.services.chant",
      },
      iosInfo: {
        iosBundleId: "com.chant.mobileapp.chant",
        iosAppStoreId: "1477159385",
      },
      navigationInfo: {
        enableForcedRedirect: 1,
      },
    },
    // "longDynamicLink":`https://chant.page.link/?link=https://chant.page.link/group?groupId=${groupId}&type=register&registrationCode=${registrationCode}&ofl=https://chant.fan/reglink&_imcp=1&apn=com.geekyants.services.chant&ibi=com.chant.mobileapp.chant&isi=1477159385`
  };

  let url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_APIKEY}`;

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        const docRef = doc(db, "group", groupId);
        updateDoc(docRef, {
          registrationLink: res.shortLink,
        }).then(() => {
          resolve(res.shortLink);
        });
      })
      .catch(reject);
  });
}
