import React from "react";
import {
  GUIDE_ENGAGE,
  GUIDE_GROW,
  GUIDE_ONBOARD,
  GUIDE_SETUP,
  TOPIC_ADD_CHANTS,
  TOPIC_ADD_LEADERBOARD_REWARDS,
  TOPIC_ADD_LOCATIONS,
  TOPIC_ADD_PARTNERS,
  TOPIC_ASSIGN_LEADERS,
  TOPIC_CREATE_WELCOME_CARD,
  TOPIC_INVITE_MEMBERS,
  TOPIC_POST_EVENTS,
  TOPIC_POST_IN_CHAT_STREAM,
  TOPIC_PROMOTE_GROUP,
  TOPIC_PUBLISH_GROUP,
  TOPIC_PUBLISH_POLL,
  TOPIC_PUBLISH_WEBSITE,
  TOPIC_REVIEW_CHANT_OVERVIEW,
  TOPIC_REVIEW_MATCHDAY,
  TOPIC_REVIEW_MEMBERSHIP_CARD,
  TOPIC_RUN_GIVEAWAY,
  TOPIC_RUN_PREDICTION_GIVEAWAY,
  TOPIC_SELECT_ONBOARDING,
  TOPIC_SELL_MERCHANDISE,
  TOPIC_SELL_TICKETS,
  TOPIC_SETUP_CHANT_STADIUM,
  TOPIC_SET_GENERAL_SETTINGS,
  TOPIC_UPLOAD_PHOTOS,
} from "../../../../constants/gettingStarted";
import MaximizedViewDetails from "../MaximizedViewDetails/MaximizedViewDetails";

const useGetStartedDetails = (openDetails) => {
  const details = {
    [TOPIC_REVIEW_CHANT_OVERVIEW]: (
      <MaximizedViewDetails
        guideId={GUIDE_SETUP}
        topicId={TOPIC_REVIEW_CHANT_OVERVIEW}
        openDetails={openDetails}
        title={"Review Chant Overview"}
        details={
          "Chant is a purpose-built platform for supporter groups offering a private app, member management, website, store, tickets and more – powered by your club’s schedule and stats."
        }
        customComponent={
          <div>
            <p style={{ fontWeight: 400, fontSize: "14px", color: "#333333" }}>
              Most features are automated. Our goal is to simplify the job of
              running a group so you can have a pint and watch the match. With
              some brief setup you can be up and running.
            </p>
            <p style={{ fontWeight: 400, fontSize: "14px", color: "#333333" }}>
              Groups following global clubs are networked with other groups into
              a global community.
            </p>
            <p style={{ fontWeight: 400, fontSize: "14px", color: "#333333" }}>
              Chant supports open and private groups with a range of onboarding
              options.
            </p>
            <p style={{ fontWeight: 400, fontSize: "14px", color: "#333333" }}>
              Chant supports open and private groups with a range of onboarding
              options. The app is free – we’ll eventually add sponsors. The
              optional website offering is $10/mo. Transactions include the
              standard Stripe processing fee + a 3% fee for Chant.
            </p>
            <p style={{ fontWeight: 400, fontSize: "14px", color: "#333333" }}>
              Reach out to support@chant.fan for help or to join a demo.
            </p>
          </div>
        }
      />
    ),
    [TOPIC_SET_GENERAL_SETTINGS]: (
      <MaximizedViewDetails
        guideId={GUIDE_SETUP}
        topicId={TOPIC_SET_GENERAL_SETTINGS}
        openDetails={openDetails}
        title={"Set general settings and payments"}
        details="Manage general app and design settings in the Settings section of the dashboard. Chant uses Stripe for groups that need a payment option for members."
        list={[
          "Request a link to register for a Stripe account to collect dues/donations, sell merchandise and tickets. Members pay via credit/debit, Apple Pay or Google Pay.",
          "Update group colors (based on club color values) and manage the group’s logo.",
          "Reach out to support@chant.fan to change the group name.",
          "Some features have their own Settings (Packages, Store, Website).",
        ]}
      />
    ),
    [TOPIC_ADD_LOCATIONS]: (
      <MaximizedViewDetails
        guideId={GUIDE_SETUP}
        topicId={TOPIC_ADD_LOCATIONS}
        openDetails={openDetails}
        title={"Add locations"}
        details="Add event locations once in Content/Locations."
        list={[
          "Locations are used across features (events, website, directory).",
          "Review Event check-ins by location. Share with your partner to say ‘thanks’ or request a discount",
        ]}
      />
    ),
    [TOPIC_ADD_PARTNERS]: (
      <MaximizedViewDetails
        guideId={GUIDE_SETUP}
        topicId={TOPIC_ADD_PARTNERS}
        openDetails={openDetails}
        title={"Add partners"}
        details="Feature commercial and philanthropic partners. Collect donations."
        list={[
          "Partners displayed in App (Partners section and home feed), Website and Registration",
          "Recommended: Set up your own group as a partner to collect donations.",
          "Add partner discounts and QR codes to show offers below the member card.",
          "Donations distributed to the group (requires Stripe). Group distributes funds to partners.",
        ]}
      />
    ),
    [TOPIC_UPLOAD_PHOTOS]: (
      <MaximizedViewDetails
        guideId={GUIDE_SETUP}
        topicId={TOPIC_UPLOAD_PHOTOS}
        openDetails={openDetails}
        title={"Upload photos"}
        details={
          "Bulk upload photos from the admin dashboard to seed the gallery. Members can add photos from the app."
        }
        list={[
          "Photos are displayed in the gallery sections of the app and website. Members can mark favorites.",
          "Members can report offensive images. Admin receive a notification. Image highlighted in red.",
          "Photos are compressed with higher resolution available for download.",
          "Admin/Leaders responsible for moderating gallery in accordance with club, group & Chant terms.",
        ]}
      />
    ),
    [TOPIC_ADD_CHANTS]: (
      <MaximizedViewDetails
        guideId={GUIDE_SETUP}
        topicId={TOPIC_ADD_CHANTS}
        openDetails={openDetails}
        title={"Add Chants"}
        details={
          "Populate group chants to be used in the app (More/Chants) and Stadium solution (web)."
        }
        list={[
          "Leaders/Capo push real-time chant alerts - press/hold a chant in the app.",
          "Chant cards flip to show words with a notification sent to members.",
        ]}
      />
    ),
    [TOPIC_REVIEW_MEMBERSHIP_CARD]: (
      <MaximizedViewDetails
        guideId={GUIDE_SETUP}
        topicId={TOPIC_REVIEW_MEMBERSHIP_CARD}
        openDetails={openDetails}
        title={"Review Membership Card"}
        details={"Chant offers customizable membership cards in the app."}
        list={[
          "Default card includes group name/logo, member name/thumbnail, member number, member since year and member package (if used).",
          "Explore the option to upload a custom background (details above appear over the background)",
          "You can also upload your own member card (a static image) that will appear as uploaded. No personal details appear on the card.",
        ]}
      />
    ),
    [TOPIC_SELECT_ONBOARDING]: (
      <MaximizedViewDetails
        guideId={GUIDE_ONBOARD}
        topicId={TOPIC_SELECT_ONBOARDING}
        title={"Select Onboarding Option"}
        openDetails={openDetails}
        details="Chant offers options to run open and private groups. Sell member packages. Share a QR code. Onboard ‘members’ and ‘fans’ (restricted permissions)."
        list={[
          "Upload member emails (Onboard Options #1-2) to pre-register members. Member must use this email when registering in the app to gain group access.",
          "If you have an existing member database, populate the ‘member import’ template (bottom of Onboard Option #2) and work with Chant to import data (support@chant.fan).",
          "Explore the option to onboard non-members (fans) with restricted app permissions (Onboard Option #4). Anyone with the QR code/link can enter the group.",
        ]}
      />
    ),
    [TOPIC_ASSIGN_LEADERS]: (
      <MaximizedViewDetails
        guideId={GUIDE_ONBOARD}
        topicId={TOPIC_ASSIGN_LEADERS}
        title={"Assign leaders / titles"}
        openDetails={openDetails}
        details={
          "After a member registers in the app you can change their role to provide additional leadership options."
        }
        list={[
          "In the admin dashboard go to Members / Edit Member to change roles, add a title and show a person in the ‘Leaders’ section of the Members List (in app).",
          "Roles include admin (full access), leader (full app access), Capo (community building in app) and ticket scanner.",
          "No limit to number of admin/leaders. Have member close and reopen app to see new permissions.",
        ]}
      />
    ),
    [TOPIC_CREATE_WELCOME_CARD]: (
      <MaximizedViewDetails
        guideId={GUIDE_ONBOARD}
        topicId={TOPIC_CREATE_WELCOME_CARD}
        title={"Create ‘Welcome’ card"}
        openDetails={openDetails}
        details={
          "Create an optional card to welcome members with a custom message."
        }
        list={[
          "Includes the option to create separate welcome messages for Members and Fans.",
          "Card appears near the top of the app home feed until a member closes or the ‘show in home feed’ date passes.",
          "Admin can republish the card at any time.",
          "Includes the option to add an external link (Renew Membership, etc) and show a member the email address they used to register.",
        ]}
      />
    ),
    [TOPIC_INVITE_MEMBERS]: (
      <MaximizedViewDetails
        guideId={GUIDE_ONBOARD}
        topicId={TOPIC_INVITE_MEMBERS}
        title={"Invite Members"}
        openDetails={openDetails}
        details={
          "Notify members and share registration details manually (outside of Chant). Chant does not email members."
        }
        list={[
          "See the ‘Promote’ social media template (Members / Promote) with options to upload an app screenshot / image and QR code for group access.",
          "Find a variety of QR codes below the Promote template with options to onboard members/fans, link to membership packages or point members to Chant in the app stores.",
          "Also find the Chant logos for inclusion in social posts and mention Chant (@thechantapp).",
        ]}
      />
    ),
    [TOPIC_REVIEW_MATCHDAY]: (
      <MaximizedViewDetails
        guideId={GUIDE_ENGAGE}
        topicId={TOPIC_REVIEW_MATCHDAY}
        title={"Review matchday features"}
        openDetails={openDetails}
        details={
          "Chant automates matchday features (schedule, live stats, predictions, starting xi, player of match), all powered by the club’s schedule."
        }
        list={[
          "Cards appear automatically in the app home feed and match thread.",
          "Predictions close 5 min before the match. Player of match voting opens in the 80th min and closes at FT.",
          "See the Schedule for past matchday cards.",
        ]}
      />
    ),
    [TOPIC_POST_EVENTS]: (
      <MaximizedViewDetails
        guideId={GUIDE_ENGAGE}
        topicId={TOPIC_POST_EVENTS}
        title={"Post Events"}
        openDetails={openDetails}
        details={
          "Members check-in for event points and spot on the Event Leaderboard. Request and reward Volunteers. Request RSVPs."
        }
        list={[
          "Set your point strategy by event to reward attendance",
          "Geo-location used for check-in. Set your locations in Content/Locations.",
          "Request volunteers and offer additional leaderboard points.",
          "Review points by event and location in the Leaderboard report (coming soon).",
          "Members track points in the Leaderboard and ‘My Profile’.",
          "Check-ins tracked by location so you can share stats with your pub partner each season.",
          "Admin can manually check in a member in the event card (in app). See past events in Schedule.",
        ]}
      />
    ),
    [TOPIC_ADD_LEADERBOARD_REWARDS]: (
      <MaximizedViewDetails
        guideId={GUIDE_ENGAGE}
        topicId={TOPIC_ADD_LEADERBOARD_REWARDS}
        title={"Add Leaderboard Rewards"}
        openDetails={openDetails}
        details={
          "Share rewards for loyal members who top the Events, Score and Stats Leaderboards."
        }
        list={[
          "Consider rewarding the top point earner plus entering the top 10 into a drawing.",
          "Score Leaderboard points set by Chant (100 for correct match outcome + 250 for correct score).",
          "Stats Leaderboard points set by accuracy of prediction (possession, shots, SoG, etc).",
        ]}
      />
    ),
    [TOPIC_POST_IN_CHAT_STREAM]: (
      <MaximizedViewDetails
        guideId={GUIDE_ENGAGE}
        topicId={TOPIC_POST_IN_CHAT_STREAM}
        title={"Post in Chat Stream / enable channels"}
        openDetails={openDetails}
        details={
          "Members can post/comment in the Chat Stream and automated match threads. Channels can be enabled for topic-specific conversations. DMs provide 1:1 discussion."
        }
        list={[
          "Stream: All groups have ‘Group Post’ option. Groups for global clubs can use ‘Global Posts’ (seen by all groups). Admin/leaders can post privately to other leaders.",
          "Match threads created automatically 8 hours before the match.",
          "Channels: Option for admin to enable Chat Channels, like Slack/Discord, for topic-specific chats (see Dashboard/Settings/App). Admin/leaders create group-wide channels. All members can create private channels.",
          "Admin/Leaders responsible for moderating chat in accordance with club, group & Chant terms.",
          "Members can report a post/comment (appears in red) with a notification sent to the admin.",
        ]}
      />
    ),
    [TOPIC_RUN_PREDICTION_GIVEAWAY]: (
      <MaximizedViewDetails
        guideId={GUIDE_ENGAGE}
        topicId={TOPIC_RUN_PREDICTION_GIVEAWAY}
        title={"Run a prediction giveaway"}
        openDetails={openDetails}
        details={
          "Prediction cards are posted in the app home feed two days before a match and provide an easy way to reward members."
        }
        list={[
          "Offer a prediction giveaway item (via prediction card in the app).",
          "One winner is selected automatically at FT and shared in the Chat Stream.",
          "Consider asking your pub partner to offer a free pint when there is a winner.",
          "Enter the item the day before the match for inclusion in the automated notification.",
          "Entrants added to the Score and Stats Leaderboards.",
        ]}
      />
    ),
    [TOPIC_RUN_GIVEAWAY]: (
      <MaximizedViewDetails
        guideId={GUIDE_ENGAGE}
        topicId={TOPIC_RUN_GIVEAWAY}
        title={"Run a giveaway"}
        openDetails={openDetails}
        details={
          "Streamline group giveaways by posting a giveaway in the app. Manage the entire process with the click of a button."
        }
        list={[
          "Add via More/Giveaways or the ‘+’ button in home feed.",
          "Offer multiple items per giveaway.",
          "Sell additional entries (local laws permitting).",
          "Feature partner if item is donated.",
          "Run the giveaway from the app (Post live, Send 10-min warning, Close, Select winner).",
        ]}
      />
    ),
    [TOPIC_PUBLISH_POLL]: (
      <MaximizedViewDetails
        guideId={GUIDE_ENGAGE}
        topicId={TOPIC_PUBLISH_POLL}
        title={"Publish a Poll"}
        openDetails={openDetails}
        details={
          "Capture group feedback by posting a poll in the app home feed."
        }
        list={[
          "Add question, responses and optional image",
          "Set length of poll from 1 hour to 30 days.",
          "Provide a field for members to enter additional feedback up to 150 characters (optional)",
          "Show individual responses to all members (optional)",
          "Results automatically posted in the Chat Stream.",
        ]}
      />
    ),
    [TOPIC_SELL_TICKETS]: (
      <MaximizedViewDetails
        guideId={GUIDE_ENGAGE}
        topicId={TOPIC_SELL_TICKETS}
        title={"Sell Group / Individual Tickets"}
        openDetails={openDetails}
        details={
          "Post tickets for sale to the group for match tickets, special events, bus, tailgate, parking and screenings)."
        }
        list={[
          "Offer free or paid tickets (requires Stripe) in the app and via a web page.",
          "Option to set member and non-member pricing.",
          "Check-in members via the app using a QR scanner or purchase list.",
          "Members can post individual tickets for sale to other members (at or below list price). Feature simply connects the members with the transaction taking place outside of Chant.",
        ]}
      />
    ),

    [TOPIC_PUBLISH_GROUP]: (
      <MaximizedViewDetails
        guideId={GUIDE_GROW}
        topicId={TOPIC_PUBLISH_GROUP}
        title={"Publish Group in Directory"}
        openDetails={openDetails}
        details={
          "Chant offers a global supporter directory. Publish your group so fans can find you."
        }
        list={[
          "Use the directory page as a free microsite",
          "Post your directory web link to social media",
          "Offer options for fans to join the group",
        ]}
      />
    ),
    [TOPIC_SELL_MERCHANDISE]: (
      <MaximizedViewDetails
        guideId={GUIDE_GROW}
        topicId={TOPIC_SELL_MERCHANDISE}
        title={"Sell Merchandise"}
        openDetails={openDetails}
        details={"Chant provides a store to sell merchandise."}
        list={[
          "Post merchandise in the Store to be sold on a stand-alone store web page, on your Chant website and as an upsell with member packages.",
          "Offer member and non-member prices.",
          "Use the Orders report to manage fulfillment.",
          "In Store settings create a link in the app to your web store.",
        ]}
      />
    ),
    [TOPIC_PUBLISH_WEBSITE]: (
      <MaximizedViewDetails
        guideId={GUIDE_GROW}
        topicId={TOPIC_PUBLISH_WEBSITE}
        title={"Publish Website"}
        openDetails={openDetails}
        details={
          "Chant provides the option to publish a full website for the group."
        }
        list={[
          "The website is integrated with the app (events, news, gallery, store, leaders, etc) and remains automatically updated.",
          "Use your own web URL and redirect to your new chant URL (found in Website setup)",
          "The website is $10/mo (invoiced annually).",
        ]}
      />
    ),
    [TOPIC_SETUP_CHANT_STADIUM]: (
      <MaximizedViewDetails
        guideId={GUIDE_GROW}
        topicId={TOPIC_SETUP_CHANT_STADIUM}
        title={"Engage Fans in Stadium"}
        openDetails={openDetails}
        details={
          "Chant provides a way to engage members around the stadium with real-time chant alerts, predictions, player of match voting, donations and a way to join the group."
        }
        list={[
          "Publish your Stadium mobile web page and share a QR code allowing fans to engage",
          "Set up your group as a ‘Partner’ and collect donations for Tifo",
          "Ensure you offer a way to join the group (buy package, QR code, etc)",
          "Partner with your club to roll this out as a way to engage fans around the stadium",
        ]}
      />
    ),
    [TOPIC_PROMOTE_GROUP]: (
      <MaximizedViewDetails
        guideId={GUIDE_GROW}
        topicId={TOPIC_PROMOTE_GROUP}
        title={"Promote your group"}
        openDetails={openDetails}
        details={"Chant offers a way to create a social media post."}
        list={[
          "Populate the social media template with an app screenshot or group photo",
          "Add a QR code (found below the template) providing easy group access",
          "Add the Chant logo (found below the template) and mention @thechantapp",
        ]}
      />
    ),
  };

  const gettingStartedTitlesMap = {
    setup: {
      [TOPIC_REVIEW_CHANT_OVERVIEW]: "Review Chant Overview",
      [TOPIC_SET_GENERAL_SETTINGS]: "Set general settings and payments",
      [TOPIC_ADD_LOCATIONS]: "Add locations",
      [TOPIC_ADD_PARTNERS]: "Add partners",
      [TOPIC_UPLOAD_PHOTOS]: "Upload photos",
      [TOPIC_ADD_CHANTS]: "Add Chants",
      [TOPIC_REVIEW_MEMBERSHIP_CARD]: "Review Membership Card",
    },
    onboard: {
      [TOPIC_SELECT_ONBOARDING]: "Select Onboarding Option",
      [TOPIC_ASSIGN_LEADERS]: "Assign leaders / titles",
      [TOPIC_CREATE_WELCOME_CARD]: "Create ‘Welcome’ card",
      [TOPIC_INVITE_MEMBERS]: "Invite Members",
    },
    engage: {
      [TOPIC_REVIEW_MATCHDAY]: "Review matchday features",
      [TOPIC_POST_EVENTS]: "Post Events",
      [TOPIC_ADD_LEADERBOARD_REWARDS]: "Add Leaderboard Rewards",
      [TOPIC_POST_IN_CHAT_STREAM]: "Post in Chat Stream / enable channels",
      [TOPIC_RUN_PREDICTION_GIVEAWAY]: "Run a prediction giveaway",
      [TOPIC_RUN_GIVEAWAY]: "Run a giveaway",
      [TOPIC_PUBLISH_POLL]: "Publish a Poll",
      [TOPIC_SELL_TICKETS]: "Sell Group / Individual Tickets",
    },
    grow: {
      [TOPIC_PUBLISH_GROUP]: "Publish Group in Directory",
      [TOPIC_SELL_MERCHANDISE]: "Sell Merchandise",
      [TOPIC_PUBLISH_WEBSITE]: "Publish Website",
      [TOPIC_SETUP_CHANT_STADIUM]: "Engage Fans in Stadium",
      [TOPIC_PROMOTE_GROUP]: "Promote your group",
    },
  };

  const gettingStartedTopicsCount = Object.entries(
    gettingStartedTitlesMap
  ).reduce(
    (acc, [key, value]) => {
      acc[key] = Object.keys(value).length;
      acc.totalCount += Object.keys(value).length;
      return acc;
    },
    { totalCount: 0 }
  );

  return { details, gettingStartedTitlesMap, gettingStartedTopicsCount };
};

export default useGetStartedDetails;
