import { Button, Checkbox, DatePicker, Modal, Spin, Tooltip } from "antd";
import React from "react";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import ImageUploaderButton from "../website/ImageUploaderButton";
import {
  uploadImage,
  addPackage,
  getPackage,
  updatePackage,
} from "../../../services/firebaseService/endPoints/admin/members";
import { connect } from "react-redux";
import {
  getStoreItems,
  getStoreItemsForRegistration,
} from "../../../services/firebaseService/endPoints/admin/store";
import moment from "moment";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";
import { verifyStripeAccountId } from "../../../utils/errors";
import logger from "../../../utils/logger";

const statusDataList = ["Active", "Inactive"].reduce((acc, val) => {
  let key = val.toLocaleLowerCase();
  let value = val;
  acc.push({ key, value });
  return acc;
}, []);

const MONTHS = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const priceTypes = ["Per member", "Per adult", "Per couple", "Per family"];

const shippingOptions = [
  { value: "pickup", label: "Pick up only" },
  { value: "shipping", label: "Shipping only" },
  { value: "both", label: "Option of shipping or pickup" },
  { value: "none", label: "Not applicable" },
];
const productChoices = ["None", "1 per member", "1 per adult", "1 per package"];

const allTshirtSizes = [
  "Youth Small",

  "Youth Medium",

  "Youth Large",

  "Youth XL",

  "Adult XS",

  "Adult Small",

  "Adult Medium",

  "Adult Large",

  "Adult XL",

  "Adult 2XL",

  "Adult 3XL",

  "Women’s XS",

  "Women’s Small",

  "Women’s Medium",

  "Women’s Large",

  "Women’s XL",

  "Women’s 2XL",
];

class MemberPackageForm extends React.Component {
  state = {
    status: "active",
    season: new Date().getFullYear(),
    name: "",
    desc: "",
    image: undefined,
    price: {
      amount: 0,
      type: "Per member",
      shipping: 0,
    },
    childPrice: {
      amount: 0,
      shipping: 0,
    },
    offerShipping: "",
    shippingNote: "",
    pickupNote: "",
    productChoice: "None",
    products: [],
    askTshirtSize: false,
    tshirtSizes: [],
    showSizes: false,
    storeItems: [],
    includesChildPrice: false,
    packageExpiryDate: 0,
    offerAutorenewal: false,
    autoRenewalDate: {
      month: "",
      date: "",
    },
    emailMemo: "",
    editChanges: {
      priceChange: false,
      nameChange: false,
      childPriceChange: false,
    },
    saving: false,
  };

  savePackage = async () => {
    debugger;
    try {
      const { price, childPrice } = this.state;

      const totalPrice =
        price?.amount +
        price?.shipping +
        childPrice?.amount +
        childPrice?.shipping;

      if (totalPrice === 0) {
        alert(
          "Packages can not be free as the purchase goes through Stripe. Use an alternate method to onboard members if there is no fee to be collected."
        );
        return;
      }

      if (
        !verifyStripeAccountId(
          this.props?.adminData?.data?.paymentDetails?.stripeAccountId
        )
      ) {
        return;
      }

      this.setState({ saving: true });
      let data = this.state;
      // if (this.state.offerAutorenewal) {
      //     if (!this.state.autoRenewalDate.month || !this.state.autoRenewalDate.date) {
      //         alert("Auto-renewal date is required!");
      //         return;
      //     }
      // }

      if (!this.state.name) {
        alert("Package name is required");
        this.setState({ saving: false });
        return;
      }

      if (!this.state.packageExpiryDate) {
        alert("Package expiry date is required");
        this.setState({ saving: false });
        return;
      }

      let image = "";
      if (data.image && typeof data.image !== "string") {
        image = await uploadImage(
          data.image,
          `regForm/${this.props.currentGroup}/pack/${Date.now()}`
        ).then(({ downloadUrl }) => downloadUrl);
        data.image = image;
      } else {
        if (!data.image) {
          alert("Image is required");
          this.setState({ saving: false });
          return;
        }
      }

      if (!this.state.offerShipping) {
        alert("Please select shipping option.");
        this.setState({ saving: false });
        return;
      }

      let editChanges = JSON.parse(JSON.stringify(data.editChanges));

      delete data.showSizes;
      delete data.storeItems;
      delete data.editChanges;

      let paymentDetails = this.props.adminData.data.paymentDetails || {};
      let stripeId = paymentDetails.stripeAccountId || "";
      let currency = (paymentDetails.currency || "usd").toLowerCase();

      if (this.props.id) {
        await updatePackage(
          data,
          this.props.id,
          this.props.currentGroup,
          editChanges,
          stripeId,
          currency
        );
      } else {
        await addPackage(data, this.props.currentGroup, stripeId, currency);
      }
      postUpdateCDNUtil(this.props.currentGroup);

      this.props.close();
    } catch (error) {
      this.setState({ saving: false });
      logger.error("Save Package Error: ", error);
    }
  };

  componentDidMount() {
    let promises = [];
    if (this.props.id) {
      let packagePromise = getPackage(
        this.props.currentGroup,
        this.props.id
      ).then((data) => {
        this.setState({
          ...data,
          childPrice: data.childPrice || {
            amount: 0,
            shipping: 0,
          },
        });
      });

      promises.push(packagePromise);
    }

    let productsPromise = getStoreItemsForRegistration(
      this.props.currentGroup
    ).then((items) => {
      this.setState({ storeItems: items });
    });

    promises.push(productsPromise);

    Promise.all(promises);
  }

  handleDateChange = (name, value) => {
    if (!Array.isArray(value)) value = moment(value).isValid() ? value : "";
    let hours = 0,
      minutes = 0,
      seconds = 0;
    // return;
    if (value) {
      this.setState({
        [name]: moment(value)
          .set({
            h: hours,
            m: minutes,
            second: seconds,
          })
          .unix(),
      });
    }
  };

  render() {
    let filteredStoreItems = (this.state.storeItems || []).filter((item) => {
      if (this.state.products.includes(item.id)) {
        return true;
      }
      return item.status === "live";
    });

    return (
      <Modal
        width={1000}
        title="Add Package"
        centered
        visible={this.props.visible || true}
        footer={null}
        onOk={() => {}}
        onCancel={() => {
          this.props.close();
        }}
      >
        <div style={{ width: "100%" }}>
          <div className="col">
            <div className="row" style={{ width: "95%" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          Status<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <FSelect
                          value={this.state.status}
                          onChange={(e) => {
                            this.setState({
                              status: e.target.value,
                            });
                          }}
                          dataList={statusDataList}
                          dataKey="key"
                          dataValue="value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          Season<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <FSelect
                          value={this.state.season}
                          onChange={(e) => {
                            if (this.props.id) {
                              let isConfirmed = window.confirm(
                                "Warning: If updating the package for the following season you should update both the package expiration date and the season. Members can purchase one package per season."
                              );
                              if (isConfirmed)
                                this.setState({
                                  season: e.target.value,
                                });
                            } else {
                              this.setState({
                                season: e.target.value,
                              });
                            }
                          }}
                          dataList={[
                            new Date().getFullYear(),
                            new Date().getFullYear() + 1,
                          ]}
                          dataOnlyValue={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          Package Name<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <FInput
                          value={this.state.name}
                          name="name"
                          onChange={(e) => {
                            let editChanges = this.state.editChanges;
                            editChanges.nameChange = true;
                            editChanges.productId = this.state.stripeProductId;
                            this.setState({
                              name: e.target.value,
                              editChanges: editChanges,
                            });
                          }}
                          maxLength={50}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Description</label>
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group row">
                              <textarea
                                className="form-control form-control-sm col-12"
                                value={this.state.desc}
                                name="desc"
                                onChange={(e) => {
                                  this.setState({
                                    desc: e.target.value,
                                  });
                                }}
                                maxLength={500}
                                rows={2}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Package Expiration Date</label>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          placement="topLeft"
                          title={
                            <div style={{ width: 400 }}>
                              Important: After this date, members who have not
                              renewed will be locked out of Chant. Include a
                              ‘grace period’ allowing time to renew after your
                              next membership drive begins.
                            </div>
                          }
                        >
                          <span className="border-0">
                            &nbsp;<i class="fa fa-question-circle-o"></i>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="row">
                              <DatePicker
                                tabIndex="2"
                                value={
                                  this.state.packageExpiryDate
                                    ? moment.unix(this.state.packageExpiryDate)
                                    : ""
                                }
                                onChange={(value) => {
                                  if (this.props.id) {
                                    let isConfirmed = window.confirm(
                                      "Warning: If updating the package for the following season you should update both the package expiration date and the season. Members can purchase one package per season."
                                    );
                                    if (isConfirmed) {
                                      this.handleDateChange(
                                        "packageExpiryDate",
                                        value
                                      );
                                    }
                                  } else {
                                    this.handleDateChange(
                                      "packageExpiryDate",
                                      value
                                    );
                                  }
                                }}
                                className="form-control form-control-sm"
                                size={10}
                              />
                            </div>
                          </div>
                          <div>
                            Package expires on this date (if not on
                            auto-renewal).
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row mt-2" style={{ flexFlow: "nowrap" }}>
                      <div className="col-md-4">
                        <label>Offer auto-renewal?</label>
                      </div>
                      <div className="row" style={{ flexFlow: "nowrap" }}>
                        <Checkbox
                          style={{ marginLeft: "18px" }}
                          checked={this.state.offerAutorenewal}
                          onChange={(e) => {
                            this.setState({
                              offerAutorenewal: e.target.checked,
                            });
                          }}
                          value="secondary"
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          tabIndex="2"
                        />
                        <div className="ml-2">
                          <span>
                            If selected members will be offered option to
                            auto-renew the package.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.offerAutorenewal && (
                  <>
                    {/* <div className='row' >
                                        <div className='col-md-9'>
                                            <div className='row mt-2' style={{ flexFlow: 'nowrap' }}>
                                                <div className='col-md-4'>
                                                    <label>Auto-renewal date<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className='d-flex' style={{ flexFlow: 'nowrap', gap: 20 }}>
                                                    <select
                                                        value={this.state.autoRenewalDate.month}
                                                        className="form-control form-control-sm col-3"
                                                        onChange={(e) => {
                                                            let autoRenewalDate = this.state.autoRenewalDate;
                                                            let value = e.target.value;
                                                            if (Number(value) >= 0) {
                                                                this.setState({
                                                                    autoRenewalDate: {
                                                                        ...autoRenewalDate,
                                                                        month: Number(value)
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <option value={''}>Month</option>
                                                        {Array.from(MONTHS, ((_, index) => <option value={index + 1}>{MONTHS[index + 1]}</option>))}
                                                    </select>
                                                    <select
                                                        value={this.state.autoRenewalDate.date}
                                                        className="form-control form-control-sm col-2"
                                                        onChange={(e) => {
                                                            let autoRenewalDate = this.state.autoRenewalDate;
                                                            let value = e.target.value;
                                                            if (Number(value) >= 0) {
                                                                this.setState({
                                                                    autoRenewalDate: {
                                                                        ...autoRenewalDate,
                                                                        date: Number(value)
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <option value={''}>Date</option>
                                                        {Array.from({ length: this.state.autoRenewalDate.month===2 ? 28 : [1, 3, 5, 7, 8, 10, 12].includes(this.state.autoRenewalDate.month) ? 31 : 30 }, ((_, index) => <option value={index + 1}>{index + 1}</option>))}
                                                    </select>
                                                    <div className='ml-2'>
                                                        <span>Stripe will renew subscriptions on this date each year.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                    {/* commented out for now. */}
                    {/* <div className='row'>
                                        <div className='col-md-9'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label>
                                                        Auto-renewal email memo
                                                    </label>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className='row'>
                                                        <div className='col-md-8'>
                                                            <div className='form-group row'>
                                                                <textarea
                                                                    className='form-control form-control-sm col-12'
                                                                    value={this.state.emailMemo}
                                                                    name="desc"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            emailMemo: e.target.value
                                                                        })
                                                                    }}
                                                                    maxLength={500}
                                                                    rows={2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  </>
                )}
                <div className="row mb-2">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label>
                          Image<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <ImageUploaderButton
                            text="Upload Image"
                            images={[this.state.image]}
                            onSave={(images) => {
                              this.setState({
                                image: images[0],
                              });
                            }}
                            aspectRatio={1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label>
                          Price<span style={{ color: "red" }}>*</span>
                        </label>
                        <Tooltip
                          placement="topLeft"
                          title={() => (
                            <div style={{ width: 400 }}>
                              <p style={{ marginBottom: 0 }}>Per Member</p>
                              <p>
                                One price for all members. No discounts for
                                couples/families. No children added separately.
                              </p>
                              <br />
                              <p style={{ marginBottom: 0 }}>Per Adult</p>
                              <p style={{ marginBottom: 0 }}>
                                {" "}
                                Allows a separate price for children.
                              </p>
                              <p>
                                Child package does not allow Chant access. Only
                                child’s name is collected. A member number is
                                assigned. Details are stored with parent
                                account.
                              </p>
                              <br />
                              <p style={{ marginBottom: 0 }}>Per Couple</p>
                              <p>
                                {" "}
                                Provide a couples discount and separate price
                                for children.
                              </p>
                              <br />
                              <p style={{ marginBottom: 0 }}>Per Family</p>
                              <p>
                                {" "}
                                One price for the entire family. Member prompted
                                to select '# of children' at no additional cost.
                              </p>
                            </div>
                          )}
                        >
                          <span className="border-0">
                            &nbsp;<i className="fa fa-question-circle-o"></i>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="col-md-8">
                        <div className="row align-items-center">
                          <input
                            prefix="$"
                            type="Number"
                            onChange={(e) => {
                              let price = this.state.price;
                              let editChanges = this.state.editChanges;
                              editChanges.productId =
                                this.state.stripeProductId;
                              let value = e.target.value;
                              if (Number(value) >= 0) {
                                if (
                                  value.includes(".") &&
                                  value.length - value.indexOf(".") > 2
                                ) {
                                  value = value.slice(
                                    0,
                                    value.indexOf(".") + 3
                                  );
                                }
                                editChanges.priceChange = true;
                                this.setState({
                                  price: {
                                    ...price,
                                    amount: Number(value),
                                  },
                                  editChanges: editChanges,
                                });
                              }
                            }}
                            value={this.state.price.amount}
                            className="form-control form-control-sm col-2 no-arrow-field"
                          />
                          <div className="mx-1">
                            <select
                              value={this.state.price.type}
                              className="form-control form-control-sm col-12"
                              onChange={(e) => {
                                let price = this.state.price;
                                price.type = e.target.value;
                                let updatedState = { price };
                                if (
                                  price.type === "Per family" ||
                                  price.type === "Per member"
                                ) {
                                  updatedState.childPrice = {
                                    amount: 0,
                                    shipping: 0,
                                  };

                                  updatedState.includesChildPrice = false;
                                }

                                this.setState(updatedState);
                              }}
                            >
                              {priceTypes.map((type) => (
                                <option value={type}>{type}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-2">
                            <div
                              className="row"
                              style={{
                                flexWrap: "nowrap",
                                alignItems: "center",
                              }}
                            >
                              <span className="mr-1">+ </span>
                              <input
                                prefix="$"
                                type="Number"
                                onChange={(e) => {
                                  let price = this.state.price;
                                  let editChanges = this.state.editChanges;
                                  editChanges.productId =
                                    this.state.stripeProductId;
                                  let value = e.target.value;
                                  if (Number(value) >= 0) {
                                    editChanges.priceChange = true;
                                    if (
                                      value.includes(".") &&
                                      value.length - value.indexOf(".") > 2
                                    ) {
                                      value = value.slice(
                                        0,
                                        value.indexOf(".") + 3
                                      );
                                    }
                                    this.setState({
                                      price: {
                                        ...price,
                                        shipping: Number(value),
                                      },
                                      editChanges: editChanges,
                                    });
                                  }
                                }}
                                value={this.state.price.shipping}
                                className="form-control form-control-sm col-8 no-arrow-field"
                              />
                              <span className="ml-1"> Shipping</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.includesChildPrice ? (
                  <div className="row">
                    <div className="col-md-9">
                      <div
                        className="row mt-2"
                        style={{ position: "relative" }}
                      >
                        <div className="col-md-4"></div>

                        <div className="col-md-8">
                          <div className="row align-items-center">
                            <input
                              prefix="$"
                              type="Number"
                              min={0}
                              onChange={(e) => {
                                let childPrice = this.state.childPrice;
                                let editChanges = this.state.editChanges;
                                editChanges.childProductId =
                                  this.state.childPrice.childProductId;
                                let value = e.target.value;
                                if (Number(value) >= 0) {
                                  editChanges.childPriceChange = true;
                                  if (
                                    value.includes(".") &&
                                    value.length - value.indexOf(".") > 2
                                  ) {
                                    value = value.slice(
                                      0,
                                      value.indexOf(".") + 3
                                    );
                                  }
                                  this.setState({
                                    childPrice: {
                                      ...childPrice,
                                      amount: Number(value),
                                    },
                                    editChanges: editChanges,
                                  });
                                }
                              }}
                              value={this.state.childPrice.amount}
                              className="form-control form-control-sm col-2 no-arrow-field"
                            />
                            <div className="mx-1 col-md-3">
                              <div className="row">
                                <input
                                  value={"Per child"}
                                  className="form-control form-control-sm "
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div
                                className="row"
                                style={{
                                  flexWrap: "nowrap",
                                  alignItems: "center",
                                }}
                              >
                                <span className="mr-1">+ </span>
                                <input
                                  prefix="$"
                                  type="Number"
                                  onChange={(e) => {
                                    let childPrice = this.state.childPrice;
                                    let editChanges = this.state.editChanges;
                                    editChanges.childProductId =
                                      this.state.childPrice.childProductId;
                                    let value = e.target.value;
                                    if (Number(value) >= 0) {
                                      editChanges.childPriceChange = true;
                                      if (
                                        value.includes(".") &&
                                        value.length - value.indexOf(".") > 2
                                      ) {
                                        value = value.slice(
                                          0,
                                          value.indexOf(".") + 3
                                        );
                                      }
                                      this.setState({
                                        childPrice: {
                                          ...childPrice,
                                          shipping: Number(value),
                                        },
                                        editChanges: editChanges,
                                      });
                                    }
                                  }}
                                  value={this.state.childPrice.shipping}
                                  className="form-control form-control-sm col-8 no-arrow-field"
                                />
                                <span className="ml-1"> Shipping</span>
                              </div>
                            </div>
                          </div>
                          <button
                            className="ant-btn"
                            type="button"
                            style={{
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              left: "20rem",
                            }}
                            onClick={() => {
                              this.setState({
                                childPrice: {
                                  amount: 0,
                                  shipping: 0,
                                },
                                includesChildPrice: false,
                              });
                            }}
                          >
                            x
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <p>
                        Child package does not allow Chant access. Only child’s
                        name is collected. A member number is assigned. Details
                        are stored with parent account.
                      </p>
                    </div>
                  </div>
                ) : (
                  this.state.price.type !== "Per family" &&
                  this.state.price.type !== "Per member" && (
                    <div className="row">
                      <div className="col-md-3"></div>
                      <div className="col-md-9" style={{ padding: "0px" }}>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ includesChildPrice: true });
                          }}
                        >
                          + Add price per child
                        </span>
                      </div>
                    </div>
                  )
                )}
                <div className="row">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label>
                          Offer shipping<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <div className="row align-items-center">
                          <div className="col-md-8">
                            <div className="row">
                              <select
                                className="form-control form-control-sm col-12"
                                onChange={(e) => {
                                  this.setState({
                                    offerShipping: e.target.value,
                                  });
                                }}
                                value={this.state.offerShipping}
                              >
                                <option key={"select"}>Select</option>
                                {shippingOptions.map((shippingOption) => (
                                  <option value={shippingOption.value}>
                                    {shippingOption.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3"></div>
                          <label>Shipping note</label>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <FInput
                          value={this.state.shippingNote}
                          name="shippingNote"
                          onChange={(e) => {
                            this.setState({
                              shippingNote: e.target.value,
                            });
                          }}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3"></div>
                          <label>Pickup note</label>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <FInput
                          value={this.state.pickupNote}
                          name="pickupNote"
                          onChange={(e) => {
                            this.setState({
                              pickupNote: e.target.value,
                            });
                          }}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mt-2">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Merchandise</label>
                            <Tooltip
                              placement="topLeft"
                              title={() => (
                                <div style={{ width: 400 }}>
                                  If package includes merchandise (or choice of
                                  merchandise) select one or more items. Product
                                  must first be added in the 'Store'. Products
                                  requiring 'size' will use the size field
                                  associated with that product.
                                </div>
                              )}
                            >
                              <span className="border-0">
                                &nbsp;
                                <i className="fa fa-question-circle-o"></i>
                              </span>
                            </Tooltip>
                          </div>
                          <div className="col-md-8">
                            <FSelect
                              value={this.state.productChoice}
                              onChange={(e) => {
                                this.setState({
                                  productChoice: e.target.value,
                                });
                              }}
                              dataList={productChoices}
                              dataOnlyValue={true}
                            />
                          </div>
                        </div>
                        {this.state.productChoice === "None" ? (
                          <div className="row mt-2">
                            {/* <div className='col-md-4'>
                                <label>
                                    Ask t-shirt size?
                                </label>
                                <Tooltip placement="topLeft" title={() => <div style={{ width: 400 }}>Use only if package includes a t-shirt and you are NOT using the 'Offer Product Choice' option.</div>}>
                                    <span className="border-0">
                                        &nbsp;<i className="fa fa-question-circle-o"></i>
                                    </span>
                                </Tooltip>
                            </div>
                            <div className='col-md-8'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            <input
                                                style={{ height: "20px", width: "20px" }}
                                                type="checkbox"
                                                className="form-control-input cursor-pointer"
                                                onChange={() => {
                                                    this.setState((state) => ({ askTshirtSize: !state.askTshirtSize }));
                                                }}
                                                checked={this.state.askTshirtSize}
                                            />
                                            {this.state.askTshirtSize && <span className='ml-2' style={{ textDecoration: 'underline' }} onClick={() => { this.setState({ showSizes: true }) }}>Select Available Sizes</span>}
                                        </div>
                                        {this.state.showSizes && <ul style={{ listStyleType: 'none' }}>
                                            {allTshirtSizes.map(size => <li>
                                                <div className='row'>
                                                    <input
                                                        style={{ height: "20px", width: "20px" }}
                                                        type="checkbox"
                                                        className="form-control-input cursor-pointer"
                                                        checked={this.state.tshirtSizes.includes(size)}
                                                        onChange={(e) => {
                                                            let tshirtSizes = this.state.tshirtSizes;
                                                            if (e.target.checked) {
                                                                tshirtSizes.push(size)
                                                            } else {
                                                                tshirtSizes.splice(tshirtSizes.indexOf(size), 1);
                                                            }
                                                            this.setState({ tshirtSizes });
                                                        }}
                                                    />
                                                    <span className='ml-2'>{size}</span>
                                                </div>
                                            </li>)}
                                        </ul>}
                                    </div>
                                </div>
                            </div> */}
                          </div>
                        ) : (
                          <div className="row mt-2">
                            <div className="col-md-4">
                              <div className="ml-5">Select options</div>
                            </div>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-4"></div>
                                <ul
                                  style={{
                                    listStyleType: "none",
                                    paddingInlineStart: 0,
                                  }}
                                >
                                  {filteredStoreItems.map((item) => (
                                    <li>
                                      <div className="d-flex">
                                        <input
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                          type="checkbox"
                                          onChange={(e) => {
                                            let products = this.state.products;
                                            if (products.includes(item.id)) {
                                              products.splice(
                                                products.indexOf(item.id),
                                                1
                                              );
                                            } else {
                                              products.push(item.id);
                                            }

                                            this.setState({ products });
                                          }}
                                          checked={this.state.products.includes(
                                            item.id
                                          )}
                                        />
                                        <span className="ml-2">
                                          {item.name}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <h6 style={{ color: "#ff0000", fontWeight: "bold" }}>
                          Important:
                        </h6>
                        <p>
                          Chant does not block members from purchasing a member
                          package based on inventory level (set in the Store) of
                          ‘included’ items.
                        </p>

                        <p>Please track inventory levels accordingly.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col"
            style={{ borderTop: "1px solid #dddddd", paddingTop: 24 }}
          >
            <div className="d-flex" style={{ justifyContent: "flex-end" }}>
              <div className="d-flex" style={{ gap: 20 }}>
                <Button
                  onClick={() => {
                    this.props.close();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    this.savePackage();
                  }}
                >
                  {this.state.saving ? (
                    <Spin size="small" spinning={true} />
                  ) : (
                    <span style={{ color: "#ffffff" }}>Submit</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  let adminData = state.adminData.allGroups[currentGroup];
  let { groupName } = state.adminData.allGroups[currentGroup].data;
  return {
    currentGroup,
    adminData,
    groupName,
  };
};
export default connect(mapStateToProps)(MemberPackageForm);
