import { db, storage } from "../connection";
import { fbCreateAdminForGroup } from "./authentication";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";
import Axios from "axios";
import {
  FieldValue,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";
import logger from "../../../utils/logger";

// End point for Super Admin

/*------- CREATE -------*/

/* Create a new group, first upload group logo then insert the record in database
 *  this function also sets up some boilerplate like registered members = 0.
 */
export function fbCreateGroup(data, logoFile, admins) {
  return new Promise(async (resolve, reject) => {
    const batch = writeBatch(db);
    const groupDocRef = data.groupType
      ? data.groupType.toLowerCase() === "club"
        ? doc(db, "group", `${data.clubId}_group`)
        : doc(collection(db, "group"))
      : doc(collection(db, "group"));

    let downloadUrl;
    // create group logo
    try {
      downloadUrl = await fbUploadGroupLogo(logoFile, groupDocRef.id);
    } catch (err) {
      let message =
        "Error uploading group logo, operation could not be completed, contact admin";
      store.dispatch(
        applicationError({ err, message, show: true, intensity: "mid" })
      );
      reject({ message });
      return;
    }

    data.configuration.logo = downloadUrl;
    data.registeredMembers = 0;
    data.membershipCard = "";
    data.createdOn = parseInt(Date.now() / 1000);
    // data.admins.forEach((admin) => {
    //     admin.id = ""
    // })
    // insert document into database
    const leaguesRef = doc(db, "leagues", data.leagueId);
    batch.set(leaguesRef, { name: data.leagueName });

    if (admins.length > 0) {
      let promises = [];
      admins.forEach((admin) => {
        if (admin.email) {
          let promise = fbCreateAdminForGroup(admin.email, groupDocRef.id, {
            name: admin.name,
            phoneNumber: admin.phoneNumber,
            type: admin.adminType,
          })
            .then(() => {
              // msg()
              // message.success(`Invite sent to ${email}`)
            })
            .catch((err) => {
              // msg()
              // this.setState({ errorInForm: { isError: true, visible: true, message: err.message } })
              // window.scrollTo(0, 0)
            });
          promises.push(promise);
        }
      });

      await Promise.all(promises);
    }

    if (data.isParent) {
      let children = [];
      if (data.children.includes("All")) {
        children = await fbGetGroupsForClub(data.clubId).then((docs) => {
          return docs.map((doc) => doc.id);
        });
      } else if (data.children.includes("Official Groups")) {
        children = await getDocs(
          query(
            collection(db, "group"),
            where("clubId", "==", data.clubId),
            where("groupType", "==", "Official Supporters Group")
          )
        ).then((snap) => {
          let res = [];
          snap.docs.forEach((doc) => {
            res.push(doc.id);
          });
          return res;
        });
      } else {
        children = data.children;
      }

      children.forEach(async (id) => {
        let docRef = doc(db, "group", id);
        let parents = await getDoc(docRef).then((doc) => {
          if (doc.data().parents) {
            return doc.data().parents;
          } else {
            return [];
          }
        });
        parents = Array.from(new Set([...parents, groupDocRef.id]));
        await updateDoc(docRef, { parents });
      });
    } else {
      delete data.children;
    }

    batch.set(groupDocRef, data);
    batch
      .commit()
      .then(() => {
        resolve({ ...data, id: groupDocRef.id });

        Axios.get(
          `https://us-central1-chant2019.cloudfunctions.net/addMatches?groupId=${groupDocRef.id}&session=${data.season}`
        );
      })
      .catch((err) => {
        let message = "Error entering group details in database, contact admin";
        store.dispatch(
          applicationError({ err, message, show: true, intensity: "high" })
        );
        reject({ message });
      });
  });
}

/*-------- READ ---------*/

// Get all groups without skip or limit
export async function fbGetAllGroupDetails() {
  let error = "";

  let groups = await getDocs(collection(db, "group"))
    .then((querySnapshot) => {
      let result = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        result.push(data);
      });
      return result;
    })
    .catch((err) => {
      let message = "Error fetching groups, contact admin";
      store.dispatch(
        applicationError({ err, message, show: true, intensity: "mid" })
      );
      error = message;
      return [];
    });

  return groups;
}

export async function fbGetGroups() {
  let error = "";

  let groups = await getDocs(collection(db, "group"))
    .then((querySnapshot) => {
      let result = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        result.push(data);
      });
      return result;
    })
    .catch((err) => {
      let message = "Error fetching groups, contact admin";
      store.dispatch(
        applicationError({ err, message, show: true, intensity: "mid" })
      );
      error = message;
      return [];
    });

  let websiteDataPromises = groups.map((group) => {
    return getDoc(doc(db, "website", group.id)).then((doc) => {
      if (doc.exists()) {
        let data = doc.data();
        let websitePublished = data.isPublished;
        let stadiumPublished = data.stadium && data.stadium.isPublished;
        return {
          ...group,
          websitePublished: websitePublished,
          stadiumPublished: stadiumPublished,
          directoryPublished: websitePublished,
        };
      } else {
        return {
          ...group,
          websitePublished: false,
          stadiumPublished: false,
          directoryPublished: false,
        };
      }
    });
  });

  groups = await Promise.all(websiteDataPromises);

  let registeredMembersPromises = groups.map((group) => {
    return getDocs(collection(db, "userGroup", group.id, "members")).then(
      (snap) => {
        let membershipNumbers = new Set();
        for (let userGroupDoc of snap.docs) {
          let data = userGroupDoc.data();
          if (
            data.membershipNumber &&
            parseInt(`0000${data.membershipNumber}`.slice(-5)) < 50000
          ) {
            membershipNumbers.add(data.membershipNumber);
          }
        }
        let membershipNumbersAsArray = Array.from(membershipNumbers);
        membershipNumbersAsArray.sort((a, b) =>
          `00000${b}`.slice(-5).localeCompare(`00000${a}`.slice(-5))
        );
        let lastMembershipNumberUserGroup = membershipNumbersAsArray[0];
        return {
          ...group,
          registeredMembers: snap.docs.length,
          lastMembershipNumberUserGroup,
        };
      }
    );
  });

  groups = await Promise.all(registeredMembersPromises);

  let preRegMembersPromises = groups.map((group) => {
    return getDoc(doc(db, "invite_emails", group.id)).then((doc) => {
      let preRegisteredMemberCount = doc.data() ? doc.data().emails.length : 0;
      return {
        ...group,
        preRegisteredMemberCount,
        totalMembers: group.registeredMembers + preRegisteredMemberCount,
      };
    });
  });

  groups = await Promise.all(preRegMembersPromises);

  let lastMembershipNumberInviteEmailsPromise = groups.map((group) => {
    return getDocs(
      collection(db, "invite_emails", group.id, "userDetails")
    ).then((snap) => {
      let membershipNumbers = new Set();
      for (let userDetailDoc of snap.docs) {
        let data = userDetailDoc.data();
        if (
          data.membershipNumber &&
          parseInt(`0000${data.membershipNumber}`.slice(-5)) < 50000
        ) {
          membershipNumbers.add(data.membershipNumber);
        }
      }
      let membershipNumbersAsArray = Array.from(membershipNumbers);
      membershipNumbersAsArray.sort((a, b) =>
        `00000${b}`.slice(-5).localeCompare(`00000${a}`.slice(-5))
      );
      let lastMembershipNumberInviteEmails = membershipNumbersAsArray[0];
      return {
        ...group,
        lastMembershipNumberInviteEmails,
      };
    });
  });

  groups = await Promise.all(lastMembershipNumberInviteEmailsPromise);

  let adminPromises = groups.map((group) => {
    return fbGetAdminsForGroup(group.id).then((admins) => {
      return { ...group, admins };
    });
  });

  groups = await Promise.all(adminPromises);

  return new Promise((resolve, reject) => {
    if (error) {
      reject(error);
    } else {
      resolve(groups);
    }
  });
}

// Get a paticular group by id, used in edit group
export function fbGetGroup(id) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "group", id))
      .then((querySnapshot) => {
        resolve(querySnapshot.data());
      })
      .catch((err) => {
        let message = "Error fetching group details, contact admin";
        store.dispatch(
          applicationError({ err, message, show: true, intensity: "mid" })
        );
        reject({ message });
      });
  });
}

export function fbGetAdminsForGroup(groupId) {
  return new Promise((resolve, reject) => {
    let registeredAdmins = [],
      unRegisteredAdmins = [];
    let promise1, promise2;
    promise1 = getDocs(
      query(
        collection(db, "userGroup", groupId, "members"),
        where("userRole", "in", ["admin", "parentAdmin"])
      )
    )
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.docs.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            registeredAdmins.push(data);
          });
        }
        return Promise.resolve(registeredAdmins);
      })
      .catch((err) => {
        return Promise.resolve();
      });

    promise2 = getDocs(
      query(collection(db, "admins"), where(`${[groupId]}.role`, "==", "admin"))
    )
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.docs.forEach((doc) => {
            let data = {};
            data.name = "".concat("(pre) ", doc.data()[groupId].name);
            data.phoneNumber = doc.data()[groupId].phoneNumber;
            data.id = doc.id;
            unRegisteredAdmins.push(data);
          });
        }
        return Promise.resolve(unRegisteredAdmins);
      })
      .catch((err) => {
        return Promise.resolve();
      });

    Promise.all([promise1, promise2]).then((values) => {
      resolve(values);
    });
  });
}

/*-------- UPDATE -------*/

/* Update a paticular group. if logoFile is provided then logo image is replaced first
 *  followed by document update. Merge is set to true, because we are not changing
 *  configuration.logo after creation.
 */
export function fbUpdateGroup(data, id, logoFile) {
  return new Promise(async (resolve, reject) => {
    const batch = writeBatch(db);
    const groupRef = doc(db, "group", id);
    // fbCreateAdminForGroup(data.admins[0].email, id)

    if (logoFile) {
      let streamRef = doc(db, "stream_lookup", id);
      let streamDoc = await getDoc(streamRef);
      try {
        const downloadUrl = await fbUploadGroupLogo(logoFile, id);
        data.configuration.logo = downloadUrl;
        if (streamDoc.exists()) {
          let streamData = streamDoc.data();
          let updatedData = JSON.parse(JSON.stringify(streamData));
          let key = Object.keys(updatedData["look_up_map"]).find((key) =>
            key.includes(id)
          );
          if (key) {
            updatedData["look_up_map"][key]["logo"] = downloadUrl;
            batch.update(streamRef, updatedData);
          }
        }
      } catch (err) {
        logger.error(err);
        let message = "Error updating group logo, contact admin";
        store.dispatch(
          applicationError({
            err,
            message,
            intensity: "high",
            errorCode: "firebase",
          })
        );
        reject({ message });
        return;
      }
    }

    if (data.clubId) {
      let currentChildren = await getDoc(groupRef).then((doc) => {
        if (doc.data().children) {
          return doc.data().children;
        } else {
          return [];
        }
      });

      if (currentChildren.length > 0) {
        if (currentChildren.includes("All")) {
          currentChildren = await fbGetGroupsForClub(data.clubId).then(
            (docs) => {
              return docs
                .map((doc) => doc.id)
                .filter((id) => id !== groupRef.id);
            }
          );
        } else if (currentChildren.includes("Official Groups")) {
          currentChildren = await getDocs(
            query(
              collection(db, "group"),
              where("clubId", "==", data.clubId),
              where("groupType", "==", "Official Supporters Group")
            )
          ).then((snap) => {
            let res = [];
            snap.docs.forEach((doc) => {
              res.push(doc.id);
            });
            return res.filter((id) => id !== groupRef.id);
          });
        }

        currentChildren.forEach(async (id) => {
          let docRef = doc(db, "group", id);
          let parents = await getDoc(docRef).then((doc) => {
            if (doc.data().parents) {
              return doc.data().parents;
            } else {
              return [];
            }
          });
          parents = parents.filter((id) => id === groupRef.id);
          await updateDoc(docRef, { parents });
        });
      }

      if (data.isParent) {
        let children = [];
        if (data.children.includes("All")) {
          children = await fbGetGroupsForClub(data.clubId).then((docs) => {
            return docs.map((doc) => doc.id).filter((id) => id !== groupRef.id);
          });
        } else if (data.children.includes("Official Groups")) {
          children = await getDocs(
            query(
              collection(db, "group"),
              where("clubId", "==", data.clubId),
              where("groupType", "==", "Official Supporters Group")
            )
          ).then((snap) => {
            let res = [];
            snap.docs.forEach((doc) => {
              res.push(doc.id);
            });
            return res.filter((id) => id !== groupRef.id);
          });
        } else {
          children = data.children;
        }

        children.forEach(async (id) => {
          let docRef = doc(db, "group", id);
          let parents = await getDoc(docRef).then((doc) => {
            if (doc.data().parents) {
              return doc.data().parents;
            } else {
              return [];
            }
          });
          parents = Array.from(new Set([...parents, groupRef.id]));
          await updateDoc(docRef, { parents });
        });
      } else {
        delete data.children;
        await updateDoc(groupRef, {
          children: deleteField(),
        });
      }
    }

    // const leaguesRef = db.collection("leagues").doc(data.leagueId)
    // batch.set(leaguesRef, { name: data.leagueName })
    let websiteUpdate = {
      clubName: data.clubName || "",
      leagueName: data.leagueName || "",
      groupName: data.groupName || "",
      country: data.country || "",
    };
    if (data.clubId) {
      websiteUpdate.clubId = data.clubId;
    }
    if (data.leagueId) {
      websiteUpdate.leagueId = data.leagueId;
    }
    let websiteRef = doc(db, "website", id);
    await getDoc(websiteRef).then((doc) => {
      if (doc.exists()) {
        let updates = {
          ...websiteUpdate,
          configuration: {
            ...((doc.data() || {}).configuration || {}),
            ...data.configuration,
          },
        };
        batch.update(websiteRef, updates);
      }
    });
    batch.set(groupRef, data, { merge: true });
    batch
      .commit()
      .then(() => {
        resolve({ ...data, id: groupRef.id });
      })
      .catch((err) => {
        logger.error(err);
        let message = "Error updating group details, contact admin";
        store.dispatch(
          applicationError({
            err,
            message,
            intensity: "high",
            errorCode: "firebase",
          })
        );
        reject({ message });
        return;
      });
  });
}

// To update the tickets & match predictions on league change from super admin dashboard
export function fbUpdateMatchPredictionAndTickets(id, clubId) {
  return new Promise(async (resolve, reject) => {
    // to update match predictions
    Axios({
      method: "post",
      url: "https://us-central1-chant2019.cloudfunctions.net/updateMatchPredictions",
      data: {
        groupId: id,
        clubId: clubId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        logger.error(err);
        reject({ err });
        return;
      });

    // to update tickets
    Axios.get(
      `https://us-central1-chant2019.cloudfunctions.net/addMatches?groupId=${id}`
    )
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        logger.error(err);
        reject({ err });
        return;
      });
  });
}

// Upload's a image to storage to groups/logo/(id)
export function fbUploadGroupLogo(file, id) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    const folderRef = ref(storageRef, `groups/logo/${id}`);

    uploadBytes(folderRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((downloadUrl) => {
        resolve(downloadUrl);
      })
      .catch(reject);
  });
}

export function fbGetGroupsForClub(clubId) {
  return new Promise((resolve, reject) => {
    getDocs(query(collection(db, "group"), where("clubId", "==", clubId)))
      .then((snap) => {
        let res = [];
        snap.docs.forEach((doc) => {
          res.push({ id: doc.id, ...doc.data() });
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function fetchTransactions(groupId, groupDetails) {
  let transactionData = [];
  await getDocs(
    query(
      collection(db, "transaction_record", groupId, "records"),
      where("status", "==", "paid"),
      where("type", "!=", "store-purchase")
    )
  ).then((snap) => {
    snap.docs.forEach((doc) => {
      let paymentIntentId = doc.id;
      let data = doc.data();
      let totalAmount = data.amount;
      let email = data.email;
      let name = data.name;
      let status = data.status;
      let timestamp = data.timestamp;
      let paymentType = data.paymentType || data.type;
      let customerDocId = data.customerDocId;
      transactionData.push({
        paymentIntentId,
        totalAmount,
        email,
        name,
        status,
        timestamp,
        paymentType,
        customerDocId,
        ...groupDetails,
      });
    });
  });

  return transactionData;
}

export async function fetchGroupsForReview() {
  let groups = await getDocs(collection(db, "group"))
    .then(async (querySnapshot) => {
      let result = [];
      for (var groupDoc of querySnapshot.docs) {
        let data = groupDoc.data();
        if (data.fromDirectory) {
          data.id = groupDoc.id;
          let websiteData = await getDoc(doc(db, "website", groupDoc.id)).then(
            (websiteDoc) => {
              let data = {};
              if (websiteDoc.exists()) {
                data = websiteDoc.data();
              }
              return data;
            }
          );
          data.websiteData = websiteData;
          result.push(data);
        }
      }
      return result;
    })
    .catch((err) => {
      let message = "Error fetching groups, contact admin";
      store.dispatch(
        applicationError({ err, message, show: true, intensity: "mid" })
      );
      return [];
    });

  return groups;
}

export async function updateReviewStatus(groupId, status) {
  const statusBoolean = status === "Live";

  getDoc(doc(db, "group", groupId)).then((doc) => {
    if (doc.exists()) {
      updateDoc(doc.ref, {
        isLive: statusBoolean,
      });
    }
  });

  getDoc(doc(db, "website", groupId)).then((doc) => {
    if (doc.exists()) {
      updateDoc(doc.ref, {
        showGroup: statusBoolean,
      });
    }
  });
}

export async function addNote(note, groupId) {
  return getDoc(doc(db, "group", groupId)).then((doc) => {
    if (doc.exists()) {
      updateDoc(doc.ref, {
        notes: note,
      });
    }
  });
}
