import { DatePicker, Select } from "antd";
import React from "react";
import { getImages } from "../../../services/firebaseService/endPoints/clubAdmin/gallery";
import Search from "../../commons/Search";
import ImageViewer from "./ImageViewer";
import { connect } from "react-redux";
const { MonthPicker } = DatePicker;

class MonthRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start: null,
      end: null,
      endOpen: false,
    };
  }

  handleChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleStartChange = (value) => {
    if (value) {
      this.handleChange("start", { month: value.month(), year: value.year() });
    } else {
      this.props.onChange(null, null);
    }
  };

  handleEndChange = (value) => {
    if (value) {
      this.handleChange("end", { month: value.month(), year: value.year() });
    } else {
      this.props.onChange(null, null);
    }
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({
        endOpen: true,
      });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({
      endOpen: open,
    });
  };

  componentDidUpdate() {
    const { start, end } = this.state;

    if (start !== null && end !== null) {
      this.props.onChange(start, end);
      this.setState({
        start: null,
        end: null,
      });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          border: "1px solid #bfbfbf",
          borderRadius: 5,
          margin: "2vh 2vw",
        }}
      >
        <MonthPicker
          placeholder="Start"
          size="default"
          onChange={this.handleStartChange}
          onOpenChange={this.handleStartOpenChange}
        />

        <MonthPicker
          placeholder="End"
          size="default"
          open={this.state.endOpen}
          onChange={this.handleEndChange}
          onOpenChange={this.handleEndOpenChange}
        />
      </div>
    );
  }
}

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageData: [],
      grouping: "date",
      filteredData: [],
      filterParams: [],
      dateRange: {
        start: null,
        end: null,
      },
      fullViewImage: null,
    };
  }

  fetchImagesWithMetadata = () => {
    getImages().then((res) => {
      this.setState({
        imageData: res,
        filteredData: res,
      });
    });
  };

  handleImageClick = (data) => {
    this.setState({ fullViewImage: { src: data.imageUrl, ...data } });
  };

  renderImages = () => {
    let nodes = [];
    let month = "";
    let year = 0;
    let internalNodes = [];
    let groupName = "";

    if (this.state.grouping === "date") {
      const images = this.state.filteredData.sort((data1, data2) => {
        let date1 = new Date(data1.uploadDate * 1000).getTime();
        let date2 = new Date(data2.uploadDate * 1000).getTime();

        return date2 - date1;
      });
      images.forEach((data) => {
        let date = new Date(data.uploadDate * 1000);
        let newMonth = date.toLocaleDateString("default", { month: "long" });
        let newYear = date.getFullYear();

        if (!(month || year)) {
          month = newMonth;
          year = newYear;
        }

        if (
          (month !== newMonth || year !== newYear) &&
          internalNodes.length > 0
        ) {
          nodes.push(
            <>
              <span style={{ fontWeight: "bold" }}>{`${month}, ${year}`}</span>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {[...internalNodes]}
              </div>
            </>
          );

          internalNodes = [
            <div
              style={{
                height: 130,
                width: 170,
                margin: 15,
                backgroundImage: `url(${data.imageUrl})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
              }}
              onClick={this.handleImageClick.bind(this, data)}
            ></div>,
          ];
          month = newMonth;
          year = newYear;
        } else {
          internalNodes.push(
            <div
              style={{
                height: 130,
                width: 170,
                margin: 15,
                backgroundImage: `url(${data.imageUrl})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
              }}
              onClick={this.handleImageClick.bind(this, data)}
            ></div>
          );
        }
      });

      if (month && year) {
        nodes.push(
          <>
            <span style={{ fontWeight: "bold" }}>{`${month}, ${year}`}</span>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {[...internalNodes]}
            </div>
          </>
        );
      }
    } else {
      let images = this.state.filteredData.sort((data1, data2) => {
        let [groupName1, groupName2] = [data1.groupName, data2.groupName];
        return groupName1.localeCompare(groupName2);
      });

      images.forEach((data) => {
        let newGroupName = data.groupName;

        if (!groupName) {
          groupName = newGroupName;
        }

        if (groupName !== newGroupName) {
          nodes.push(
            <>
              <span style={{ fontWeight: "bold" }}>{`${groupName}`}</span>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {[...internalNodes]}
              </div>
            </>
          );

          internalNodes = [
            <div
              style={{
                height: 130,
                width: 170,
                margin: 15,
                backgroundImage: `url(${data.imageUrl})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
              }}
              onClick={this.handleImageClick.bind(this, data)}
            ></div>,
          ];
          groupName = newGroupName;
        } else {
          internalNodes.push(
            <div
              style={{
                height: 130,
                width: 170,
                margin: 15,
                backgroundImage: `url(${data.imageUrl})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
              }}
              onClick={this.handleImageClick.bind(this, data)}
            ></div>
          );
        }
      });

      if (groupName) {
        nodes.push(
          <>
            <span style={{ fontWeight: "bold" }}>{`${groupName}`}</span>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {[...internalNodes]}
            </div>
          </>
        );
      }
    }

    return nodes;
  };

  handleDropdownSelect = (value) => {
    this.setState({ grouping: value });
  };

  handleMonthRangeChange = (start, end) => {
    let data = [];

    this.state.filterParams.forEach((param) => {
      data.push(
        ...this.state.imageData.filter(
          (item) =>
            item.groupName.toLowerCase() === param.toLowerCase() &&
            !data.includes(item)
        )
      );
    });

    if (this.state.filterParams.length === 0) {
      data = this.state.imageData;
    }

    if (start && end) {
      data = data.filter((data) => {
        let date = new Date(data.uploadDate * 1000);
        let month = date.getMonth();
        let year = date.getFullYear();

        let totalMonths = year * 12 + month;

        return (
          totalMonths >= start.year * 12 + start.month &&
          totalMonths <= end.year * 12 + end.month
        );
      });
    }

    this.setState({
      filteredData: data,
      dateRange: {
        start,
        end,
      },
    });
  };

  handleSearchParamChange = (list) => {
    let data = [];

    list.forEach((param) => {
      data.push(
        ...this.state.imageData.filter(
          (item) =>
            item.groupName.toLowerCase() === param.toLowerCase() &&
            !data.includes(item)
        )
      );
    });

    if (list.length === 0) {
      data = this.state.imageData;
    }

    let { start, end } = this.state.dateRange;

    if (start && end) {
      data = data.filter((data) => {
        let date = new Date(data.uploadDate * 1000);
        let month = date.getMonth();
        let year = date.getFullYear();

        let totalMonths = year * 12 + month;

        return (
          totalMonths >= start.year * 12 + start.month &&
          totalMonths <= end.year * 12 + end.month
        );
      });
    }

    this.setState({
      filteredData: data,
      filterParams: list,
    });
  };

  showNext = () => {
    let nextImageIndex = this.state.filteredData
      .map((image, index) => {
        if (image.imageUrl === this.state.fullViewImage.imageUrl) {
          return index + 1;
        }
        return null;
      })
      .filter((index) => index !== null)[0];

    this.setState({
      fullViewImage:
        this.state.filteredData[
          nextImageIndex === this.state.filteredData.length ? 0 : nextImageIndex
        ],
    });
  };

  showPrev = () => {
    let prevImageIndex = this.state.filteredData
      .map((image, index) => {
        if (image.imageUrl === this.state.fullViewImage.imageUrl) {
          return index - 1;
        }
        return null;
      })
      .filter((index) => index !== null)[0];

    this.setState({
      fullViewImage:
        this.state.filteredData[
          prevImageIndex < 0
            ? this.state.filteredData.length - 1
            : prevImageIndex
        ],
    });
  };

  handleReturn = () => {
    this.setState({
      fullViewImage: null,
    });
  };

  handleKeyPress = (e) => {
    let key = e.keyCode;
    switch (key) {
      case 37:
        this.showPrev();
        break;
      case 39:
        this.showNext();
        break;
      default:
        return;
    }
  };

  render() {
    const options = [
      { label: "By Group", value: "group" },
      { label: "By Date", value: "date" },
    ];

    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <>
        {!!this.state.fullViewImage ? (
          <div
            className="mx-auto"
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 20,
              }}
            >
              <div
                style={{
                  height: 25,
                  width: 25,
                  borderLeft: "2px solid #000000",
                  borderBottom: "2px solid #000000",
                  transform: "rotate( 45deg )",
                  marginRight: 20,
                  cursor: "pointer",
                }}
                onClick={this.showPrev}
              ></div>
            </div>
            <ImageViewer
              image={this.state.fullViewImage}
              onReturn={this.handleReturn}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 20,
              }}
            >
              <div
                style={{
                  height: 25,
                  width: 25,
                  borderTop: "2px solid #000000",
                  borderRight: "2px solid #000000",
                  transform: "rotate( 45deg )",
                  marginRight: 20,
                  cursor: "pointer",
                }}
                onClick={this.showNext}
              ></div>
            </div>
          </div>
        ) : (
          <div
            className="mx-auto"
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                paddingTop: 30,
              }}
            >
              <ul
                className="nav nav-tabs"
                style={{ color: "#ffffff", position: "relative" }}
              >
                <li
                  className="nav-item"
                  style={{ borderBottom: `4px solid ${primaryColor}` }}
                >
                  <a
                    style={{
                      height: "100%",
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                    name="current"
                  >
                    Gallery
                  </a>
                </li>
              </ul>
            </div>
            <div className="row">
              <Search
                options={Array.from(
                  new Set(this.state.imageData.map((data) => data.groupName))
                )}
                onSearchParamsChange={this.handleSearchParamChange}
                placeholder="Search by group"
              />
              <MonthRangePicker onChange={this.handleMonthRangeChange} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              ></div>
              <Select
                onChange={this.handleDropdownSelect}
                value={this.state.grouping}
                style={{ width: 150 }}
              >
                {options.map((option, index) => (
                  <Select.Option key={index} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {this.renderImages()}
            </div>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.fetchImagesWithMetadata();
  }

  componentDidUpdate() {
    if (this.state.fullViewImage !== null) {
      window.addEventListener("keydown", this.handleKeyPress);
    } else {
      window.addEventListener("keydown", () => {});
    }
  }
}

const mapStateToProps = (state) => {
  return {
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(Gallery);
