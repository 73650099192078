import React, { Component } from "react";

export default class FeatureBadge extends Component {
  render() {
    const { type } = this.props;

    return (
      <>
        <img
          alt={type === "new" ? "new" : "beta"}
          src={
            type === "new"
              ? require("../../images/new-feat.png")
              : require("../../images/beta-feat.png")
          }
          height={15}
        />
      </>
    );
  }
}
