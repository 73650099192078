export const selectCurrentGroupData = (state) =>
  state?.adminData?.allGroups?.[state?.adminData?.currentGroup]?.data ||
  undefined;

export const selectGettingStartedExpanded = (state) =>
  state?.adminData?.gettingStartedExpanded || false;

export const selectGettingStartedItemStatus = (state) =>
  state?.adminData?.allGroups?.[state?.adminData?.currentGroup]?.data
    ?.gettingStarted || {};

export const selectAllGroups = (state) => state?.groups;
