import React from "react";

class Membership extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="form-group p-2 col-7 border rounded">
            <h6 className="m-2">List of groups</h6>
            <ul className="list-group p-2">
              <li className="list-group-item d-flex">
                <span className="mr-auto">Footie Mob</span>
                <span>Renewal - 01/01/2020</span>
              </li>
              <li className="list-group-item d-flex">
                <span className="mr-auto">Footie Mob</span>
                <span>Renewal - 01/01/2020</span>
              </li>
              <li className="list-group-item d-flex">
                <span className="mr-auto my-auto">Footie Mob</span>
                <div className="d-flex flex-column">
                  <button type="button" class="btn btn-danger">
                    Make Payment
                  </button>
                  <span>Expired on - 31/12/2019</span>
                </div>
              </li>
              <li className="list-group-item d-flex">
                <span className="mr-auto">Footie Mob</span>
                <span>Renewal - 01/01/2020</span>
              </li>
            </ul>
          </div>
          <div className="form-group p-2 col-5 border rounded">
            <h6 className="m-2">Join new Group</h6>
            <form className="p-3">
              <div className="">
                <div className="form-group">
                  <label>Group</label>
                  <select className="form-control">
                    <option>--Select Group--</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Registeration Code</label>
                  <input className="form-control" />
                </div>

                <div className="row justify-content-end">
                  <button className="btn btn-dark">Join</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Membership;
