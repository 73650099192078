const initialValue = { value: "" };
const pathReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "UPDATE_PATH":
      return { value: action.payload };
    default:
      return { ...state };
  }
};

export default pathReducer;
