const initialState = {
  status: "process",
  records: undefined,
  date: "",
  connection: "",
};

function groupReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_GROUPS":
      return { ...state, ...action.payload };
    case "UPDATE_SUPERADMIN":
      return { ...state, connection: action.payload };
    default:
      return { ...state };
  }
}

export default groupReducer;
