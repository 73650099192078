export const isMobile = () => {
  const userAgent = window?.navigator?.userAgent?.toLowerCase();
  const mobileRegex =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

  const isNarrowScreen = window?.innerWidth <= 768;

  return mobileRegex?.test(userAgent) || isNarrowScreen;
};

export const getPrimaryColor = (data) => {
  let { r, g, b } = data;
  return `rgb(${r}, ${g}, ${b})`;
};
