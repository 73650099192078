import Axios from "../connection";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";

export const afGetCountries = () => {
  return new Promise((resolve, reject) => {
    Axios.get("/countries")
      .then((response) => {
        resolve(response.data.response);
      })
      .catch((err) => {
        store.dispatch(
          applicationError({
            err,
            message: "Unable to fetch country details",
            errorCode: "api-football",
          }),
        );
      });
  });
};
