const initialValue = {
  currentGroup: "",
  allGroups: {
    test: {
      currentContents: { status: "process", records: undefined },
      pastContents: { status: "process", records: undefined },
      members: { status: "process", records: undefined },
    },
  },
  connection: undefined,
  dashboardType: "admin",
  gettingStartedExpanded: false,
};
const adminDataReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "UPDATE_ADMIN_GROUPS":
      let allGroups = {};
      action.payload.groups.forEach((group) => {
        allGroups[group.id] = {
          currentContents: { status: "process", records: undefined },
          pastContents: { status: "process", records: undefined },
          members: { status: "process", records: undefined },
          data: group,
        };
      });
      let groupNameKeyMap = {};
      Object.entries(allGroups).forEach(([key, group]) => {
        groupNameKeyMap[group.data.groupName] = key;
      });

      let groupName = Object.keys(groupNameKeyMap).sort((name1, name2) =>
        name1.localeCompare(name2)
      )[0];
      return {
        allGroups: allGroups,
        currentGroup:
          window.localStorage.getItem("currentGroup") ||
          state.currentGroup ||
          groupNameKeyMap[groupName],
        connection: action.payload.connection,
      };

    case "UPDATE_CONTENTS": {
      let data = { ...state };
      data.allGroups[action.payload.groupId][`${action.payload.tab}Contents`] =
        {
          status: action.payload.status,
          records: action.payload.records,
          date: action.payload.date,
        };
      return data;
    }

    case "UPDATE_TICKETS": {
      let data = { ...state };

      data.allGroups[action.payload.groupId][`${action.payload.tab}Tickets`] = {
        status: action.payload.status,
        records: action.payload.records,
        date: action.payload.date,
      };
      return data;
    }

    case "UPDATE_MEMBERS": {
      let data = { ...state };
      data.allGroups[action.payload.groupId].members = {
        status: action.payload.status,
        records: action.payload.records,
        date: action.payload.date,
      };
      return data;
    }
    case "GROUP_CHANGE":
      return {
        allGroups: state.allGroups,
        connection: state.connection,
        currentGroup: action.payload.group,
        dashboardType: action.payload.dashboard,
      };
    case "DASHBOARD_CHANGE":
      let data = { ...state };
      data.dashboardType = action.payload;
      return data;
    case "TRIGGER_CONTENTS_UPDATE":
      return { ...state, isCurrent: false };

    case "SET_GETTING_STARTED_EXPANDED": {
      let data = { ...state };
      data.gettingStartedExpanded = action.payload;
      return data;
    }

    case "SET_GETTING_STARTED_ITEM_STATUS": {
      let data = { ...state };
      const { groupId, guideId, topicId, value } = action.payload;
      let groupData = data?.allGroups?.[groupId]?.data;

      if (value !== groupData?.gettingStarted?.[guideId]?.topics?.[topicId]) {
        let newCompletedCount;
        let newTotalCompletedCount;

        const currentCompletedCount =
          groupData?.gettingStarted?.[guideId]?.completedItems || 0;

        const totalCompletedCount =
          groupData?.gettingStarted?.totalCompletedItems || 0;

        newCompletedCount = value
          ? currentCompletedCount + 1
          : currentCompletedCount - 1;

        newTotalCompletedCount = value
          ? totalCompletedCount + 1
          : totalCompletedCount - 1;

        if (groupData) {
          groupData = {
            ...groupData,
            gettingStarted: {
              ...groupData?.gettingStarted,
              totalCompletedItems: newTotalCompletedCount,
              [guideId]: {
                ...groupData?.gettingStarted?.[guideId],
                completedItems: newCompletedCount,
                topics: {
                  ...groupData?.gettingStarted?.[guideId]?.topics,
                  [topicId]: value,
                },
              },
            },
          };
        }

        data.allGroups[groupId].data = groupData;
      }

      return data;
    }

    case "LOGOUT_USER":
      return { ...initialValue };
    default:
      return { ...state };
  }
};

export default adminDataReducer;
