import { Spin } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { db } from "../../../services/firebaseService/connection";
import {
  changeShowOnWeb,
  getImages,
} from "../../../services/firebaseService/endPoints/admin/gallery";
import { MonthRangePicker } from "../gallery/Gallery";
import { doc, getDoc, setDoc } from "firebase/firestore";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageData: [],
      filteredData: [],
      dateRange: {
        start: null,
        end: null,
      },
      tab: "all",
      galleryIntro: "",
      showAllPublicPhotos: false,
      isSaving: false,
    };
  }

  handleMonthRangeChange = (start, end) => {
    let data = this.state.filteredData;

    if (start && end) {
      data = data.filter((data) => {
        let date = new Date(data.uploadDate * 1000);
        let month = date.getMonth();
        let year = date.getFullYear();

        let totalMonths = year * 12 + month;

        return (
          totalMonths >= start.year * 12 + start.month &&
          totalMonths <= end.year * 12 + end.month
        );
      });

      this.setState({
        filteredData: data,
        dateRange: {
          start,
          end,
        },
      });
    } else {
      data = this.state.imageData;
      this.setState({
        filteredData: data,
        dateRange: {
          start: null,
          end: null,
        },
      });
    }
  };

  handleShowOnWeb = (show, id) => {
    changeShowOnWeb(show, id, this.props.currentGroup).then(() => {
      let images = this.state.imageData;
      let filteredImages = this.state.filteredData;
      images.find((image) => image.id === id).showOnWeb = show;
      filteredImages.find((image) => image.id === id).showOnWeb = show;

      this.setState({
        imageData: images,
        filteredData: filteredImages,
      });
    });
  };

  renderImages = () => {
    let nodes = [];
    let month = "";
    let year = 0;
    let internalNodes = [];

    let images = this.state.filteredData.sort((data1, data2) => {
      let date1 = moment.unix(data1.imageDate || 0).unix();
      let date2 = moment.unix(data2.imageDate || 0).unix();

      return date2 - date1;
    });

    if (this.state.tab === "selected") {
      images = images.filter((data) => data.showOnWeb);
    }

    images.forEach((data) => {
      let date = new Date((data.imageDate || 0) * 1000);
      let newMonth = date.toLocaleDateString("default", { month: "long" });
      let newYear = date.getFullYear();

      if (!(month || year)) {
        month = newMonth;
        year = newYear;
      }

      if (
        (month !== newMonth || year !== newYear) &&
        internalNodes.length > 0
      ) {
        nodes.push(
          <>
            <span style={{ fontWeight: "bold" }}>{`${month}, ${year}`}</span>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {[...internalNodes]}
            </div>
          </>
        );

        internalNodes = [
          <div
            style={{
              height: 130,
              width: 170,
              margin: 15,
              backgroundImage: `url(${data.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <div className="overlay-check">
              <input
                type="checkbox"
                checked={data.showOnWeb}
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
                onChange={() => this.handleShowOnWeb(!data.showOnWeb, data.id)}
              />
            </div>
          </div>,
        ];
        month = newMonth;
        year = newYear;
      } else {
        internalNodes.push(
          <div
            style={{
              height: 130,
              width: 170,
              margin: 15,
              backgroundImage: `url(${data.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <div className="overlay-check">
              <input
                type="checkbox"
                checked={data.showOnWeb}
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
                onChange={() => this.handleShowOnWeb(!data.showOnWeb, data.id)}
              />
            </div>
          </div>
        );
      }
    });

    if (month && year) {
      nodes.push(
        <>
          <span style={{ fontWeight: "bold" }}>{`${month}, ${year}`}</span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {[...internalNodes]}
          </div>
        </>
      );
    }
    return nodes;
  };

  changeTab = (e) => {
    let tab = e.target.name;
    this.setState({
      tab: tab,
    });
  };
  fetchWebData = () => {
    let docRef = doc(db, "website", this.props.currentGroup);
    getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        let docData = doc.data();

        this.setState({
          galleryIntro: docData.galleryIntro,
          showAllPublicPhotos: docData.showAllPublicPhotos || false,
          isSaving: false,
        });
      }
    });
  };

  saveGalleryIntro = () => {
    this.setState({ isSaving: true });
    let docRef = doc(db, "website", this.props.currentGroup);

    let galleryIntro = this.state.galleryIntro;
    let showAllPublicPhotos = this.state.showAllPublicPhotos;
    setDoc(docRef, { galleryIntro, showAllPublicPhotos }, { merge: true }).then(
      () => {
        this.fetchWebData();
      }
    );
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div
        style={{
          padding: "15px 30px",
          backgroundColor: "#ffffff",
          // boxShadow: "0px 3px 10px 1px",
          marginTop: 30,
          borderRadius: "0.25rem",
        }}
      >
        <div className="d-flex">
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: "20", fontWeight: "bold" }}>Gallery</span>
          </div>

          {/* <div className="d-flex align-items-center" style={{ flex: 3, padding: '0px 3rem' }}>
                    <div className="form-group col" style={{ flexGrow: 0, marginBottom: 0 }}>
                        <input type='checkbox' name="gallery" style={{ height: "20px", width: "20px", borderRadius: 5 }} onChange={(e) => {
                            this.setState({
                                showAllPublicPhotos: e.target.checked
                            })
                        }}
                            checked={this.state.showAllPublicPhotos} />
                    </div>
                    <div>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>Check to show all public photos from the app on your website.</p>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>Members set individual privacy settings for their photos in the app.</p>
                    </div>
                </div> */}
        </div>
        <div className="row my-2">
          <div className="col-md-11">
            <p>
              Note: You must select at least 7 photos for the gallery carousel
              to appear. For optimal website design select images in multiples
              of 7.
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="mx-auto" style={{ width: "90%", display: "flex" }}>
            <div style={{ flex: 1 }}>
              <p style={{ marginBottom: -5 }}>Intro</p>
            </div>
            <div style={{ flex: 3, display: "flex", flexDirection: "column" }}>
              <div className="form-group col">
                <textarea
                  maxLength={1000}
                  rows={2}
                  style={{
                    borderRadius: 5,
                    width: "100%",
                    borderColor: "#ced4da",
                  }}
                  value={this.state.galleryIntro}
                  name="galleryIntro"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                />
              </div>
            </div>
            <button
              style={{
                backgroundColor: primaryColor,
                borderRadius: 5,
                border: "0px none",
                boxShadow: "transparent 0px 0px 0px",
                outline: "none",
                padding: "5px 10px",
                width: "200px",
                height: 30,
                justifySelf: "flex-end",
              }}
              className="default-text-color"
              // disabled={true}
              onClick={this.saveGalleryIntro}
            >
              {this.state.isSaving ? (
                <Spin size="small" spinning={true} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>

        <div
          className="row"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <MonthRangePicker onChange={this.handleMonthRangeChange} />
        </div>
        <div
          style={{
            fontSize: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ul className="nav nav-tabs">
            <li className="nav-item" onClick={this.changeTab}>
              <a
                name="all"
                className={
                  this.state.tab === "all"
                    ? "nav-link active a-color-primary"
                    : "nav-link a-color-secondary"
                }
              >
                All
              </a>
            </li>
            <li className="nav-item" onClick={this.changeTab}>
              <a
                name="selected"
                className={
                  this.state.tab === "selected"
                    ? "nav-link active a-color-primary"
                    : "nav-link a-color-secondary"
                }
              >
                Selected
              </a>
            </li>
          </ul>
        </div>
        <div>{this.renderImages()}</div>
      </div>
    );
  }

  fetchImages = () => {
    getImages(this.props.currentGroup).then((images) => {
      this.setState({
        imageData: images,
        filteredData: images,
        files: [],
        showUploadModal: false,
      });
    });
  };

  componentDidMount = () => {
    this.fetchImages();
    this.fetchWebData();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.fetchImages();
      this.fetchWebData();
    }
  };
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  return {
    currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(Gallery);
