import Axios from "../connection";
import { store } from "../../../index";
import { applicationError } from "../../../redux/actions/error";

export const afGetLeagues = (country, season) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/leagues?${country === "World" ? `country=${country}` : `code=${country}`}&season=${season}`
    )
      .then((response) => {
        if (response.data.response) {
          const data = response.data.response;
          let res = [];

          data.forEach((leagueData) => {
            let league = {};
            league["league_id"] = leagueData.league.id;
            league.name = leagueData.league.name;
            league.type = leagueData.league.type;
            league.logo = leagueData.league.logo;
            league.country = leagueData.country.name;
            league["country_code"] = leagueData.country.code;
            league.flag = leagueData.country.flag;
            league.season = leagueData.seasons[0].year;
            league["season_start"] = leagueData.seasons[0].start;
            league["season_end"] = leagueData.seasons[0].end;
            league.standings = leagueData.seasons[0].coverage.standings ? 1 : 0;
            league.is_current = leagueData.seasons[0].current ? 1 : 0;

            let coverage = {};
            coverage.fixtures = {};
            coverage.standings = leagueData.seasons[0].coverage.standings;
            coverage.players = leagueData.seasons[0].coverage.players;
            coverage.topScorers = leagueData.seasons[0].coverage.top_scorers;
            coverage.predictions = leagueData.seasons[0].coverage.predictions;
            coverage.odds = leagueData.seasons[0].coverage.odds;
            coverage.fixtures.events =
              leagueData.seasons[0].coverage.fixtures.events;
            coverage.fixtures.lineups =
              leagueData.seasons[0].coverage.fixtures.lineups;
            coverage.statistics =
              leagueData.seasons[0].coverage.fixtures.statistics_fixtures;
            coverage.player_statistics =
              leagueData.seasons[0].coverage.fixtures.statistics_players;

            league.coverage = coverage;

            res.push(league);
          });
          resolve(res);
        } else {
          store.dispatch(
            applicationError({
              err: {},
              message: "Unable to fetch leagues",
              errorCode: "api-football",
            })
          );
        }
      })
      .catch((err) => {
        store.dispatch(
          applicationError({
            err,
            message: "Unable to fetch leagues",
            errorCode: "api-football",
          })
        );
      });
  });
};
