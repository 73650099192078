import React from "react";
import { Tooltip, Table, Popconfirm, Tag, Modal } from "antd";
import moment from "moment";

import { connect } from "react-redux";
import {
  startUpdateContentsForGroup,
  triggerContentsUpdate,
  addContents,
  startAddContents,
  startDeleteContent,
  startRefreshContents,
  refreshContents,
  setGettingStartedItemStatus,
} from "../../../redux/actions/adminData";
import {
  fbDeleteContent,
  fbGetContents,
} from "../../../services/firebaseService/endPoints/admin/content";
import { getMembers } from "../../../services/indexedDb/members";
import {
  addContentsToLocal,
  getContentsFromLocal,
} from "../../../services/indexedDb/content";
import {
  fbGetChants,
  fbUpdateChant,
  fbDeleteChant,
} from "../../../services/firebaseService/endPoints/admin/ourChants";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useRef } from "react";
import { db } from "../../../services/firebaseService/connection";
import GripVertical from "../../../images/grip-vertical.svg";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";
import { doc, updateDoc } from "@firebase/firestore";
import { startUpdateUser } from "../../../redux/actions/user";
import {
  GUIDE_SETUP,
  TOPIC_ADD_CHANTS,
} from "../../../constants/gettingStarted";

const type = "DraggableBodyRow";

export const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

class ListChant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "current",
      isLoading: true,
      pagination: { pageSize: 50, position: "top" },
      tableData: [],
      sorter: { field: "", order: "" },
      needUpdate: false,
      sortOrderChantIds: [],
      warningTrigger: false,
      prevState: {},
    };
  }

  handleEditChant = (data) => {
    localStorage.setItem(
      "chantsConfiguration",
      JSON.stringify({
        tab: this.state.tab,
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
      })
    );
    this.props.history.push(
      `/admin/chants/edit/${this.props.currentGroup}/${data.id}`
    );
  };

  removeChantFromList = (id) => {
    this.setState((prevState) => ({
      tableData: prevState.tableData.filter((data) => {
        return data.id !== id;
      }),
      pagination: {
        ...prevState.pagination,
        total: prevState.pagination.total - 1,
      },
      isLoading: false,
    }));
  };

  handleDeleteChant = (data) => {
    this.setState({ isLoading: true });
    fbDeleteChant(this.props.currentGroup, data.id).then(() => {
      this.removeChantFromList(data.id);
    });
  };

  handleChangePublish = (data) => {
    this.setState({ isLoading: true });
    fbUpdateChant(this.props.currentGroup, data.id, {
      isPublished: !data.isPublished,
    }).then(() => {
      this.removeChantFromList(data.id);
    });
  };

  changeTab = (e) => {
    e.persist();
    this.setState(() => ({
      tab: e.target.name,
      isLoading: true,
      pagination: { current: 1, position: "top" },
      tableData: [],
    }));
    this.getChants(e.target.name);
  };

  // handleTableChange = (pagination, filters, sorter) => {
  //     filters = { ...this.state.filters, ...filters }

  //     if (filters.details[0] !== this.state.filters.details[0]) sorter = { ...sorter, columnKey: 'details', order: "ascend" }
  //     if (filters.rsvp[0] !== this.state.filters.rsvp[0]) sorter = { ...sorter, columnKey: 'rsvp', order: "descend" }
  //     if (filters.showOnHomePage[0] !== this.state.filters.showOnHomePage[0]) sorter = { ...sorter, columnKey: 'showOnHomePage', order: "ascend" }

  //     const pager = { ...this.state.pagination };
  //     pager.current = pagination.current;
  //     this.setState({
  //         pagination: pager,
  //         sorter,
  //         filters
  //     });
  //     this.fetchContents((pager.current - 1) * 10, sorter, filters)
  //     document.querySelector('.ant-table-body').scrollTo(0, 0)
  // };

  // handleContentRefresh = () => {
  //     // this.props.dispatch(refreshContents(this.state.tab, this.props.currentGroup))
  //     this.props.dispatch(startRefreshContents(this.state.tab, this.props.currentGroup))
  //     this.props.dispatch(startAddContents(this.state.tab, this.props.currentGroup))

  //     this.setState((prevState) => ({ isLoading: true, tableData: [], pagination: { ...prevState.pagination,current:1, total: undefined } }))

  // }

  moveRow = (dragIndex, hoverIndex) => {
    const newData = JSON.parse(JSON.stringify(this.state.tableData));
    newData[dragIndex].sortIndex = hoverIndex + 1;
    let sortIndexChangeStep = hoverIndex - dragIndex > 0 ? -1 : 1;
    let i = sortIndexChangeStep > 0 ? hoverIndex : dragIndex + 1;
    let finalStep = sortIndexChangeStep < 0 ? hoverIndex : dragIndex - 1;
    for (; i <= finalStep; i++) {
      newData[i].sortIndex += sortIndexChangeStep;
    }

    this.setState((state) => {
      let prevState = JSON.parse(JSON.stringify(state));

      return {
        tableData: newData,
        sortOrderChantIds: newData
          .sort((a, b) => a.sortIndex - b.sortIndex)
          .map((data) => data.id),
        warningTrigger: true,
        prevState: prevState,
      };
    });
  };

  changeHeaderColor = () => {
    let primaryColor = "";
    if (
      this.props.groupData.configuration &&
      this.props.groupData.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };

  handleTableChange = () => {
    this.changeHeaderColor();
  };

  render() {
    const components = {
      body: {
        row: DraggableBodyRow,
      },
    };

    const columns = [
      {
        title: "Sort",
        width: 100,
        className: "header-white",
        render: () => <img src={GripVertical} alt="*" />,
      },
      {
        title: "Action",
        className: "header-white",
        width: 150,
        render: (data) => {
          return (
            <>
              {!(data.type && data.type === "global") ? (
                <>
                  <Tooltip title="edit">
                    <i
                      className="fa cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        this.handleEditChant(data);
                      }}
                    >
                      <img
                        src={require("../../../images/nav-icons/baseline_edit_black_24dp.png")}
                        style={{ width: 25, height: 25 }}
                      />
                    </i>
                  </Tooltip>
                  &nbsp;&nbsp;
                  <Popconfirm
                    title={"Delete chant?"}
                    onConfirm={() => {
                      this.handleDeleteChant(data);
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="delete">
                      <i className="fa cursor-pointer" aria-hidden="true">
                        <img
                          src={require("../../../images/nav-icons/baseline_delete_black_24dp.png")}
                          style={{ width: 25, height: 25 }}
                        />
                      </i>
                    </Tooltip>
                  </Popconfirm>
                  &nbsp;&nbsp;
                  <Popconfirm
                    title={`${data.isPublished ? "Archive chant?" : "Publish chant?"}`}
                    onConfirm={() => {
                      this.handleChangePublish(data);
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      title={`${data.isPublished ? "archive" : "publish"}`}
                    >
                      <i className="fa cursor-pointer" aria-hidden="true">
                        {data.isPublished ? (
                          <img
                            src={require("../../../images/nav-icons/baseline_archive_black_24dp.png")}
                            style={{ width: 25, height: 25 }}
                            alt="Archive"
                          />
                        ) : (
                          <img
                            src={require("../../../images/nav-icons/baseline_unarchive_black_24dp.png")}
                            style={{ width: 25, height: 25 }}
                            alt="Publish"
                          />
                        )}
                      </i>
                    </Tooltip>
                  </Popconfirm>
                </>
              ) : (
                <span style={{ fontWeight: 550, color: "#000000" }}>
                  Global
                </span>
              )}
            </>
          );
        },
      },
      {
        title: "Name",
        width: 320,
        key: "title",
        className: "header-white",
        dataIndex: "title",
      },
      {
        title: "Video",
        width: 250,
        key: "videoLink",
        className: "header-white",
        dataIndex: "videoLink",
        render: (videoLink) => {
          let link = videoLink;
          if (link) {
            if (!`${link}`.includes("http")) {
              link = `https://${link}`;
            }
            return (
              <a href={link} target="_blank" rel="noopener noreferrer">
                Link
              </a>
            );
          }
          return <></>;
        },
      },
    ];

    const dataToRender = this.state.tableData.sort(
      (a, b) => a.sortIndex - b.sortIndex
    );

    let primaryColor = "";
    if (
      this.props.groupData.configuration &&
      this.props.groupData.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div
        className="mx-auto"
        style={{
          width: "95%",
          display: "flex",
          flexDirection: "column",
          paddingTop: 30,
        }}
      >
        <Modal
          onOk={() => {}}
          onCancel={() => {}}
          visible={this.state.warningTrigger && !this.state.needUpdate}
          footer={[
            <button
              className="btn"
              onClick={() => {
                this.setState((state) => {
                  let prevState = JSON.parse(JSON.stringify(state.prevState));
                  return {
                    warningTrigger: false,
                    sortOrderChantIds: state.prevState.sortOrderChantIds,
                    needUpdate: false,
                    tableData: prevState.tableData,
                    prevState: {},
                  };
                });
              }}
            >
              {" "}
              Cancel
            </button>,
            <button
              className="btn btn-primary"
              onClick={() => {
                this.setState({ warningTrigger: false, needUpdate: true });
              }}
            >
              Ok
            </button>,
          ]}
        >
          Save changes when done sorting.
        </Modal>

        <div
          style={{
            fontSize: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="">
            <ul className="nav nav-tabs" style={{ position: "relative" }}>
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={
                  this.state.tab === "current"
                    ? { borderBottom: `4px solid ${primaryColor}` }
                    : {}
                }
              >
                <a
                  name="current"
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: "#ffffff",
                  }}
                >
                  Current
                </a>
              </li>
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={
                  this.state.tab === "archives"
                    ? {
                        borderBottom: `4px solid ${primaryColor}`,
                        marginLeft: 24,
                      }
                    : { marginLeft: 24 }
                }
              >
                <a
                  name="archives"
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: "#ffffff",
                  }}
                >
                  Archives
                </a>
              </li>

              <li
                className="ml-auto align-items-center collapsible-horizontal tab-menu"
                id="chants-utilities"
              >
                {this.state.needUpdate && (
                  <button
                    type="button"
                    className="btn btn-sm btn-danger ml-4"
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: "#999999",
                      color: "#ffffff",
                      borderColor: "#999999",
                    }}
                    onClick={() => {
                      updateDoc(doc(db, "group", this.props.currentGroup), {
                        sortOrderChantIds: this.state.sortOrderChantIds,
                      }).then(() => {
                        this.props.dispatch(startUpdateUser());
                        this.setState({
                          needUpdate: false,
                          warningTrigger: false,
                        });
                      });
                    }}
                  >
                    Save Changes
                  </button>
                )}
                <a
                  class="icon"
                  style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                  onClick={() => switchCollapseHorizontal("chants-utilities")}
                >
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </a>

                <div
                  style={{
                    display: "flex",
                    width: "fit-content",
                    alignSelf: "flex-end",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info ml-4 default-text-color"
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: primaryColor,
                      borderColor: primaryColor,
                    }}
                    onClick={() => {
                      this.props.history.push("/admin/chants/create");
                    }}
                  >
                    Create Chant
                  </button>
                </div>

                <div className="show-for-mobile">
                  <div className="menu-list default-text-color">
                    <div
                      style={{
                        backgroundColor: "#ffffff",
                        padding: "5px 10px",
                      }}
                      onClick={() => {
                        this.props.history.push("/admin/chants/create");
                      }}
                    >
                      Create Chant
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div style={{ maxWidth: "900px" }} className="mt-4">
            <DndProvider backend={HTML5Backend}>
              <Table
                columns={columns}
                loading={this.state.isLoading}
                components={components}
                dataSource={dataToRender}
                pagination={
                  this.state.pagination
                    ? this.state.pagination.pageSize &&
                      this.state.pagination.total
                      ? this.state.pagination.pageSize <
                        this.state.pagination.total
                        ? this.state.pagination
                        : false
                      : false
                    : false
                }
                onChange={this.handleTableChange}
                className={`table-backdrop mb-3`}
                rowKey="id"
                scroll={{ x: "max-content" }}
                bodyStyle={{
                  fontSize: window.screen.width > 500 ? "18px" : "12px",
                  backgroundColor: "#ffffff",
                }}
                onRow={(_, index) => ({
                  index,
                  moveRow: this.moveRow,
                })}
              />
            </DndProvider>
          </div>
        </div>
      </div>
    );
  }

  getChants(tab = this.state.tab) {
    fbGetChants(this.props.currentGroup, tab === "current", "admin").then(
      (data) => {
        let tableData = data || [];

        if (tableData?.length > 0) {
          this.props.dispatch(
            setGettingStartedItemStatus(
              this.props.currentGroup,
              GUIDE_SETUP,
              TOPIC_ADD_CHANTS,
              true
            )
          );
        }

        let sortOrderChantIds = this.props.groupData.sortOrderChantIds || [];
        tableData.forEach((obj) => {
          if (sortOrderChantIds.indexOf(obj.id) !== -1) {
            obj.sortIndex = sortOrderChantIds.indexOf(obj.id) + 1;
          } else {
            obj.sortIndex = sortOrderChantIds.length + 1;
          }
        });
        this.setState((prevState) => ({
          tableData: tableData,
          isLoading: false,
          pagination: { ...prevState.pagination, total: data.length },
          sortOrderChantIds: sortOrderChantIds,
        }));
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({ isLoading: true, pagination: {}, tableData: [] });
      this.getChants();
      this.changeHeaderColor();
    }
  }

  componentDidMount() {
    this.getChants();
    this.changeHeaderColor();
  }
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    groupData: state.adminData.allGroups[state.adminData.currentGroup].data,
  };
};

export default connect(mapStateToProps)(ListChant);
