import { countries } from "countries-list";
import React from "react";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { AsYouType } from "libphonenumber-js";

class PhoneNumberInput extends React.Component {
  state = {
    country: "US",
    formattedPhoneNumber: "",
  };
  render() {
    let countryList = [];
    Object.keys(countries).forEach((country) => {
      countryList.push({ code: country, label: countries[country].name });
    });

    countryList.sort((a, b) => `${a.label}`.localeCompare(b.label));
    return (
      <div className="col">
        <div
          className="row"
          style={{
            justifyContent: "center",
          }}
        >
          <div
            style={{
              background: "transparent",
              border: "2px solid #ffffff",
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginRight: "0.5rem",
              padding: "5px 10px",
            }}
          >
            <div style={{ height: "auto" }}>
              {getUnicodeFlagIcon(this.state.country)}
            </div>
            <select
              style={{
                border: "0px none transparent",
                background: "#2F2C32",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                zIndex: 1,
                opacity: 0,
                cursor: "pointer",
              }}
              value={this.state.country}
              tabIndex={this.props.tabIndex || 0}
              onChange={(e) => {
                this.setState({
                  country: e.target.value,
                });
              }}
            >
              {countryList.map((country) => {
                return (
                  <option key={country.code} value={country.code}>
                    {country.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div
            style={{
              background: "transparent",
              border: "2px solid #ffffff",
              position: "relative",
              display: "flex",
              alignItems: "center",
              padding: "5px 10px",
            }}
            className="col"
          >
            <input
              style={{
                background: "transparent",
                border: 0,
                outline: "none",
                width: "100%",
              }}
              maxLength={20}
              placeholder={this.props.placeholder}
              value={this.state.formattedPhoneNumber}
              onChange={(e) => {
                const asYouType = new AsYouType(this.state.country);
                asYouType.input(e.target.value);
                let formattedPhoneNumber = asYouType.input("");
                let phoneNumberObject = asYouType.getNumber();
                if (phoneNumberObject) {
                  let phoneNumber = phoneNumberObject.number;
                  this.props.onChange(phoneNumber);
                }
                this.setState({
                  formattedPhoneNumber,
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PhoneNumberInput;
