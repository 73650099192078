import { db, Firebase } from "../connection";
import moment from "moment";
import { fbLogoutUser } from "./user";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";
import { openTransaction } from "../../indexedDb/connection";

// export const fbGetCurrentLeagues = () => {
//     return new Promise((resolve, reject) => {
//         db.collection("leagues").get()
//             .then((querySnapshot) => {
//                 let result = []
//                 querySnapshot.forEach((snapshot) => {
//                     let data = snapshot.data()
//                     data.id = snapshot.id
//                     result.push(data)
//                 })
//                 resolve(result)
//             })
//     })
// }

// export const fbRegisterMember = (leagueId, clubId, regCode, data) => {
//     return new Promise((resolve, reject) => {
//         // .where("leagueId", "==", leagueId)
//         // .where("clubId", "==", clubId)
//         if (fbAuth.currentUser) {
//             db.collection("group").where("registrationCode", "==", regCode).get()
//                 .then(async (result) => {
//                     if (result.docs.length > 0) {
//                         let userData = {}
//                         let group = result.docs[0].data()
//                         group.id = result.docs[0].id

//                         const querySnapshot = await db.collection("users").doc(fbAuth.currentUser.uid).get()
//                         let batch = db.batch()
//                         if (querySnapshot.exists) {
//                             userData = querySnapshot.data()
//                             if (userData.groupIds.find(id => id === group.id)) {
//                                 reject({ message: "You are already a member of this group" })
//                                 return
//                             }

//                         } else {
//                             const userRef = db.collection("users").doc(fbAuth.currentUser.uid)

//                             userData.name = data.name
//                             userData.email = data.email
//                             userData.phoneNumber = data.phoneNumber
//                             userData.birthDay = { month: "", day: "" }
//                             userData.homeTown = ""

//                             batch.set(userRef, userData)
//                         }

//                         // setting userGroup profile details given as per registration
//                         userData.name = data.name

//                         const userGroupRef = db.collection("userGroup")
//                             .doc(group.id).collection("members").doc(fbAuth.currentUser.uid)
//                         const userGroupData = {
//                             // groupId: group.id,
//                             userRole: 'member',
//                             activityCount: { capMe: 0, tailgating: 0, travel: 0, volunteer: 0, watchParty: 0 },
//                             chatHandle: "",
//                             favouriteMoment: "",
//                             favouritePlayer: "",
//                             lockStatus: false,
//                             memberSince: moment(new Date()).format("YYYY"),
//                             name: userData.name,
//                             pics: [],
//                             preferredLanguage: "",
//                             preferredPaymentMethod: "",
//                             privateChannelIds: [],
//                             profileImage: "",
//                             registrationDate: moment(new Date).format("MM-DD-YYYY"),
//                             title: ""
//                         }
//                         batch.set(userGroupRef, userGroupData)

//                         const userRef = db.collection("users").doc(fbAuth.currentUser.uid)
//                         batch.update(userRef, {
//                             groupIds: Firebase.firestore.FieldValue.arrayUnion(group.id)
//                         })

//                         const groupRef = db.collection("group").doc(group.id)
//                         batch.update(groupRef, {
//                             registeredMembers: Firebase.firestore.FieldValue.increment(1)
//                         })

//                         batch.commit()
//                             .then(() => {
//                                 resolve(group.groupName)
//                                 fbLogoutUser()
//                             })
//                             .catch(reject)
//                     } else {
//                         reject({ message: "You are not authorized for this" })
//                     }

//                 })
//                 .catch((err) => {
//                     store.dispatch(applicationError({ err, show: true, message: err.message }))
//                     fbLogoutUser()
//                 })
//         } else {
//             reject({ message: "User not signed in" })
//         }
//     })

// }

export const getUserDetails = (groupId, userId) => {
  return new Promise((resolve, reject) => {
    // db
    //     .collection('userGroup')
    //     .doc(groupId)
    //     .collection('members')
    //     .doc(userId)
    //     .get()
    //     .then(doc => {
    //         if (doc.exists) {
    //             resolve({ ...doc.data(), id: doc.id });
    //         } else {
    //             resolve({});
    //         }
    //     }).catch(e => {
    //         reject(e);
    //     })

    const indexDb = store.getState().adminData.connection;
    const members = openTransaction(
      indexDb,
      `members-${groupId}`,
      `members-${groupId}`
    );
    if (!members) return;
    let contents, getRequest;
    contents = members.index("pageId");
    getRequest = contents.openCursor();
    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        try {
          if (cursor.value.id === userId) {
            resolve(cursor.value);
          }
        } catch (err) {}
        cursor.continue();
      } else {
        resolve({});
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching members from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
};
