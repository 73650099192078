export default class Ticket {
  constructor() {
    this.notifications = [];
    this.type = "";
    this.matchId = "";

    this.matchName = "";
    this.title = "";
  }

  createTicketId = () => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  toDataSet(data, publishStatus) {
    this.confirmStatus = false;

    this.groupTicket = {};
    this.groupTicket.publishStatus = publishStatus;

    this.groupTicket.showForFans = data.showForFans;
    this.groupTicket.showOnWeb = data.showOnWeb;
    this.groupTicket.showForMembers = data.showForMembers;
    this.groupTicket.groupTerms = data.groupTerms;
    if (data.processingFee) {
      this.groupTicket.processingFee = data.processingFee;
    } else {
      this.hasError = { message: "Processing fee is required." };
      return;
    }
    if (!`${data.contactEmail}`.trim()) {
      this.hasError = { message: "Contact email is required." };
      return;
    }
    this.groupTicket.ticketDetail = {};
    this.groupTicket.ticketDetail.price = data.price;
    this.groupTicket.ticketDetail.row = data.row ? data.row : "TBD";
    this.groupTicket.ticketDetail.seats = data.seats ? data.seats : "TBD";
    if (data.section) {
      this.groupTicket.ticketDetail.section = data.section;
    } else {
      this.groupTicket.ticketDetail.section = "TBD";
    }

    if (data.tickets) {
      data.tickets.map((ticketDetail) => {
        delete ticketDetail.ticketLimit;
      });
    }

    this.groupTicket.addedOn =
      data.addedOn === null ? Math.round(Date.now() / 1000) : data.addedOn;
    if (data.type) {
      this.groupTicket.ticketType = data.type;
    } else {
      this.hasError = { message: "Ticket type is required." };
      return;
    }

    if (data.eventDate) {
      this.groupTicket.eventDate = data.eventDate;
    } else {
      this.hasError = { message: "Event date is required." };
      return;
    }

    if (data.saleEndDate) {
      this.groupTicket.saleEndDate = data.saleEndDate;
    } else {
      this.hasError = { message: "Sale end date is required." };
      return;
    }

    if (data.saleEndTime) {
      this.groupTicket.saleEndTime = data.saleEndTime;
    } else {
      this.hasError = { message: "Sale end time is required." };
      return;
    }

    this.groupTicket.eventTime = data.eventDate;
    this.groupTicket.eventCode = data.eventCode
      ? data.eventCode
      : "abcdefghijklm"; //update
    this.groupTicket.eventDesc = data.eventDescription;
    this.groupTicket.eventLocation = data.location;

    this.groupTicket.lastDownloadOn = data.lastDownloadOn
      ? data.lastDownloadOn
      : 0;
    this.groupTicket.soldTickets = data.soldTickets ? data.soldTickets : 0;
    this.groupTicket.broughtBy = data.broughtBy ? data.broughtBy : [];
    this.groupTicket.ticketLimitPerMember = Number(data.limit);
    this.groupTicket.passesLimit = data.passLimit ? Number(data.passLimit) : 0;
    this.groupTicket.totalTickets = Number(data.quantity);
    this.groupTicket.remainingTickets =
      Number(data.quantity) - Number(this.groupTicket.soldTickets);
    this.groupTicket.isbarCodeEnabled = data.barcode;
    this.groupTicket.requireUserNames = data.requireMember;
    this.groupTicket.ticketsEmailed = data.email;
    this.groupTicket.noOfCheckins = data.noOfCheckins;
    this.groupTicket.receiptDescription = data.receiptDescription || "";
    this.groupTicket.ticketDeliveryMethodApp =
      data.ticketDeliveryMethodApp || "";
    this.groupTicket.ticketDeliveryMethodWeb =
      data.ticketDeliveryMethodWeb || "";
    this.groupTicket.contactEmail = data.contactEmail || "";
    this.groupTicket.refundTerms = data.refundTerms || "";

    if (data.type !== "Tailgate Season Pass") {
      data.tickets = data.tickets.filter((ticket) => {
        return (
          ticket.name ||
          ticket.memberPrice.toString() ||
          ticket.limitPerPerson.toString() ||
          ticket.quantity.toString() ||
          ticket.subTicketType.toString() ||
          ticket.requireNameEmail.toString()
        );
      });

      if (data.tickets.length === 0) {
        this.hasError = {
          message: "Please add tickets.",
        };
      }

      let hasError = false;
      data.tickets.forEach((ticket) => {
        let validationFields = [
          "name",
          "memberPrice",
          "limitPerPerson",
          "quantity",
          "subTicketType",
          "requireNameEmail",
        ];

        if (ticket.subTicketType !== "Group Match") {
          validationFields = [...validationFields, "generateQRCode"];
        }

        validationFields.forEach((key) => {
          if (!ticket[key] && !hasError) {
            this.hasError = {
              message: "Please complete all required fields..",
            };
            hasError = true;
          }
        });
      });

      if (hasError) {
        return;
      }

      this.groupTicket.tickets = data.tickets.reduce((acc, val, index) => {
        if (val.memberPrice && val.memberPrice.includes(".")) {
          let decimalPart = val.memberPrice.split(".")[1];
          if (parseInt(decimalPart)) {
            if (decimalPart.length !== 2) {
              val.memberPrice = val.memberPrice + "0";
            }
          } else {
            val.memberPrice = val.memberPrice.split(".")[0];
          }
        } else {
          val.memberPrice = val.memberPrice || 0;
        }
        if (val.nonMemberPrice && val.nonMemberPrice.includes(".")) {
          let decimalPart = val.nonMemberPrice.split(".")[1];
          if (parseInt(decimalPart)) {
            if (decimalPart.length !== 2) {
              val.nonMemberPrice = val.nonMemberPrice + "0";
            }
          } else {
            val.nonMemberPrice = val.nonMemberPrice.split(".")[0];
          }
        } else {
          val.nonMemberPrice = val.nonMemberPrice || 0;
        }
        val.sortIndex = index + 1;
        acc[val.id || this.createTicketId()] = val;
        if (val.id) {
          delete acc[val.id].id;
        }
        return acc;
      }, {});

      this.groupTicket.totalTickets = Object.values(
        this.groupTicket.tickets
      ).reduce((acc, ticket) => {
        acc += Number(ticket.quantity);
        return acc;
      }, 0);

      this.groupTicket.soldTickets = Object.values(
        this.groupTicket.tickets
      ).reduce((acc, ticket) => {
        acc += Number(ticket.soldTickets);
        return acc;
      }, 0);

      this.groupTicket.remainingTickets =
        this.groupTicket.totalTickets - this.groupTicket.soldTickets;

      this.groupTicket.noOfCheckins = Object.values(
        this.groupTicket.tickets
      ).reduce((acc, ticket) => {
        acc += Number(ticket.noOfCheckins);
        return acc;
      }, 0);
    }
    this.groupTicket.collectedAmount = data.collectedAmount
      ? data.collectedAmount
      : 0;
    this.groupTicket.outstandingAmount =
      this.groupTicket.totalTickets * this.groupTicket.ticketDetail.price -
      this.groupTicket.collectedAmount;

    this.match = {};
    if (
      data.type === "Group Match" ||
      data.type === "Bus" ||
      data.type === "Tailgate"
    ) {
      if (!data.matchId) {
        this.hasError = { message: "Match is required." };
        return;
      }
    }
    this.match.fixtureId = Number(data.matchId);
    this.match.eventDate = data.match ? data.match.event_date : null;
    this.match.eventTimestamp = data.match ? data.match.event_timestamp : null;
    this.match.venue = data.match ? data.match.venue : null;
    this.match.groupId = data.groupId;

    this.match.homeTeam = {};
    this.match.homeTeam.teamId = data.match
      ? data.match.homeTeam.team_id
      : null;
    this.match.homeTeam.teamName = data.match
      ? data.match.homeTeam.team_name
      : null;
    this.match.homeTeam.logo = data.match ? data.match.homeTeam.logo : null;

    this.match.awayTeam = {};
    this.match.awayTeam.teamId = data.match
      ? data.match.awayTeam.team_id
      : null;
    this.match.awayTeam.teamName = data.match
      ? data.match.awayTeam.team_name
      : null;
    this.match.awayTeam.logo = data.match ? data.match.awayTeam.logo : null;

    this.matchTicket = {};
    this.showTicketCard = true;
    this.ticketId = data.ticketId;
    if (data.type !== "Group Match") {
      if (data.eventName) {
        this.groupTicket.eventName = data.eventName;
      } else {
        this.hasError = {
          message: "Event Name is required",
        };
      }
    }
  }

  fromDataSet(data) {
    this.addedOn =
      data.groupTicket.addedOn === null
        ? Math.round(Date.now() / 1000)
        : data.groupTicket.addedOn;
    this.type = data.groupTicket.ticketType;
    this.matchId = data.match.fixtureId;
    this.eventDescription = data.groupTicket.eventDesc;
    this.eventDate = data.groupTicket.eventDate;
    this.section = data.groupTicket.ticketDetail.section;
    this.lastDownloadOn = data.groupTicket.lastDownloadOn;
    this.quantity = data.groupTicket.totalTickets;
    this.price = data.groupTicket.ticketDetail.price;
    this.limit = data.groupTicket.ticketLimitPerMember;
    this.passLimit = data.groupTicket.passesLimit;
    this.email = data.groupTicket.ticketsEmailed;
    this.barcode = data.groupTicket.isbarCodeEnabled;
    this.requireMember = data.groupTicket.requireUserNames;
    this.eventName = data.groupTicket.eventName;
    this.location = (
      !!!data.groupTicket.eventLocation
        ? null
        : typeof data.groupTicket.eventLocation === "string"
    )
      ? { name: data.groupTicket.eventLocation }
      : data.groupTicket.eventLocation;
    this.eventCode = data.groupTicket.eventCode;
    this.oldTicketsCount = data.groupTicket.totalTickets;
    this.noOfCheckins = data.groupTicket.noOfCheckins;
    this.eventCode = data.groupTicket.eventCode;
    this.saleEndDate = data.groupTicket.saleEndDate;
    this.saleEndTime = data.groupTicket.saleEndDate;
    this.showForFans = data.groupTicket.showForFans || false;
    this.showOnWeb = data.groupTicket.showOnWeb || false;
    this.showForMembers = data.groupTicket.showForMembers || true;
    this.groupTerms = data.groupTicket.groupTerms || "";
    this.processingFee = data.groupTicket.processingFee || "";
    this.receiptDescription = data.groupTicket.receiptDescription || "";
    this.ticketDeliveryMethodApp =
      data.groupTicket.ticketDeliveryMethodApp || "";
    this.ticketDeliveryMethodWeb =
      data.groupTicket.ticketDeliveryMethodWeb || "";
    this.contactEmail = data.groupTicket.contactEmail || "";
    this.refundTerms = data.groupTicket.refundTerms || "";

    let tickets = data.groupTicket.tickets;

    if (tickets && !Array.isArray(tickets)) {
      tickets = Object.keys(tickets)
        .map((key) => {
          let ticket = tickets[key];
          ticket.id = key;
          return ticket;
        })
        .sort((a, b) => a.sortIndex - b.sortIndex);
    }

    this.tickets = tickets || [];

    this.row = data.groupTicket.ticketDetail.row;
    this.seats = data.groupTicket.ticketDetail.seats;
    this.eventCode = data.groupTicket.eventCode;
    this.collectedAmount = data.groupTicket.collectedAmount;
    this.soldTickets = data.groupTicket.soldTickets;
    this.broughtBy = data.groupTicket.broughtBy;
    this.remainingTickets = data.groupTicket.remainingTickets;
    this.editConfiguration = { imageChanged: false };
    this.lastDownloadOn = data.groupTicket.lastDownloadOn
      ? data.groupTicket.lastDownloadOn
      : null;
    if (data.match.homeTeam.teamName || data.match.awayTeam.teamName) {
      this.matchName =
        data.match.homeTeam.teamName + " vs " + data.match.awayTeam.teamName;
    }

    if (data.groupTicket.image) {
      this.imageList = [{ uid: "-1", url: data.groupTicket.image }];
    }
  }

  fromAPIData(data) {
    this.editConfiguration = { imageChanged: false };
    this.id = data.id;
    if (data.data.match.homeTeam.teamName || data.data.match.awayTeam.teamName)
      this.matchName =
        data.data.match.homeTeam.teamName +
        " vs " +
        data.data.match.awayTeam.teamName;
    this.groupTicket = [];
    this.matchId = data.data.match.fixtureId || 0;
    this.groupTicket.publishStatus = data.data.groupTicket.publishStatus || "";
    this.groupTicket.ticketType = data.data.groupTicket.ticketType;
    this.groupTicket.addedOn = data.data.groupTicket.addedOn;
    this.groupTicket.eventName = data.data.groupTicket.eventName;
    this.groupTicket.eventDate = data.data.groupTicket.eventDate;
    this.groupTicket.eventCode = data.data.groupTicket.eventCode;
    this.groupTicket.saleEndDate = data.data.groupTicket.saleEndDate;
    this.groupTicket.saleEndTime = data.data.groupTicket.saleEndTime;

    this.groupTicket.ticketDetail = [];
    this.groupTicket.ticketDetail.price =
      data.data.groupTicket.ticketDetail.price;
    this.groupTicket.ticketDetail.section =
      data.data.groupTicket.ticketDetail.section;
    this.groupTicket.ticketLimitPerMember =
      data.data.groupTicket.ticketLimitPerMember;
    this.groupTicket.passesLimit = data.data.groupTicket.passesLimit;
    this.groupTicket.lastDownloadOn = data.data.groupTicket.lastDownloadOn;
    this.groupTicket.totalTickets = data.data.groupTicket.totalTickets;
    this.groupTicket.noOfCheckins = data.data.groupTicket.noOfCheckins;
    this.groupTicket.remainingTickets = data.data.groupTicket.remainingTickets;
    this.groupTicket.barcode = data.data.groupTicket.isbarCodeEnabled;
    this.groupTicket.requireMember = data.data.groupTicket.requireUserNames;
    this.groupTicket.email = data.data.groupTicket.ticketsEmailed;
    this.groupTicket.collectedAmount =
      data.data.groupTicket.collectedAmount % 1 !== 0
        ? data.data.groupTicket.collectedAmount.toFixed(2)
        : data.data.groupTicket.collectedAmount;
    this.groupTicket.outstandingAmount =
      data.data.groupTicket.outstandingAmount % 1 !== 0
        ? data.data.groupTicket.outstandingAmount.toFixed(2)
        : data.data.groupTicket.outstandingAmount;
    this.groupTicket.soldTickets = data.data.groupTicket.soldTickets;
    this.groupTicket.showForFans = data.data.groupTicket.showForFans || false;
    this.groupTicket.showOnWeb = data.data.groupTicket.showOnWeb || false;
    this.groupTicket.showForMembers =
      data.data.groupTicket.showForMembers || true;
    this.groupTerms = data.data.groupTicket.groupTerms;
    this.processingFee = data.data.groupTicket.processingFee;
    this.receiptDescription = data.data.groupTicket.receiptDescription || "";
    this.ticketDeliveryMethodWeb =
      data.data.groupTicket.ticketDeliveryMethodWeb || "";
    this.ticketDeliveryMethodApp =
      data.data.groupTicket.ticketDeliveryMethodApp || "";
    this.refundTerms = data.data.groupTicket.refundTerms || "";
    this.contactEmail = data.data.groupTicket.contactEmail || "";

    if (data.data.groupTicket.image) {
      this.imageList = [{ uid: "-1", url: data.data.groupTicket.image }];
    }
    if (
      data.data.groupTicket.tickets &&
      ((Array.isArray(data.data.groupTicket.tickets) &&
        data.data.groupTicket.tickets.length > 0) ||
        Object.keys(data.data.groupTicket.tickets).length > 0)
    ) {
      let tickets = data.data.groupTicket.tickets;

      if (tickets && !Array.isArray(tickets)) {
        tickets = Object.keys(tickets)
          .map((key) => {
            let ticket = tickets[key];
            ticket.id = key;
            return ticket;
          })
          .sort((a, b) => a.sortIndex - b.sortIndex);
      }

      this.groupTicket.tickets = tickets || [];
      this.groupTicket.totalTickets =
        this.groupTicket.totalTickets ||
        this.groupTicket.tickets.reduce((acc, value) => {
          acc = acc + parseInt(value.quantity) || 0;
          return acc;
        }, 0);
      this.groupTicket.noOfCheckins =
        this.groupTicket.noOfCheckins ||
        this.groupTicket.tickets.reduce((acc, value) => {
          acc = acc + parseInt(value.checkins) || 0;
          return acc;
        }, 0);
      this.groupTicket.soldTickets =
        this.groupTicket.soldTickets ||
        this.groupTicket.tickets.reduce((acc, value) => {
          acc = acc + parseInt(value.soldTickets) || 0;
          return acc;
        }, 0);
      this.groupTicket.remainingTickets =
        this.groupTicket.remainingTickets ||
        this.groupTicket.totalTickets - this.groupTicket.soldTickets;
    }
  }

  ticketMembers(data) {
    this.purchaseId = data.id;
    this.ticketId = data.data.ticketId;
    this.users = data.data.usersList;
  }
}
