import { collection, getDocs } from "firebase/firestore";
import { db } from "../../connection";
import { getDefaultClub } from "./groups";

export async function getContents() {
  let threshold = new Date();
  threshold.setHours(0, 0, 0, 0);

  threshold = parseInt(threshold.getTime() / 1000);

  let clubId = getDefaultClub();
  let posts = await getDocs(
    collection(db, "groupAdminPost", `${clubId}_club_contents`, "posts"),
  ).then((snap) => {
    let posts = [];

    snap.docs.forEach((post) => {
      posts.push({
        ...post.data(),
        id: post.id,
      });
    });
    let rsvpPromises = posts.map((post) => {
      return getDocs(
        collection(
          db,
          "groupAdminPost",
          `${clubId}_club_contents`,
          "posts",
          post.id,
          "rsvp",
        ),
      ).then((snap) => {
        let rsvps = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        return {
          ...post,
          rsvp: rsvps,
        };
      });
    });

    return Promise.all(rsvpPromises);
  });

  let currentPosts = [],
    pastPosts = [];
  posts.forEach((post) => {
    if (threshold < post.details.eventStartDate) {
      currentPosts.push(post);
    } else {
      pastPosts.push(post);
    }
  });

  return { currentPosts, pastPosts };
}
