import { Select, Popconfirm } from "antd";
import Axios from "axios";
import React from "react";
import { updateUserRole } from "../../services/firebaseService/endPoints/admin/members.js";
import logger from "../../utils/logger.js";

const { Option } = Select;

class CustomDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.value,
    };
  }

  onChanged = (e) => {
    if (
      window.confirm(
        this.state.userRole === "fan"
          ? "Are you sure? Fan record will move to All Members tab."
          : "You are changing permissions for this member. Are you Sure?"
      )
    ) {
      // this.onCancel(item)
      let role = e;
      if (role === "parent-admin") {
        role = "parentAdmin";
      }
      this.setState({ userRole: e });
      var id = this.props.userId;
      this.props.editMemberInLocalDb({ id, userRole: role });

      updateUserRole(
        this.props.groupData.id,
        this.props.userId,
        role.toString(),
        this.props.value
      )
        .then((val) => {
          if (e !== "member") {
            var bdy = {
              tit: `${this.props.groupData.groupName} permissions alert`,
              bdy: `${this.props.userName} has been granted ${role} permissions by ${this.props.myName}.`,
              image: "",
              receiverDocId: "",
              topic: "",
              gName: `${this.props.groupData.groupName}`,
              gId: `${this.props.groupData.id}`,
              gCId: `${this.props.groupData.clubId}`,
              gLId: `${this.props.groupData.leagueId}`,
              type: "general_updates",
              timestamp: "",
              authorName: "",
              authorDocId: "",
              senderName: "",
              senderDocId: "",
              matchId: "",
              cName: "",
              cType: "",
              cId: "",
              pId: "",
            };
            Axios.post(
              `https://us-central1-chant2019.cloudfunctions.net/sendNotificationToAdmin`,
              JSON.stringify(bdy),
              {
                headers: {
                  "content-type": "text/json",
                },
              }
            );
          }
        })
        .catch((err) => {
          logger.error("Error: ", err);
        });
    }
  };

  render() {
    return (
      <div>
        <Select
          // style={{ borderColor: '#D3D3D3', borderRadius: '3px', paddingHorizontal: '2px', border: `${this.props.disabled ? 0 : 1}` }}
          style={{ width: `150px` }}
          disabled={this.props.disabled}
          required={this.props.required}
          value={this.state.userRole}
          name={this.props.name}
          onChange={this.onChanged}
          className={this.props.className}
          options={
            this.props.dataList.length > 0 &&
            this.props.dataList.map((data) => {
              return { data, data };
            })
          }
          tabIndex={this.props.tabIndex || 0}
        >
          {this.props.dataList.length > 0 &&
            this.props.dataList.map((data) => {
              return (
                <Option value={data}>
                  {/* <Popconfirm title="Are you sure delete this task?"
                                onConfirm={this.onChanged}
                                // onCancel={}
                                okText="Yes"
                                cancelText="No"> */}
                  {data}
                  {/* </Popconfirm> */}
                </Option>
              );
            })}
        </Select>
      </div>
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        userRole: this.props.value,
      });
    }
  }
}
export default CustomDropDown;
