import React from "react";
import DigitBadge from "../DigitBadge/DigitBadge";
import "./Timeline.css";

const Timeline = ({
  onItemClick,
  onTitleClick,
  timelineItems,
  timelineItemStatus,
}) => {
  return (
    <div
      className="timeline"
      style={{ display: "flex", flexDirection: "column", gap: "24px" }}
    >
      {timelineItems.map((item, index) => {
        return (
          <div
            className="timeline-element"
            key={item.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: "12px" }}>
              <div
                className="flex-center"
                style={{
                  zIndex: 8,
                }}
                // onClick={() => onItemClick(item.id)}
              >
                <DigitBadge
                  digit={index + 1}
                  badgeColor={
                    timelineItemStatus?.[item.id] ? "#65A30D" : "#DBDBDB"
                  }
                  digitColor={
                    timelineItemStatus?.[item.id] ? "#65A30D" : "#999999"
                  }
                  bgColor="#F1F2F2"
                />
              </div>
              <div onClick={() => onTitleClick(item.id)}>
                <p
                  className={`timeline-title ${timelineItemStatus?.[item.id] ? "timeline-title--complete" : "timeline-title--incomplete"}`}
                  style={{ cursor: item?.titleAction ? "pointer" : "default" }}
                >
                  {item.title}
                </p>
                {/* <p className="timeline-subtitle">{item.subTitle}</p> */}
              </div>
            </div>

            <div className="flex-center timeline-arrow">
              <img
                alt="getting-started-details"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  item?.onArrowClick && item?.onArrowClick(true, item.details)
                }
                height={20}
                width={20}
                src={require("../../images/chevron-right.svg")}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
