export default class UserRegistrationDetails {
  constructor() {
    this.package = {};
    this.firstName = "";
    this.lastName = "";
    this.email = "";
  }

  toDataSet(data, formFields) {
    this.userRole = "member";
    if (data.selectedPackage) {
      this.package = data.selectedPackage.details.id;
      this.membershipPacakageAmount = JSON.parse(
        JSON.stringify(data.selectedPackage.details.price),
      );

      if (data.noOfChildren && data.selectedPackage.details.costPerChild) {
        this.membershipPacakageAmount.value = `${parseFloat(this.membershipPacakageAmount.value) + parseInt(data.noOfChildren) * parseFloat(data.selectedPackage.details.costPerChild)}`;
      }

      if (
        data.isCheckoutScreen &&
        data.selectedPackage.details.shippingOption &&
        data.selectedPackage.details.shippingOption ===
          "Offer choice of pickup or shipping"
      ) {
        if (data.shippingOption) {
          this.shippingOption = data.shippingOption;
          if (data.shippingOption === "shipping") {
            this.shippingCharge = {
              value: data.selectedPackage.details.shippingCharge,
              unit: "$",
            };
          }
        } else {
          this.hasError = { message: "Select a shipping option" };
        }
      }

      if (
        data.isCheckoutScreen &&
        data.selectedPackage.details.shippingOption &&
        data.selectedPackage.details.shippingOption === "Show Shipping"
      ) {
        this.shippingCharge = {
          value: data.selectedPackage.details.shippingCharge,
          unit: "$",
        };
      }
    } else {
      this.hasError = { message: "Select a package" };
      return;
    }

    if (data.donatedAmount) {
      this.donationAmount = { value: "" + data.donatedAmount, unit: "$" };
    }
    if (data.firstName) {
      this.firstName = data.firstName;
    } else {
      this.hasError = { message: "First Name is required" };
      return;
    }

    if (data.lastName) {
      this.lastName = data.lastName;
    } else {
      this.hasError = { message: "Last Name is required" };
      return;
    }
    if (data.email) {
      this.email = data.email;
    } else {
      this.hasError = { message: "Email is required" };
      return;
    }
    if (formFields.phone) {
      if (data.phone) {
        this.phone = data.phone;
      } else {
        this.hasError = { message: "Phone is required" };
        return;
      }
    }
    if (formFields.address) {
      if (data.streetName) {
        this.address = data.streetName;
      } else {
        this.hasError = { message: "Adddress is required" };
        return;
      }

      if (data.address2) {
        this.address2 = data.address2;
      }

      if (data.city) {
        this.city = data.city;
      } else {
        this.hasError = { message: "City is required" };
        return;
      }

      if (data.state) {
        this.state = data.state;
      } else {
        this.hasError = { message: "State is required" };
        return;
      }

      if (data.zip) {
        this.zip = data.zip;
      } else {
        this.hasError = { message: "Zip is required" };
        return;
      }

      if (data.country) {
        this.country = data.country;
      } else {
        this.hasError = { message: "Country is required" };
        return;
      }
    }
    if (data.officialClubMember) {
      this.officialClubMember = data.officialClubMember;
    }
    if (data.seasonTicketHolderSection) {
      this.seasonTicketHolder = {
        section: data.seasonTicketHolderSection,
        noOfSeats: data.seasonTicketHolderNoOfSeats,
      };
    }
    if (data.joinEmailingList) {
      this.joinEmailingList = data.joinEmailingList;
    }
    if (data.shareVolunteerOpportunities) {
      this.shareVolunteerOpportunities = data.shareVolunteerOpportunities;
    }

    if (data.selectedPackage.details.includesTshirt) {
      if (data.tshirtSize) {
        this.tshirtSize = data.tshirtSize;
      } else {
        this.hasError = { message: "T-shirt size is required" };
        return;
      }
    }

    if (data.membershipNumber) {
      this.membershipNumber = data.membershipNumber;
    }

    if (data.selectedPackage && data.selectedPackage.details.familyMembership) {
      this.member2 = {
        userRole: "member",
      };
      if (data.member2firstName) {
        this.member2.firstName = data.member2firstName;
      } else {
        this.hasError = { message: "First Name is required for second member" };
        return;
      }

      if (data.member2lastName) {
        this.member2.lastName = data.member2lastName;
      } else {
        this.hasError = { message: "Last Name is required for second member" };
        return;
      }
      if (data.member2email) {
        if (data.member2email.trim() === data.email.trim()) {
          this.hasError = {
            message:
              "Email addresses must be unique for member #1 and member #2",
          };
          return;
        }
        this.member2.email = data.member2email;
      } else {
        this.hasError = { message: "Email is required for second member" };
        return;
      }
      if (data.noOfChildren) {
        this.noOfChildren = data.noOfChildren;
        this.children = Array.from({ length: parseInt(this.noOfChildren) }).map(
          (_, index) => {
            let child = {};
            if (
              data[`child${index + 1}Name`] &&
              data[`child${index + 1}Name`].trim()
            ) {
              child.name = data[`child${index + 1}Name`];
            } else {
              this.hasError = {
                message: `Child ${index + 1} Name is required`,
              };
            }
            if (data.selectedPackage.details.hasChildTshirtSizes) {
              if (
                data[`child${index + 1}TshirtSize`] &&
                data[`child${index + 1}TshirtSize`].trim()
              ) {
                child.tshirtSize = data[`child${index + 1}TshirtSize`];
              } else {
                this.hasError = {
                  message: `Child ${index + 1} Tshirt Size is required`,
                };
              }
            }

            return child;
          },
        );
      }
      if (data.member2joinMailingList) {
        this.member2.joinMailingList = data.member2joinMailingList;
      }

      if (data.selectedPackage.details.includesTshirt) {
        if (data.member2tshirtSize) {
          this.member2.tshirtSize = data.member2tshirtSize;
        } else {
          this.hasError = {
            message: "T-shirt size is required for second member",
          };
          return;
        }
      }

      if (data.member2officialClubMember) {
        this.member2.officialClubMember = data.member2officialClubMember;
      }

      this.member2.address = this.address;
      this.member2.city = this.city;
      this.member2.state = this.state;
      this.member2.country = this.country;
      this.member2.zip = this.zip;
      this.member2.membershipNumber = data.member2membershipNumber;
      this.familyMembershipId = data.familyMembershipId;
      this.member2.familyMembershipId = data.member2familyMembershipId;
      this.member2.userRole = "member";
    }
  }
}
