import { db } from "../connection";

export function getUserGroups(groupid, emailId) {
  return new Promise((resolve, reject) => {
    db.collection("userGroup")
      .doc(groupid)
      .collection("members")
      .where("email", "==", emailId)
      .get()
      .then((snap) => {
        if (snap.docs.length > 0) {
          resolve(snap.docs[0].id);
        }
        resolve(null);
      });
  });
}

export function getPreregisteredEmails(groupId) {
  return new Promise((resolve, reject) => {
    db.collection("invite_emails")
      .doc(groupId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          resolve(data.emails);
        } else {
          resolve([]);
        }
      });
  });
}

export function getUidForExistingUser(email) {
  return new Promise((resolve, reject) => {
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((snap) => {
        if (snap.docs.length > 0) {
          resolve(snap.docs[0].id);
        }
        resolve(null);
      })
      .catch(reject);
  });
}
