import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import groupReducer from "./reducers/group";
import userReducer from "./reducers/user";
import adminDataReducer from "./reducers/adminData";
import countryReducer from "./reducers/appData/country";
import errorReducer from "./reducers/error";
import pathReducer from "./reducers/appData/path";
import promoteReducer from "./reducers/appData/promote";
import superAdminReducer from "./reducers/superadmin";

const composeEnhancer =
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== "production"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

function configureStore() {
  return createStore(
    combineReducers({
      groups: groupReducer,
      user: userReducer,
      adminData: adminDataReducer,
      country: countryReducer,
      error: errorReducer,
      path: pathReducer,
      promote: promoteReducer,
      superAdmin: superAdminReducer,
    }),
    composeEnhancer(applyMiddleware(thunk))
  );
}

export default configureStore;
