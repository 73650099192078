import moment from "moment";

export default class Content {
  constructor() {
    // Initializing common fields for database and state
    this.notifications = [];
    this.type = "";
    this.matchId = "";

    this.matchName = "";
    this.title = "";
    // this.additionalLocations = [];
  }

  toDataSet(data) {
    // Initializing database only fields
    this.isHome = "";
    this.showOnHomePageStart = "";
    this.showOnHomePageEnd = "";
    this.awayTeam = { logo: "", team_id: "", team_name: "" };
    this.groupId = "";
    this.details = new Details();
    this.details.toDataSet(data);
    this.chatChannel = new ChatChannel();
    this.chatChannel.toDataSet(data);

    //New data of maps & rsvp
    this.linkedToTicket = false;
    this.ticketId = "";
    this.ticketName = "";
    this.ticketMatchId = "";
    // this.mapsData = {
    //   placeId: '',
    //   address: '',
    //   latitude: '',
    //   longitude: '',
    //   name: '',
    //   locationId: ''
    // };
    this.groups = [];
    if (data.groups) {
      this.groups = data.groups.length > 0 ? data.groups : ["global"];
    }
    if (data.isLinkTicket) this.linkedToTicket = data.isLinkTicket;
    if (data.ticketId) this.ticketId = data.ticketId;
    if (data.ticketName) this.ticketName = data.ticketName;
    if (data.ticketMatchId) this.ticketMatchId = data.ticketMatchId;
    // if (data.locationData && data.locationData.locationData) {
    //   let locationData = data.locationData.locationData;
    //   this.mapsData.longitude = locationData.longitude;
    //   this.mapsData.latitude = locationData.latitude;
    //   this.mapsData.address = (locationData.address + "").toString() === "" ? "" : locationData.address;
    //   this.mapsData.placeId = locationData.placeId;
    //   this.mapsData.name = data.locationData.name;
    //   this.mapsData.locationId = data.locationData.id
    // }

    if (
      data.additionalLocations &&
      data.additionalLocations.filter((location) => location).length > 0
    ) {
      this.additionalLocations = data.additionalLocations
        .filter((location) => location)
        .map((data) => {
          let mapsData = {};
          let locData = data.locationData;
          mapsData.longitude = locData.longitude;
          mapsData.latitude = locData.latitude;
          mapsData.address =
            (locData.address + "").toString() === "" ? "" : locData.address;
          mapsData.placeId = locData.placeId;
          mapsData.name = data.name;
          mapsData.locationId = data.id;
          return mapsData;
        });
    }

    if (data.groupId) {
      this.groupId = data.groupId;
    } else {
      this.hasError = {
        message: "Unable to fetch group details contact admin",
      };
      return;
    }

    if (this.details.hasError) {
      this.hasError = this.details.hasError;
      return;
    }

    if (this.chatChannel.hasError) {
      this.hasError = this.chatChannel.hasError;
      return;
    }

    if (data.showOnHomePageStart) {
      this.showOnHomePageStart = data.showOnHomePageStart;
      this.showOnHomePageEnd = data.showOnHomePageEnd;
    }

    if (data.notifications) {
      this.notifications = data.notifications.filter(
        (notification) => notification && moment.unix(notification).isValid()
      );
    }

    if (data.type) {
      this.type = data.type;
    } else {
      this.hasError = { message: "Content type is required" };
      return;
    }

    // sort this
    if (data.matchId) {
      this.matchId = data.matchId;
      if (data.matchList) {
        const matchDetails = data.matchList.find((match) => {
          return Number(match?.fixture_id) === Number(data?.matchId);
        });
        if (matchDetails && data.clubId) {
          // sort this (club id)
          this.isHome =
            Number(matchDetails.homeTeam.team_id) === Number(data.clubId);
          this.awayTeam = this.isHome
            ? matchDetails.awayTeam
            : matchDetails.homeTeam;
          this.matchName = `${matchDetails.homeTeam.team_name} vs ${
            matchDetails.awayTeam.team_name
          } (${moment(matchDetails.event_date).format("Do-MMM-YY")})`;
        } else {
          this.hasError = {
            message: "Unable to fetch match/club details, contact admin",
          };
          return;
        }
      } else {
        this.hasError = {
          message: "Unable to fetch match list, contact admin",
        };
        return;
      }
    }

    if (data.title) {
      this.title = data.title;
    }

    this.showForFans = data.showForFans;
    this.checkIn = {
      ...(data.checkIn || {}),
    };
    this.volunteerRequests = {};
    if (data.volunteerRequests) {
      data.volunteerRequests.map((request) => {
        if (request.title && request.slotCount) {
          let id = request.id;
          delete request.id;
          this.volunteerRequests[id] = request;
        } else {
          this.hasError = {
            message:
              "Title and # of slots are required for every volunteer slot.",
          };
        }
      });
    }

    this.videoConferenceLink = data.videoConferenceLink || "";
    this.meetingCode = data.meetingCode || "";

    return;
  }

  fromDataSet(data) {
    this.author = "";
    this.location = "";
    this.chatChannelName = "";
    this.channelArchiveDateStart = "";
    this.channelArchiveDateEnd = "";
    this.showOnHomePageStart = "";
    this.showOnHomePageEnd = "";
    this.imageFile = null;
    this.eventEndDate = "";
    this.eventStartDate = "";
    this.editConfiguration = { hasChannel: !!data.chatChannel };

    //New data for maps & rsvp
    this.isLinkTicket = false;
    this.ticketId = "";
    this.ticketName = "";
    this.ticketMatchId = "";
    // this.locationData = {
    //   name: '',
    //   id: '',
    //   locationData: {
    //     placeId: '',
    //     address: 'Add Google Maps Link',
    //     latitude: '',
    //     longitude: ''
    //   }
    // };

    this.groups = [];
    if (data.groups) {
      this.groups = data.groups.length > 0 ? data.groups : ["global"];
    }
    if (data.linkedToTicket) this.isLinkTicket = data.linkedToTicket;
    if (data.ticketId) this.ticketId = data.ticketId;
    if (data.ticketName) this.ticketName = data.ticketName;
    if (data.ticketMatchId) this.ticketMatchId = data.ticketMatchId;
    this.additionalLocations = [
      data.mapsData,
      ...(data.additionalLocations || []),
    ]
      .filter((locData) => locData)
      .map((locData) => {
        let locationData = {
          name: "",
          id: "",
          locationData: {
            placeId: "",
            address: "",
            latitude: "",
            longitude: "",
          },
          hidden: false,
        };
        locationData.name = locData.name;
        locationData.id = locData.locationId;
        locationData.locationData.placeId = locData.placeId;
        locationData.locationData.address = locData.address;
        locationData.locationData.latitude = locData.latitude;
        locationData.locationData.longitude = locData.longitude;
        return locationData;
      });
    if (
      Array.isArray(this.additionalLocations) &&
      this.additionalLocations.length === 0
    ) {
      this.additionalLocations = [undefined];
    }
    // let mapsData = data.mapsData;
    // if (mapsData) {
    //   this.locationData.locationData.placeId = mapsData.placeId;
    //   this.locationData.locationData.address = mapsData.address;
    //   this.locationData.locationData.latitude = mapsData.latitude;
    //   this.locationData.locationData.longitude = mapsData.longitude;
    //   this.locationData.name = mapsData.name;
    //   this.locationData.id = mapsData.locationId
    // }

    if (data.type) {
      this.type = data.type;
    }
    if (data.chatChannel) {
      this.chatChannelName = data.chatChannel;
    }
    if (data.title) {
      this.title = data.title;
    }
    if (data.details.description) {
      this.details = data.details.description;
    }

    if (data.details.author) {
      this.author = data.details.author;
    }

    if (data.details.location) {
      this.location = data.details.location;
    }

    if (data.details.eventStartDate) {
      this.eventStartDate = data.details.eventStartDate;
      if (
        data.details.eventEndDate &&
        data.details.eventEndDate !== 9999999999
      ) {
        this.eventEndDate = data.details.eventEndDate;
      }
    }

    if (data.matchId) {
      this.matchId = data.matchId;
      if (data.matchName) {
        this.matchName = data.matchName;
      }
    }

    if (data.details.image) {
      this.imageFile = data.details.image;
    }

    // if (data.chatChannel.channelName) {
    //     this.chatChannelName = data.chatChannel.channelName
    // }

    // if (data.chatChannel.eventStartDate) {
    //     this.channelArchiveDateStart = data.chatChannel.eventStartDate
    //     this.channelArchiveDateEnd = data.chatChannel.eventEndDate
    // }

    if (data.notifications) {
      if (data.notifications.length > 0) {
        this.notifications = data.notifications.filter((notification) => {
          return moment.unix(notification).isValid();
        });
      }
    }

    if (data.showOnHomePageStart) {
      this.showOnHomePageStart = data.showOnHomePageStart;
      this.showOnHomePageEnd = data.showOnHomePageEnd;
    }

    this.showForFans = data.showForFans || false;
    this.checkIn = data.checkIn || {};
    if (!Object.keys(this.checkIn).includes("validateGeolocation")) {
      this.checkIn.validateGeolocation = true;
    }
    this.volunteerRequests = Object.keys(data.volunteerRequests || {}).map(
      (key) => {
        return {
          ...data.volunteerRequests[key],
          id: key,
        };
      }
    );

    this.videoConferenceLink = data.videoConferenceLink || "";
    this.meetingCode = data.meetingCode || "";

    return;
  }
}

class Details {
  constructor() {
    this.author = "";
    this.description = "";
    this.location = "";
  }

  toDataSet(data) {
    this.eventEndDate = "";
    this.eventStartDate = "";

    if (data.author) {
      this.author = data.author;
    } else {
      //   if (data.type === "News") {
      //     this.hasError = { message: "Content type news requires an author" };
      //     return;
      //   }
    }

    if (data.details) {
      this.description = data.details;
    }

    if (data.location) {
      this.location = data.location;
    }

    if (data.eventStartDate) {
      this.eventStartDate = data.eventStartDate;
      if (data.eventEndDate) {
        this.eventEndDate = data.eventEndDate;
      } else {
        this.eventEndDate = 9999999999;
      }
    } else {
      this.hasError = { message: "Event start date is required" };
      return;
    }

    return;
  }
}

class ChatChannel {
  constructor() {
    this.channelName = "";
    this.startDate = "";
    this.endDate = "";
  }

  toDataSet(data) {
    if (data.chatChannelName) {
      this.channelName = data.chatChannelName;
      if (data.channelArchiveDateStart) {
        this.startDate = data.channelArchiveDateStart;
        this.endDate = data.channelArchiveDateEnd;
      }
    }
    return;
  }
}
