import logger from "../../utils/logger";

export function initDb() {
  return new Promise((resolve, reject) => {
    let db, store, tx;
    let request = window.indexedDB.open("chant-membership", 1);
    request.onupgradeneeded = (e) => {
      db = request.result;
      store = db.createObjectStore("membership-details", {
        keyPath: "membershipNumber",
      });
    };

    request.onerror = (e) => {
      logger.error("Failed to create database:", e.target.errorCode);
    };

    request.onsuccess = (e) => {
      db = request.result;
      tx = db.transaction("membership-details", "readwrite");
      store = tx.objectStore("membership-details");
      let err = null;
      db.onversionchange = function () {
        db.close();
      };
      db.onerror = function (e) {
        err = e.target.errorCode;
      };
      resolve({ err, indexDb: db, tx, store });
    };
  });
}

export function deleteDb() {
  return new Promise((resolve, reject) => {
    const deleteRequest = window.indexedDB.deleteDatabase("chant-membership");
    deleteRequest.onsuccess = function (event) {
      resolve();
    };

    deleteRequest.onerror = function (event) {
      reject();
    };
  });
}
