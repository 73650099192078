const initialValue = { isCurrent: false, data: [] };
const countryReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "UPDATE_COUNTRY":
      return { isCurrent: true, data: [...action.payload] };
    default:
      return { ...state };
  }
};

export default countryReducer;
