import React from "react";
import {
  fbGetGroupRegistrationCode,
  fbChangeGroupRegistrationCode,
} from "../../../services/firebaseService/endPoints/admin/groupConfig";
import { connect } from "react-redux";
import { FInput } from "../../commons/formFields/FInput";
import LoadingModal from "../../commons/LoadingModal";
import { Popconfirm } from "antd";
import generateRegistrationCode from "../../../helperFunctions/generateRegistrationCode";

class RegistrationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationCode: "",
      isLoading: true,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleChangeRegistrationCode = () => {
    this.setState({ isLoading: true });
    const registrationCode = generateRegistrationCode();
    fbChangeGroupRegistrationCode(
      this.props.currentGroup,
      registrationCode,
    ).then(() => {
      this.setState({ registrationCode, isLoading: false });
    });
  };

  render() {
    return (
      <div>
        <LoadingModal loading={this.state.isLoading} />
        <form
          onSubmit={this.handleSubmit}
          className="border rounded mt-4 p-2 px-md-5 py-md-4 mx-md-auto mx-2"
          style={{ maxWidth: "700px" }}
        >
          <FInput
            name="registrationCode"
            value={this.state.registrationCode}
            label={`Registration code for ${this.props.groupName}`}
            disabled
            display="col"
          />
          <div className="d-flex justify-content-center">
            <Popconfirm
              title="Changing this code will make the previous registration code invalid. Are you sure?"
              onConfirm={this.handleChangeRegistrationCode}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-sm btn-danger">
                Generate a new code
              </button>
            </Popconfirm>
          </div>
        </form>
      </div>
    );
  }

  getRegistrationCode() {
    fbGetGroupRegistrationCode(this.props.currentGroup).then(
      (registrationCode) => {
        this.setState({ registrationCode, isLoading: false });
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({ isLoading: true });
      this.getRegistrationCode();
    }
  }

  componentDidMount() {
    this.getRegistrationCode();
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentGroup: state.adminData.currentGroup,
    groupName:
      state.adminData.allGroups[state.adminData.currentGroup].data.groupName,
  };
};

export default connect(mapStateToProps)(RegistrationCode);
