import { collection, getDocs } from "firebase/firestore";
import { db, storage } from "../../connection";
import { getGroups } from "./groups";

export async function getImages() {
  let groups = await getGroups();

  let imageUrlsPromises = groups.map((group) => {
    return getDocs(collection(db, "gallery", group.id, "images")).then(
      (snap) => {
        let data = snap.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
            groupId: group.id,
            groupName: group.groupName,
          };
        });

        return data;
      }
    );
  });

  let images = await Promise.all(imageUrlsPromises).then((imageArrays) => {
    let res = [];
    imageArrays.forEach((array) => {
      res.push(...array);
    });
    return res;
  });

  // let imagePromises = [];
  // let storageRef = storage.ref();
  // groups.forEach(group => {
  //     let galleryRef = storageRef.child(`${group.groupName}/gallery/`);
  //     imagePromises.push(galleryRef.listAll());
  // });

  // let imageRefs = await Promise.all(imagePromises).then(groups => {
  //     let images = [];
  //     groups.forEach(gallery => {
  //         gallery.items.forEach(imageRef => {
  //             images.push(new Promise((resolve, reject) => {
  //                 let metadata = imageRef.getMetadata();
  //                 let url = imageRef.getDownloadURL();

  //                 Promise.all([metadata, url]).then(data => {
  //                     resolve(data)
  //                 })
  //         });

  //     return images
  // }).catch(err => {
  // });

  // let result = await Promise.all(imageRefs).then(data => {
  //     let result = [];
  //     data.forEach(rec => {
  //         result.push({
  //             metadata: rec[0],
  //             url: rec[1]
  //         })
  //     });
  //     return result;
  // });

  return images;
}

export async function downloadImage(path) {
  let xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = () => {
    let blob = xhr.response;
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = "image";
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  xhr.open("GET", path);
  xhr.send();
}
