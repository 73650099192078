import { db, fbAuth, Firebase, storage } from "../../connection";
import { store } from "../../../..";
import { applicationError } from "../../../../redux/actions/error";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "@firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import Axios from "axios";
import logger from "../../../../utils/logger";

export function getMyId() {
  return fbAuth.currentUser.uid;
}

export function updateUserRole(groupId, memberId, role, currentRole) {
  let promises = [];
  promises.push(
    new Promise((resolve, reject) => {
      setDoc(
        doc(db, "userGroup", groupId, "members", memberId),
        { userRole: role },
        { merge: true }
          .then(() => {
            resolve();
          })
          .catch((e) => {
            reject(e);
          })
      );
    })
  );

  if (role === "fan" || currentRole === "fan") {
    let url = `https://us-central1-chant2019.cloudfunctions.net/subscribeUersToTopics?groupId=${groupId}&&userId=${memberId}&&userRole=${role}`;

    promises.push(fetch(url));
  }

  return Promise.all(promises);
}

export function fbGetMembers(groupId) {
  return new Promise((resolve, reject) => {
    getDocs(collection(db, "userGroup", groupId, "members"))
      .then((querySnapshot) => {
        // let result = []
        // querySnapshot.forEach((doc) => {
        //     let data = doc.data()
        //     data.id = doc.id
        //     data.userRole = data.userRole || ''
        //     result.push(data)
        // });

        let promises = [];

        querySnapshot.forEach((docSnap) => {
          let id = docSnap.id;
          promises.push(
            getDoc(doc(db, "users", id))
              .then((userDoc) => {
                if (userDoc.exists()) {
                  let data = userDoc.data() || {};
                  let hometown = data.homeTown;
                  let userBirthday = data.birthDay || {};
                  let birthday = {
                    date: `${userBirthday.day}`,
                    month: `${userBirthday.month}`.substring(0, 3),
                  };

                  return { hometown, birthday };
                }
                return {};
              })
              .then(({ hometown, birthday }) => {
                let data = docSnap.data() || {};
                data.id = docSnap.id;
                data.userRole = data.userRole || "";
                data.hometown = hometown || data.hometown;

                let registrationDate = data.registrationDate;
                data.registrationDate = data.registeredOn;
                data.registeredOnChantDate = registrationDate;
                data.birthday = data.birthday || birthday;
                return data;
              })
          );
        });

        Promise.all(promises).then((data) => {
          resolve(data);
        });

        // resolve(result)
      })
      .catch((err) => {
        let message = "There was an error fetching members for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            show: true,
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });
  });
}

export function fbGetLatestMembers(groupId, date) {
  return new Promise((resolve, reject) => {
    getDocs(
      query(
        collection(db, "userGroup", groupId, "members"),
        where("registrationDate", ">", date)
      )
    )
      .then((querySnapshot) => {
        let result = [];
        querySnapshot.docs.forEach((doc) => {
          result.push({ ...doc.data(), id: doc.id });
        });
        resolve(result);
      })
      .catch((err) => {
        let message = "There was an error fetching members for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            show: true,
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });
  });
}

export function fbLockMember(groupId, memberId) {
  return new Promise((resolve, reject) => {
    setDoc(
      doc(db, "userGroup", groupId, "members", memberId),
      { lockStatus: true },
      { merge: true }
    )
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}

export function fbUnlockMember(groupId, memberId) {
  return new Promise((resolve, reject) => {
    setDoc(
      doc(db, "userGroup", groupId, "members", memberId),
      { lockStatus: false },
      { merge: true }
    )
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}

export function fbGetPendingMembers(groupId) {
  return getDoc(doc(db, "invite_emails", groupId));
}

export function fbSaveFormLayout(layoutData, groupId) {
  return new Promise((resolve, reject) => {
    let docRef = doc(db, "registration_form_layout", groupId);
    setDoc(docRef, layoutData, {
      merge: true,
    })
      .then(resolve)
      .catch(reject);
  });
}

export function fbGetFormLayout(groupId) {
  return new Promise((resolve, reject) => {
    let docRef = doc(db, "registration_form_layout", groupId);
    getDoc(docRef)
      .then(async (doc) => {
        if (doc.exists()) {
          let packages = await getPackages(groupId);
          resolve({ ...(doc.data() || {}), id: doc.id, packages });
        }
        resolve();
      })
      .catch(reject);
  });
}

export function publishOrUnpublishForm(isPublished, groupId, fullGroupName) {
  return new Promise((resolve, reject) => {
    let docRef = doc(db, "registration_form_layout", groupId);
    setDoc(
      docRef,
      {
        isPublished,
        groupName: fullGroupName,
      },
      {
        merge: true,
      }
    ).then(() => {
      getDoc(docRef).then((doc) => {
        let data = doc.data() || {};
        resolve(data.isPublished);
      });
    });
  });
}

export function uploadImage(file, path, index) {
  return new Promise((resolve, reject) => {
    let storageRef = ref(storage);
    let folderRef = ref(storageRef, `${path}`);
    uploadBytes(folderRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((downloadUrl) => {
        resolve({ downloadUrl, index });
      })
      .catch(reject);
  });
}

export function getPackages(groupId) {
  return new Promise((resolve, reject) => {
    getDocs(
      collection(db, "registration_form_layout", groupId, "packages")
    ).then((snapshot) => {
      let data = [];
      snapshot.docs.forEach((doc) => {
        if (doc.exists() && !(doc.data() || {}).isDeleted) {
          data.push({
            ...(doc.data() || {}),
            id: doc.id,
            sortIndex: (doc.data() || {}).sortOrder || 9999,
          });
        }
      });

      data = data.sort((a, b) => a.sortIndex - b.sortIndex);
      resolve(data);
    });
  });
}

export function getAllPackages(groupId) {
  return new Promise((resolve, reject) => {
    getDocs(
      collection(db, "registration_form_layout", groupId, "packages")
    ).then((snapshot) => {
      let data = [];
      snapshot.docs.forEach((doc) => {
        if (doc.exists()) {
          data.push({ ...doc.data(), id: doc.id });
        }
      });
      resolve(data);
    });
  });
}

export function addPackage(data, groupId, merchantId, currency) {
  let expiryDate = data.packageExpiryDate;

  data.sortOrder = 1;

  return new Promise((resolve, reject) => {
    let docRef = doc(
      collection(db, "registration_form_layout", groupId, "packages")
    );
    let promises = [];

    let packageUpdatePromise = setDoc(docRef, data, {
      merge: true,
    }).catch((err) => {
      reject(err);
    });

    let expiryDateUpdatePromise = getDoc(doc(db, "group", groupId))
      .then((docSnap) => {
        if (docSnap.exists()) {
          let packageIdExpiryMap = docSnap.data().packageIdExpiryMap || {};
          let packageExpiryMap = docSnap.data().packageExpiryMap || {};
          packageIdExpiryMap[docRef.id] = expiryDate;
          packageExpiryMap[docRef.id] = {
            expiryDate: expiryDate || 0,
            name: data.name,
          };
          updateDoc(doc(db, "group", groupId), {
            packageIdExpiryMap,
            packageExpiryMap,
          });
        }
      })
      .catch(reject);
    promises = [packageUpdatePromise, expiryDateUpdatePromise];
    if (data.offerAutorenewal) {
      let reqBody = {
        name: data.name,
        packageId: docRef.id,
        groupId: groupId,
        price: data.price,
        merchantId: merchantId,
        currency: currency,
      };
      let url =
        "https://us-central1-chant2019.cloudfunctions.net/stripe2/createProduct";
      promises.push(
        Axios.post(url, JSON.stringify(reqBody), {
          headers: {
            "content-type": "text/json",
          },
        })
          .then((res) => {
            return {
              productId: res.data.productId,
              priceId: res.data.priceId,
              shippingPriceId: res.data.shippingPriceId,
              shippingPriceIdWithFee: res.data.shippingPriceIdWithFee,
              priceIdWithFee: res.data.priceIdWithFee,
              secondaryPriceIdWithFee: res.data.secondaryPriceIdWithFees,
              secondaryShippingPriceIdWithFee:
                res.data.secondaryShippingPriceIdWithFees,
            };
          })
          .catch((err) => {
            logger.error("Error ", err);
          })
      );

      if (data.childPrice) {
        let childReqBody = {
          name: `${data.name}-children`,
          packageId: docRef.id,
          groupId: groupId,
          price: data.childPrice,
          merchantId: merchantId,
          currency: currency,
          isSubProduct: true,
        };
        promises.push(
          Axios.post(url, JSON.stringify(childReqBody), {
            headers: {
              "content-type": "text/json",
            },
          })
            .then((res) => {
              return {
                productId: res.data.productId,
                priceId: res.data.priceId,
                shippingPriceId: res.data.shippingPriceId,
                shippingPriceIdWithFee: res.data.shippingPriceIdWithFee,
                priceIdWithFee: res.data.priceIdWithFee,
              };
            })
            .catch((err) => {
              logger.error("Error ", err);
            })
        );
      }
    }

    Promise.all(promises)
      .then(async (res) => {
        let batch = writeBatch(db);

        let snap = await getDocs(
          collection(db, "registration_form_layout", groupId, "packages")
        );
        snap.docs.forEach((doc) => {
          if (doc.id !== docRef.id && doc.data().sortOrder) {
            batch.update(doc.ref, { sortOrder: increment(1) });
          }
        });

        await batch.commit();

        if (res.length >= 3) {
          let product = res[2];
          let updateObj = {
            stripeProductId: product.productId,
            price: {
              ...data.price,
              priceId: product.priceId,
              shippingPriceId: product.shippingPriceId,
              priceIdWithFee: product.priceIdWithFee,
              shippingPriceIdWithFee: product.shippingPriceIdWithFee,
              secondaryPriceIdWithFee: product.secondaryPriceIdWithFee,
              secondaryShippingPriceIdWithFee:
                product.secondaryShippingPriceIdWithFee,
            },
          };
          if (res.length >= 4) {
            let childProduct = res[3];
            updateObj = {
              ...updateObj,
              childPrice: {
                ...data.childPrice,
                priceId: childProduct.priceId,
                shippingPriceId: childProduct.shippingPriceId,
                priceIdWithFee: childProduct.priceIdWithFee,
                shippingPriceIdWithFee: childProduct.shippingPriceIdWithFee,
                childProductId: childProduct.productId,
              },
            };
          }
          updateDoc(docRef, updateObj);
        }
      })
      .then(() => {
        resolve();
      });
  });
}

export function updatePackage(
  data,
  packageId,
  groupId,
  editChanges,
  merchantId,
  currency
) {
  try {
    let expiryDate = data.packageExpiryDate;

    return new Promise((resolve, reject) => {
      let docRef = doc(
        db,
        "registration_form_layout",
        groupId,
        "packages",
        packageId
      );

      let packageUpdatePromise = setDoc(docRef, data, {
        merge: true,
      }).catch((err) => {
        reject(err);
      });

      let expiryDateUpdatePromise = getDoc(doc(db, "group", groupId))
        .then((docSnap) => {
          if (docSnap.exists()) {
            let packageIdExpiryMap = docSnap.data().packageIdExpiryMap || {};
            let packageExpiryMap = docSnap.data().packageExpiryMap || {};
            packageIdExpiryMap[packageId] = expiryDate;
            packageExpiryMap[packageId] = {
              expiryDate: expiryDate || 0,
              name: data.name,
            };
            updateDoc(doc(db, "group", groupId), {
              packageIdExpiryMap,
              packageExpiryMap,
            });
          }
        })
        .catch(reject);

      let promises = [packageUpdatePromise, expiryDateUpdatePromise];
      if (
        !Object.keys(data.price).includes("priceId") ||
        !Object.keys(data.price).includes("secondaryPriceIdWithFee")
      ) {
        editChanges.priceChange = true;
        editChanges.productId = data.stripeProductId;
      }

      if (!Object.keys(data.childPrice).includes("childProductId")) {
        editChanges.childPriceChange = true;
        editChanges.childProductId = data.childPrice.childProductId;
      }
      if (
        data.offerAutorenewal &&
        (editChanges.nameChange ||
          editChanges.priceChange ||
          editChanges.childPriceChange)
      ) {
        let reqBody = {
          name: data.name,
          price: data.price,
          editChanges: editChanges,
          merchantId: merchantId,
          currency: currency,
          packageId: packageId,
          groupId: groupId,
        };

        let url =
          "https://us-central1-chant2019.cloudfunctions.net/stripe2/updateProduct";
        promises.push(
          Axios.post(url, JSON.stringify(reqBody), {
            headers: {
              "content-type": "text/json",
            },
          })
            .then((res) => {
              logger.log(JSON.parse(JSON.stringify(res)));
              return res.data;
            })
            .catch((err) => {
              logger.error("Error ", err);
            })
        );

        if (
          data.includesChildPrice &&
          (editChanges.nameChange || editChanges.childPriceChange)
        ) {
          let reqBody = {
            name: `${data.name}-children`,
            price: data.childPrice,
            editChanges: editChanges,
            merchantId: merchantId,
            currency: currency,
            packageId: packageId,
            groupId: groupId,
            isSubProduct: true,
          };
          let url =
            "https://us-central1-chant2019.cloudfunctions.net/stripe2/updateProduct";
          promises.push(
            Axios.post(url, JSON.stringify(reqBody), {
              headers: {
                "content-type": "text/json",
              },
            })
              .then((res) => {
                logger.log(JSON.parse(JSON.stringify(res)));
                return res.data;
              })
              .catch((err) => {
                logger.error("Error ", err);
              })
          );
        }
      }

      Promise.all(promises)
        .then(async (res) => {
          if (res.length >= 3) {
            let product = res[2];
            if (Object.keys(product || {}).length > 0) {
              if (product?.priceId || product?.secondaryPriceIdWithFees) {
                await updateDoc(docRef, {
                  price: {
                    ...data.price,
                    priceId: product.priceId,
                    shippingPriceId: product.shippingPriceId,
                    priceIdWithFee: product.priceIdWithFee,
                    shippingPriceIdWithFee: product.shippingPriceIdWithFee,
                    secondaryPriceIdWithFee:
                      product.secondaryPriceIdWithFees || "",
                    secondaryShippingPriceIdWithFee:
                      product.secondaryShippingPriceIdWithFees,
                  },
                });
              }
            }

            if (res.length >= 4) {
              let childProduct = res[3];
              if (Object.keys(childProduct || {}).length > 0) {
                await updateDoc(docRef, {
                  childPrice: {
                    ...data.childPrice,
                    priceId: childProduct.priceId,
                    shippingPriceId: childProduct.shippingPriceId,
                    priceIdWithFee: childProduct.priceIdWithFee,
                    shippingPriceIdWithFee: childProduct.shippingPriceIdWithFee,
                    childProductId: childProduct.productId,
                  },
                });
              }
            }
          }
        })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          throw err;
        });
    });
  } catch (error) {
    logger.log("Update Package Failed: ", error);
  }
}

export function getPackage(groupId, packageId) {
  return new Promise(async (resolve, reject) => {
    let data = {};
    await getDoc(
      doc(db, "registration_form_layout", groupId, "packages", packageId)
    )
      .then((doc) => {
        if (doc.exists()) {
          data = doc.data() || {};
        }
      })
      .catch(reject);

    if (!data.packageExpiryDate) {
      await getDoc(doc(db, "registration_form_layout", groupId))
        .then((doc) => {
          if (doc.exists()) {
            data.packageExpiryDate = doc.data().renewalDate;
          }
        })
        .catch(reject);
    }

    resolve(data);
  });
}

export function setPackageSortOrder(idOrderMap, groupId) {
  return new Promise((resolve, reject) => {
    let collectionRef = collection(
      db,
      "registration_form_layout",
      groupId,
      "packages"
    );

    let writePromises = Object.entries(idOrderMap).map((entry) =>
      updateDoc(doc(db, collectionRef.path, entry[0]), { sortOrder: entry[1] })
    );
    Promise.all(writePromises)
      .then(() => resolve())
      .catch(reject);
  });
}

export function getRegsitrationLayout(groupId) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "registration_form_layout", groupId))
      .then((doc) => {
        if (doc.exists()) {
          let data = doc.data();
          resolve(data);
          return;
        }
        resolve(null);
        return;
      })
      .catch(reject);
  });
}
