import React from "react";

class OptionContainer extends React.Component {
  state = {};

  render() {
    const { title, subtitle, children } = this.props;
    return (
      <div
        style={{
          flex: 1,
          margin: 10,
          color: "#ffffff",
          backgroundColor: "#4f4f4f",
          padding: "1rem",
          position: "relative",
        }}
      >
        <div className="row justify-content-center">
          <div style={{ borderBottom: `7px solid ${this.props.color}` }}>
            <h2
              style={{ color: "#ffffff", fontWeight: "bold", marginBottom: 2 }}
            >
              {title}
            </h2>
          </div>
        </div>
        <div className="row justify-content-center mt-2">
          <span style={{ fontWeight: "bold" }}>{subtitle}</span>
        </div>
        {children}
      </div>
    );
  }
}

export default OptionContainer;
