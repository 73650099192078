export const GUIDE_SETUP = "setup";
export const GUIDE_ONBOARD = "onboard";
export const GUIDE_ENGAGE = "engage";
export const GUIDE_GROW = "grow";

//SETUP
export const TOPIC_REVIEW_CHANT_OVERVIEW = "reviewChantOverview";
export const TOPIC_SET_GENERAL_SETTINGS = "setGeneralSettings";
export const TOPIC_ADD_LOCATIONS = "addLocations";
export const TOPIC_ADD_PARTNERS = "addPartners";
export const TOPIC_UPLOAD_PHOTOS = "uploadPhotos";
export const TOPIC_ADD_CHANTS = "addChants";
export const TOPIC_REVIEW_MEMBERSHIP_CARD = "reviewMembershipCard";

//ONBOARD
export const TOPIC_SELECT_ONBOARDING = "selectOnboarding";
export const TOPIC_ASSIGN_LEADERS = "assignLeaders";
export const TOPIC_CREATE_WELCOME_CARD = "createWelcomeCard";
export const TOPIC_INVITE_MEMBERS = "inviteMembers";

//ENGAGE
export const TOPIC_REVIEW_MATCHDAY = "reviewMatchday";
export const TOPIC_POST_EVENTS = "postEvents";
export const TOPIC_ADD_LEADERBOARD_REWARDS = "addLeaderboardRewards";
export const TOPIC_POST_IN_CHAT_STREAM = "postInChatStream";
export const TOPIC_RUN_PREDICTION_GIVEAWAY = "runPredictionGiveaway";
export const TOPIC_RUN_GIVEAWAY = "runGiveaway";
export const TOPIC_PUBLISH_POLL = "publishPoll";
export const TOPIC_SELL_TICKETS = "sellTickets";

//GROW
export const TOPIC_PUBLISH_GROUP = "publishGroup";
export const TOPIC_SELL_MERCHANDISE = "sellMerchandise";
export const TOPIC_PUBLISH_WEBSITE = "publishWebsite";
export const TOPIC_SETUP_CHANT_STADIUM = "setupChantStadium";
export const TOPIC_PROMOTE_GROUP = "promoteGroup";
