import { db, storage } from "../../connection";
import moment from "moment";
import { store } from "../../../../index";
import { applicationError } from "../../../../redux/actions/error";
import {
  collection,
  getDocs,
  limit,
  query,
  writeBatch,
  where,
} from "@firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "@firebase/storage";
import Axios from "axios";
import logger from "../../../../utils/logger";

const imagePath = "tickets/images/";

// Read all the contents
export async function fbGetTickets(ticketId, groupId) {
  let res = null;
  // const getSingleGroupTicket = httpsCallable(fbFunction, 'getSingleGroupTicket');
  // await getSingleGroupTicket({ "groupId": groupId, "ticketId": ticketId })
  //   .then(ticket => {
  //     res = ticket;
  //   })
  //   .catch(error => {
  //   });
  await Axios.get(
    "https://us-central1-chant2019.cloudfunctions.net/getSingleGroupTicket?groupId=" +
      groupId +
      "&ticketId=" +
      ticketId
  )
    .then((ticket) => {
      res = ticket;
    })
    .catch((error) => {
      logger.error(error);
    });
  return res;
}

export function fbGetAllTickets(groupId, tab) {
  let obj = [
    {
      groupTicket: {
        ticketDetail: {
          price: "string",
          row: "string",
          seats: "string",
          section: "string",
        },
        eventName: "string",
        eventDate: "string",
        eventTime: "string",
        eventCode: "string",
        eventDesc: "string",
        eventLocation: "string",
        ticketType: "Bus",
        ticketLimitPerMember: 0,
        totalTickets: 0,
        noOfCheckins: 0,
        lastDownloadOn: 0,
        remainingTickets: 0,
        isbarCodeEnabled: false,
        broughtBy: [
          {
            userDocId: "string",
            noOfTickets: 0,
            encryptedString: "string",
          },
        ],
      },
      match: {
        fixtureId: 327214,
        eventDate: 1584041520,
        eventTimestamp: 0,
        venue: "string",
        groupId: "string",
        homeTeam: {
          teamId: 0,
          teamName: "abc",
          logo: "string",
        },
        awayTeam: {
          teamId: 0,
          teamName: "def",
          logo: "string",
        },
      },
      matchTicket: {},
      showTicketCard: true,
      type: "string",
    },
  ];

  return obj;
}

export function fbDeleteTicket(id, deleteImage, groupId) {
  return new Promise(async (resolve, reject) => {
    getDocs(query(collection(db, "groupAdminPost", id, "rsvp"), limit(1))).then(
      async (querySnapshot) => {
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
          reject({
            message: "This content has rsvps, cant delete the content",
          });
          return;
        }

        if (deleteImage) {
          try {
            await fbDeleteImage(id);
          } catch (err) {
            let message =
              "There was an error deleting the content image, could not complete the operation, contact admin";
            store.dispatch(
              applicationError({
                message,
                intensity: "high",
                err,
                errorCode: "firebase",
              })
            );
            reject({ message });
            return;
          }
        }
        const doc = db
          .collection("groupAdminPost")
          .doc(groupId)
          .collection("posts")
          .doc(id);
        const batch = writeBatch(db);

        batch.delete(doc);
        batch
          .commit()
          .then(() => {
            resolve();
          })
          .catch((err) => {
            let message =
              "There was an error deleting the content completely, could not complete the operation, contact admin";
            store.dispatch(
              applicationError({
                message,
                intensity: "high",
                err,
                errorCode: "firebase",
              })
            );
            reject({ message });
          });
      }
    );
  });
}

// Delete image for content, called by delete content
function fbDeleteImage(id) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    const imageRef = ref(storageRef, `content/images/${id}`);
    deleteObject(imageRef).then(resolve).catch(reject);
  });
}

export function uploadImage(file, id, oldImageName) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    const fileName = oldImageName ? oldImageName : moment().unix();

    const folderRef = ref(storageRef, `${imagePath}${id}/${fileName}`);
    uploadBytes(folderRef, file)
      .then(async (snapshot) => {
        const downloadUrl = await getDownloadURL(snapshot.ref);
        resolve({ downloadUrl, fullPath: snapshot.metadata.fullPath });
      })
      .catch(reject);
  });
}

export function fetchAllTicketTransaction(groupId) {
  return new Promise((res, rej) => {
    getDocs(
      query(
        collection(db, "transaction_record", groupId, "records"),
        where("paymentType", "==", "tickets")
      )
    )
      .then((snap) => {
        let records = [];
        snap.docs.forEach((doc) => {
          let data = doc.data() || {};
          let obj = {};
          let dataTicketParams = data.ticketParams;
          if (!dataTicketParams) {
            dataTicketParams = "{}";
          }
          let ticketParams = JSON.parse(dataTicketParams);
          if (
            ticketParams.purchasedSubTickets &&
            Object.keys(ticketParams.purchasedSubTickets).length !== 0
          ) {
            obj = {
              customerDocId: data.customerDocId,
              userName: ticketParams.userName,
              userEmail: ticketParams.userEmail,
              matchId: ticketParams.fixtureId,
              eventId: ticketParams.eventId,
              eventDate: moment
                .unix(ticketParams.eventTimestamp)
                .format("MMM DD YYYY"),
              amount: ticketParams.totalCost,
              paymentIntent: doc.id,
            };

            Object.values(ticketParams.purchasedSubTickets).forEach(
              (subTicket, index) => {
                obj[`subticket #${index + 1} name`] = subTicket.name;
                obj[`ticketsBought ${index + 1}`] = subTicket.ticketBought;
                (subTicket.attendees || []).forEach(
                  (attendee, attendeeIndex) => {
                    obj[
                      `subticket #${index + 1} Guest name #${attendeeIndex + 1}`
                    ] = attendee.fullName;
                    obj[`subticket #${index + 1} email #${attendeeIndex + 1}`] =
                      attendee.email;
                  }
                );
              }
            );

            records.push(obj);
          }
        });

        res();
      })
      .catch(rej);
  });
}
