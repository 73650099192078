import React from "react";
// import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";
import {
  disableLoginFlow,
  loadingUser,
  startUpdateUser,
} from "../../redux/actions/user";
import { db, fbAuth } from "../../services/firebaseService/connection";
import LoadingModal from "../commons/LoadingModal";
import PhoneNumberInput from "./PhoneNumberInput";
import {
  // getAuth,
  isSignInWithEmailLink,
  RecaptchaVerifier,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPhoneNumber,
} from "firebase/auth";
import { fbGetUserByPhone } from "../../services/firebaseService/endPoints/user";
import logger from "../../utils/logger";
import { isEmailValid } from "../../utils/validation";
import { Spin } from "antd";

class MobileVerification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEmailLogin: false,
      emailSent: false,
      sendingEmail: false,
      phoneNumber: props.phoneNumber || "",
      otp: "",
      isChecked: false,
      confirmResult: "",
      isLoading: false,
      showAlert: { show: false, type: "success", message: "" },
    };
  }

  // auth = getAuth();

  handleisChecked = (e) => {
    e.persist();
    this.setState({ isChecked: !this.state.isChecked });
  };
  handleInputChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePhoneChange = (phoneNumber) => {
    if (this.state.confirmResult) {
      this.setState({ confirmResult: "" });
    }
    this.setState({ phoneNumber });
  };
  // handleOtpChange = otp => {
  //   // if (this.state.confirmResult) {
  //   //   this.setState({ confirmResult: "" });
  //   // }
  //   this.setState({ otp });
  // };

  handleRequestOtp = () => {
    this.setState({ isLoading: true, showAlert: { show: false } });

    // if (!isValidPhoneNumber(this.state.phoneNumber)) {
    //   this.setState({
    //     showAlert: {
    //       show: true,
    //       type: "error",
    //       message: "Enter a valid phone number"
    //     },
    //     isLoading: false
    //   });
    //   return;
    // }
    if (!this.state.isChecked) {
      this.setState({
        showAlert: {
          show: true,
          type: "error",
          message: "Accept Terms & Conditions",
        },
        isLoading: false,
      });
      return;
    }

    fbGetUserByPhone(this.state.phoneNumber).then((userData) => {
      if (
        this.state.phoneNumber === "+14046681194" ||
        (userData && userData.id)
      ) {
        if (userData?.authEmail) {
          this.setState({
            showEmailLogin: true,
            userEmail: userData.email,
            isLoading: false,
          });

          // this.handleEmailLogin(userData?.email);
        } else {
          signInWithPhoneNumber(
            fbAuth,
            this.state.phoneNumber,
            window.recaptchaVerifier
          )
            .then((confirmResult) => {
              this.setState({ confirmResult, isLoading: false });
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
                showAlert: {
                  show: true,
                  type: "error",
                  message:
                    err.message === "TOO_SHORT"
                      ? "Enter a valid phone number"
                      : err.message,
                },
              });
            });
        }
      } else {
        this.setState({
          isLoading: false,
          showAlert: {
            show: true,
            type: "error",
            message:
              "No account found. If logging in for the first time you must first Register in the Chant app.",
          },
        });
      }
    });
  };

  handleVerifyOtp = () => {
    this.setState({ isLoading: true, showAlert: { show: false } });

    this.state.confirmResult
      .confirm(this.state.otp)
      .then((result) => {
        this.setState({ isLoading: false });
        this.props.dispatch(loadingUser());
        if (this.props.path.value) {
          this.props.history.replace(this.props.path.value || "");
        } else {
          this.props.history.replace("/");
        }
        // window.location.reload()
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          showAlert: {
            show: true,
            type: "error",
            message: "Invalid otp, try again",
          },
        });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleEmailLogin = () => {
    if (!isEmailValid(this.state.userEmail)) {
      this.setState({
        showAlert: {
          show: true,
          type: "error",
          message: "Email login failed - invalid email",
        },
      });
      return;
    }

    this.setState({
      // isLoading: true,
      sendingEmail: true,
      showAlert: { show: false },
      userEmail: this.state.userEmail,
    });

    const actionCodeSettings = {
      url: `https://register.chant.fan/login?email=${this.state.userEmail}`,
      handleCodeInApp: true,
      dynamicLinkDomain: "chant.page.link",
    };

    sendSignInLinkToEmail(fbAuth, this.state.userEmail, actionCodeSettings)
      .then(() => {
        this.setState({ sendingEmail: false, emailSent: true });
        this.props.dispatch(disableLoginFlow());
      })
      .catch((err) => {
        this.setState({
          sendingEmail: false,
          showAlert: {
            show: true,
            type: "error",
            message: "Failed to login by email",
          },
        });
      });
  };

  render() {
    return (
      <>
        <div className="container mt-5" style={{ color: "#ffffff" }}>
          <LoadingModal loading={this.state.isLoading} />

          {this.state.showAlert.show && (
            <div class="alert alert-danger" role="alert">
              {this.state.showAlert.message}
            </div>
          )}

          <form
            onSubmit={this.handleSubmit}
            className="p-2 px-md-5 py-md-4 mx-auto"
            style={{
              maxWidth: "700px",
              height: "75vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <div className="form-group">
                <p
                  style={{
                    fontSize: 22,
                    fontWeight: "bold",
                    marginBottom: 0,
                    paddingBottom: "8px",
                    textAlign: "center",
                  }}
                >
                  Admin login
                </p>
                {!this.state.showEmailLogin && (
                  <p style={{ fontSize: 16, fontWeight: "bold" }}>
                    Log in to manage your group. You must first be registered in
                    the app.
                  </p>
                )}
                {/* <PhoneInput
                  className="form-control form-control-sm"
                  placeholder="Enter your phone number"
                  value={this.state.phoneNumber}
                  // value={formatPhoneNumberIntl("+1")}
                  onChange={this.handlePhoneChange}
                  country="US"
                /> */}
                {!this.state.showEmailLogin ? (
                  <div>
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-md-8">
                        <PhoneNumberInput
                          onChange={(phoneNumber) => {
                            this.handlePhoneChange(phoneNumber);
                          }}
                          placeholder="Enter mobile number"
                        />
                      </div>
                    </div>
                    {!this.state.confirmResult ? (
                      <div style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          checked={this.state.isChecked}
                          onChange={this.handleisChecked}
                          style={{
                            marginRight: "7px",
                            marginTop: "10px",
                            marginLeft: "5px",
                          }}
                        />
                        I have read and accept the Chant{" "}
                        <span>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.chant.fan/terms"
                          >
                            Terms of use
                          </a>
                        </span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <p
                      style={{
                        fontSize: "20px",
                        marginBottom: 0,
                        paddingTop: "10px",
                      }}
                    >
                      Authenticate via email
                    </p>
                    <p
                      style={{
                        color: "#EF4045",
                        marginBottom: 0,
                        paddingBottom: "12px",
                      }}
                    >
                      {this.state.userEmail}
                    </p>
                    {!this.state.emailSent && (
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: 0,
                          paddingBottom: "16px",
                        }}
                      >
                        Email must be received on this device. Proceed?
                      </p>
                    )}
                    {this.state.sendingEmail ? (
                      <Spin />
                    ) : !this.state.emailSent ? (
                      <div className="flex-center" style={{ gap: "12px" }}>
                        <button
                          disabled={false}
                          className="btn"
                          style={{
                            border: "2px solid #ffffff",
                            backgroundColor: "transparent",
                            color: "#ffffff",
                          }}
                          onClick={this.handleEmailLogin}
                          id="sign-in-button"
                        >
                          Continue
                        </button>
                        <button
                          disabled={false}
                          className="btn"
                          style={{
                            border: "2px solid #ffffff",
                            backgroundColor: "transparent",
                            color: "#ffffff",
                          }}
                          onClick={() =>
                            this.setState({ showEmailLogin: false })
                          }
                          id="sign-in-button"
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <p
                        style={{
                          fontSize: "16px",
                          marginBottom: 0,
                          paddingBottom: "16px",
                        }}
                      >
                        Email sent.
                      </p>
                    )}
                  </div>
                )}
              </div>

              {!this.state.showEmailLogin && (
                <div className="form-group">
                  {!this.state.confirmResult ? (
                    <>
                      <div className="d-flex justify-content-center mb-3">
                        <button
                          disabled={false}
                          className="btn"
                          style={{
                            border: "2px solid #ffffff",
                            backgroundColor: "transparent",
                            color: "#ffffff",
                          }}
                          onClick={this.handleRequestOtp}
                          id="sign-in-button"
                        >
                          Submit
                        </button>
                      </div>
                      <p style={{ fontSize: "12px", textAlign: "center" }}>
                        Authentication code sent via SMS. Charges may apply.
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="row" style={{ justifyContent: "center" }}>
                        <div className="form-group col-md-8">
                          <label>Enter Verification Code</label>
                          <div
                            style={{
                              background: "transparent",
                              border: "2px solid #ffffff",
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              marginRight: "0.5rem",
                              padding: "5px 10px",
                            }}
                            className="col"
                          >
                            <input
                              placeholder="Enter code sent via text message"
                              value={this.state.otp}
                              className="form-control form-control-sm"
                              name="otp"
                              style={{
                                background: "transparent",
                                border: 0,
                                outline: "none",
                                width: "100%",
                                color: "#ffffff",
                              }}
                              maxLength={6}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn"
                          style={{
                            border: "2px solid #ffffff",
                            backgroundColor: "transparent",
                            color: "#ffffff",
                          }}
                          onClick={
                            this.props.handleVerifyOtp
                              ? this.props.handleVerifyOtp.bind(this)
                              : this.handleVerifyOtp
                          }
                        >
                          Login
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
      </>
    );
  }

  componentDidMount = () => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        fbAuth,
        "sign-in-button",
        {
          size: "invisible",
          callback: () => {},
        }
      );

      window.recaptchaVerifier.render().then((id) => {
        logger.log(id);
      });

      if (isSignInWithEmailLink(fbAuth, window.location.href)) {
        const params = new URLSearchParams(window.location.search);

        let email = params.get("email");

        this.setState({ isLoading: true });

        if (email) {
          signInWithEmailLink(fbAuth, email, window.location.href)
            .then((result) => {
              if (result) {
                this.setState({ isLoading: false });
                this.props.dispatch(loadingUser());
                if (this.props.path.value) {
                  this.props.history.replace(this.props.path.value || "");
                } else {
                  this.props.history.replace("/");
                }
              }
            })
            .catch(() => {
              this.setState({
                isLoading: false,
                showAlert: {
                  show: true,
                  type: "error",
                  message: "Failed to login by email link",
                },
              });
            });
        }

        this.setState({ isLoading: false });
      }
      // }
    } catch (e) {
      this.setState({
        isLoading: false,
        showAlert: {
          show: true,
          type: "error",
          message:
            "Captcha Error in Safari? Either use Chrome (advised) or in Safari Settings/Privacy uncheck ‘prevent cross-site ip tracking’ and ‘hide ip addresses from trackers’. Ensure either google or captcha.net are allowed to use 3rd party cookies.",
        },
      });
    }
  };

  componentWillUnmount() {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = "";
    }
  }
}

const mapStateToProps = (state) => {
  return {
    path: state.path,
  };
};

export default connect(mapStateToProps)(MobileVerification);
