import { Table, Tooltip } from "antd";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import {
  getStoreItems,
  setSortOrder,
} from "../../../services/firebaseService/endPoints/admin/store";
import { DraggableBodyRow } from "../ourChants/List";
import GripVertical from "../../../images/grip-vertical.svg";
import { getCurrencyUnicode } from "../../../helperFunctions/util";

class Store extends React.Component {
  state = {
    needsUpdate: false,
    selectedTab: "merch",
  };

  moveRow = (dragIndex, hoverIndex) => {
    const newData = JSON.parse(JSON.stringify(this.props.tableData));
    newData[dragIndex].sortIndex = hoverIndex + 1;
    let sortIndexChangeStep = hoverIndex - dragIndex > 0 ? -1 : 1;
    let i = sortIndexChangeStep > 0 ? hoverIndex : dragIndex + 1;
    let finalStep = sortIndexChangeStep < 0 ? hoverIndex : dragIndex - 1;
    for (; i <= finalStep; i++) {
      newData[i].sortIndex += sortIndexChangeStep;
    }

    this.props.updateTable(newData);
    this.props.mandateUpdate();
  };

  render() {
    const components = {
      body: {
        row: DraggableBodyRow,
      },
    };

    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    const columns = [
      {
        width: 80,
        render: (data) => {
          return (
            <div className="row">
              <img src={GripVertical} alt="*" />
              {data && data.id && (
                <i
                  className="fa fa-pencil cursor-pointer ml-3"
                  onClick={() => {
                    this.props.goToPath(`/admin/store/edit/${data.id}`);
                  }}
                ></i>
              )}
              {/* <i className='fa fa-user cursor-pointer ml-3'></i> */}
            </div>
          );
        },
      },
      {
        title: "Item",
        className: "header-white",
        width: 250,
        dataIndex: "name",
      },
      {
        title: "Status",
        className: "header-white",
        width: 100,
        dataIndex: "status",
      },
      {
        title: "Display On",
        className: "header-white",
        width: 150,
        dataIndex: "displayOn",
        render: (list, record) =>
          record.status === "live" ? (
            <ul style={{ listStyleType: "none" }}>
              {list.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          ) : (
            ""
          ),
      },
      {
        title: "Quantity",
        className: "header-white",
        dataIndex: "comboValues",
        render: (data, record) => {
          let quantity = 0;
          if (data) {
            quantity = data.reduce((acc, val) => {
              return acc + val.quantity;
            }, 0);
          } else {
            quantity = record.quantity;
          }
          if (!isNaN(quantity)) {
            return quantity;
          }
          return "";
        },
      },
      {
        title: "Sold",
        className: "header-white",
        dataIndex: "comboValues",
        render: (data, record) => {
          let sold = 0;
          if (data) {
            sold = data.reduce((acc, val) => {
              return acc + (val.sold || 0);
            }, 0);
          } else {
            sold = record.sold;
          }

          return <span>{!isNaN(sold) ? sold : ""}</span>;
        },
      },
      {
        title: "Remaining",
        className: "header-white",
        dataIndex: "comboValues",
        render: (data, record) => {
          let quantity = 0;
          if (data) {
            quantity = data.reduce((acc, val) => {
              return acc + val.quantity;
            }, 0);
          } else {
            quantity = record.quantity;
          }

          let sold = 0;
          if (data) {
            sold = data.reduce((acc, val) => {
              return acc + val.sold;
            }, 0);
          } else {
            sold = record.sold;
          }

          return (
            <span>
              {quantity && sold ? quantity - sold : quantity ? quantity : ""}
            </span>
          );
        },
      },
      {
        title: (
          <span>
            Revenue
            <Tooltip
              title={
                <div style={{ maxWidth: 400 }}>
                  <span>
                    'Included' items (merchandise that is 'included' in a
                    membership packages) is not represented here as revenue.
                    That revenue is displayed as part of the membership package
                    in Member table.{" "}
                  </span>
                </div>
              }
              placement="topLeft"
            >
              <span className="border-0">
                &nbsp;<i className="fa fa-question-circle-o"></i>
              </span>
            </Tooltip>
          </span>
        ),
        className: "header-white",
        dataIndex: "revenue",
        render: (data) => {
          let revenue = 0;
          if (data) {
            revenue = data;
          }

          return (
            <span>
              {!isNaN(revenue)
                ? `${groupCurrency}${revenue.toFixed(Math.round(revenue) === revenue ? 0 : 2)}`
                : ""}
            </span>
          );
        },
      },
      // {
      //     title: <span>
      //         Shipping
      //     </span>,
      //     dataIndex: 'shipping',
      //     render: (data) => {
      //         let shipping = 0;
      //         if (data) {
      //             shipping = data;
      //         }

      //         return <span>{!isNaN(shipping) ? `$${shipping}` : ''}</span>;
      //     }
      // },
    ];

    let newItem = this.props.tableData.filter((elem) => elem.sortIndex === 0);

    let dataSource = this.props.tableData.sort(
      (row1, row2) => row1.sortIndex - row2.sortIndex
    );

    if (newItem.length > 0) {
      dataSource.forEach((elem) => {
        elem.sortIndex += 1;
      });
    }
    return (
      <div
        className="mx-auto"
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        {/* <div className='row mt-3' style={{
                alignItems: 'end',
                borderBottom: '1px solid #888888'
            }}>
                <div className='col-md-7'>
                    <ul className="nav nav-tabs" style={{ fontSize: "15px" }}>
                        <li className="nav-item" onClick={this.changeTab}>
                            <a
                                name="merch"
                                className={
                                    this.props.match.params.list === "merch"
                                        ? "nav-link active a-color-primary"
                                        : "nav-link a-color-secondary"
                                }
                            >
                                <span style={{
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>Merchandise</span>
                            </a>
                        </li>
                    </ul>
                </div>
                
                <div className='col-md-5 mb-2'>
                    <div className='row' style={{ justifyContent: 'flex-end' }}>
                        {this.state.needsUpdate && <button
                            className='mr-2'
                            style={{
                                backgroundColor: '#ff4040',
                                borderRadius: 5,
                                border: '0px none',
                                boxShadow: 'transparent 0px 0px 0px',
                                outline: 'none',
                                padding: '5px 10px',
                                width: '200px',
                                color: '#ffffff',
                                alignSelf: "flex-end",
                                height: 40
                            }}
                            onClick={() => {
                                let idOrderMap = this.state.tableData.reduce((acc, val, index) => {
                                    acc[val.id] = val.sortIndex || index;
                                    return acc;
                                }, {});

                                setSortOrder(idOrderMap, this.props.currentGroup).then(() => {
                                    this.setState({ needsUpdate: false });
                                });
                            }}

                        >
                            Update Changes
                        </button>}
                        <button
                            style={{
                                backgroundColor: '#ff4040',
                                borderRadius: 5,
                                border: '0px none',
                                boxShadow: 'transparent 0px 0px 0px',
                                outline: 'none',
                                padding: '5px 10px',
                                width: '200px',
                                color: '#ffffff',
                                alignSelf: "flex-end",
                                height: 40
                            }}
                            onClick={() => {
                                this.props.history.push('/admin/store/create');
                            }}

                        >
                            Add Item
                        </button>
                    </div>
                </div>
            </div> */}
        <div className="mt-3">
          <DndProvider backend={HTML5Backend}>
            <Table
              columns={columns}
              dataSource={dataSource}
              components={components}
              onRow={(_, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              bodyStyle={{
                backgroundColor: "#ffffff",
              }}
              pagination={
                dataSource.length > 50
                  ? { pageSize: 50, position: "top" }
                  : false
              }
              className="table-backdrop"
              scroll={{ x: "max-content" }}
            />
          </DndProvider>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.changeHeaderColor();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.changeHeaderColor();
    }
  }

  changeHeaderColor = () => {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Store);
