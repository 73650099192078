import { Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import { db } from "../../../services/firebaseService/connection";
import { fbGetPartners } from "../../../services/firebaseService/endPoints/admin/partners";
import { doc, setDoc } from "firebase/firestore";
import { getWebsiteConfig } from "../../../services/firebaseService/endPoints/admin/website";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";

class CommunityPartners extends React.Component {
  state = {
    overview: "",
    partners: [],
    isSaving: false,
    // imageFileKeys: [['logo', 'image1', 'image2', 'image3']]
  };

  saveToFirebase = () => {
    const save = async () => {
      this.setState({ isSaving: true });
      let communityPartners = {
        overview: this.state.overview,
      };
      let docRef = doc(db, "website", this.props.adminData.data.id);
      setDoc(docRef, { communityPartners }, { merge: true }).then(() => {
        this.fetchData();
        postUpdateCDNUtil(this.props.currentGroup);
        this.setState({ isSaving: false });
      });
    };
    save();
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div
        className="border rounded"
        style={{
          backgroundColor: "#ffffff",
          // boxShadow: "0px 3px 10px 1px",
          marginTop: 30,
          padding: "15px 30px",
        }}
      >
        <p style={{ fontSize: "20", fontWeight: "bold", marginBottom: -20 }}>
          Community Partners <span style={{ fontSize: 14 }}>(optional)</span>
        </p>
        <div className="row" style={{ padding: "0px 1rem" }}>
          <div style={{ flex: 2, paddingTop: 27 }}>Overview</div>
          <div style={{ flex: 8, padding: "0px 3rem" }}>
            <div className="form-group col">
              <div>
                <label></label>
              </div>
              <textarea
                className="placeholder-light"
                style={{
                  borderRadius: 5,
                  borderColor: "#ced4da",
                  width: "100%",
                }}
                value={this.state.overview}
                // rows={3}
                maxLength={1000}
                name="overview"
                placeholder="(optional) brief description (max 250 chars)"
                tabIndex="2"
                onChange={(e) => {
                  this.setState({ overview: e.target.value });
                }}
              />
            </div>
          </div>
          <div style={{ flex: 1, padding: "0px 1rem" }}>
            <div className="d-flex justify-content-center">
              <div className="p-2 mt-2">
                <button
                  style={{
                    backgroundColor: primaryColor,
                    borderRadius: 5,
                    border: "0px none",
                    boxShadow: "transparent 0px 0px 0px",
                    outline: "none",
                    padding: "5px 10px",
                  }}
                  className="default-text-color"
                  disabled={this.state.isSaving}
                  onClick={this.saveToFirebase}
                >
                  {this.state.isSaving ? (
                    <Spin size="small" spinning={true} />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: "0px 1rem" }}>
          <div className="col-12">
            <div className="row">
              <div className="col">
                <p>
                  The partners listed below will appear in the Partners section
                  of your website. Set via the Partners section of the admin
                  dashboard.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ul style={{ listStyleType: "none" }}>
                  {this.state.partners.map((partner) => (
                    <li>{partner.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div>
            {/* {this.state.partners.map((partner, index) => {
                        return (
                            <>
                                <div className="row" style={{ marginTop: -20 }}>
                                    <div style={{ flex: 1, paddingTop: 27 }}>Partner #{index + 1}</div>
                                    <div style={{ flex: 3 }}>
                                        <FInput
                                            value={partner.name}
                                            onChange={(e) => this.handleInputChange(e, index)}
                                            name="name"
                                            display="col"
                                            placeholder="Enter Partner Name"
                                            tabIndex="2"
                                        />
                                        <div className="form-group col">
                                            <ImageUploadButton
                                                text="Add logo (or primary image)"
                                                aspectRatio={1}
                                                images={[this.state.partners[index].logo]}
                                                onSave={(images) => {
                                                    let partners = this.state.partners;
                                                    partners[index].logo = images[0]
                                                    this.setState({
                                                        partners
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col">
                                            
                                            <div style={{ border: '1px solid #ced4da', borderRadius: 5 }}>
                                                <Editor
                                                    editorState={partner.desc}
                                                    onChange
                                                    onEditorStateChange={(state) => this.handleEditorStateChange(state, index, 'desc')}
                                                    toolbar={{
                                                        options: ['inline', 'list', 'link'],
                                                        inline: {
                                                            inDropdown: false,
                                                            options: ['bold', 'italic', 'underline'],
                                                        },
                                                        list: {
                                                            inDropdown: false,
                                                            options: ['unordered', 'ordered'],
                                                        },
                                                        link: {
                                                            inDropdown: false,
                                                            defaultTargetOption: '_self',
                                                            options: ['link', 'unlink'],
                                                            linkCallback: undefined
                                                        },
                                                    }}
                                                    stripPastedStyles={true}
                                                    tabIndex='2'
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: '0px 1rem' }}>
                                            <div className="pr-1">
                                                <div className="form-group col">
                                                    <ImageUploadButton
                                                        text="(optional) Add image #1"
                                                        images={[this.state.partners[index].image1]}
                                                        onSave={(images) => {
                                                            let partners = this.state.partners;
                                                            partners[index].image1 = images[0]
                                                            this.setState({
                                                                partners
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-1">
                                                <div className="form-group col">
                                                    <ImageUploadButton
                                                        text="(optional) Add image #2"
                                                        images={[this.state.partners[index].image2]}
                                                        onSave={(images) => {
                                                            let partners = this.state.partners;
                                                            partners[index].image2 = images[0]
                                                            this.setState({
                                                                partners
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-1">
                                                <div className="form-group col">
                                                    <ImageUploadButton
                                                        text="(optional) Add image #3"
                                                        images={[this.state.partners[index].image3]}
                                                        onSave={(images) => {
                                                            let partners = this.state.partners;
                                                            partners[index].image3 = images[0]
                                                            this.setState({
                                                                partners
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div style={{ flex: 1 }}>Collect donations?</div>
                                    <div className="row" style={{ flex: 3, padding: '0px 1rem' }} >
                                        <div className="form-group col">
                                            <input type='checkbox' checked={partner.shouldCollectDonation} name="shouldCollectDonation" style={{ height: "20px", width: "20px", borderRadius: 5 }} onChange={(e) => this.handleInputChange({ target: { name: e.target.name, value: e.target.checked } }, index)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div style={{ flex: 1, paddingTop: 27 }}>Suggested amounts</div>
                                    <div className="row" style={{ flex: 3, padding: '0px 1rem' }}>
                                        <FInput
                                            className="no-arrow-field"
                                            value={partner.suggestedAmounts[0]}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                let suggestedAmounts = partner.suggestedAmounts;
                                                if (Number(e.target.value) > 0) {
                                                    suggestedAmounts[0] = e.target.value.indexOf('.') !== -1 ? Number(e.target.value.slice(0, e.target.value.indexOf('.') + 3)) : Number(e.target.value);
                                                    this.handleInputChange({ target: { name: 'suggestedAmounts', value: suggestedAmounts } }, index)
                                                } else if (Number(e.target.value)===0) {
                                                    suggestedAmounts[0] = '';
                                                    this.handleInputChange({ target: { name: 'suggestedAmounts', value: suggestedAmounts } }, index)
                                                } else {
                                                    this.handleInputChange({ target: { name: 'suggestedAmounts', value: suggestedAmounts } }, index)
                                                }
                                            }
                                            }

                                            name="suggestedAmounts"
                                            display="col"
                                            tabIndex="2"
                                            type="number"
                                            style={{ width: 100 }}
                                        />
                                        <FInput
                                            className="no-arrow-field"
                                            value={partner.suggestedAmounts[1]}
                                            onChange={(e) => {
                                                let suggestedAmounts = partner.suggestedAmounts;
                                                suggestedAmounts[1] = e.target.value.indexOf('.') !== -1 ? Number(e.target.value.slice(0, e.target.value.indexOf('.') + 3)) : Number(e.target.value);
                                                this.handleInputChange({ target: { name: 'suggestedAmounts', value: suggestedAmounts } }, index)
                                            }}
                                            name="suggestedAmounts"
                                            display="col"
                                            tabIndex="2"
                                            type="number"
                                            style={{ width: 100 }}
                                        />

                                        <FInput
                                            className="no-arrow-field"
                                            value={partner.suggestedAmounts[2]}
                                            onChange={(e) => {
                                                let suggestedAmounts = partner.suggestedAmounts;
                                                suggestedAmounts[2] = e.target.value.indexOf('.') !== -1 ? Number(e.target.value.slice(0, e.target.value.indexOf('.') + 3)) : Number(e.target.value);
                                                this.handleInputChange({ target: { name: 'suggestedAmounts', value: suggestedAmounts } }, index)
                                            }}
                                            name="suggestedAmounts"
                                            display="col"
                                            tabIndex="2"
                                            type="number"
                                            style={{ width: 100 }}
                                        />

                                    </div>
                                </div>

                            </>)

                    })} */}
            {/* <div className="d-flex">
                        <div style={{ flex: 1 }}>

                        </div>
                        <div style={{ flex: 3 }}>

                            <Button
                                type="link"
                                onClick={() => {
                                    let partners = this.state.partners;
                                    partners.push(PARTNER_TEMPLATE());
                                    this.setState({
                                        partners: partners
                                    });
                                }}>
                                &#43; Add Partner
                            </Button>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
    );
  }

  fetchData() {
    getWebsiteConfig(this.props.currentGroup).then((data) => {
      let overview = (data.communityPartners || {}).overview;
      this.setState({
        overview: overview,
      });
    });
    fbGetPartners(this.props.currentGroup).then((data) => {
      let partners = data.filter(
        (partner) =>
          (!partner.status || partner.status === "Active") &&
          partner.displayOn.includes("Website: Partners")
      );
      this.setState({
        partners,
      });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(CommunityPartners);
