import { message } from "antd";
import { continents, countries } from "countries-list";
import moment from "moment";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";
import { db, storage } from "../connection";
import { fbGetGroups } from "./group";
import logger from "../../../utils/logger";

let countryCodes = {};
Object.keys(countries).forEach((code) => {
  countryCodes[code] = countries[code].name;
});

export async function fbGetPolls() {
  let members = await getAllMembers();
  return new Promise((resolve, reject) => {
    try {
      db.collection("polls")
        .doc("superadmin_polls")
        .collection("poll")
        .get()
        .then((snap) => {
          let res = [];
          snap.docs.forEach((doc) => {
            let stats = {};
            let optKeys = ["op1", "op2", "op3", "op4", "op5"];
            optKeys
              .filter((key) => doc.data()[key] && doc.data()[key] !== null)
              .forEach((key) => {
                stats[doc.data()[key].name] =
                  (doc.data()[key].votes * 100) / doc.data().noOfPolls;
                if (isNaN(stats[doc.data()[key].name])) {
                  stats[doc.data()[key].name] = 0;
                }
              });
            let groups = doc.data().groups;
            let targetMembersCount;

            if (groups.includes("global")) {
              targetMembersCount = members.length;
            } else {
              targetMembersCount = members.filter(
                (member) =>
                  groups.includes(member.groupId) ||
                  groups.includes(members.leagueId) ||
                  groups.includes(members.clubId) ||
                  groups.includes(continents[members.region]) ||
                  groups.includes(countryCodes[members.country])
              ).length;
            }

            res.push({ ...doc.data(), id: doc.id, stats, targetMembersCount });
          });

          let polls = res;
          let filteredPolls = polls.filter((poll) => !poll.isActive);
          polls = [
            ...polls
              .filter(
                (poll) => poll.isActive && moment().unix() < poll.closesOn
              )
              .sort((a, b) => b.addedOn - a.addedOn),
            ...polls
              .filter((poll) => moment().unix() > poll.closesOn)
              .sort((a, b) => b.addedOn - a.addedOn),
            ...filteredPolls.sort((a, b) => b.addedOn - a.addedOn),
          ];

          resolve(polls);
        });
    } catch (err) {
      message = "Error fetching polls";
      store.dispatch(
        applicationError({ err, message, show: true, intensity: "mid" })
      );
      reject({ message });
    }
  });
}

export function fbCreateSuperAdminPoll(data, pollImage, sponsorImage) {
  return new Promise(async (resolve, reject) => {
    let docRef = db
      .collection("polls")
      .doc(`superadmin_polls`)
      .collection("poll")
      .doc();

    let pollImageUrl;
    let sponsorImageUrl;
    if (pollImage) {
      try {
        pollImageUrl = await fbUploadPollImage(pollImage, docRef);
        data.pollImage = pollImageUrl || "";
      } catch (e) {
        let message =
          "Error uploading poll image, operation could not be completed, contact admin";
        reject({ message });
        return;
      }
    }
    if (sponsorImage) {
      try {
        sponsorImageUrl = await fbUploadSponsorImage(sponsorImage, docRef);
        data.sponsorImage = sponsorImageUrl || "";
      } catch (e) {
        let message =
          "Error uploading sponsor image, operation could not be completed, contact admin";
        reject({ message });
        return;
      }
    }

    await docRef
      .set(
        {
          ...data,
        },
        {
          merge: true,
        }
      )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fbUpdateSuperAdminPoll(data, pollImage, sponsorImage, id) {
  return new Promise(async (resolve, reject) => {
    let docRef = db
      .collection("polls")
      .doc(`superadmin_polls`)
      .collection("poll")
      .doc(id);

    let pollImageUrl;
    let sponsorImageUrl;
    if (pollImage) {
      try {
        pollImageUrl = await fbUploadPollImage(pollImage, docRef);
        data.pollImage = pollImageUrl || "";
      } catch (e) {
        let message =
          "Error uploading poll image, operation could not be completed, contact admin";
        reject({ message });
        return;
      }
    }
    if (sponsorImage) {
      try {
        sponsorImageUrl = await fbUploadSponsorImage(sponsorImage, docRef);
        data.sponsorImage = sponsorImageUrl || "";
      } catch (e) {
        let message =
          "Error uploading sponsor image, operation could not be completed, contact admin";
        reject({ message });
        return;
      }
    }

    await docRef
      .set(
        {
          ...data,
        },
        {
          merge: true,
        }
      )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getPoll(id) {
  return new Promise((resolve, reject) => {
    db.collection("polls")
      .doc("superadmin_polls")
      .collection("poll")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve({ ...doc.data(), id: doc.id });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function fbUploadPollImage(image, ref) {
  let path = `/polls/superadmin/${ref.id}/pollImage`;
  return uploadImage(image, path);
}
function fbUploadSponsorImage(image, ref) {
  let path = `/polls/superadmin/${ref.id}/sponsorImage`;
  return uploadImage(image, path);
}

async function uploadImage(image, path) {
  let storageRef = storage.ref(path);
  let url = await storageRef
    .put(image)
    .then((snap) => {
      return snap.ref.getDownloadURL();
    })
    .then((url) => url);
  return url;
}
async function getAllMembers() {
  let groups = await fbGetGroups();

  let memberPromises = groups.map((group) => {
    return db
      .collection("userGroup")
      .doc(group.id)
      .collection("members")
      .get()
      .then((snap) => {
        let res = [];
        snap.docs.forEach((doc) => {
          res.push({
            ...doc.data(),
            id: doc.id,
            country: group.supportersCountry,
            region: group.supportersRegion,
            groupName: group.groupName,
            groupId: group.id,
            leagueId: group.leagueId,
            clubId: group.clubId,
          });
        });

        return res;
      });
  });

  let members = await Promise.all(memberPromises)
    .then((membersArrays) => {
      let res = [];
      membersArrays.forEach((membersArray) => {
        res.push(...membersArray);
      });
      return res;
    })
    .catch((err) => {
      logger.error(err);
    });
  return members;
}

export async function postLive(id) {
  let docRef = db
    .collection("polls")
    .doc("superadmin_polls")
    .collection("poll")
    .doc(id);

  let doc = await docRef.get().then((doc) => doc.data());

  let data = {
    isActive: true,
    postedOn: moment().unix(),
    closesOn:
      moment().unix() +
      doc.duration.days * 24 * 60 * 60 +
      doc.duration.hours * 60 * 60,
  };

  await docRef.update({ ...data });
}

export async function deletePolls(id) {
  await db
    .collection("polls")
    .doc(`superadmin_polls`)
    .collection("poll")
    .doc(id)
    .delete();
}
