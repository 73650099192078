import React from "react";
import { connect } from "react-redux";
import AppstoreBadge from "../../../images/appstore-badge.png";
import GooglePlayBadge from "../../../images/google-play-badge.png";
import { Modal } from "antd";

class GetStarted extends React.Component {
  state = {
    showModal: false,
  };
  componentDidMount() {
    this.setState({
      showModal: this.props.showModal,
    });
    setTimeout(() => {
      this.setState({
        showModal: false,
      });
    }, 5000);
  }
  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <>
        {this.state.showModal && (
          <Modal
            okText=""
            cancelText=""
            onCancel={() => {}}
            onOk={() => {}}
            closable={false}
            closeIcon={null}
            footer={null}
            visible={this.state.showModal}
            width={500}
            bodyStyle={{
              backgroundColor: primaryColor,
              color: "#ffffff",
            }}
          >
            <div className="col-12 py-5">
              <div className="row" style={{ justifyContent: "center" }}>
                <span style={{ fontSize: 30 }}>Congrats!</span>
              </div>
              <div className="row px-4">
                <span style={{ fontSize: 20, textAlign: "center" }}>
                  Your group has been submitted and will be live soon after
                  review.
                </span>
              </div>
            </div>
          </Modal>
        )}
        <div className="col">
          <p>
            New to Chant? Explore your new {this.props.adminData.data.groupName}{" "}
            app and the admin dashboard (best via laptop).
          </p>
        </div>
        <br />
        <div className="col">
          <div className="row">
            <div className="col-md-4">
              <h5 style={{ fontWeight: "bold" }}>Download Chant & log in.</h5>
              <p style={{ fontSize: 14 }}>
                You are registered and can log into the Chant app – private &
                personalized for your group.
              </p>
              <p style={{ fontSize: 14 }}>
                Add other members by pre-registering them in Members / Onboard
                above. They will then ‘Register’ in the app.
              </p>
              <p style={{ fontSize: 14 }}>
                Interested in having a full website? See the website section
                above.
              </p>
            </div>
            <div
              className="col-md-8"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <a
                href="https://apps.apple.com/us/app/chant/id1477159385#?platform=iphone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AppstoreBadge} style={{ width: 220 }} />
              </a>
              <a
                href="https://www.google.com/url?q=https://play.google.com/store/apps/details?id%3Dcom.geekyants.services.chant&sa=D&source=editors&ust=1627655534193000&usg=AOvVaw2s_yFiXGPhTWCjmFL1Mrya"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GooglePlayBadge} style={{ width: 220 }} />
              </a>
            </div>
          </div>
        </div>
        <br />
        <div className="col">
          <div className="row">
            <div className="col-md">
              <h5 style={{ fontWeight: "bold" }}>What next?</h5>
              <p style={{ fontSize: 14 }}>
                Use the Get Started tool to guide you through setup and launch
                your group on Chant. Reach out to support@chant.fan if you would
                like a demo.
              </p>
            </div>
            {/* <div
              className="col-md-6 d-flex"
              style={{ flexDirection: "column" }}
            >
              <div
                className="row mb-1"
                style={{ height: "100%", justifyContent: "space-around" }}
              >
                <div
                  className="col border d-flex mr-2"
                  onClick={() => {
                    this.props.history.replace("/admin/groupSettings");
                  }}
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: primaryColor,
                    color: "#ffffff",
                    fontSize: 20,
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  Select Group Colors
                </div>
                <div
                  className="col border d-flex ml-2"
                  onClick={() => {
                    this.props.history.replace("/admin/members/csv");
                  }}
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: primaryColor,
                    color: "#ffffff",
                    fontSize: 20,
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  Onboard Members
                </div>
              </div>
              <div
                className="row"
                style={{ height: "100%", justifyContent: "space-around" }}
              >
                <div
                  className="col border d-flex mr-2"
                  onClick={() => {
                    this.props.history.replace("/admin/content");
                  }}
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: primaryColor,
                    color: "#ffffff",
                    fontSize: 20,
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  Add Events
                </div>

                <div
                  className="col border d-flex ml-2"
                  onClick={() => {
                    this.props.history.replace("/admin/help");
                  }}
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: primaryColor,
                    color: "#ffffff",
                    fontSize: 20,
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  Review Help
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <br />
        <div className="col">
          <h5 style={{ fontWeight: "bold" }}>
            Questions? Reach out to support@chant.fan
          </h5>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(GetStarted);
