import { store } from "../..";
import { applicationError } from "../../redux/actions/error";
import { openTransaction } from "./connection";

export function addGroupsToLocal(groupList) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().groups.connection;
    const groups = openTransaction(
      indexDb,
      `superadmin-groups`,
      `superadmin-groups`,
      "readwrite"
    );
    if (!groups) return;

    groupList.forEach((group, index) => {
      const addRequest = groups.add(group);
      addRequest.onsuccess = function (event) {
        if (index === groupList.length - 1) {
          resolve();
        }
      };
      addRequest.onerror = function (event) {};
    });
  });
}

export function clearGroupsFromLocal() {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().groups.connection;
    const groups = openTransaction(
      indexDb,
      `superadmin-groups`,
      `superadmin-groups`,
      "readwrite"
    );
    if (!groups) return;

    const clearRequest = groups.clear();
    clearRequest.onsuccess = function (event) {
      resolve();
    };
    clearRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error clearing groups from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}

// export function editContentInLocal(content, tab, groupId) {
//     return new Promise((resolve, reject) => {
//         const indexDb = store.getState().adminData.connection
//         const cursorRequest = indexDb.transaction(`${tab}Contents-${groupId}`, 'readwrite').objectStore(`${tab}Contents-${groupId}`).openCursor(content.id)
//         cursorRequest.onsuccess = function (event) {
//             let cursor = cursorRequest.result
//             if (cursor) {
//                 let data = cursor.value
//                 cursor.update({ ...data, ...content })
//             }
//         }
//     })
// }

export function getGroupsFromLocal(skip, limit, orderBy, order, filters) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().groups.connection;
    let contents = openTransaction(
      indexDb,
      `superadmin-groups`,
      `superadmin-groups`
    );
    if (!contents) return;

    let getRequest;
    if (orderBy) {
      contents = contents.index(orderBy);
      getRequest = contents.openCursor(null, order);
    } else {
      contents = contents.index("pageId");
      getRequest = contents.openCursor();
    }
    let skiped = false,
      index = 0;
    const result = [];
    let hasFilters =
      Object.keys(filters).filter((key) => filters[key]).length > 0;
    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        if (!hasFilters && !skiped && skip) {
          cursor.advance(skip);
          skiped = true;
        } else {
          let predicate = true;
          let value = cursor.value;
          if (
            filters &&
            filters.search &&
            !value.groupName
              .toLowerCase()
              .includes(filters.search.toLowerCase())
          ) {
            predicate = false;
          }
          if (
            predicate &&
            filters &&
            filters.club &&
            value.clubId !== filters.club
          ) {
            predicate = false;
          }
          if (
            predicate &&
            filters &&
            filters.league &&
            value.leagueId !== filters.league
          ) {
            predicate = false;
          }
          if (
            predicate &&
            filters &&
            filters.status &&
            value.status !== filters.status
          ) {
            predicate = false;
          }
          if (
            predicate &&
            filters &&
            filters.owner &&
            value.owner !== filters.owner
          ) {
            predicate = false;
          }
          if (predicate && filters && filters.adoption) {
            let hasStripe = !!(
              value.paymentDetails && value.paymentDetails.stripeAccountId
            );
            if (filters.adoption === "Stripe" && !hasStripe) {
              predicate = false;
            } else if (
              filters.adoption === "Website" &&
              !value.websitePublished
            ) {
              predicate = false;
            } else if (
              filters.adoption === "Directory" &&
              !value.directoryPublished
            ) {
              predicate = false;
            } else if (
              filters.adoption === "Stadium" &&
              !value.stadiumPublished
            ) {
              predicate = false;
            } else if (
              filters.adoption === "Global" &&
              !value.clubPostAllowed
            ) {
              predicate = false;
            }
          }
          if (!hasFilters) {
            if (index < limit) {
              if (predicate) {
                result.push(cursor.value);
                index++;
              }
              cursor.continue();
            } else {
              cursor.advance(1000);
            }
          } else {
            if (predicate) {
              result.push(cursor.value);
              index++;
            }
            cursor.continue();
          }
        }
      } else {
        resolve(result);
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching groups from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}

export function searchGroupsFromLocal(searchText, field) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().groups.connection;
    let contents = openTransaction(
      indexDb,
      `superadmin-groups`,
      `superadmin-groups`
    );
    if (!contents) return;
    contents = contents.index("pageId");
    const getRequest = contents.openCursor();
    const result = [];
    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        try {
          if (cursor.value[field].match(new RegExp(searchText, "i"))) {
            result.push(cursor.value);
          }
        } catch (err) {}
        cursor.continue();
      } else {
        resolve(result);
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching groups from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}

export function editGroupInLocal(content) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().groups.connection;
    const groups = openTransaction(
      indexDb,
      `superadmin-groups`,
      `superadmin-groups`,
      "readwrite"
    );
    if (!groups) return;
    const cursorRequest = groups.openCursor(content.id);
    cursorRequest.onsuccess = function (event) {
      let cursor = cursorRequest.result;
      if (cursor) {
        let data = cursor.value;
        if (data.children && data.children.length > 0 && !!!content.children) {
          delete data.children;
        }
        cursor.update({ ...data, ...content });
        resolve();
      }
    };
  });
}

export function getStats(filters) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().groups.connection;
    let contents = openTransaction(
      indexDb,
      `superadmin-groups`,
      `superadmin-groups`
    );
    if (!contents) return;

    contents = contents.index("pageId");
    let getRequest = contents.openCursor();
    let stats = {
      regMembers: 0,
      preRegMembers: 0,
      totalMembers: 0,
    };
    let hasFilters =
      Object.keys(filters).filter((key) => filters[key]).length > 0;
    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        let predicate = true;
        let value = cursor.value;
        if (
          filters &&
          filters.search &&
          !value.groupName.toLowerCase().includes(filters.search.toLowerCase())
        ) {
          predicate = false;
        }
        if (
          predicate &&
          filters &&
          filters.club &&
          value.clubId !== filters.club
        ) {
          predicate = false;
        }
        if (
          predicate &&
          filters &&
          filters.league &&
          value.leagueId !== filters.league
        ) {
          predicate = false;
        }
        if (
          predicate &&
          filters &&
          filters.status &&
          value.status !== filters.status
        ) {
          predicate = false;
        }
        if (
          predicate &&
          filters &&
          filters.owner &&
          value.owner !== filters.owner
        ) {
          predicate = false;
        }
        if (predicate && filters && filters.adoption) {
          let hasStripe = !!(
            value.paymentDetails && value.paymentDetails.stripeAccountId
          );
          if (filters.adoption === "Stripe" && !hasStripe) {
            predicate = false;
          } else if (
            filters.adoption === "Website" &&
            !value.websitePublished
          ) {
            predicate = false;
          } else if (
            filters.adoption === "Directory" &&
            !value.directoryPublished
          ) {
            predicate = false;
          } else if (
            filters.adoption === "Stadium" &&
            !value.stadiumPublished
          ) {
            predicate = false;
          } else if (filters.adoption === "Global" && !value.clubPostAllowed) {
            predicate = false;
          }
        }
        if (hasFilters) {
          if (predicate) {
            stats.regMembers += cursor.value.registeredMembers;
            stats.preRegMembers += cursor.value.preRegisteredMemberCount;
            stats.totalMembers += cursor.value.totalMembers;
          }
        } else {
          stats.regMembers += cursor.value.registeredMembers;
          stats.preRegMembers += cursor.value.preRegisteredMemberCount;
          stats.totalMembers += cursor.value.totalMembers;
        }

        cursor.continue();
      } else {
        resolve(stats);
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching groups from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}

export async function fetchAllClubsAndLeagues() {
  return new Promise((resolve, reject) => {
    let allClubs = [];
    let allLeagues = [];
    const indexDb = store.getState().groups.connection;
    let contents = openTransaction(
      indexDb,
      `superadmin-groups`,
      `superadmin-groups`
    );
    if (!contents) return;

    contents = contents.index("pageId");
    let getRequest = contents.openCursor();

    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        let value = cursor.value;
        let clubId = value.clubId;
        if (allClubs.filter((elem) => elem.id === clubId).length === 0) {
          allClubs.push({ id: value.clubId, label: value.clubName });
        }

        let leagueId = value.leagueId;
        if (allLeagues.filter((elem) => elem.id === leagueId).length === 0) {
          allLeagues.push({ id: value.leagueId, label: value.leagueName });
        }

        cursor.continue();
      } else {
        allClubs.sort((a, b) => a.label.localeCompare(b.label));
        allLeagues.sort((a, b) => a.label.localeCompare(b.label));
        resolve({ allClubs, allLeagues });
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching groups from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}
